import * as React from 'react';

function SvgNavigationMenuVertical(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M11.987 24.003c-1.861 0-3.375-1.514-3.375-3.375s1.514-3.375 3.375-3.375 3.375 1.514 3.375 3.375-1.514 3.375-3.375 3.375zm0-5.25c-1.034 0-1.875.841-1.875 1.875s.841 1.875 1.875 1.875 1.875-.841 1.875-1.875-.841-1.875-1.875-1.875zM11.987 6.753c-1.861 0-3.375-1.514-3.375-3.375S10.126.003 11.987.003s3.375 1.514 3.375 3.375-1.514 3.375-3.375 3.375zm0-5.25c-1.034 0-1.875.841-1.875 1.875s.841 1.875 1.875 1.875 1.875-.841 1.875-1.875-.841-1.875-1.875-1.875zM11.987 15.378a3.379 3.379 0 01-3.375-3.375c0-1.861 1.514-3.375 3.375-3.375s3.375 1.514 3.375 3.375a3.379 3.379 0 01-3.375 3.375zm0-5.25c-1.034 0-1.875.841-1.875 1.875s.841 1.875 1.875 1.875 1.875-.841 1.875-1.875-.841-1.875-1.875-1.875z" />
    </svg>
  );
}

export default SvgNavigationMenuVertical;
