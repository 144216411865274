import * as React from 'react';

function SvgImageFileCamera(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M3 24a2.252 2.252 0 01-2.25-2.25V2.25A2.252 2.252 0 013 0h15c.59 0 1.147.227 1.571.639l2.996 2.879c.435.424.683 1.012.683 1.615V21.75A2.252 2.252 0 0121 24H3zM3 1.5a.75.75 0 00-.75.75v19.5c0 .414.336.75.75.75h18a.75.75 0 00.75-.75V5.133a.754.754 0 00-.227-.537l-2.996-2.879A.747.747 0 0018 1.5H3z" />
      <path d="M7.5 18a2.252 2.252 0 01-2.25-2.25v-4.5A2.252 2.252 0 017.5 9h1.036l.543-1.085A.746.746 0 019.75 7.5h4.5c.286 0 .543.159.671.415L15.463 9H16.5a2.252 2.252 0 012.25 2.25v4.5A2.252 2.252 0 0116.5 18h-9zm0-7.5a.75.75 0 00-.75.75v4.5c0 .414.336.75.75.75h9a.75.75 0 00.75-.75v-4.5a.75.75 0 00-.75-.75H15a.746.746 0 01-.671-.415L13.787 9h-3.573l-.543 1.085A.746.746 0 019 10.5H7.5z" />
      <path d="M12 15.75a2.628 2.628 0 01-2.625-2.625A2.629 2.629 0 0112 10.5a2.628 2.628 0 012.625 2.625A2.627 2.627 0 0112 15.75zM12 12c-.62 0-1.125.505-1.125 1.125S11.38 14.25 12 14.25s1.125-.505 1.125-1.125S12.62 12 12 12z" />
    </svg>
  );
}

export default SvgImageFileCamera;
