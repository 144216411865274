import * as React from 'react';

function SvgWrench1(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M5.289 24.006a5.247 5.247 0 01-2.273-.517.75.75 0 01-.208-1.208l1.424-1.422c.144-.144.224-.336.224-.541s-.079-.397-.224-.542c-.144-.144-.337-.224-.542-.224s-.397.079-.542.224L1.723 21.2a.746.746 0 01-.656.208.75.75 0 01-.55-.417 5.279 5.279 0 010-4.565 5.266 5.266 0 013.015-2.714 5.301 5.301 0 011.772-.306 5.23 5.23 0 011.781.311l6.632-6.631a5.312 5.312 0 01.206-4.058A5.333 5.333 0 0118.717.005c.789 0 1.552.173 2.269.513a.753.753 0 01.208 1.208L19.77 3.151a.766.766 0 101.084 1.083l1.424-1.428a.756.756 0 01.66-.21.75.75 0 01.548.416 5.279 5.279 0 010 4.565 5.263 5.263 0 01-3.015 2.714 5.3 5.3 0 01-1.771.306 5.238 5.238 0 01-1.781-.311l-6.632 6.633a5.308 5.308 0 01-.206 4.058 5.266 5.266 0 01-3.012 2.717 5.214 5.214 0 01-1.78.312zm-.543-1.54a3.822 3.822 0 001.818-.181 3.776 3.776 0 002.159-1.949 3.783 3.783 0 00-.001-3.267.75.75 0 01.147-.853l7.341-7.342a.745.745 0 01.853-.147 3.774 3.774 0 002.905.153 3.777 3.777 0 002.162-1.946 3.78 3.78 0 00.332-2.188l-.549.55c-.428.428-.998.663-1.604.663s-1.175-.235-1.602-.663a2.267 2.267 0 010-3.204l.546-.547a3.823 3.823 0 00-3.978 2.128 3.786 3.786 0 00.001 3.267.752.752 0 01-.147.853l-7.341 7.34a.743.743 0 01-.854.146 3.765 3.765 0 00-2.904-.152 3.775 3.775 0 00-2.161 1.946 3.78 3.78 0 00-.332 2.191l.55-.549c.427-.427.996-.662 1.602-.662s1.175.235 1.602.663a2.268 2.268 0 010 3.205l-.545.545z" />
    </svg>
  );
}

export default SvgWrench1;
