import * as React from 'react';

function SvgSocialProfileNetwork(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M23.25 24a.749.749 0 01-.624-.334l-3-4.5a.745.745 0 01-.126-.416V13.5c0-1.188-2.292-2.797-3.75-3.802v3.189l1.649 1.322a.75.75 0 01-.938 1.171l-3.648-2.925a.818.818 0 01-.061-.055.55.55 0 00-.782-.001.553.553 0 000 .782l3.565 4.02a.748.748 0 01.189.498v2.5c0 .616.873 1.857 1.493 2.542a.749.749 0 01-.053 1.06.749.749 0 01-1.059-.053c-.184-.203-1.001-1.134-1.495-2.15a3.474 3.474 0 01-2.341.902H5.733a3.486 3.486 0 01-3.483-3.48V15a.75.75 0 011.5 0v1.5H9A.75.75 0 019 18H3.75v1.017C3.75 20.11 4.639 21 5.732 21h6.535a2 2 0 001.956-1.6v-1.416l-3.345-3.774a2.018 2.018 0 01-.57-1.419c0-.549.214-1.064.601-1.452a2.04 2.04 0 011.452-.6c.533 0 1.036.202 1.419.568l.47.377V7.5a.75.75 0 011.5 0v.371c.208.15.448.315.728.508C18.386 9.692 21 11.49 21 13.5v5.023l2.874 4.311A.751.751 0 0123.25 24zM6 1.5A.75.75 0 016 0h4.5a.75.75 0 010 1.5H6z" />
      <path d="M2.25 12.75C1.009 12.75 0 11.741 0 10.5s1.009-2.25 2.25-2.25c.647 0 1.251.275 1.675.75l1.496-.854a2.959 2.959 0 01.007-2.004L3.442 4.156A2.238 2.238 0 012.25 4.5C1.009 4.5 0 3.491 0 2.25S1.009 0 2.25 0a2.252 2.252 0 012.13 2.972l1.913 1.914a2.995 2.995 0 011.957-.732c.7 0 1.363.239 1.898.679l1.271-.847A2.953 2.953 0 0111.25 3c0-1.654 1.346-3 3-3s3 1.346 3 3-1.346 3-3 3a2.996 2.996 0 01-1.997-.767l-1.216.811a3.004 3.004 0 01-2.786 4.111 3.004 3.004 0 01-1.989-.76l-1.763 1.007.001.098c0 1.241-1.01 2.25-2.25 2.25zm0-3a.75.75 0 100 1.5.75.75 0 000-1.5zm6-4.096c-.827 0-1.5.673-1.5 1.5s.673 1.5 1.5 1.5 1.5-.673 1.5-1.5-.673-1.5-1.5-1.5zm6-4.154c-.827 0-1.5.673-1.5 1.5s.673 1.5 1.5 1.5 1.5-.673 1.5-1.5-.673-1.5-1.5-1.5zm-12 0a.75.75 0 100 1.5.75.75 0 000-1.5z" />
    </svg>
  );
}

export default SvgSocialProfileNetwork;
