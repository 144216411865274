import { css } from '@emotion/core';

const baseStyles = {};

/**
 * Search field styling
 */
const searchFieldStyles = (theme, isExpanded) => `
    .textField {
      padding: 0;
      padding-bottom: ${theme.lineHeight.$9};
        .textFieldBorder {         
          background-color: ${theme.color.light$8};
          box-shadow: none;
        }
        svg,
        input,
        input::placeholder {
          color: ${theme.color.light$4};
        }
        svg {
          fill: ${theme.color.light$4};
        }
        input:focus {
          color: ${theme.color.light};
        }
        input {
          padding-left: ${isExpanded ? theme.spacing.$3 : ``};
        }
        i {
          padding-left: ${theme.spacing.$2};
          margin-right: -${theme.spacing.$2};
        }
    }
`;

/**
 * Styles for the header menu of the tabs
 */
const sectionHeaderStyles = (theme) => `
  p {
    padding-left: ${theme.spacing.$5};
    color: ${theme.color.light$4};
  } 
`;

export const webStyles = {
  ...baseStyles,
  ...searchFieldStyles,
  ...sectionHeaderStyles,
  content: (theme, isExpanded) => css`
    ${searchFieldStyles(theme, isExpanded)}
    ${sectionHeaderStyles(theme)}
    flex-grow: 1;
    width: ${theme.percentage.full};
    ${theme.centeredStyles.flexColumn}
  `,
};

export const nativeStyles = {
  ...baseStyles,
};
