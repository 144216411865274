import * as React from 'react';

function SvgPhoneBook(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M3.75 24.013a2.252 2.252 0 01-2.25-2.25v-2.25H.75a.75.75 0 010-1.5h.75v-3H.75a.75.75 0 010-1.5h.75v-3H.75a.75.75 0 010-1.5h.75v-3H.75a.75.75 0 010-1.5h.75v-2.25A2.252 2.252 0 013.75.013h15A2.252 2.252 0 0121 2.263v19.5a2.252 2.252 0 01-2.25 2.25h-15zM3 21.763c0 .414.336.75.75.75h15a.75.75 0 00.75-.75v-19.5a.75.75 0 00-.75-.75h-15a.75.75 0 00-.75.75v2.25h.75a.75.75 0 010 1.5H3v3h.75a.75.75 0 010 1.5H3v3h.75a.75.75 0 010 1.5H3v3h.75a.75.75 0 010 1.5H3v2.25zM23.25 6.763a.75.75 0 01-.75-.75v-3a.75.75 0 011.5 0v3a.75.75 0 01-.75.75zM23.25 12.763a.75.75 0 01-.75-.75v-3a.75.75 0 011.5 0v3a.75.75 0 01-.75.75zM23.25 18.763a.75.75 0 01-.75-.75v-3a.75.75 0 011.5 0v3a.75.75 0 01-.75.75z" />
      <path d="M13.899 18.762a3.347 3.347 0 01-2.428-1.056.7.7 0 01-.071-.073 24.033 24.033 0 01-4.5-7.99c-.007-.024-.015-.049-.02-.074a3.327 3.327 0 011.572-3.83l.447-.252a1.618 1.618 0 012.197.613l1.057 1.882a1.619 1.619 0 01-.61 2.194.114.114 0 00-.045.156l1.704 3.03c.006.01.03.029.059.037a.118.118 0 00.031.005.115.115 0 00.056-.015 1.614 1.614 0 012.196.613l1.06 1.877c.436.775.162 1.76-.612 2.198l-.449.252a3.363 3.363 0 01-1.644.433zm-1.364-2.115c.351.39.848.615 1.365.615.315 0 .629-.083.908-.241l.448-.251c.052-.03.072-.1.042-.154l-1.06-1.876a.117.117 0 00-.1-.058.118.118 0 00-.055.014 1.61 1.61 0 01-2.194-.61l-1.698-3.019a1.616 1.616 0 01.613-2.197c.052-.03.072-.1.042-.154l-1.057-1.88a.115.115 0 00-.155-.042l-.446.251a1.831 1.831 0 00-.85 2.168 22.556 22.556 0 004.16 7.396.444.444 0 01.037.038z" />
    </svg>
  );
}

export default SvgPhoneBook;
