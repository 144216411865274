import * as React from 'react';

function SvgNavigationMenuHorizontal(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M3.375 15.398C1.514 15.398 0 13.884 0 12.023s1.514-3.375 3.375-3.375 3.375 1.514 3.375 3.375-1.514 3.375-3.375 3.375zm0-5.25A1.877 1.877 0 001.5 12.023c0 1.034.841 1.875 1.875 1.875s1.875-.841 1.875-1.875a1.877 1.877 0 00-1.875-1.875zM20.625 15.398c-1.861 0-3.375-1.514-3.375-3.375s1.514-3.375 3.375-3.375S24 10.162 24 12.023s-1.514 3.375-3.375 3.375zm0-5.25a1.877 1.877 0 00-1.875 1.875c0 1.034.841 1.875 1.875 1.875s1.875-.841 1.875-1.875a1.877 1.877 0 00-1.875-1.875zM12 15.398c-1.861 0-3.375-1.514-3.375-3.375S10.139 8.648 12 8.648s3.375 1.514 3.375 3.375-1.514 3.375-3.375 3.375zm0-5.25a1.877 1.877 0 00-1.875 1.875c0 1.034.841 1.875 1.875 1.875s1.875-.841 1.875-1.875A1.877 1.877 0 0012 10.148z" />
    </svg>
  );
}

export default SvgNavigationMenuHorizontal;
