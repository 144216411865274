import { mix } from 'polished';

/**
 * Object helper for better Text field styling
 */
const TextFieldStylesHelper = ({
  theme,
  hasAnError,
  isDisabled,
  isInputOnFocus,
  isTextFieldOnHover,
  isSearchMode,
  errorColor,
}) => {
  const {
    danger,
    dark,
    dark$9,
    dark$7,
    dark$6,
    dark$5,
    dark$3,
    primary,
    light,
  } = theme.color;
  return {
    getInputBorderColor: () => {
      if (!isSearchMode) {
        if (hasAnError && !isDisabled) {
          return errorColor;
        }
        if (isInputOnFocus) {
          return primary;
        }
        if (isDisabled || isTextFieldOnHover) {
          return dark$6;
        }
        return dark$5;
      }
      return 'transparent';
    },

    getBackgroundColor: () => {
      const isActive =
        !isDisabled && ((isTextFieldOnHover && !isInputOnFocus) || hasAnError);

      if (isSearchMode) {
        if (isActive) {
          return hasAnError ? mix(0.04, danger, theme.color.light) : dark$7;
        }
        return dark$9;
      }
      return 'transparent';
    },

    getBoxShadow: () => {
      const isFieldWithShadow =
        !isDisabled &&
        (isInputOnFocus || hasAnError || (!isSearchMode && isTextFieldOnHover));

      const isFieldWithSolidShadow =
        isSearchMode || isInputOnFocus || (hasAnError && !isSearchMode);
      const solidShadowColor = hasAnError ? errorColor : primary;

      if (isFieldWithShadow) {
        if (isFieldWithSolidShadow) {
          return theme.boxShadow.solidMedium(mix(0.4, solidShadowColor, light));
        }
        return theme.boxShadow.solidThin(dark$9);
      }
      return 'none';
    },

    getIconColor: () => {
      const isOnFocusOrHover = isInputOnFocus || isTextFieldOnHover;

      if (isDisabled) {
        return dark$5;
      }
      if (isSearchMode) {
        if (hasAnError) {
          return errorColor;
        }
        return isOnFocusOrHover ? dark : dark$3;
      }

      return dark;
    },

    getTextColor: () => {
      if (isDisabled) {
        return dark$5;
      }
      if (isSearchMode && hasAnError) {
        return errorColor;
      }
      return dark;
    },

    getErrorMessageColor: () => {
      if (isDisabled) {
        return dark$5;
      }
      if (hasAnError) {
        return errorColor;
      }
      if (isInputOnFocus) {
        return primary;
      }
      return dark$3;
    },
  };
};

export default TextFieldStylesHelper;
