import * as React from 'react';

function SvgHierarchyFiles(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M16.5 23.998c-.827 0-1.5-.673-1.5-1.5v-1.5h-.75a.75.75 0 010-1.5H15v-3c0-.827.673-1.5 1.5-1.5h3.441c.402 0 .779.156 1.062.441l1.059 1.06c.283.283.438.66.438 1.06v4.939c0 .827-.673 1.5-1.5 1.5h-4.5zm0-1.5H21v-4.939l-1.06-1.061H16.5v6zM16.5 10.498A1.502 1.502 0 0115 9.002V7.498h-.75a.75.75 0 010-1.5H15v-3c0-.827.673-1.5 1.5-1.5h3.441c.401 0 .778.156 1.061.44l1.06 1.061c.283.283.438.66.438 1.06V9a1.503 1.503 0 01-1.5 1.498h-4.5zM16.5 9l4.5-.002V4.059l-1.06-1.061H16.5V9zM2.25 2.998a.75.75 0 01-.75-.75v-1.5a.75.75 0 011.5 0v1.5a.75.75 0 01-.75.75zM2.25 14.998a.75.75 0 01-.75-.75v-3a.75.75 0 011.5 0v3a.75.75 0 01-.75.75zM3.75 20.998a2.252 2.252 0 01-2.25-2.25v-1.5a.75.75 0 011.5 0v1.5c0 .414.336.75.75.75h1.5a.75.75 0 010 1.5h-1.5zM8.25 20.998a.75.75 0 010-1.5h3a.75.75 0 010 1.5h-3zM2.25 8.998a.75.75 0 01-.75-.75v-3a.75.75 0 011.5 0v.75h2.25a.75.75 0 010 1.5H3v.75a.75.75 0 01-.75.75zM8.25 7.498a.75.75 0 010-1.5h3a.75.75 0 010 1.5h-3z" />
    </svg>
  );
}

export default SvgHierarchyFiles;
