import * as React from 'react';

function SvgUserLive(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M9.139 23.986a.75.75 0 01-.75-.75v-4.458a.75.75 0 011.5 0v4.458a.75.75 0 01-.75.75zM13.265 23.986a.75.75 0 01-.586-.281 5.9 5.9 0 01-1.289-3.628v-1.299a.75.75 0 011.5 0v1.293c.005.598.133 1.187.375 1.733a4.366 4.366 0 00.376-1.739v-1.287a.75.75 0 011.5 0v1.293a5.9 5.9 0 01-1.291 3.634.75.75 0 01-.585.281zM4.942 24.03a1.815 1.815 0 01-1.799-1.659c-.002-.019-.002-.04-.002-.061v-3.533a.75.75 0 011.5 0v3.493a.31.31 0 00.304.259c.032-.002.051-.002.071-.002H6.14a.75.75 0 010 1.5H5.028a.86.86 0 01-.086.003zM18.309 24.029a1.689 1.689 0 01-1.669-1.626v-2.725c.03-.931.763-1.651 1.668-1.651l.067.001h.931a.75.75 0 010 1.5h-.959c-.121 0-.206.079-.209.175v.575h1.5a.75.75 0 010 1.5h-1.5v.6c.003.077.091.15.196.15h.971a.75.75 0 010 1.5l-.996.001zM17.326 12.685a.75.75 0 01-.695-1.03c.509-1.26.511-2.65.007-3.912a5.221 5.221 0 00-2.804-2.878 5.212 5.212 0 00-4.018-.052 5.221 5.221 0 00-2.878 2.804 5.217 5.217 0 00-.052 4.018.747.747 0 01-.418.975.747.747 0 01-.975-.419 6.71 6.71 0 01.067-5.166 6.711 6.711 0 013.7-3.606 6.711 6.711 0 015.165.066 6.705 6.705 0 013.606 3.7 6.726 6.726 0 01-.01 5.03.744.744 0 01-.695.47z" />
      <path d="M18.848 15.17a.751.751 0 01-.613-1.181 7.932 7.932 0 000-9.113 7.932 7.932 0 00-6.482-3.355 7.85 7.85 0 00-4.545 1.441 7.86 7.86 0 00-3.241 5.116 7.86 7.86 0 001.327 5.91.751.751 0 01-.613 1.182.753.753 0 01-.614-.318c-1.448-2.056-2.008-4.553-1.578-7.03s1.799-4.639 3.855-6.087A9.346 9.346 0 0111.751.021a9.436 9.436 0 017.71 3.991 9.434 9.434 0 010 10.84.749.749 0 01-.613.318z" />
      <path d="M11.764 12.685c-1.861 0-3.375-1.514-3.375-3.375s1.514-3.375 3.375-3.375S15.14 7.449 15.14 9.31s-1.515 3.375-3.376 3.375zm0-5.25c-1.034 0-1.875.841-1.875 1.875s.841 1.875 1.875 1.875 1.875-.841 1.875-1.875-.84-1.875-1.875-1.875z" />
      <path d="M7.542 16.435a.752.752 0 01-.708-.996 5.198 5.198 0 013.212-3.213 5.178 5.178 0 013.991.228 5.189 5.189 0 012.661 2.984.75.75 0 01-.708.997H7.542zm7.215-1.5a3.687 3.687 0 00-1.371-1.13 3.693 3.693 0 00-2.845-.163 3.704 3.704 0 00-1.764 1.293h5.98z" />
    </svg>
  );
}

export default SvgUserLive;
