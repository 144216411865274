import * as React from 'react';

function SvgOutlook(props) {
  return (
    <svg viewBox="0 0 32 32" {...props}>
      <g data-name="STYLE = COLOR">
        <path
          d="M28.596 2H11.404A1.404 1.404 0 0010 3.404V5l9.69 3L30 5V3.404A1.404 1.404 0 0028.596 2z"
          fill="#0364b8"
        />
        <path
          d="M31.65 17.405A11.341 11.341 0 0032 16a.666.666 0 00-.333-.576l-.013-.008-.004-.002-10.838-6.175a1.499 1.499 0 00-.145-.082 1.5 1.5 0 00-1.334 0 1.49 1.49 0 00-.145.082L8.35 15.415l-.004.002-.012.007A.666.666 0 008 16a11.344 11.344 0 00.35 1.405l11.492 8.405z"
          fill="#0a2767"
        />
        <path fill="#28a8ea" d="M24 5h-7l-2.021 3L17 11l7 6h6v-6l-6-6z" />
        <path fill="#0078d4" d="M10 5h7v6h-7z" />
        <path fill="#50d9ff" d="M24 5h6v6h-6z" />
        <path fill="#0364b8" d="M24 17l-7-6h-7v6l7 6 10.832 1.768L24 17z" />
        <path fill="none" d="M10.031 5H30" />
        <path fill="#0078d4" d="M17 11h7v6h-7z" />
        <path fill="#064a8c" d="M10 17h7v6h-7z" />
        <path fill="#0078d4" d="M24 17h6v6h-6z" />
        <path
          d="M20.19 25.218l-11.793-8.6.495-.87s10.745 6.12 10.909 6.212a.528.528 0 00.42-.012l10.933-6.23.496.869z"
          fill="#0a2767"
          opacity={0.5}
        />
        <path
          d="M31.667 16.577l-.014.008-.003.002-10.838 6.174a1.497 1.497 0 01-1.46.091l3.774 5.061 8.254 1.797v.004A1.498 1.498 0 0032 28.5V16a.666.666 0 01-.333.577z"
          fill="#1490df"
        />
        <path
          d="M32 28.5v-.738l-9.983-5.688-1.205.687a1.497 1.497 0 01-1.46.091l3.774 5.061 8.254 1.797v.004A1.498 1.498 0 0032 28.5z"
          opacity={0.05}
        />
        <path
          d="M31.95 28.883L21.007 22.65l-.195.11a1.497 1.497 0 01-1.46.092l3.774 5.061 8.254 1.797v.004a1.501 1.501 0 00.57-.83z"
          opacity={0.1}
        />
        <path
          d="M8.35 16.59v-.01h-.01l-.03-.02A.65.65 0 018 16v12.5A1.498 1.498 0 009.5 30h21a1.503 1.503 0 00.37-.05.637.637 0 00.18-.06.142.142 0 00.06-.02 1.048 1.048 0 00.23-.13c.02-.01.03-.01.04-.03z"
          fill="#28a8ea"
        />
        <path
          d="M18 24.667V8.333A1.337 1.337 0 0016.667 7H10.03V14.456l-1.68.958-.005.002-.012.007A.666.666 0 008 16v.005V16 26h8.667A1.337 1.337 0 0018 24.667z"
          opacity={0.1}
        />
        <path
          d="M17 25.667V9.333A1.337 1.337 0 0015.667 8H10.03V14.456l-1.68.958-.005.002-.012.007A.666.666 0 008 16v.005V16 27h7.667A1.337 1.337 0 0017 25.667z"
          opacity={0.2}
        />
        <path
          d="M17 23.667V9.333A1.337 1.337 0 0015.667 8H10.03V14.456l-1.68.958-.005.002-.012.007A.666.666 0 008 16v.005V16 25h7.667A1.337 1.337 0 0017 23.667z"
          opacity={0.2}
        />
        <path
          d="M16 23.667V9.333A1.337 1.337 0 0014.667 8H10.03V14.456l-1.68.958-.005.002-.012.007A.666.666 0 008 16v.005V16 25h6.667A1.337 1.337 0 0016 23.667z"
          opacity={0.2}
        />
        <path
          data-name="Back Plate"
          d="M1.333 8h13.334A1.333 1.333 0 0116 9.333v13.334A1.333 1.333 0 0114.667 24H1.333A1.333 1.333 0 010 22.667V9.333A1.333 1.333 0 011.333 8z"
          fill="#0078d4"
        />
        <path
          d="M3.867 13.468a4.181 4.181 0 011.642-1.814A4.965 4.965 0 018.119 11a4.617 4.617 0 012.413.62 4.14 4.14 0 011.598 1.733 5.597 5.597 0 01.56 2.55 5.901 5.901 0 01-.577 2.666 4.239 4.239 0 01-1.645 1.794A4.8 4.8 0 017.963 21a4.729 4.729 0 01-2.468-.627 4.204 4.204 0 01-1.618-1.736 5.459 5.459 0 01-.567-2.519 6.055 6.055 0 01.557-2.65zm1.75 4.258a2.716 2.716 0 00.923 1.194 2.411 2.411 0 001.443.435 2.533 2.533 0 001.541-.449 2.603 2.603 0 00.897-1.197 4.626 4.626 0 00.286-1.665 5.063 5.063 0 00-.27-1.686 2.669 2.669 0 00-.866-1.24 2.387 2.387 0 00-1.527-.473 2.493 2.493 0 00-1.477.439 2.741 2.741 0 00-.944 1.203 4.776 4.776 0 00-.007 3.44z"
          fill="#fff"
        />
        <path fill="none" d="M0 0h32v32H0z" />
      </g>
    </svg>
  );
}

export default SvgOutlook;
