import * as React from 'react';

function SvgRobotHead1(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M15.001 10.5c-1.241 0-2.25-1.009-2.25-2.25S13.76 6 15.001 6s2.25 1.009 2.25 2.25-1.009 2.25-2.25 2.25zm0-3a.75.75 0 100 1.5.75.75 0 000-1.5zM9.001 10.5c-1.241 0-2.25-1.009-2.25-2.25S7.76 6 9.001 6s2.25 1.009 2.25 2.25-1.009 2.25-2.25 2.25zm0-3a.75.75 0 100 1.5.75.75 0 000-1.5zM15.751 19.5a.75.75 0 010-1.5h3a.75.75 0 010 1.5h-3zM15.751 22.5a.75.75 0 010-1.5h3a.75.75 0 010 1.5h-3z" />
      <path d="M2.247 24a.753.753 0 01-.742-.641l-.819-5.563a3.731 3.731 0 011.059-3.194 3.728 3.728 0 012.468-1.098c-.415-1.332-.68-2.592-.791-3.755H.751a.75.75 0 010-1.5h2.615c.044-1.866.538-3.481 1.472-4.805C6.627.905 9.711 0 12 0c2.29 0 5.374.906 7.163 3.445.932 1.323 1.427 2.938 1.471 4.805h2.617a.75.75 0 010 1.5h-2.673c-.11 1.163-.375 2.422-.791 3.755a3.756 3.756 0 013.524 4.291l-.818 5.563a.754.754 0 01-.851.633.752.752 0 01-.633-.851l.818-5.563A2.253 2.253 0 0019.6 15H4.4a2.245 2.245 0 00-1.594.662 2.238 2.238 0 00-.636 1.918l.819 5.561a.753.753 0 01-.742.859zm15.966-10.5c.543-1.627.851-3.121.915-4.443a.672.672 0 01.007-.175c.06-1.822-.343-3.36-1.198-4.573C16.478 2.239 13.913 1.5 12 1.5c-1.913 0-4.477.738-5.936 2.808-1.507 2.138-1.6 5.229-.278 9.192h12.427z" />
      <path d="M6.001 24c-.827 0-1.5-.673-1.5-1.5v-3c0-.827.673-1.5 1.5-1.5h6c.827 0 1.5.673 1.5 1.5v3c0 .827-.673 1.5-1.5 1.5h-6zm0-1.5h6v-3h-6v3z" />
    </svg>
  );
}

export default SvgRobotHead1;
