import * as React from 'react';

function SvgExpand1(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M.75 23.998a.755.755 0 01-.26-.047l-.022-.008A.756.756 0 010 23.248v-6.75a.75.75 0 011.5 0v4.939l7.72-7.72a.744.744 0 011.06 0 .744.744 0 010 1.06l-7.72 7.72H7.5a.75.75 0 010 1.5H.75zM14.25 10.498a.743.743 0 01-.53-.22c-.142-.141-.22-.33-.22-.53s.078-.389.22-.53l7.72-7.72H16.5a.75.75 0 010-1.5h6.75a.735.735 0 01.293.06.75.75 0 01.4.404l.01.026a.739.739 0 01.047.26v6.75a.75.75 0 01-1.5 0V2.559l-7.72 7.72a.746.746 0 01-.53.219zM16.5 23.998a.75.75 0 010-1.5h4.939l-7.72-7.72a.744.744 0 010-1.06.749.749 0 011.06 0l7.72 7.72v-4.939A.75.75 0 0124 16.498v6.75a.744.744 0 01-.047.258l-.006.017a.763.763 0 01-.412.419l-.026.01a.733.733 0 01-.259.047H16.5zM9.75 10.498a.743.743 0 01-.53-.22L1.5 2.559v4.939a.75.75 0 01-1.5 0V.748A.74.74 0 01.048.487L.055.466a.754.754 0 01.41-.411L.49.045a.737.737 0 01.26-.047H7.5a.75.75 0 010 1.5H2.561l7.72 7.72c.142.141.22.33.22.53s-.078.389-.22.53a.747.747 0 01-.531.22z" />
    </svg>
  );
}

export default SvgExpand1;
