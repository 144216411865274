import React from 'react';
import styled from '@emotion/styled';
import {
  getExtraProps,
  withTheme,
  removeKeysFromObject,
} from '@embracesbs/helpers';
import Ink from 'react-ink';
import Icon, {
  webDefaultProps as iconWebDefaultProps,
} from '@embracesbs/component-icon';
import Avatar, {
  webDefaultProps as avatarWebDefaultProps,
} from '@embracesbs/component-avatar';
import Text from '@embracesbs/component-text';
import Button from '@embracesbs/component-button';
import { webStyles } from './Chip.styles';
import {
  getBackGroundColor,
  getBackGroundHoverColor,
  getColor,
} from './ChipHelperStyle';
import { propTypes, defaultProps } from './Chip.props';

const StyledChip = styled.div`
  ${({ theme }) => webStyles.chip(theme)}
  ${({
    isDisabled,
    avatar,
    onClick,
    onClose,
    backgroundColor,
    color,
    hoverBackgroundColor,
    hoverColor,
    hasHoverColor,
    theme,
  }) => `
  padding: 0 ${onClose ? 0 : theme.spacing.$4} 0 ${
    avatar ? theme.spacing.$2 : theme.spacing.$4
  }; 
  cursor: ${isDisabled || !onClick ? 'default' : 'pointer'};
  color: ${color};
  background-color: ${backgroundColor};
    &:hover {      
      background-color: ${hoverBackgroundColor};
      ${
        hasHoverColor
          ? `.chip-text, .chip-icon, svg { color: ${hoverColor};}`
          : ``
      }     
    }    
  `}
`;

const StyledAvatar = styled(Avatar)`
  ${({ theme }) => webStyles.avatar(theme)}
  user-select: none;
`;

const StyledButton = styled(Button)`
  ${({ theme }) => webStyles.btnClose(theme)}
  ${({ iconColorBtn, hasHoverColor, hoverColor }) => `
    i {
      color: ${iconColorBtn};
    }
    ${hasHoverColor ? `&:hover { i { color: ${hoverColor}; }; }` : ``}  
  `}
`;

/**
 * Chip component
 * @param {string} text
 * @param {array} extraProps
 * @param {bool} isDisabled
 * @param {bool} isSelected
 * @param {bool} isError
 * @param {func} onClick
 * @param {object} avatar
 * @param {string} icon
 * @param {func} onClose
 * @param {node} children
 */
const Chip = (props) => {
  const {
    children = null,
    text,
    extraProps,
    isDisabled,
    isSelected,
    isError,
    onClick,
    avatar,
    icon,
    theme,
    onClose,
  } = props;

  const avatarObject = avatar || {};
  const iconObject = icon || {};

  const avatarProps = {
    ...avatarWebDefaultProps,
    ...{ isDisabled, size: 24 },
    ...avatarObject,
  };

  const iconProps = {
    ...iconWebDefaultProps,
    ...{
      size: theme.iconSize.$4Number,
      spacing: { right: theme.spacing.$4Number },
    },
    ...iconObject,
  };

  const color = getColor({ isDisabled, isSelected, isError }, theme);
  const backgroundColor = getBackGroundColor(
    { isDisabled, isSelected, isError },
    theme,
  );
  const hoverBackgroundColor = getBackGroundHoverColor(
    { isDisabled, isSelected, isError },
    theme,
  );

  const hasHoverColor = !isDisabled && !isSelected && !isError;
  const hoverColor = theme.color.dark;

  return (
    <StyledChip
      {...getExtraProps(props, extraProps)}
      color={color}
      backgroundColor={backgroundColor}
      hoverBackgroundColor={hoverBackgroundColor}
      isDisabled={isDisabled}
      hasHoverColor={hasHoverColor}
      hoverColor={hoverColor}
      onClick={onClick}
      avatar={avatar}
      onClose={onClose}
      tabIndex={!isDisabled ? 0 : -1}
      aria-label={text || 'Chip'}
      aria-selected={isSelected}
      {...removeKeysFromObject(props, ['data-testid'])}
    >
      {!isDisabled && onClick && <Ink background={false} />}
      {avatar && <StyledAvatar {...avatarProps} />}
      {icon && <Icon className="chip-icon" {...iconProps} />}
      {text && (
        <Text
          className="chip-text"
          textColor={color}
          content={text}
          extraProps={['style']}
          style={{ userSelect: 'none', whiteSpace: 'nowrap' }}
          {...getExtraProps(null, extraProps, 'InnerText')}
        />
      )}
      {children}
      {onClose && (
        <StyledButton
          iconColorBtn={color}
          hasHoverColor={hasHoverColor}
          hoverColor={hoverColor}
          isDisabled={isDisabled}
          isSelected={isSelected}
          onClick={onClose}
          variant="icon"
          iconName="Close"
          iconSize={14}
          aria-label={text ? `Remove ${text}` : 'Remove'}
          {...getExtraProps(null, extraProps, 'CloseButton')}
        />
      )}
    </StyledChip>
  );
};

Chip.propTypes = propTypes;
Chip.defaultProps = defaultProps;

/** @component */
export default withTheme(Chip);
