import * as React from 'react';

function SvgTableInsertRowBefore(props) {
  return (
    <svg data-name="Layer 1" viewBox="0 0 22 22" {...props}>
      <path d="M18.715 1.25H3.286A2.038 2.038 0 001.25 3.286v3.451a.75.75 0 001.5 0V3.286a.536.536 0 01.536-.536h9.964v16.5H3.286a.536.536 0 01-.536-.536v-3.451a.75.75 0 10-1.5 0v3.451a2.038 2.038 0 002.036 2.036h15.429a2.038 2.038 0 002.035-2.036V3.286a2.038 2.038 0 00-2.035-2.036zm.535 17.464a.536.536 0 01-.535.536H14.75v-7.5h4.5zm-4.5-8.464v-7.5h3.965a.536.536 0 01.535.536v6.964z" />
      <path d="M6.5 5.75a.75.75 0 00-.75.75v3.75H2a.75.75 0 000 1.5h3.75v3.75a.75.75 0 001.5 0v-3.75H11a.75.75 0 000-1.5H7.25V6.5a.75.75 0 00-.75-.75z" />
    </svg>
  );
}

export default SvgTableInsertRowBefore;
