import * as React from 'react';

function SvgPaperWrite(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M9.331 19.916a.754.754 0 01-.742-.856l.53-3.712a.754.754 0 01.212-.424l9.546-9.546c.565-.566 1.319-.878 2.121-.878s1.556.312 2.121.878a3.003 3.003 0 010 4.243l-9.546 9.546a.757.757 0 01-.424.212l-3.712.53a.786.786 0 01-.106.007zm.884-1.634l2.475-.353 9.369-9.369a1.501 1.501 0 00-2.122-2.121l-9.369 9.369-.353 2.474z" />
      <path d="M2.248 23.999a2.252 2.252 0 01-2.25-2.25v-18a2.252 2.252 0 012.25-2.25h2.25c0-.827.673-1.5 1.5-1.5h6c.827 0 1.5.673 1.5 1.5h2.25a2.252 2.252 0 012.25 2.25.75.75 0 01-1.5 0 .75.75 0 00-.75-.75h-2.25c0 .827-.673 1.5-1.5 1.5h-6c-.827 0-1.5-.673-1.5-1.5h-2.25a.75.75 0 00-.75.75v18c0 .414.336.75.75.75h13.5a.75.75 0 00.75-.75v-3a.75.75 0 011.5 0v3a2.252 2.252 0 01-2.25 2.25h-13.5zm3.75-21h6v-1.5h-6v1.5z" />
      <path d="M5.248 8.999a.75.75 0 010-1.5h7.5a.75.75 0 010 1.5h-7.5zM5.248 13.499a.75.75 0 010-1.5h3a.75.75 0 010 1.5h-3z" />
    </svg>
  );
}

export default SvgPaperWrite;
