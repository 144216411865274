import PropTypes from 'prop-types';

export const propTypes = {
  /** An array of strings which includes the extra prop keys */
  extraProps: PropTypes.array,
  /** Auto-hide when needed */
  hideTracksWhenNotNeeded: PropTypes.bool,
  /** Universal mode ensure that the initial markup on client and server are the same */
  universal: PropTypes.bool,
  /** Auto-hide setting */
  autoHide: PropTypes.bool,
  /** Auto-hide minimum */
  autoHeightMin: PropTypes.number,
  /** Callback is invoked When the scrollbar reach the bottom of the content */
  onScrollToTheBottom: PropTypes.func,
  /** The children of the scrollbar */
  children: PropTypes.node,
};

export const thumbPropTypes = {
  styles: PropTypes.object,
};

export const defaultProps = {
  extraProps: [],
  hideTracksWhenNotNeeded: true,
  universal: true,
  autoHide: true,
  autoHeightMin: 100,
  onScrollToTheBottom: () => {},
  children: null,
};
