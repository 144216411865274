import * as React from 'react';

function SvgSmileyAngel(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M12 21c-4.135 0-7.5-3.365-7.5-7.5a.75.75 0 011.5 0c0 3.308 2.692 6 6 6s6-2.692 6-6a.75.75 0 011.5 0c0 4.135-3.365 7.5-7.5 7.5zM18.493 10.5a.75.75 0 01-.707-.501 1.488 1.488 0 00-.767-.853 1.486 1.486 0 00-1.147-.061c-.43.152-.763.485-.915.915a.752.752 0 01-.707.5.744.744 0 01-.677-.427.745.745 0 01-.03-.573 2.984 2.984 0 011.829-1.829 2.981 2.981 0 012.293.122A2.98 2.98 0 0119.2 9.5a.745.745 0 01-.03.573.744.744 0 01-.677.427zM9.75 10.5a.752.752 0 01-.707-.5 1.503 1.503 0 00-1.914-.915c-.43.152-.763.485-.915.915a.752.752 0 01-.707.5.744.744 0 01-.677-.427.745.745 0 01-.03-.573 2.984 2.984 0 011.829-1.829c.322-.114.658-.172.997-.172 1.27 0 2.408.804 2.831 2.001a.751.751 0 01-.707 1z" />
      <path d="M12 24C5.383 24 0 18.617 0 12c0-2.532.816-5.018 2.307-7.063C.756 4.451 0 3.814 0 3 0 1.857 1.511 1.052 4.619.539 7.648.039 11.034 0 12 0c.966 0 4.352.039 7.381.539C22.489 1.052 24 1.857 24 3c0 .814-.756 1.451-2.307 1.937A12.027 12.027 0 0124 12c0 6.617-5.383 12-12 12zM3.9 5.331A10.491 10.491 0 001.5 12c0 5.79 4.71 10.5 10.5 10.5S22.5 17.79 22.5 12c0-2.431-.851-4.79-2.4-6.669C17.597 5.823 14.297 6 12 6s-5.597-.177-8.1-.669zm1.278-1.306c1.499.24 3.753.475 6.822.475s5.323-.235 6.822-.475C16.918 2.392 14.523 1.5 12 1.5s-4.918.892-6.822 2.525zm13.338-2.098a12.024 12.024 0 012.106 1.738c1.088-.272 1.606-.526 1.804-.666-.343-.243-1.543-.719-3.91-1.072zM1.574 2.999c.198.141.716.395 1.804.666a12.06 12.06 0 012.106-1.738c-2.367.353-3.567.829-3.91 1.072z" />
    </svg>
  );
}

export default SvgSmileyAngel;
