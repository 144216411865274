import * as React from 'react';

function SvgEmailActionReplyAll(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M23.3 22.5c-.4 0-.8-.3-.8-.8 0-3.7-3-6.8-6.8-6.8h-2.2v2.2c0 .6-.3 1.2-.7 1.6-.4.4-1 .6-1.5.6-.6 0-1.2-.3-1.6-.7l-5.5-5.9c-.8-.9-.8-2.2 0-3.1l5.5-5.9c.3-.4.9-.7 1.6-.7.6 0 1.1.2 1.6.7.4.4.7 1 .7 1.6v2.2h2.2c4.5 0 8.3 3.7 8.3 8.2v6c-.1.4-.4.8-.8.8zm-12-18c-.2 0-.4.1-.6.2l-5.5 5.9c-.3.3-.3.7 0 1l5.5 5.9c.1.2.3.2.5.2s.4-.1.5-.2c.2-.1.2-.3.2-.5v-2.9c0-.4.3-.8.8-.8h3c2.7 0 5.2 1.3 6.8 3.5v-1.3c0-3.7-3-6.8-6.8-6.8h-3c-.2 0-.4-.1-.5-.2-.1.1-.2-.1-.2-.3V5.3c0-.2-.1-.4-.2-.5-.2-.2-.3-.3-.5-.3z" />
      <path d="M6.6 18.9c-.2 0-.4-.1-.6-.2L.5 12.8c-.8-.9-.8-2.2 0-3.1L6 3.8c.1-.2.3-.2.6-.2.2 0 .4.1.5.2.3.3.3.8 0 1.1l-5.5 5.9c-.3.3-.3.7 0 1l5.5 5.9c.3.3.3.8 0 1.1-.1 0-.3.1-.5.1z" />
    </svg>
  );
}

export default SvgEmailActionReplyAll;
