import * as React from 'react';

function SvgFileRar(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M11.249 23.998a.754.754 0 01-.643-.364l-1.607-2.678v2.292a.75.75 0 01-1.5 0v-7.5a.75.75 0 01.75-.75h1.125a2.628 2.628 0 012.625 2.625 2.627 2.627 0 01-1.764 2.478l1.657 2.761c.103.172.133.373.084.568s-.17.358-.342.461a.742.742 0 01-.385.107zm-1.875-5.25c.62 0 1.125-.505 1.125-1.125s-.505-1.125-1.125-1.125h-.375v2.25h.375zM23.249 23.998a.754.754 0 01-.643-.364l-1.607-2.678v2.292a.75.75 0 01-1.5 0v-7.5a.75.75 0 01.75-.75h1.125a2.627 2.627 0 012.625 2.625 2.627 2.627 0 01-1.763 2.478l1.656 2.761a.748.748 0 01-.643 1.136zm-1.875-5.25c.62 0 1.125-.505 1.125-1.125s-.505-1.125-1.125-1.125h-.375v2.25h.375zM17.249 23.998a.75.75 0 01-.75-.75v-2.25h-1.5v2.25a.75.75 0 01-1.5 0v-6c0-1.241 1.009-2.25 2.25-2.25s2.25 1.009 2.25 2.25v6a.75.75 0 01-.75.75zm-.75-4.5v-2.25a.75.75 0 00-1.5 0v2.25h1.5z" />
      <path d="M2.249 23.998a2.252 2.252 0 01-2.25-2.25v-19.5a2.252 2.252 0 012.25-2.25h10.629c.601 0 1.165.234 1.59.658l5.872 5.872c.425.425.659.99.659 1.59v4.629a.75.75 0 01-1.5 0v-3.75h-5.25a2.252 2.252 0 01-2.25-2.25v-5.25h-9.75a.75.75 0 00-.75.75v19.5c0 .414.336.75.75.75h3a.75.75 0 010 1.5h-3zm11.25-17.25c0 .414.336.75.75.75h4.939l-5.689-5.689v4.939z" />
    </svg>
  );
}

export default SvgFileRar;
