import * as React from 'react';

function SvgFileCode(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M3 24.003a2.252 2.252 0 01-2.25-2.25v-19.5A2.252 2.252 0 013 .003h15.045c.591 0 1.149.228 1.572.64l2.955 2.882a2.26 2.26 0 01.678 1.611v16.616a2.252 2.252 0 01-2.25 2.25H3zm0-22.5a.75.75 0 00-.75.75v19.5c0 .414.336.75.75.75h18a.75.75 0 00.75-.75V5.136a.757.757 0 00-.226-.538L18.57 1.717a.747.747 0 00-.525-.214H3z" />
      <path d="M14.295 17.253a.752.752 0 01-.53-1.281l3.22-3.22-3.22-3.22c-.142-.141-.22-.33-.22-.53s.078-.389.22-.53a.749.749 0 011.06 0l3.75 3.75a.752.752 0 010 1.061l-3.75 3.75a.743.743 0 01-.53.22zM9.795 17.253a.743.743 0 01-.53-.22l-3.75-3.75a.752.752 0 010-1.061l3.75-3.75a.744.744 0 011.06 0c.142.141.22.33.22.53s-.078.389-.22.53l-3.22 3.22 3.22 3.22a.752.752 0 01-.53 1.281z" />
    </svg>
  );
}

export default SvgFileCode;
