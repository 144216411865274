import * as React from 'react';

function SvgPinRemove(props) {
  return (
    <svg
      id="pin-remove_svg__Laag_1"
      x={0}
      y={0}
      viewBox="0 0 24 24"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {
          '.pin-remove_svg__st0{fill:none;stroke:#000;stroke-width:1.5;stroke-linecap:round;stroke-linejoin:round}'
        }
      </style>
      <path d="M17.2 13.5c-.2 0-.5-.1-.6-.3-.2-.3-.1-.8.2-1l5.4-3.4c.3-.2.5-.7.2-1l-.1-.1-5.8-5.8c-.3-.3-.8-.3-1.1 0l-.1.1-3.4 5.4c-.2.3-.7.5-1 .2-.3-.2-.5-.7-.2-1L14 1c.1-.1.2-.3.3-.4.8-.9 2.3-.9 3.2 0l5.8 5.8c.1.1.2.2.3.4.7 1 .4 2.4-.7 3.1l-5.4 3.4c0 .2-.2.2-.3.2zM13.9 20.5c-.6 0-1.2-.2-1.6-.7l-8.2-8.2c-.1-.1-.3-.3-.4-.5-.3-.5-.4-1.1-.2-1.7.2-.6.5-1.1 1-1.4.4-.2.8-.1 1 .3.2.4.1.8-.3 1 0 .2-.2.4-.2.6-.1.2 0 .4.1.6 0 .1.1.1.1.2l8.2 8.2c.3.3.8.3 1.1 0l.1-.1c.2-.4.7-.5 1-.3.4.2.5.7.3 1-.1.2-.2.3-.4.5-.4.3-1 .5-1.6.5z" />
      <path d="M.8 24c-.2 0-.4-.1-.5-.2-.3-.3-.3-.8 0-1.1l8-8c.3-.3.8-.3 1.1 0s.3.8 0 1.1l-8 8c-.3.1-.5.2-.6.2zM21.8 22.5c-.2 0-.4-.1-.5-.2L1.7 2.8c-.1-.2-.2-.3-.2-.6s.1-.4.2-.5c.2-.1.3-.2.5-.2s.4.1.5.2l19.5 19.5c.1.1.2.3.2.5s-.1.4-.2.5-.2.3-.4.3z" />
    </svg>
  );
}

export default SvgPinRemove;
