import { mix } from 'polished';

/**
 * Get color of the chip
 *
 * @param {object} attributes
 * @param {object} theme
 */
export const getColor = (attributes, theme) => {
  const { isDisabled, isSelected, isError } = attributes;

  if (isDisabled) return theme.color.dark$5;
  if (isSelected || isError) return theme.color.light;
  return theme.color.dark$3;
};

/**
 * Get background color for the Chip
 *
 * @param {object} attributes
 * @param {*} theme
 */
export const getBackGroundColor = (attributes, theme) => {
  const { isDisabled, isSelected, isError } = attributes;

  if (isDisabled) return theme.color.dark$9;
  if (isSelected) return theme.color.primary;
  if (isError) return theme.color.danger;
  return theme.color.dark$7;
};

/**
 *
 * @param {object} attributes
 * @param {*} theme
 */
export const getBackGroundHoverColor = (attributes, theme) => {
  const { isDisabled, isSelected, isError } = attributes;

  if (isDisabled) return theme.color.dark$9;
  if (isSelected) return theme.color.primary$2;
  if (isError) return mix(0.8, theme.color.danger, theme.color.light);
  return theme.color.dark$6;
};

/**
 * Only for native
 *
 * @param {object} attributes
 * @param {*} theme
 */
export const getIconColor = (attributes, theme) => {
  const { isDisabled, isSelected, isError } = attributes;

  if (isSelected || isError) return theme.color.light;
  if (isDisabled) return theme.color.dark$5;
  return theme.color.dark$3;
};
