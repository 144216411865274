import * as React from 'react';

function SvgTaskListText1(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M7.5 11.26a.75.75 0 010-1.5h9a.75.75 0 010 1.5h-9zM7.5 15.01a.75.75 0 010-1.5h9a.75.75 0 010 1.5h-9zM7.5 18.76a.75.75 0 010-1.5h9a.75.75 0 010 1.5h-9z" />
      <path d="M4.5 24.01a2.252 2.252 0 01-2.25-2.25V6.01A2.252 2.252 0 014.5 3.76h3.063C7.924 1.631 9.81.01 12 .01c2.19 0 4.076 1.621 4.437 3.75H19.5a2.252 2.252 0 012.25 2.25v15.75a2.252 2.252 0 01-2.25 2.25h-15zm0-18.75a.75.75 0 00-.75.75v15.75c0 .414.336.75.75.75h15a.75.75 0 00.75-.75V6.01a.75.75 0 00-.75-.75h-3.75a.75.75 0 01-.75-.75c0-1.654-1.346-3-3-3s-3 1.346-3 3a.75.75 0 01-.75.75H4.5z" />
      <circle cx={12} cy={4.135} r={1.125} />
    </svg>
  );
}

export default SvgTaskListText1;
