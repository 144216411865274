import React from 'react';
import styled from '@emotion/styled';
import {
  getExtraProps,
  withTheme,
  getSpacingString,
} from '@embracesbs/helpers';
import Text from '@embracesbs/component-text';
import { propTypes, defaultProps } from './TextFieldWrapper.props';
import { webStyles } from './TextFieldWrapper.styles';

const TextFieldWrapperContainer = styled.label`
  ${({ theme, isFullHeight }) =>
    webStyles.textFieldWrapperContainer(theme, isFullHeight)}
  ${({ spacing, isTopLabel }) => `    
    padding: ${spacing};
    flex-direction: ${isTopLabel ? 'column' : 'row'};
  `}
`;

const StyledText = styled.div`
  ${() => webStyles.styledText()}
`;

/**
 * Text Field Wrapper web component
 *
 * @param {array} extraProps - An array of strings which includs the extra prop keys
 * @param {node} children - The buttons to be shown in the group
 * @param {number|object} spacing - The spacing between the inner elements
 * @param {boolean} isTopLabel - positioning of the text
 * @param {string} labelText - Text label
 * @param {boolean} isFullHeight - if the wrapper needs to have 100% height
 * @param {boolean} isRequired - Whether the wrapped field is required
 */
const TextFieldWrapper = (props) => {
  const {
    theme,
    extraProps,
    children,
    spacing,
    isTopLabel,
    labelText,
    isFullHeight,
    isRequired,
  } = props;
  const currentSpacingString = getSpacingString(spacing, defaultProps.spacing);
  const isDisabled = React.Children.toArray(children).some(
    (child) => child.props && child.props.isDisabled,
  );

  return (
    <TextFieldWrapperContainer
      {...getExtraProps(props, extraProps)}
      spacing={currentSpacingString}
      isTopLabel={isTopLabel}
      isFullHeight={isFullHeight}
    >
      {labelText && (
        <StyledText>
          <Text
            content={`${labelText}${isRequired ? ' *' : ''}`}
            spacing={{
              bottom: isTopLabel && theme.spacing.$2Number,
              right: !isTopLabel && theme.spacing.$4Number,
            }}
            {...(isDisabled ? { textColor: theme.color.dark$5 } : {})}
          />
        </StyledText>
      )}
      {children}
    </TextFieldWrapperContainer>
  );
};

TextFieldWrapper.propTypes = propTypes;
TextFieldWrapper.defaultProps = defaultProps;

export default withTheme(TextFieldWrapper);
