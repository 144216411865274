import * as React from 'react';

function SvgStrategySplit(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M12 24.001a.75.75 0 01-.75-.75v-1.3c0-3.81-1.734-6.226-5.45-7.58l-.502 1.209a1.31 1.31 0 01-1.383.793 1.304 1.304 0 01-.863-.505L.259 12.16a1.303 1.303 0 01.862-2.076l4.598-.647a1.298 1.298 0 01.676.085c.322.133.574.383.708.705.134.322.135.676.002.999l-.731 1.76c2.212.817 3.82 1.984 4.875 3.543V6.75H9.651c-.349 0-.676-.136-.922-.382a1.296 1.296 0 01-.38-.922c0-.233.063-.462.181-.661L10.872.632a1.303 1.303 0 012.251-.014l2.358 4.179c.171.29.219.639.132.976a1.305 1.305 0 01-1.262.977H12.75v9.778c1.055-1.558 2.664-2.726 4.875-3.543l-.73-1.757a1.308 1.308 0 011.384-1.792l4.601.647a1.305 1.305 0 01.859 2.078l-2.791 3.706a1.303 1.303 0 01-2.247-.285l-.501-1.211c-3.716 1.354-5.45 3.77-5.45 7.58v1.3a.75.75 0 01-.75.75zm7.957-9.311l2.386-3.168-3.931-.553 1.545 3.721zm-15.913 0l1.545-3.721-3.931.553 2.386 3.168zm9.969-9.44L12 1.682 9.989 5.25h4.024z" />
    </svg>
  );
}

export default SvgStrategySplit;
