import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { getExtraProps, getSpacingString } from '@embracesbs/helpers';
import imageStyles from './Image.styles';
import { webPropTypes, webDefaultProps } from './Image.props';

/**
 * Shared styles for different elements
 * @param {string} imageShape
 * @param {string} fit
 */
const StyledElement = (imageShape, fit, margin) => `
  ${imageStyles.shape[imageShape]}
  ${fit === 'fit' ? `object-fit: cover;` : ``}
  ${fit === 'stretch' ? `object-fit: fill;` : ``}
  ${fit === 'center' ? `object-fit: contain;` : ``}
  margin: ${margin};
`;

const StyledImage = styled.img`
  ${({ fit, imageShape, margin }) => `
    ${StyledElement(imageShape, fit, margin)}
  `}
`;

const StyledSource = styled.source`
  ${({ fit, imageShape, margin }) => `
    ${StyledElement(imageShape, fit, margin)}
  `}
`;

/**
 * check if file in src is a WebP file
 */
const isWebP = (filename) => {
  const extension = filename.slice(filename.lastIndexOf('.'));
  return /\.(webp\b)/i.test(extension);
};

/** check if src errors */
const useFallbackSrc = (src, fallbackSrc) => {
  const [imgSrc, setImgSrc] = useState(src);

  useEffect(() => {
    setImgSrc(src);
  }, [src]);

  /** onError function */
  const onError = () => {
    setImgSrc(fallbackSrc);
  };

  return [imgSrc, onError];
};

/**
 * Image component
 * @param {string} alt - Alt text of the image
 * @param {string} fit - The fit of the image
 * @param {array} extraProps - An array of strings which includes the extra prop keys
 * @param {string} imageShape - The shape of the image
 * @param {string|number} height - The height of the image
 * @param {string} src - Image source
 * @param {string} fallbackSrc - Fallback image source (optional)
 * @param {string|number} width - The width of the image
 * @param {number|object} spacing - The spacing around the Image
 * @param {func} onLoad - onLoad function
 */
const Image = (props) => {
  const {
    alt,
    fit,
    extraProps,
    imageShape,
    height,
    src,
    fallbackSrc,
    width,
    spacing,
    onLoad,
    forwardedRef,
  } = props;

  const sharedProps = {
    alt,
    imageShape,
    height,
    width,
    fit,
    margin: getSpacingString(spacing, webDefaultProps.spacing),
    onLoad,
  };

  if (typeof src !== 'string') return null;

  const [imgSrc, onError] = useFallbackSrc(src, fallbackSrc);

  if (isWebP(src) && fallbackSrc) {
    return (
      <picture>
        <StyledSource
          {...getExtraProps(props, extraProps)}
          type="image/webp"
          srcSet={imgSrc}
          {...sharedProps}
        />
        <StyledImage
          {...getExtraProps(props, extraProps)}
          src={fallbackSrc}
          onError={onError}
          ref={forwardedRef}
          {...sharedProps}
        />
      </picture>
    );
  }
  return (
    <StyledImage
      {...getExtraProps(props, extraProps)}
      type={isWebP(src) ? 'image/webp' : null}
      src={imgSrc}
      onError={onError}
      ref={forwardedRef}
      {...sharedProps}
    />
  );
};

Image.propTypes = webPropTypes;
Image.defaultProps = webDefaultProps;

/** @component */
export default Image;
