import * as React from 'react';

function SvgPhoneActionsReceive(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M15.16 23.646a5.26 5.26 0 01-2.826-.829l-.036-.024A41.177 41.177 0 011.166 11.642C-.157 9.565.14 6.882 1.894 5.128l.63-.629a2.234 2.234 0 011.59-.658c.602 0 1.167.234 1.591.658L8.357 7.15c.425.425.659.99.658 1.592 0 .602-.234 1.167-.659 1.591a.75.75 0 000 1.06l4.244 4.243c.141.141.33.219.53.219a.744.744 0 00.53-.219c.425-.425.99-.658 1.592-.658s1.167.234 1.591.658l2.644 2.644c.433.433.667.998.667 1.599s-.235 1.166-.66 1.591l-.628.629a5.204 5.204 0 01-3.706 1.547zM4.116 5.341a.744.744 0 00-.53.219l-.631.63a3.728 3.728 0 00-.511 4.667 39.666 39.666 0 0010.703 10.699l.023.016a3.705 3.705 0 001.984.575c.996 0 1.938-.393 2.652-1.108l.629-.629a.745.745 0 00.22-.531.745.745 0 00-.215-.526l-2.656-2.656c-.142-.142-.33-.219-.531-.219s-.389.078-.53.219c-.425.425-.99.658-1.592.658a2.238 2.238 0 01-1.591-.658l-4.244-4.243a2.253 2.253 0 010-3.182.75.75 0 00.001-1.061l-2.651-2.65a.743.743 0 00-.53-.22z" />
      <path d="M13.107 11.637a.735.735 0 01-.261-.048l-.032-.012a.75.75 0 01-.4-.404l-.01-.026a.733.733 0 01-.047-.259v-6a.75.75 0 011.5 0v4.189l8.47-8.47a.744.744 0 011.06 0c.142.141.22.33.22.53s-.078.389-.22.53l-8.47 8.47h4.189a.75.75 0 010 1.5h-5.999z" />
    </svg>
  );
}

export default SvgPhoneActionsReceive;
