import * as React from 'react';

function SvgParagraphJustifiedAlign(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M.75 3.763a.75.75 0 010-1.5h22.5a.75.75 0 010 1.5H.75zM.75 8.263a.75.75 0 010-1.5h22.5a.75.75 0 010 1.5H.75zM.75 12.763a.75.75 0 010-1.5h22.5a.75.75 0 010 1.5H.75zM.75 17.263a.75.75 0 010-1.5h22.5a.75.75 0 010 1.5H.75zM.75 21.763a.75.75 0 010-1.5h18a.75.75 0 010 1.5h-18z" />
    </svg>
  );
}

export default SvgParagraphJustifiedAlign;
