import * as React from 'react';

function SvgMinus(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M12.8 12.8H.8c-.5 0-.8-.4-.8-.8s.3-.8.8-.8h12.1" />
      <path d="M12.8 11.2h10.5c.4 0 .8.3.8.8s-.3.8-.8.8H12.8" />
    </svg>
  );
}

export default SvgMinus;
