import * as React from 'react';

function SvgHierarchy6(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M17.25 23.998a2.252 2.252 0 01-2.25-2.25v-4.5a2.252 2.252 0 012.25-2.25h1.5v-.75a.75.75 0 00-.75-.75H6a.75.75 0 00-.75.75v.75h1.5A2.252 2.252 0 019 17.248v4.5a2.252 2.252 0 01-2.25 2.25h-4.5A2.252 2.252 0 010 21.748v-4.5a2.252 2.252 0 012.25-2.25h1.5v-.75A2.252 2.252 0 016 11.998h5.25v-3h-1.5a2.252 2.252 0 01-2.25-2.25v-4.5a2.252 2.252 0 012.25-2.25h4.5a2.252 2.252 0 012.25 2.25v4.5a2.252 2.252 0 01-2.25 2.25h-1.5v3H18a2.252 2.252 0 012.25 2.25v.75h1.5a2.252 2.252 0 012.25 2.25v4.5a2.252 2.252 0 01-2.25 2.25h-4.5zm0-7.5a.75.75 0 00-.75.75v4.5c0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75v-4.5a.75.75 0 00-.75-.75h-4.5zm-15 0a.75.75 0 00-.75.75v4.5c0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75v-4.5a.75.75 0 00-.75-.75h-4.5zm7.5-15a.75.75 0 00-.75.75v4.5c0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75v-4.5a.75.75 0 00-.75-.75h-4.5z" />
    </svg>
  );
}

export default SvgHierarchy6;
