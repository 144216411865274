import PropTypes from 'prop-types';
import { theme } from '@embracesbs/helpers';

export const ColorEnum = {
  0: theme.color.primary,
  1: theme.color.secondary,
  2: theme.color.support1,
  3: theme.color.support2,
  4: theme.color.support3,
  5: theme.color.support4,
  6: theme.color.support5,
  7: theme.color.support6,
  8: theme.color.support7,
  9: theme.color.support8,
};

const basePropTypes = {
  /** An array of strings which includes the extra prop keys */
  extraProps: PropTypes.array,
  /** The source for the image */
  source: PropTypes.string,
  /** Alternative text should the image fail to load */
  alt: PropTypes.string,
  /** The size of the avatar */
  size: PropTypes.number,
  /** Whether the avatar should be round */
  isRound: PropTypes.bool,
  /** The spacing around the Avatar */
  spacing: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.shape({
      bottom: PropTypes.number,
      left: PropTypes.number,
      right: PropTypes.number,
      top: PropTypes.number,
    }),
  ]),
};

export const webPropTypes = {
  ...basePropTypes,
  /** Indicates the avatar is disabled */
  isDisabled: PropTypes.bool,
  /** Alternative letter for image */
  letter: PropTypes.string,
  /** Indicates that the avatar is for a team */
  isTeam: PropTypes.bool,
  /** Variant of the avatar */
  variant: PropTypes.oneOf(['letter', 'image', 'skeleton', 'icon']),
  /** User online status */
  status: PropTypes.oneOf(['online', 'offline', 'away', 'busy']),
  /** Indicates a click interaction */
  onClick: PropTypes.func,
  /** Custom icon name only works for type icon */
  iconName: PropTypes.string,
  /** Custom icon background color */
  iconBackground: PropTypes.string,
  /** Color id */
  colorId: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6, 7, 8, 9]),
  /** To determine if the Avatar should show a loader */
  hasLoader: PropTypes.bool,
};

export const nativePropTypes = {
  ...basePropTypes,
  /** Whether the avatar should have an online dot beside it */
  isOnline: PropTypes.bool,
};

const baseDefaultProps = {
  extraProps: [],
  source: '',
  alt: 'Avatar Image',
  size: 32,
  isRound: true,
  spacing: 0,
};

export const webDefaultProps = {
  ...baseDefaultProps,
  isDisabled: false,
  letter: null,
  isTeam: false,
  variant: 'icon',
  status: null,
  onClick: null,
  iconName: null,
  iconBackground: null,
  colorId: null,
  hasLoader: false,
};

export const nativeDefaultProps = {
  ...baseDefaultProps,
  isOnline: false,
};
