import * as React from 'react';

function SvgSmileyCrying(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M12 24C5.383 24 0 18.617 0 12S5.383 0 12 0s12 5.383 12 12-5.383 12-12 12zm0-22.5C6.21 1.5 1.5 6.21 1.5 12S6.21 22.5 12 22.5 22.5 17.79 22.5 12 17.79 1.5 12 1.5z" />
      <path d="M18.048 18.75a.747.747 0 01-.672-.417 5.976 5.976 0 00-5.389-3.337c-.926 0-1.819.21-2.654.623a6.037 6.037 0 00-2.713 2.713.745.745 0 01-1.005.34.746.746 0 01-.338-1.005 7.539 7.539 0 013.39-3.391 7.416 7.416 0 013.319-.78 7.466 7.466 0 016.734 4.171.746.746 0 01-.339 1.005.754.754 0 01-.333.078zM5.732 9.518a.757.757 0 01-.714-.52.74.74 0 01.047-.572 4.484 4.484 0 013.958-2.415c.294 0 .589.03.877.088a.751.751 0 01-.149 1.485.75.75 0 01-.148-.015 2.942 2.942 0 00-.583-.059 2.968 2.968 0 00-2.62 1.6.747.747 0 01-.668.408zM18.268 9.518a.747.747 0 01-.668-.408 2.968 2.968 0 00-2.621-1.599c-.194 0-.389.02-.58.058a.753.753 0 01-.884-.586.748.748 0 01.586-.884c.288-.058.583-.088.877-.088 1.653 0 3.207.948 3.958 2.415a.746.746 0 01-.668 1.092zM5.25 14.018a.75.75 0 01-.75-.75v-.75a.75.75 0 011.5 0v.75a.75.75 0 01-.75.75zM18.75 14.018a.75.75 0 01-.75-.75v-.75a.75.75 0 011.5 0v.75a.75.75 0 01-.75.75z" />
    </svg>
  );
}

export default SvgSmileyCrying;
