import * as React from 'react';

function SvgListBullets(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M3 5.998c-1.654 0-3-1.346-3-3s1.346-3 3-3 3 1.346 3 3-1.346 3-3 3zm0-4.5c-.827 0-1.5.673-1.5 1.5s.673 1.5 1.5 1.5 1.5-.673 1.5-1.5-.673-1.5-1.5-1.5zM3 14.998c-1.654 0-3-1.346-3-3s1.346-3 3-3 3 1.346 3 3-1.346 3-3 3zm0-4.5c-.827 0-1.5.673-1.5 1.5s.673 1.5 1.5 1.5 1.5-.673 1.5-1.5-.673-1.5-1.5-1.5zM3 23.998c-1.654 0-3-1.346-3-3s1.346-3 3-3 3 1.346 3 3-1.346 3-3 3zm0-4.5c-.827 0-1.5.673-1.5 1.5s.673 1.5 1.5 1.5 1.5-.673 1.5-1.5-.673-1.5-1.5-1.5zM8.25 4.498a.75.75 0 010-1.5h15a.75.75 0 010 1.5h-15zM8.25 13.498a.75.75 0 010-1.5h15a.75.75 0 010 1.5h-15zM8.25 22.498a.75.75 0 010-1.5h15a.75.75 0 010 1.5h-15z" />
    </svg>
  );
}

export default SvgListBullets;
