import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import CircularProgress from '@embracesbs/component-circularprogress';
import Text from '@embracesbs/component-text';
import Icon, { allPossibleIconSizes } from '@embracesbs/component-icon';
import Button from '@embracesbs/component-button';
import Animation from '@embracesbs/component-animation';
import Badge from '@embracesbs/component-badge';
import BadgeWrapper from '@embracesbs/component-badgewrapper';
import {
  getExtraProps,
  withTheme,
  removeKeysFromObject,
  getSpacingString,
  readableColor,
} from '@embracesbs/helpers';
import { webPropTypes, webDefaultProps, ColorEnum } from './Avatar.props';
import { webStyles } from './Avatar.styles';

const AvatarContainer = styled.div`
  ${({ size, isDisabled, margin }) => `
    ${webStyles.avatarContainer(size, isDisabled, margin)}
  `}
`;

const LoadingContainer = styled.div`
  ${webStyles.loadingContainer()}
`;

const AvatarImage = styled.img`
  ${({ theme, size, isRound, isDisabled, iconBackground, variant }) => `
    ${webStyles.avatarSizeAndBorder(
      theme,
      size,
      isRound,
      isDisabled,
      iconBackground,
      variant,
    )};
  `};
`;

const AvatarLetter = styled(Text)`
  ${({
    theme,
    size,
    isRound,
    isDisabled,
    iconBackground,
    variant,
    content,
  }) => `
    ${webStyles.avatarSizeAndBorder(
      theme,
      size,
      isRound,
      isDisabled,
      iconBackground,
      variant,
      content,
    )};
  `}
`;

const buttonDefault = css`
  width: 100%;
  height: 100%;
`;

const StyledButton = styled(Button)`
  ${buttonDefault}
  ${({ theme, iconBackground }) => `
    ${webStyles.avatarButton(theme, iconBackground)};
  `};
`;

const StyledIcon = styled(Icon)`
  ${() => `
    ${webStyles.iconButton()};
  `}
`;

const StyledAnimationWrapper = styled.div`
  ${({ theme }) => `
    ${webStyles.avatarSkeleton(theme)};
  `}
`;

/**
 * Avatar web component
 * @param {array} extraProps - An array of strings which includes the extra prop keys
 * @param {string} source - The source for the image
 * @param {func} onClick - Add on onClick function on the Avatar
 * @param {string} alt - Alternative text should the image fail to load
 * @param {number} size - The size of the avatar
 * @param {boolean} isRound - Whether the avatar should be round
 * @param {boolean} isDisabled - Indicates the avatar is disabled
 * @param {string} letter - The letter which will be presented instead of an image
 * @param {bool} isTeam - Indicates that the avatar is used for a team
 * @param {string} status - Indicates the online status of the user
 * @param {string} variant - Indicates the variant of the avatar needs to be one of image, letter, default or skeleton
 * @param {string} iconName - Custom icon name only works for type icon
 * @param {string} iconBackground - Custom icon backgroundColor
 * @param {number} colorId - Id to determine which color to use for the avatar
 * @param {boolean} hasLoader - To determine if the Avatar should show a loader
 * @param {number|object} spacing - The spacing around the Avatar
 */
const Avatar = (props) => {
  const {
    onClick,
    theme,
    extraProps,
    source,
    alt,
    size,
    isRound,
    isDisabled,
    letter,
    variant,
    status,
    isTeam,
    iconName,
    iconBackground,
    colorId,
    hasLoader,
    spacing,
  } = props;

  const [loading, setLoading] = useState((!letter || source) && hasLoader);

  if (hasLoader) {
    useEffect(() => {
      if (!source) {
        setLoading(false);
      }
    }, [source, letter]);
  }

  /** Get a color based on the id provided */
  const getColorFromId = (id) => {
    const number = parseInt(id, 10);
    if (number >= 0 && number <= 9) return ColorEnum[number];
    return theme.color.dark$5;
  };

  /** Get closest number of the icon size array */
  const getIconSize = (goal) => {
    /** Get the closest number */
    function geNumber(prev, curr) {
      return Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev;
    }

    const closest = allPossibleIconSizes.reduce(geNumber);
    return closest;
  };

  /** Get the correct icon name */
  const getIconName = () => {
    // Check if the avatar belongs to a team
    if (isTeam) return 'MultipleNeutral2';
    // Check if there is a custom icon name given to the prop
    if (iconName) return iconName;

    // Default return icon for users
    return 'SingleNeutral';
  };

  /** Create archived or deleted avatar */
  const handleAvatar = () => {
    switch (variant) {
      case 'letter':
        return (
          <AvatarLetter
            {...getExtraProps(props, extraProps)}
            content={letter}
            size={size}
            isRound={isRound}
            isDisabled={isDisabled}
            // eslint-disable-next-line react/prop-types
            iconBackground={iconBackground || getColorFromId(colorId)}
            align="center"
            textColor={readableColor(
              // eslint-disable-next-line react/prop-types
              theme.color.light,
              iconBackground || getColorFromId(colorId),
            )}
          />
        );
      case 'image':
        return (
          <AvatarImage
            {...getExtraProps(props, extraProps)}
            src={source}
            variant={variant}
            alt={alt}
            size={size}
            isRound={isRound}
            isDisabled={isDisabled}
            onLoad={() => setLoading(false)}
          />
        );
      case 'skeleton':
        return (
          <StyledAnimationWrapper>
            <Animation
              animation="skeleton"
              animationHeight="100%"
              animationWidth="100%"
            />
          </StyledAnimationWrapper>
        );
      case 'icon':
      default:
        return (
          <StyledIcon
            spacing={size / 3.5}
            isRounded
            iconName={getIconName()}
            size={getIconSize(Math.round(size / 2.5))}
            // eslint-disable-next-line react/prop-types
            background={iconBackground || getColorFromId(colorId)}
            color={readableColor(
              // eslint-disable-next-line react/prop-types
              theme.color.light,
              iconBackground || getColorFromId(colorId),
            )}
          />
        );
    }
  };

  const badge = <Badge variant="status" size={size / 3.3} status={status} />;
  const Wrapper =
    !isDisabled && !isTeam && status && variant !== 'skeleton'
      ? BadgeWrapper
      : ({ children }) => children;
  return (
    <AvatarContainer
      iconBackground={iconBackground}
      size={size}
      isDisabled={isDisabled}
      margin={getSpacingString(spacing, webDefaultProps.spacing)}
      {...removeKeysFromObject(props, ['data-testid'])}
    >
      <Wrapper bottomRight badge={badge}>
        {loading && (
          <LoadingContainer>
            <CircularProgress
              size={size < 32 ? size : 32}
              progressColor={theme.color.dark}
            />
          </LoadingContainer>
        )}
        {onClick && !isDisabled ? (
          <>
            <StyledButton
              iconBackground={iconBackground}
              iconName={iconName}
              iconSize={getIconSize(Math.round(size / 2.5))}
              variant="round"
              onClick={onClick}
              bgColor="transparent"
              text=" "
            />
            {variant !== 'icon' && handleAvatar()}
          </>
        ) : (
          handleAvatar()
        )}
      </Wrapper>
    </AvatarContainer>
  );
};

Avatar.propTypes = webPropTypes;
Avatar.defaultProps = webDefaultProps;

export default withTheme(Avatar);
