import * as React from 'react';

function SvgFileSql(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M2.249 24.004a2.252 2.252 0 01-2.25-2.25v-19.5a2.252 2.252 0 012.25-2.25h10.628c.601 0 1.166.234 1.591.658l5.871 5.871c.419.419.66.999.66 1.591v3.129a.75.75 0 01-1.5 0v-2.25h-5.25a2.252 2.252 0 01-2.25-2.25v-5.25h-9.75a.75.75 0 00-.75.75v19.5c0 .414.336.75.75.75h3a.75.75 0 010 1.5h-3zm11.25-17.25c0 .414.336.75.75.75h4.939l-5.689-5.689v4.939zM21.749 22.504a2.252 2.252 0 01-2.25-2.25v-6a.75.75 0 011.5 0v6c0 .414.336.75.75.75h1.5a.75.75 0 010 1.5h-1.5z" />
      <path d="M8.249 22.504a.75.75 0 010-1.5h1.5a.75.75 0 00.75-.75c0-.715-.434-1.075-1.2-1.65-.802-.602-1.8-1.35-1.8-2.85a2.252 2.252 0 012.25-2.25h1.5a.75.75 0 010 1.5h-1.5a.75.75 0 00-.75.75c0 .715.434 1.075 1.2 1.65.802.602 1.8 1.35 1.8 2.85a2.252 2.252 0 01-2.25 2.25h-1.5zM17.249 24.004a.743.743 0 01-.53-.22l-1.307-1.307a2.266 2.266 0 01-1.913-2.223v-4.5c0-1.241 1.009-2.25 2.25-2.25s2.25 1.009 2.25 2.25v4.5a2.25 2.25 0 01-.895 1.795l.675.675a.744.744 0 010 1.06.749.749 0 01-.53.22zm-1.5-9a.75.75 0 00-.75.75v4.5a.75.75 0 001.5 0v-4.5a.75.75 0 00-.75-.75z" />
    </svg>
  );
}

export default SvgFileSql;
