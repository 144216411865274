import * as React from 'react';

function SvgMicrophoneOff(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M12 17.25A5.256 5.256 0 016.75 12v-.75a.75.75 0 011.5 0V12a3.754 3.754 0 004.219 3.721.75.75 0 01.684 1.204.741.741 0 01-.499.283 5.105 5.105 0 01-.654.042zM16.5 12.75a.75.75 0 01-.75-.75V5.25a3.751 3.751 0 00-7.03-1.819.75.75 0 01-1.312-.728A5.254 5.254 0 0111.999 0a5.256 5.256 0 015.251 5.25V12a.75.75 0 01-.75.75z" />
      <path d="M12 24a.75.75 0 01-.75-.75v-3.024a8.118 8.118 0 01-7.5-7.892V9.75a.75.75 0 011.5 0v2.566c.089 3.544 3.06 6.435 6.624 6.435l.113-.001c.997 0 1.946-.2 2.837-.595a.753.753 0 01.99.382.752.752 0 01-.382.99 8.39 8.39 0 01-2.682.69v3.033A.75.75 0 0112 24zM18.94 15.782a.75.75 0 01-.689-1.047 6.085 6.085 0 00.499-2.419V9.75a.75.75 0 011.5 0v2.566a7.544 7.544 0 01-.622 3.013.747.747 0 01-.688.453zM21 21.75a.756.756 0 01-.541-.23l-18-18.75a.748.748 0 111.082-1.039l18 18.75a.745.745 0 01.209.535.745.745 0 01-.75.734z" />
    </svg>
  );
}

export default SvgMicrophoneOff;
