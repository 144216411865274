import PropTypes from 'prop-types';

export const propTypes = {
  /** An array of strings which includes the extra prop keys */
  extraProps: PropTypes.array,
  /** The children of the component */
  children: PropTypes.node,
  /** The table headers if children is not passed */
  headerData: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        iconName: PropTypes.string.isRequired,
        keyValue: PropTypes.string.isRequired,
      }),
      PropTypes.shape({
        keyValue: PropTypes.string.isRequired,
        textContent: PropTypes.string.isRequired,
      }),
    ]),
  ),
  /** The table body if children is not passed */
  bodyData: PropTypes.arrayOf(PropTypes.object),
  /** optional border options  */
  border: PropTypes.shape({
    hasBorder: PropTypes.bool,
    hasTopBorder: PropTypes.bool,
    isFullWidth: PropTypes.bool,
    offsetWidth: PropTypes.number,
  }),
  /** Is table has fixed style */
  isTableFixed: PropTypes.bool,
  /** Height of the rows */
  rowHeight: PropTypes.number,
  /** Style of each column */
  columnStyle: PropTypes.arrayOf(
    PropTypes.shape({
      align: PropTypes.oneOf(['center', 'flex-start', 'flex-end']),
      isSingleLine: PropTypes.bool,
      justify: PropTypes.oneOf([
        'flex-start',
        'flex-end',
        'center',
        'space-between',
        'space-around',
        'space-evenly',
      ]),
      leftSpacing: PropTypes.number,
      maxWidth: PropTypes.number,
      minWidth: PropTypes.number,
      width: PropTypes.number,
    }),
  ),
  /** Row click callback that receives the object and its index */
  onRowClick: PropTypes.func,
};

export const defaultProps = {
  extraProps: [],
  children: null,
  headerData: [],
  bodyData: [],
  border: {
    isFullWidth: true,
    hasTopBorder: false,
    hasBorder: false,
    offsetWidth: 24,
  },
  rowHeight: 40,
  columnStyle: [],
  isTableFixed: true,
  onRowClick: null,
};
