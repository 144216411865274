import * as React from 'react';

function SvgDisabilityWalkBlind(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M9 6.751c-1.861 0-3.375-1.514-3.375-3.375S7.139.001 9 .001s3.375 1.514 3.375 3.375S10.861 6.751 9 6.751zm0-5.25c-1.034 0-1.875.841-1.875 1.875S7.966 5.251 9 5.251s1.875-.841 1.875-1.875S10.034 1.501 9 1.501zM13.525 24.038c-.191 0-.382-.025-.569-.073a2.234 2.234 0 01-1.374-1.037L9 18.796l-2.593 4.148c-.009.015-.031.052-.055.087a2.25 2.25 0 11-3.748-2.491l2.646-4.254v-3.832l-.207.058a.753.753 0 00-.543.72v1.769c0 1.241-1.009 2.25-2.25 2.25S0 16.242 0 15.001v-1.77a5.302 5.302 0 013.789-5.042 18.958 18.958 0 015.082-.69h.135c5.279 0 9.097 4.196 9.558 4.726a2.258 2.258 0 01.207 2.661l5.11 7.96a.75.75 0 01-1.262.811l-5.029-7.833a2.25 2.25 0 01-2.397-.619c-.713-.76-1.534-1.4-2.443-1.906v2.99l2.658 4.273a2.251 2.251 0 01-1.883 3.476zM6 10.765a.751.751 0 01.75.75v4.986a.75.75 0 01-.113.396l-2.771 4.455a.759.759 0 00.197 1.059.753.753 0 001.04-.209l3.261-5.219c.138-.22.375-.352.636-.352s.498.132.636.353l3.229 5.166a.76.76 0 00.661.387.745.745 0 00.722-.561.743.743 0 00-.079-.568l-2.806-4.51a.75.75 0 01-.113-.396v-4.4a.749.749 0 011.039-.692 11.573 11.573 0 014.008 2.779.763.763 0 00.569.257.744.744 0 00.5-.192.752.752 0 00.097-1.007l-.009-.011C17.307 13.062 13.807 9 9 9l-.165-.001a17.31 17.31 0 00-4.634.632A3.787 3.787 0 001.5 13.233v1.768a.75.75 0 001.5 0v-1.77a2.261 2.261 0 011.626-2.16 13.41 13.41 0 011.233-.293.74.74 0 01.141-.013z" />
    </svg>
  );
}

export default SvgDisabilityWalkBlind;
