import * as React from 'react';

function SvgToiletSign1(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M5.25 7.503c-2.068 0-3.75-1.682-3.75-3.75S3.182.003 5.25.003 9 1.685 9 3.753s-1.683 3.75-3.75 3.75zm0-6C4.009 1.503 3 2.512 3 3.753s1.009 2.25 2.25 2.25S7.5 4.994 7.5 3.753s-1.01-2.25-2.25-2.25zM3 24.003a.747.747 0 01-.746-.675l-.682-6.825H.75a.75.75 0 01-.75-.75v-3c0-2.895 2.355-5.25 5.25-5.25s5.25 2.355 5.25 5.25v3a.75.75 0 01-.75.75h-.822l-.682 6.825a.747.747 0 01-.746.675H3zm3.821-1.5l.682-6.825a.747.747 0 01.746-.675H9v-2.25c0-1.781-1.288-3.32-3-3.672v5.172a.75.75 0 01-1.5 0V9.081c-1.712.352-3 1.892-3 3.672v2.25h.75c.387 0 .708.29.746.675l.682 6.825h3.143zM17.25 7.503c-2.068 0-3.75-1.682-3.75-3.75s1.682-3.75 3.75-3.75S21 1.685 21 3.753s-1.682 3.75-3.75 3.75zm0-6c-1.241 0-2.25 1.009-2.25 2.25s1.009 2.25 2.25 2.25 2.25-1.009 2.25-2.25-1.01-2.25-2.25-2.25zM15 24a.749.749 0 01-.74-.627l-.646-3.873H11.25a.75.75 0 01-.712-.988l2.25-6.75a9.007 9.007 0 011.332-2.72.751.751 0 01.549-.31l.062-.002c.2 0 .388.078.529.22l1.99 1.99 1.99-1.99a.743.743 0 01.591-.218c.22.018.42.131.548.31a9.09 9.09 0 011.339 2.74l2.244 6.731a.75.75 0 01-.711.987h-2.365l-.646 3.873a.749.749 0 01-.74.627H15zm3.864-1.5l.646-3.874a.747.747 0 01.74-.626h1.959l-1.931-5.794a7.595 7.595 0 00-.64-1.534L17.78 12.53a.744.744 0 01-1.06 0l-1.858-1.858a7.64 7.64 0 00-.642 1.539L12.29 18h1.959c.368 0 .679.264.74.627l.646 3.873h3.229z" />
    </svg>
  );
}

export default SvgToiletSign1;
