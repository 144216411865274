const baseStyles = {
  chip: (theme) => `
    ${theme.centeredStyles.flex}
    flex-direction: row;
    padding: ${theme.spacing.$2};
    height:  ${theme.lineHeight.$9};
    border-radius: ${theme.borderRadius.$6};
  `,
};

export const webStyles = {
  ...baseStyles,
  chip: (theme) => `
    ${baseStyles.chip(theme)}
    position: relative;
    margin-right: ${theme.spacing.$3};
  `,
  avatar: (theme) => `
    margin-right: ${theme.spacing.$4};
  `,
  btnClose: (theme) => `
    height: ${theme.lineHeight.$9};
    background: transparent;
    &:hover { background: transparent; };
  `,
};

export const nativeStyles = {
  ...baseStyles,
  chip: (theme) => `
    ${baseStyles.chip(theme)}
  `,
  root: (theme) => `
    border-radius: ${theme.borderRadius.$6};
  `,
  avatar: (theme) => `
    margin-right: ${theme.spacing.$2};
  `,
};
