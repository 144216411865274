import * as React from 'react';

function SvgColorTube(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M4.761 20.045a.755.755 0 01-.542-.231l-1.687-1.758a.749.749 0 01-.196-.655l.952-5.182a.636.636 0 01.025-.088.75.75 0 01.135-.255l7.32-8.297a1.49 1.49 0 01.387-1.451L12.619.664A2.235 2.235 0 0114.21.006c.601 0 1.167.234 1.591.658l6.028 6.026a2.253 2.253 0 010 3.182l-1.466 1.465a1.493 1.493 0 01-1.452.385l-8.278 7.305-.022.018a.75.75 0 01-.359.16l-5.378.829a.541.541 0 01-.113.011zm.271-1.55l3.528-.544-4.055-4.055-.624 3.4 1.151 1.199zm5.137-1.057l7.529-6.644-6-6-6.644 7.529 5.115 5.115zM14.21 1.507a.744.744 0 00-.53.219L12.216 3.19l7.086 7.087 1.467-1.465a.75.75 0 000-1.06L14.74 1.726a.74.74 0 00-.53-.219z" />
      <path d="M11.155 14.125a.743.743 0 01-.53-.22l-2.037-2.037a.744.744 0 010-1.06c.141-.142.33-.22.53-.22s.389.078.53.22l2.037 2.037a.752.752 0 010 1.061.752.752 0 01-.53.219zM3.683 23.996a2.222 2.222 0 01-2.172-2.266.754.754 0 01.75-.734.753.753 0 01.75.766.713.713 0 00.2.512.708.708 0 00.503.221h.038c.955 0 2.122-.313 3.357-.644 1.497-.401 3.194-.856 4.902-.856l.058.002c1.719.12 3.398.68 4.849 1.618a.752.752 0 01-.408 1.38.753.753 0 01-.407-.12 8.788 8.788 0 00-4.119-1.38c-1.5.005-3.019.412-4.487.805-1.334.357-2.594.695-3.737.695h-.031l-.046.001z" />
    </svg>
  );
}

export default SvgColorTube;
