import * as React from 'react';

function SvgImageFileBmp(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M2.625 24a2.252 2.252 0 01-2.25-2.25V2.25A2.252 2.252 0 012.625 0h10.629c.601 0 1.165.234 1.59.658l5.872 5.872c.425.425.659.99.659 1.59v4.629a.75.75 0 01-1.5 0V9h-5.25a2.252 2.252 0 01-2.25-2.25V1.5h-9.75a.75.75 0 00-.75.75v19.5c0 .414.336.75.75.75h.75a.75.75 0 010 1.5h-.75zm11.25-17.25c0 .414.336.75.75.75h4.939l-5.689-5.689V6.75z" />
      <path d="M6.375 24a.75.75 0 01-.75-.75v-7.5a.75.75 0 01.75-.75h1.5a2.252 2.252 0 012.25 2.25V18c0 .563-.206 1.091-.575 1.5.37.409.575.937.575 1.5v.75A2.252 2.252 0 017.875 24h-1.5zm1.5-1.5a.75.75 0 00.75-.75V21a.75.75 0 00-.75-.75h-.75v2.25h.75zm0-3.75a.75.75 0 00.75-.75v-.75a.75.75 0 00-.75-.75h-.75v2.25h.75zM16.875 24a.75.75 0 01-.75-.75v-4.792l-.857 1.428a.752.752 0 01-1.286-.001l-.857-1.428v4.792a.75.75 0 01-1.5 0v-7.5a.752.752 0 01.751-.749c.262 0 .508.14.643.365l1.607 2.678 1.607-2.678a.75.75 0 011.392.385v7.5a.75.75 0 01-.75.75zM19.875 24a.75.75 0 01-.75-.75v-7.5a.75.75 0 01.75-.75h.75c1.654 0 3 1.346 3 3s-1.346 3-3 3v2.25a.75.75 0 01-.75.75zm.75-4.5c.827 0 1.5-.673 1.5-1.5s-.673-1.5-1.5-1.5v3z" />
    </svg>
  );
}

export default SvgImageFileBmp;
