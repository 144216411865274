import * as React from 'react';

function SvgLoginKey(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M7.022 24.009a6.99 6.99 0 01-2.745-.562 6.984 6.984 0 01-3.774-3.838 6.991 6.991 0 01.045-5.383 6.984 6.984 0 013.838-3.774 6.977 6.977 0 014.902-.138L18.685.917c.586-.587 1.366-.91 2.196-.91s1.61.323 2.197.909a3.125 3.125 0 01.297 4.051l.367 1.371A2.326 2.326 0 0121.5 9.26c-.202 0-.404-.027-.6-.079l-1.334-.358-.171.171.358 1.334a2.326 2.326 0 01-2.842 2.843l-1.334-.358-1.896 1.896a7.105 7.105 0 01-.183 5.011 6.984 6.984 0 01-3.838 3.774 7.04 7.04 0 01-2.638.515zm.001-12.573c-.71 0-1.408.137-2.075.407a5.494 5.494 0 00-3.019 2.969 5.498 5.498 0 00-.035 4.235 5.498 5.498 0 002.969 3.019 5.509 5.509 0 004.235.035 5.498 5.498 0 003.019-2.969 5.56 5.56 0 000-4.319.746.746 0 01.16-.823l2.545-2.545a.743.743 0 01.725-.194l1.751.47a.823.823 0 001.005-1.007l-.47-1.751a.753.753 0 01.194-.725l.784-.784a.744.744 0 01.724-.195l1.752.47a.822.822 0 001.005-1.006l-.469-1.752a.751.751 0 01.194-.724 1.61 1.61 0 000-2.272 1.597 1.597 0 00-1.136-.47c-.429 0-.833.167-1.136.471l-9.741 9.741a.744.744 0 01-.823.161 5.504 5.504 0 00-2.158-.442z" />
      <path d="M5.911 20.405c-1.28 0-2.321-1.041-2.321-2.321s1.041-2.321 2.321-2.321 2.321 1.041 2.321 2.321-1.041 2.321-2.321 2.321zm0-3.142a.822.822 0 10.001 1.643.822.822 0 00-.001-1.643z" />
    </svg>
  );
}

export default SvgLoginKey;
