const baseStyles = {};

export const webStyles = {
  ...baseStyles,
  styledCardHeader: (theme) => `
    display: flex;
    align-items: center;
    ${theme.textStyle.body};
  `,
};

export const nativeStyles = {
  ...baseStyles,
};
