import * as React from 'react';

function SvgImageFileJpg(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M2.25 24A2.252 2.252 0 010 21.75V2.25A2.252 2.252 0 012.25 0h10.629c.601 0 1.165.234 1.59.658l5.872 5.872c.425.425.659.99.659 1.591v4.629a.75.75 0 01-1.5 0V9h-5.25A2.252 2.252 0 0112 6.75V1.5H2.25a.75.75 0 00-.75.75v19.5c0 .414.336.75.75.75h3a.75.75 0 010 1.5h-3zM13.5 6.75c0 .414.336.75.75.75h4.939L13.5 1.811V6.75z" />
      <path d="M8.25 24a.75.75 0 010-1.5h1.5a.75.75 0 00.75-.75v-6a.75.75 0 011.5 0v6A2.252 2.252 0 019.75 24h-1.5zM14.25 24a.75.75 0 01-.75-.75v-7.5a.75.75 0 01.75-.75H15c1.654 0 3 1.346 3 3s-1.346 3-3 3v2.25a.75.75 0 01-.75.75zm.75-4.5c.827 0 1.5-.673 1.5-1.5s-.673-1.5-1.5-1.5v3zM21.75 24a2.252 2.252 0 01-2.25-2.25v-4.5A2.252 2.252 0 0121.75 15h1.5a.75.75 0 010 1.5h-1.5a.75.75 0 00-.75.75v4.5a.75.75 0 001.5 0V21a.75.75 0 010-1.5h.75a.75.75 0 01.75.75v1.5A2.252 2.252 0 0121.75 24z" />
    </svg>
  );
}

export default SvgImageFileJpg;
