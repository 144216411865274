import React from 'react';
import styled from '@emotion/styled';
import {
  getExtraProps,
  withTheme,
  getSpacingString,
  removeKeysFromObject,
} from '@embracesbs/helpers';
import { propTypes, defaultProps } from './Text.props';

const StyledSingleLineText = styled.div`
  display: flex;
`;

const StyledSpan = styled.span`
  ${({ theme }) => `padding-right: ${theme.spacing.$2};`}
  display: block;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`;

const StyledLines = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  ${({ numberOfLines, lineHeight, maxHeight }) => `
    -webkit-line-clamp: ${numberOfLines};
    line-height: ${lineHeight};
    max-height: ${maxHeight};
  `}
`;

/**
 * Text can be wrapped with different dom element passed via props
 *
 * @param {*} tag
 */
const StyledTextTag = (tagType) => styled(tagType)`
  ${({ theme, textStyle, textColor, align, currentSpacing }) => `
  ${theme.textStyle[textStyle]};
    color: ${textColor};
    text-align: ${align};
    margin: ${currentSpacing};
    white-space: pre-line;
  `}
`;

/** Returns the proper HTML tag to put on the text component */
const getTagType = (selectedTextStyle, selectedTag) => {
  const headings = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'];
  if (headings.includes(selectedTextStyle)) return selectedTextStyle;

  return selectedTag || 'div';
};

/**
 * Text component
 * @param {string} align
 * @param {array} extraProps
 * @param {string} textColor
 * @param {string} tag
 * @param {string|number} content
 * @param {string} textStyle
 * @param {number|object} spacing
 * @param {bool} singleLine
 * @param {number} numberOfLines
 */
const Text = (props) => {
  const {
    align,
    extraProps,
    textColor,
    tag,
    theme,
    content,
    textStyle,
    spacing,
    singleLine,
    numberOfLines,
    forwardedRef,
  } = props;

  const currentSpacingString = getSpacingString(spacing, defaultProps.spacing);
  const tagType = getTagType(textStyle, tag);
  const StyledText = StyledTextTag(tagType);

  /** Returns the value for a given key from the style object */
  const getValueFromStyleObject = (key) => {
    const currentTextStyle = theme.textStyle[textStyle];

    const lastIndexOfKey =
      currentTextStyle.lastIndexOf(key) + `${key}: `.length;
    const indexOfSemicolonAfterKey = currentTextStyle.indexOf(
      ';',
      lastIndexOfKey,
    );

    return currentTextStyle.substring(lastIndexOfKey, indexOfSemicolonAfterKey);
  };

  /** Returns the text content */
  const getTextContent = () => {
    if (singleLine) {
      return (
        <StyledSingleLineText>
          <StyledSpan>{content}</StyledSpan>
        </StyledSingleLineText>
      );
    }

    if (parseFloat(numberOfLines) > 0) {
      const lineHeightString = 'line-height';
      let lineHeight = null;

      if (theme.textStyle[textStyle].includes(lineHeightString)) {
        lineHeight = getValueFromStyleObject(lineHeightString);
      } else {
        lineHeight = `${
          1.5 * parseFloat(getValueFromStyleObject('font-size'))
        }rem`;
      }

      const maxHeight = `${numberOfLines * parseFloat(lineHeight)}rem`;

      return (
        <StyledLines
          numberOfLines={numberOfLines}
          lineHeight={lineHeight}
          maxHeight={maxHeight}
        >
          {content}
        </StyledLines>
      );
    }

    return content;
  };

  return (
    <StyledText
      {...getExtraProps(props, extraProps)}
      align={align}
      textColor={textColor}
      textStyle={textStyle}
      theme={theme}
      currentSpacing={currentSpacingString}
      ref={forwardedRef}
      {...removeKeysFromObject(props, ['data-testid'])}
    >
      {getTextContent()}
    </StyledText>
  );
};

Text.propTypes = propTypes;
Text.defaultProps = defaultProps;

/** @component */
export default withTheme(Text);
