const baseStyles = {};

export const webStyles = {
  ...baseStyles,
  styledWrapper: (theme, isDark) => `
    ${isDark ? theme.colorIn.graphic.dark : theme.colorIn.graphic.color}
  `,
};

export const nativeStyles = {
  ...baseStyles,
};
