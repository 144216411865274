import * as React from 'react';

function SvgFlipLeft(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M14.25 24a.75.75 0 010-1.5h7.5a.75.75 0 00.75-.75V2.25a.75.75 0 00-.75-.75h-7.5a.75.75 0 010-1.5h7.5A2.252 2.252 0 0124 2.25v19.5A2.252 2.252 0 0121.75 24h-7.5zM11.25 6a.75.75 0 01-.75-.75V.75a.75.75 0 011.5 0v4.5a.75.75 0 01-.75.75zM11.25 24a.75.75 0 01-.75-.75v-6a.75.75 0 011.5 0v6a.75.75 0 01-.75.75zM6.75 1.5a.75.75 0 010-1.5h1.5a.75.75 0 010 1.5h-1.5zM6.75 24a.75.75 0 010-1.5h1.5a.75.75 0 010 1.5h-1.5zM.75 7.5A.75.75 0 010 6.75v-1.5a.75.75 0 011.5 0v1.5a.75.75 0 01-.75.75zM.75 13.5a.75.75 0 01-.75-.75v-1.5a.75.75 0 011.5 0v1.5a.75.75 0 01-.75.75zM.75 19.5a.75.75 0 01-.75-.75v-1.5a.75.75 0 011.5 0v1.5a.75.75 0 01-.75.75zM.75 3A.75.75 0 010 2.25 2.252 2.252 0 012.25 0a.75.75 0 010 1.5c-.414 0-.75.336-.75.75S1.164 3 .75 3zM2.25 24A2.252 2.252 0 010 21.75a.75.75 0 011.5 0c0 .414.336.75.75.75a.75.75 0 010 1.5z" />
      <path d="M18.365 15.75a.752.752 0 01-.707-.5 6.014 6.014 0 00-5.664-4 5.97 5.97 0 00-4.242 1.768l3.029.453a.747.747 0 01.631.853.753.753 0 01-.855.63l-4.306-.644a.49.49 0 01-.067-.011l-.07-.01a.747.747 0 01-.634-.831l.527-4.404a.75.75 0 011.489.178l-.267 2.235a7.469 7.469 0 014.766-1.718c3.176 0 6.02 2.01 7.078 5.001a.745.745 0 01-.03.573.744.744 0 01-.678.427z" />
    </svg>
  );
}

export default SvgFlipLeft;
