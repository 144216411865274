import * as React from 'react';

function SvgSingleNeutralActionsGraduate(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M7.5 9.751c-2.688 0-4.875-2.187-4.875-4.875S4.812.001 7.5.001s4.875 2.187 4.875 4.875S10.188 9.751 7.5 9.751zm0-8.25c-1.861 0-3.375 1.514-3.375 3.375S5.639 8.251 7.5 8.251s3.375-1.514 3.375-3.375S9.361 1.501 7.5 1.501z" />
      <path d="M.75 18.001a.75.75 0 01-.75-.75c0-4.135 3.364-7.5 7.5-7.501 2.136 0 4.176.914 5.597 2.507a.743.743 0 01.189.542.744.744 0 01-.749.707.751.751 0 01-.56-.25A6.005 6.005 0 007.5 11.25c-3.309 0-6 2.692-6 6.001a.75.75 0 01-.75.75zM16.5 24.005c-.192 0-.38-.037-.559-.109l-2.998-1.199a1.493 1.493 0 01-.942-1.4V18.71l-1.5-.549v2.677a.75.75 0 01-1.501 0v-3.772c0-.366.27-.678.626-.738l6.589-2.713a.744.744 0 01.571 0l6.751 2.779c.288.118.47.396.464.708a.747.747 0 01-.493.69L21 18.71v2.591a1.486 1.486 0 01-.944 1.396l-3 1.2a1.468 1.468 0 01-.556.108zm-3-2.704l3 1.204 3-1.2V19.26l-2.742 1.004a.75.75 0 01-.517 0L13.5 19.259v2.042zm3-2.541l4.68-1.713-4.68-1.927-4.68 1.927 4.68 1.713z" />
    </svg>
  );
}

export default SvgSingleNeutralActionsGraduate;
