import * as React from 'react';

function SvgHumanResourcesHierarchy(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M20.249 24c-1.654 0-3-1.346-3-3 0-1.37.947-2.564 2.25-2.902V15h-6.75v3.098A3.016 3.016 0 0114.999 21c0 1.654-1.346 3-3 3s-3-1.346-3-3c0-1.37.947-2.564 2.25-2.902V15h-6.75v3.098A3.016 3.016 0 016.749 21c0 1.654-1.346 3-3 3s-3-1.346-3-3c0-1.37.947-2.564 2.25-2.902v-3.371c0-.677.55-1.227 1.227-1.227h7.023v-2.25a.75.75 0 011.5 0v2.25h7.023c.677 0 1.227.551 1.227 1.227v3.371A3.016 3.016 0 0123.249 21c0 1.654-1.346 3-3 3zm0-4.5c-.827 0-1.5.673-1.5 1.5s.673 1.5 1.5 1.5 1.5-.673 1.5-1.5-.673-1.5-1.5-1.5zm-8.25 0c-.827 0-1.5.673-1.5 1.5s.673 1.5 1.5 1.5 1.5-.673 1.5-1.5-.673-1.5-1.5-1.5zm-8.25 0c-.827 0-1.5.673-1.5 1.5s.673 1.5 1.5 1.5 1.5-.673 1.5-1.5-.673-1.5-1.5-1.5zM11.999 7.5c-2.068 0-3.75-1.682-3.75-3.75S9.931 0 11.999 0s3.75 1.682 3.75 3.75-1.682 3.75-3.75 3.75zm0-6c-1.241 0-2.25 1.009-2.25 2.25S10.758 6 11.999 6s2.25-1.009 2.25-2.25-1.009-2.25-2.25-2.25z" />
      <path d="M7.347 12a.745.745 0 01-.728-.933 5.682 5.682 0 015.268-4.316l.036-.001.041.001a5.683 5.683 0 015.264 4.316.751.751 0 01-1.455.366 4.193 4.193 0 00-3.85-3.182 4.194 4.194 0 00-3.85 3.182.747.747 0 01-.726.567z" />
    </svg>
  );
}

export default SvgHumanResourcesHierarchy;
