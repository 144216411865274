import * as React from 'react';

function SvgQuestionHelpMessage(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M5.25 24a.747.747 0 01-.75-.75V19.5H2.25A2.252 2.252 0 010 17.25v-15A2.252 2.252 0 012.25 0h19.5A2.252 2.252 0 0124 2.25v15a2.252 2.252 0 01-2.25 2.25H11.5l-5.8 4.35a.753.753 0 01-.45.15zm-3-22.5a.75.75 0 00-.75.75v15c0 .414.336.75.75.75h3a.75.75 0 01.75.75v3l4.8-3.6a.753.753 0 01.45-.15h10.5a.75.75 0 00.75-.75v-15a.75.75 0 00-.75-.75H2.25z" />
      <path d="M12 12a.75.75 0 01-.75-.75v-.256a2.251 2.251 0 011.5-2.122 2.254 2.254 0 001.5-2.121c0-.6-.234-1.165-.66-1.59a2.231 2.231 0 00-1.59-.66c-.601 0-1.166.234-1.591.659a2.231 2.231 0 00-.659 1.59.75.75 0 01-1.5 0A3.754 3.754 0 0112 3.001c1.002 0 1.944.39 2.652 1.099a3.728 3.728 0 011.098 2.652 3.756 3.756 0 01-2.5 3.535.752.752 0 00-.5.708v.256A.75.75 0 0112 12z" />
      <circle cx={12} cy={14.625} r={1.125} />
    </svg>
  );
}

export default SvgQuestionHelpMessage;
