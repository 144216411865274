import * as React from 'react';

function SvgFileHqx(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M2.253 24.003a2.252 2.252 0 01-2.25-2.25v-19.5a2.252 2.252 0 012.25-2.25H12.88c.601 0 1.167.234 1.592.66l5.871 5.871c.425.425.66.99.66 1.591v3.128a.75.75 0 01-1.5 0v-2.25h-5.25a2.252 2.252 0 01-2.25-2.25v-5.25h-9.75a.75.75 0 00-.75.75v19.5c0 .414.336.75.75.75h3a.75.75 0 010 1.5h-3zm11.25-17.25c0 .414.336.75.75.75h4.938l-5.688-5.688v4.938z" />
      <path d="M11.253 22.503a.75.75 0 01-.75-.75v-2.25h-1.5v2.25a.75.75 0 01-1.5 0v-7.5a.75.75 0 011.5 0v3.75h1.5v-3.75a.75.75 0 011.5 0v7.5a.75.75 0 01-.75.75zM17.253 24.003a.743.743 0 01-.53-.22l-1.307-1.307a2.266 2.266 0 01-1.913-2.223v-4.5c0-1.241 1.009-2.25 2.25-2.25s2.25 1.009 2.25 2.25v4.5c0 .714-.339 1.375-.895 1.794l.675.675a.744.744 0 010 1.06.75.75 0 01-.53.221zm-1.5-9a.75.75 0 00-.75.75v4.5a.75.75 0 001.5 0v-4.5a.75.75 0 00-.75-.75zM23.253 22.503a.744.744 0 01-.696-.472l-.804-2.009-.804 2.009a.745.745 0 01-.975.418.74.74 0 01-.41-.401.742.742 0 01-.007-.574l1.389-3.471-1.389-3.472a.747.747 0 01.418-.975.745.745 0 01.974.418l.804 2.009.804-2.009a.747.747 0 01.975-.418.745.745 0 01.411.401.742.742 0 01.007.574l-1.389 3.471 1.389 3.472a.747.747 0 01-.418.975.748.748 0 01-.279.054z" />
    </svg>
  );
}

export default SvgFileHqx;
