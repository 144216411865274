import * as React from 'react';

function SvgControlsNext(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M1.5 21.871c-.827 0-1.5-.673-1.5-1.5V3.629a1.502 1.502 0 012.35-1.235l12.175 8.371a1.496 1.496 0 01.625 1.512 1.49 1.49 0 01-.626.961L2.35 21.607a1.491 1.491 0 01-.85.264zm0-1.5L13.675 12 1.502 3.63 1.5 20.371zM20.25 22.5A2.252 2.252 0 0118 20.25V3.75a2.252 2.252 0 012.25-2.25h1.5A2.252 2.252 0 0124 3.75v16.5a2.252 2.252 0 01-2.25 2.25h-1.5zm0-19.5a.75.75 0 00-.75.75v16.5c0 .414.336.75.75.75h1.5a.75.75 0 00.75-.75V3.75a.75.75 0 00-.75-.75h-1.5z" />
    </svg>
  );
}

export default SvgControlsNext;
