import * as React from 'react';

function SvgRealEstateSearchHouseTarget(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M12 24a.75.75 0 01-.75-.75v-1.531c-4.758-.367-8.603-4.211-8.969-8.969H.75a.75.75 0 010-1.5h1.531c.367-4.758 4.211-8.603 8.969-8.969V.75a.75.75 0 011.5 0v1.531c4.758.367 8.603 4.211 8.969 8.969h1.531a.75.75 0 010 1.5h-1.531c-.366 4.758-4.211 8.603-8.969 8.969v1.531A.75.75 0 0112 24zm0-5.25a.75.75 0 01.75.75v.715c3.928-.359 7.105-3.537 7.465-7.465H19.5a.75.75 0 010-1.5h.715c-.359-3.928-3.537-7.105-7.465-7.465V4.5a.75.75 0 01-1.5 0v-.715c-3.928.36-7.105 3.537-7.465 7.465H4.5a.75.75 0 010 1.5h-.715c.359 3.928 3.537 7.105 7.465 7.465V19.5a.75.75 0 01.75-.75z" />
      <path d="M9.75 16.136c-.827 0-1.5-.673-1.5-1.5v-3.035l-.27.225a.753.753 0 01-1.056-.096.75.75 0 01.096-1.056l4.5-3.75a.75.75 0 01.96 0l4.5 3.75a.744.744 0 01.267.508.747.747 0 01-1.227.644l-.27-.225v3.035c0 .827-.673 1.5-1.5 1.5h-4.5zm0-1.5h4.5v-4.285L12 8.476l-2.25 1.875v4.285z" />
    </svg>
  );
}

export default SvgRealEstateSearchHouseTarget;
