import * as React from 'react';

function SvgAwardTrophyStar(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M5.631 24a.749.749 0 01-.707-1 7.47 7.47 0 014.572-4.572 7.528 7.528 0 011.75-.393v-1.564A10.46 10.46 0 011.5 6V2.25A2.252 2.252 0 013.75 0H7.5a.75.75 0 010 1.5H3.75a.75.75 0 00-.75.75V6c0 4.963 4.037 9 9 9s9-4.037 9-9V2.25a.75.75 0 00-.75-.75H16.5a.75.75 0 010-1.5h3.75a2.252 2.252 0 012.25 2.25V6a10.459 10.459 0 01-9.754 10.471v1.562a7.468 7.468 0 012.483.699 7.448 7.448 0 013.839 4.269.75.75 0 01-.707.999H5.631zm11.565-1.5a5.91 5.91 0 00-2.614-2.415 5.942 5.942 0 00-2.526-.589c-.021.002-.04.004-.061.004a.471.471 0 01-.048-.003 6.01 6.01 0 00-1.952.345A5.938 5.938 0 006.797 22.5h10.399z" />
      <path d="M9.092 12.001c-.477 0-.922-.256-1.16-.667a1.348 1.348 0 01-.077-1.19l.88-2.022-1.586-1.56a1.332 1.332 0 01.921-2.286l.047.001h1.644l1.04-2.044a1.338 1.338 0 011.805-.591c.257.13.462.335.592.592l1.04 2.047h1.692c.336 0 .658.131.907.368a1.336 1.336 0 01.007 1.922l-1.581 1.552.876 2.012a1.334 1.334 0 01-.713 1.761 1.34 1.34 0 01-1.188-.076L12 10.561l-2.247 1.264a1.328 1.328 0 01-.661.176zM12 8.95c.129 0 .256.033.368.096l2.204 1.24-.887-2.038a.754.754 0 01.162-.834l1.663-1.633h-1.73a.745.745 0 01-.668-.41L12 3.185l-1.11 2.182a.747.747 0 01-.668.41H8.49l1.664 1.637c.22.216.285.551.162.834l-.887 2.038 2.204-1.24A.745.745 0 0112 8.95z" />
    </svg>
  );
}

export default SvgAwardTrophyStar;
