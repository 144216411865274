import * as React from 'react';

function SvgMessagesPeopleUserIdea(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M4.5 10.5C2.4 10.5.7 8.8.7 6.7S2.4 3 4.5 3s3.8 1.7 3.8 3.8-1.7 3.7-3.8 3.7zm0-6c-1.2 0-2.2 1-2.2 2.2S3.3 9 4.5 9s2.2-1 2.2-2.2-1-2.3-2.2-2.3zM3 24c-.4 0-.7-.3-.7-.7L1.6 18H.8c-.5 0-.8-.3-.8-.8V15c0-2.5 2-4.5 4.5-4.5S9 12.5 9 15v2.2c0 .5-.3.8-.8.8h-.8l-.7 5.3c0 .4-.3.7-.7.7H3zm2.3-1.5l.7-5.3c0-.4.4-.7.7-.7h.8V15c0-1.7-1.3-3-3-3s-3 1.3-3 3v1.5h.8c.4 0 .7.3.7.7l.7 5.3h1.6zM15.8 13.5c-.4 0-.8-.3-.8-.8V12h-.8c-.4 0-.8-.3-.8-.8V9.7c-.8-.7-1.4-1.8-1.4-3 0-2 1.7-3.7 3.8-3.7 2.1 0 3.8 1.7 3.8 3.8 0 1.2-.6 2.3-1.5 3v1.5c0 .4-.3.8-.8.8h-.8v.8c0 .3-.3.6-.7.6zm.7-3V9.3c0-.3.1-.5.4-.6.7-.4 1.1-1.2 1.1-2 0-1.2-1-2.3-2.2-2.3V4v.4c-1.2 0-2.3 1-2.3 2.2 0 .8.4 1.6 1.1 2 .3.2.4.5.4.7v1.2h1.5z" />
      <path d="M14.2 21c-.1 0-.2 0-.3-.1-.3-.1-.5-.4-.5-.7v-3.8h-2.2c-.4 0-.8-.3-.8-.8s.3-.8.8-.8h3c.4 0 .8.3.8.8v2.7l3.2-3.2c.1-.1.3-.2.5-.2h3c.4 0 .8-.3.8-.8v-12c0-.4-.3-.8-.8-.8h-12c-.4.2-.7.5-.7.9s-.3.8-.8.8-.7-.3-.7-.8C7.5 1 8.5 0 9.8 0h12C23 0 24 1 24 2.2v12c0 1.2-1 2.2-2.2 2.2h-2.7l-4.3 4.3c-.2.2-.4.3-.6.3z" />
    </svg>
  );
}

export default SvgMessagesPeopleUserIdea;
