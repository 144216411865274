import PropTypes from 'prop-types';
import { theme } from '@embracesbs/helpers';

export const propTypes = {
  /** Text color */
  color: PropTypes.string,
  /** An array of strings which includes the extra prop keys */
  extraProps: PropTypes.array,
  /** Text to render */
  text: PropTypes.string,
  /** Text alignment */
  align: PropTypes.oneOf(['left', 'center', 'right']),
  /** Text transform property */
  textTransform: PropTypes.oneOf(['none', 'uppercase']),
  /** Defines padding around the component */
  spacing: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.shape({
      bottom: PropTypes.number,
      left: PropTypes.number,
      right: PropTypes.number,
      top: PropTypes.number,
    }),
  ]),
};

export const defaultProps = {
  color: theme.color.dark$3,
  extraProps: [],
  text: null,
  align: 'left',
  textTransform: 'none',
  spacing: {
    top: theme.spacing.$5Number,
    right: theme.spacing.$6Number,
    bottom: theme.spacing.$4Number,
    left: theme.spacing.$6Number,
  },
};
