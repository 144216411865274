import * as React from 'react';

function SvgCommonFileQuestion(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M17.25 24c-3.722 0-6.75-3.028-6.75-6.75s3.028-6.75 6.75-6.75S24 13.528 24 17.25 20.972 24 17.25 24zm0-12C14.355 12 12 14.355 12 17.25s2.355 5.25 5.25 5.25 5.25-2.355 5.25-5.25S20.145 12 17.25 12z" />
      <path d="M17.25 18.75a.75.75 0 010-1.5c.62 0 1.125-.505 1.125-1.125S17.87 15 17.25 15s-1.125.505-1.125 1.125a.75.75 0 01-1.5 0c0-1.448 1.177-2.625 2.625-2.625s2.625 1.177 2.625 2.625-1.177 2.625-2.625 2.625z" />
      <circle cx={17.25} cy={20.625} r={1.125} />
      <path d="M2.25 21A2.252 2.252 0 010 18.75V2.25A2.252 2.252 0 012.25 0h10.629c.601 0 1.165.234 1.59.658l2.872 2.872c.425.425.659.99.659 1.591V8.25a.75.75 0 01-1.5 0V5.121c0-.197-.08-.39-.219-.53l-2.872-2.872a.748.748 0 00-.53-.219H2.25a.75.75 0 00-.75.75v16.5c0 .414.336.75.75.75h6a.75.75 0 010 1.5h-6z" />
    </svg>
  );
}

export default SvgCommonFileQuestion;
