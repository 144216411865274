import * as React from 'react';

function SvgHyperlink3(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M5.253 21.497a5.213 5.213 0 01-3.712-1.535A5.213 5.213 0 01.006 16.25c0-1.404.545-2.722 1.535-3.712l4.773-4.774a5.215 5.215 0 013.716-1.539c1.402 0 2.719.544 3.708 1.531a5.225 5.225 0 01.538 6.804.752.752 0 01-1.048.165.744.744 0 01-.299-.489.745.745 0 01.134-.558 3.732 3.732 0 00-.384-4.86 3.726 3.726 0 00-2.649-1.094 3.729 3.729 0 00-2.655 1.099l-4.774 4.774a3.754 3.754 0 000 5.303c.707.707 1.649 1.096 2.652 1.096s1.945-.389 2.652-1.096l.932-.932a.744.744 0 011.06 0 .744.744 0 010 1.06l-.932.932a5.205 5.205 0 01-3.712 1.537z" />
      <path d="M13.978 18.024a5.213 5.213 0 01-3.709-1.535 5.223 5.223 0 01-.536-6.803.752.752 0 011.047-.166.746.746 0 01.3.49.745.745 0 01-.134.558 3.731 3.731 0 00.383 4.859 3.725 3.725 0 002.65 1.096 3.727 3.727 0 002.654-1.1l4.773-4.773c.708-.708 1.098-1.65 1.098-2.652s-.39-1.943-1.098-2.652c-.707-.707-1.649-1.096-2.652-1.096s-1.945.389-2.652 1.096l-.75.75a.744.744 0 01-1.06 0c-.142-.141-.22-.33-.22-.53s.078-.389.22-.53l.75-.75c.99-.99 2.309-1.536 3.712-1.536s2.722.545 3.712 1.536a5.213 5.213 0 011.538 3.712 5.213 5.213 0 01-1.538 3.712l-4.773 4.773a5.213 5.213 0 01-3.711 1.54h-.004z" />
    </svg>
  );
}

export default SvgHyperlink3;
