import * as React from 'react';

function SvgRemoveSquare(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M2.25 23.999A2.252 2.252 0 010 21.749v-19.5a2.252 2.252 0 012.25-2.25h19.5A2.252 2.252 0 0124 2.249v19.5a2.252 2.252 0 01-2.25 2.25H2.25zm0-22.5a.75.75 0 00-.75.75v19.5c0 .414.336.75.75.75h19.5a.75.75 0 00.75-.75v-19.5a.75.75 0 00-.75-.75H2.25z" />
      <path d="M16.5 17.249a.743.743 0 01-.53-.22L12 13.06l-3.97 3.97a.744.744 0 01-1.06 0 .752.752 0 010-1.061l3.97-3.97-3.97-3.97c-.142-.141-.22-.33-.22-.53s.078-.389.22-.53c.141-.142.33-.22.53-.22s.389.078.53.22l3.97 3.97 3.97-3.97a.744.744 0 011.06 0c.142.141.22.33.22.53s-.078.389-.22.53l-3.97 3.97 3.97 3.97a.752.752 0 010 1.061.746.746 0 01-.53.219z" />
    </svg>
  );
}

export default SvgRemoveSquare;
