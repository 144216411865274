import * as React from 'react';

function SvgMultipleNeutral1(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M6 7.5c-2.068 0-3.75-1.682-3.75-3.75S3.932 0 6 0s3.75 1.682 3.75 3.75S8.068 7.5 6 7.5zm0-6c-1.241 0-2.25 1.009-2.25 2.25S4.759 6 6 6s2.25-1.009 2.25-2.25S7.241 1.5 6 1.5zM3.75 24a.747.747 0 01-.746-.675L2.321 16.5H.75a.75.75 0 01-.75-.75V13.5c0-3.308 2.692-6 6-6s6 2.692 6 6v2.25a.75.75 0 01-.75.75H9.679l-.682 6.825A.748.748 0 018.25 24h-4.5zm3.821-1.5l.682-6.825A.748.748 0 019 15h1.5v-1.5C10.5 11.019 8.481 9 6 9s-4.5 2.019-4.5 4.5V15H3c.387 0 .708.29.746.675l.683 6.825h3.142zM18 7.5c-2.068 0-3.75-1.682-3.75-3.75S15.932 0 18 0s3.75 1.682 3.75 3.75S20.068 7.5 18 7.5zm0-6c-1.241 0-2.25 1.009-2.25 2.25S16.759 6 18 6s2.25-1.009 2.25-2.25S19.241 1.5 18 1.5zM15.75 24a.748.748 0 01-.746-.676l-.683-6.824H13.5a.75.75 0 010-1.5H15c.387 0 .708.291.746.676l.683 6.824h3.143l.683-6.825A.746.746 0 0121 15h1.5v-1.5a4.469 4.469 0 00-1.321-3.18A4.466 4.466 0 0018 9.004a4.523 4.523 0 00-3.857 2.182.751.751 0 01-1.286-.772 6.032 6.032 0 015.138-2.91c1.605 0 3.111.623 4.244 1.754A5.96 5.96 0 0124 13.499v2.25a.75.75 0 01-.75.75h-1.571l-.683 6.825a.748.748 0 01-.746.676h-4.5z" />
    </svg>
  );
}

export default SvgMultipleNeutral1;
