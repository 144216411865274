import * as React from 'react';

function SvgVideoFileMov(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M2.25 24A2.252 2.252 0 010 21.75V2.25A2.252 2.252 0 012.25 0h10.629c.6 0 1.165.234 1.59.658l5.872 5.872c.425.426.659.991.659 1.591v4.629a.75.75 0 01-1.5 0V9h-5.25A2.252 2.252 0 0112 6.75V1.5H2.25a.75.75 0 00-.75.75v19.5c0 .414.336.75.75.75h1.5a.75.75 0 010 1.5h-1.5zM13.5 6.75c0 .414.336.75.75.75h4.939L13.5 1.811V6.75z" />
      <path d="M11.25 24a.75.75 0 01-.75-.75v-4.792l-.857 1.428c-.134.224-.381.364-.643.364s-.509-.14-.643-.364L7.5 18.458v4.792a.75.75 0 01-1.5 0v-7.5a.752.752 0 01.75-.75c.262 0 .508.14.643.365L9 18.042l1.607-2.678a.753.753 0 01.844-.337c.323.09.549.387.549.723v7.5a.75.75 0 01-.75.75zM15.75 24a2.252 2.252 0 01-2.25-2.25v-4.5c0-1.241 1.009-2.25 2.25-2.25S18 16.009 18 17.25v4.5A2.252 2.252 0 0115.75 24zm0-7.5a.75.75 0 00-.75.75v4.5a.75.75 0 001.5 0v-4.5a.75.75 0 00-.75-.75zM21.75 24.004a.75.75 0 01-.624-.334A9.65 9.65 0 0119.5 18.3v-2.55a.75.75 0 011.5 0v2.55c0 1.184.258 2.354.75 3.423a8.197 8.197 0 00.75-3.423v-2.55a.75.75 0 011.5 0v2.55a9.65 9.65 0 01-1.626 5.37.75.75 0 01-.624.334z" />
    </svg>
  );
}

export default SvgVideoFileMov;
