import PropTypes from 'prop-types';

export const propTypes = {
  /** An array of strings which includes the extra prop keys */
  extraProps: PropTypes.array,
  /** The id used when pointing a certain element via its data-for prop */
  id: PropTypes.string,
  /** The tooltip position */
  place: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
  /** Whether the tooltip should be light */
  isLight: PropTypes.bool,
};

export const defaultProps = {
  extraProps: [],
  id: null,
  place: 'top',
  isLight: false,
};
