import { mix } from 'polished';
import { unit, readableColor } from '@embracesbs/helpers';

export const subStyleEnums = {
  CONTAINED: 'contained',
  OUTLINED: 'outlined',
  TEXT: 'text',
  ICON: 'icon',
  TOGGLE: 'toggle',
  CHOICE: 'choice',
  LINK: 'link',
  TERMINATED: 'terminated',
  ROUND: 'round',
  ROUNDOUTLINED: 'roundOutlined',
};

const styleEnums = {
  ...subStyleEnums,
  DISABLED: 'disabled',
  DEFAULT: 'default',
};

const sharedStyles = {
  contained: (theme, isDark) => `
    background-color: ${isDark ? theme.color.dark : theme.color.primary};
    color: ${readableColor(theme.color.light, theme.color.primary)};
    &:hover {
      background-color: ${
        isDark
          ? mix(0.1, theme.color.light, theme.color.dark)
          : mix(0.1, theme.color.dark, theme.color.primary)
      };
    }
  `,
  icon: (theme, text) => `
    background-color: transparent;
    padding: ${theme.spacing.$2} ${theme.spacing.$3};
    text-transform: none;
    ${theme.centeredStyles.flexColumn}
    ${theme.textStyle.description2};
    padding: ${theme.spacing.$4};
    border-radius: ${!text ? '50%' : '0'};
  `,
  toggle: (theme) => `
    background-color: ${theme.color.light};
    border: ${unit(1)} solid ${theme.color.secondary$5};
    border-radius: 0;
    padding: ${theme.spacing.$3};
    text-transform: none;
  `,
  choice: (theme) => `
    background-color: ${theme.color.light}; 
    border: ${unit(1)} solid ${theme.color.secondary$5}};
    border-radius: ${theme.borderRadius.$6};
    height: ${unit(40)};
    padding: 0 ${theme.spacing.$4};
    text-transform: none;
  `,
  link: (theme) => `
    height: ${unit(24)};
    border-radius: ${theme.borderRadius.$6};
    text-transform: none;
    text-decoration: none;
    user-select: none;
  `,
};

const variants = {
  default: {
    contained: (theme) => `
      ${sharedStyles.contained(theme)};
      box-shadow: ${theme.boxShadow.dp['2']};
      height: ${unit(40)};
    `,
    outlined: (theme) => `
      background-color: ${theme.color.light};
      box-shadow: 0 ${unit(1)} ${unit(2)} ${unit(1)} ${theme.color.dark$6};
      color: ${readableColor(theme.color.dark, theme.color.light)};
      height: ${unit(40)};
      &:hover {
        background-color: ${theme.color.dark$9};
        color: ${readableColor(theme.color.primary, theme.color.dark$9)};
      }
    `,
    text: (theme) => `
      background-color: transparent;
      color: ${readableColor(theme.color.dark, theme.color.light)};
      height: ${unit(40)};
      &:hover {
        background-color: ${theme.color.dark$9};
        color: ${readableColor(theme.color.primary, theme.color.dark$9)};
      }
    `,
    icon: (theme, _iconSize, text) => `
      ${sharedStyles.icon(theme, text)};
      &:hover {
        background-color: ${theme.color.dark$9};
        color: ${readableColor(theme.color.primary, theme.color.dark$9)};
      }
    `,
    toggle: (theme) => `
      ${sharedStyles.toggle(theme)};
      &:hover {
        color: ${readableColor(theme.color.primary, theme.color.light)};
      }
    `,
    choice: (theme) => `
      ${sharedStyles.choice(theme)};
      color: ${readableColor(theme.color.secondary, theme.color.light)};
      &:hover {
        background-color: ${theme.color.secondary$6};
        color: ${readableColor(theme.color.secondary, theme.color.light)};
      }
    `,
    link: (theme) => `
      ${sharedStyles.link(theme)};
      background-color: transparent;
      color: ${readableColor(theme.color.secondary, theme.color.light)};
      justify-content: flex-start;
      overflow: hidden;
      &:hover {
        background-color: ${theme.color.secondary$5};
        color: ${readableColor(theme.color.secondary, theme.color.secondary$5)};
      }
    `,
    terminated: (theme) => `
      background-color: ${theme.color.danger};    
      box-shadow: ${theme.boxShadow.dp['2']};
      color: ${theme.color.light};
      height: ${unit(40)};
      &:hover {
        background-color: ${mix(0.1, theme.color.dark, theme.color.danger)};
      }
    `,
    round: (theme, iconSize, _text, isDark) => `
      ${sharedStyles.contained(theme, isDark)};
      border-radius: 50%;
      padding: ${unit(iconSize / 2)};
    `,
    roundOutlined: (theme, iconSize) => `
      background-color: ${theme.color.light}; 
      color: ${readableColor(theme.color.primary, theme.color.light)};
      border: ${unit(1)} solid ${theme.color.primary};
      border-radius: 50%;
      padding: ${unit(iconSize / 2)};
      &:hover {
        background-color: ${theme.color.dark$9};
      }
    `,
  },
  disabled: {
    contained: (theme) => `
      background-color: ${theme.color.dark$5};   
      box-shadow: ${theme.boxShadow.dp['2']};
      color: ${theme.color.dark$5};
      height: ${unit(40)};
    `,
    outlined: (theme) => `
      background-color: ${theme.color.light}; 
      box-shadow: 0 ${unit(1)} ${unit(2)} ${unit(1)} ${theme.color.dark$6};
      color: ${theme.color.dark$5};
      height: ${unit(40)};
    `,
    text: (theme) => `
      background-color: transparent;
      color: ${theme.color.dark$5};
      height: ${unit(40)};
    `,
    icon: (theme, _iconSize, text) => `
      ${sharedStyles.icon(theme, text)};
      color: ${theme.color.dark$5};
    `,
    toggle: (theme) => `
      ${sharedStyles.toggle(theme)};
      color: ${theme.color.dark$5};
    `,
    choice: (theme) => `
      ${sharedStyles.choice(theme)};
      color: ${theme.color.dark$5};
    `,
    link: (theme) => `
      ${sharedStyles.link(theme)};
      color: ${theme.color.secondary$5};
    `,
    terminated: (theme) => `
      background-color: ${theme.color.dark$5};
      color: ${theme.color.dark$5};
    `,
    round: (theme, iconSize) => `
      background-color: ${theme.color.dark$5};
      color: ${theme.color.light};
      border-radius: 50%;
      padding: ${unit(iconSize / 2)};
    `,
    roundOutlined: (theme, iconSize) => `
      background-color: ${theme.color.light}; 
      color: ${theme.color.dark$5};
      border: ${unit(1)} solid ${theme.color.dark$5};
      border-radius: 50%;
      padding: ${unit(iconSize / 2)};
    `,
  },
};

const nativeVariants = {
  default: {
    contained: (theme) => `
      background-color: ${theme.color.primary};
    `,
    outlined: (theme) => `
      background-color: ${theme.color.light};
      border: ${unit(1)} solid ${theme.color.dark$5};
    `,
    text: (theme) => `
      background-color: ${theme.color.light};
    `,
    terminated: (theme) => `
      background-color: ${theme.color.danger};   
    `,
    round: (theme, iconSize, isDark) => `
      background-color: ${isDark ? theme.color.dark : theme.color.primary};
      border-radius: 50%;
      padding: ${unit(iconSize / 2)};
      height: auto;
    `,
    roundOutlined: (theme, iconSize) => `
      background-color: ${theme.color.light}; 
      border: ${unit(1)} solid ${theme.color.primary};
      border-radius: 50%;
      padding: ${unit(iconSize / 2)};
      height: auto;
    `,
  },
  disabled: {
    contained: (theme) => `
      background-color: ${theme.color.dark$5};   
    `,
    outlined: (theme) => `
      background-color: ${theme.color.light}; 
      border: ${unit(1)} solid ${theme.color.dark$5};
    `,
    text: (theme) => `
      background-color: ${theme.color.light};
    `,
    terminated: (theme) => `
      background-color: ${theme.color.dark$5};   
    `,
    round: (theme, iconSize, isDark) => `
      background-color: ${isDark ? theme.color.dark : theme.color.dark$5};
      border-radius: 50%;
      padding: ${unit(iconSize / 2)};
      height: auto;
    `,
    roundOutlined: (theme, iconSize) => `
      background-color: ${theme.color.light}; 
      border: ${unit(1)} solid ${theme.color.dark$5};
      border-radius: 50%;
      padding: ${unit(iconSize / 2)};
      height: auto;
    `,
  },
};

const nativeTextColor = {
  default: {
    contained: (theme) => theme.color.light,
    outlined: (theme) => theme.color.dark,
    text: (theme) => theme.color.dark,
    terminated: (theme) => theme.color.light,
    round: (theme) => theme.color.light,
    roundOutlined: (theme) => theme.color.primary,
  },
  disabled: {
    contained: (theme) => theme.color.dark$5,
    outlined: (theme) => theme.color.dark$5,
    text: (theme) => theme.color.dark$5,
    terminated: (theme) => theme.color.dark$5,
    round: (theme) => theme.color.light,
    roundOutlined: (theme) => theme.color.dark$5,
  },
};

export const webStyles = {
  ...variants,
  btnStyle: (theme, variant, isDisabled, iconSize, text, isDark) => ` 
    border: 0;
    position: relative;
    ${theme.centeredStyles.flex}
    text-align: ${variant === `link` ? `left` : `center`};
    transition: ${theme.transition.basic};
    padding: 0 ${theme.spacing.$4};
    border-radius: ${theme.borderRadius.$1};
    ${theme.textStyle.button};
    ${variants[isDisabled ? styleEnums.DISABLED : styleEnums.DEFAULT][variant](
      theme,
      iconSize,
      text,
      isDark,
    )};
  `,
};

export const nativeStyles = {
  btnStyle: (theme, variant, isDisabled, iconSize, isDark) => ` 
    background-color: ${isDisabled ? theme.color.dark$6 : theme.color.primary}; 
    justify-content: ${theme.textAlign.center};
    align-items: ${theme.textAlign.center};
    flex-direction: row;
    padding: 0 ${theme.spacing.$3};
    height: ${unit(40)};  
    border-radius: ${theme.borderRadius.$1};
    ${nativeVariants[isDisabled ? styleEnums.DISABLED : styleEnums.DEFAULT][
      variant
    ](theme, iconSize, isDark)};
  `,
  textColor: (theme, variant, isDisabled) =>
    nativeTextColor[isDisabled ? styleEnums.DISABLED : styleEnums.DEFAULT][
      variant
    ](theme),
};
