import * as React from 'react';

function SvgNavigationMenu(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M2.25 18.753a.75.75 0 010-1.5h19.5a.75.75 0 010 1.5H2.25zM2.25 12.753a.75.75 0 010-1.5h19.5a.75.75 0 010 1.5H2.25zM2.25 6.753a.75.75 0 010-1.5h19.5a.75.75 0 010 1.5H2.25z" />
    </svg>
  );
}

export default SvgNavigationMenu;
