import * as React from 'react';

function SvgFileZip(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M12.36 23.998a1.493 1.493 0 01-1.384-.91 1.49 1.49 0 01-.013-1.147l2.181-5.442h-1.896a.75.75 0 010-1.5h1.892a1.503 1.503 0 011.396 2.058L12.355 22.5l1.896-.001a.75.75 0 010 1.5H12.36zM17.249 23.998a.75.75 0 01-.75-.75v-7.5a.75.75 0 011.5 0v7.5a.75.75 0 01-.75.75zM20.249 23.998a.75.75 0 01-.75-.75v-7.5a.75.75 0 01.75-.75H21c1.654 0 3 1.346 3 3s-1.346 3-3 3h-.001v2.25a.75.75 0 01-.75.75zm.751-4.5c.827 0 1.5-.673 1.5-1.5s-.673-1.5-1.5-1.5h-.001l.001 3z" />
      <path d="M2.249 23.998a2.252 2.252 0 01-2.25-2.25v-19.5a2.252 2.252 0 012.25-2.25h10.628c.602 0 1.166.234 1.591.658l5.871 5.872c.425.424.659.989.66 1.59v4.63a.75.75 0 01-1.5 0v-3.75h-5.25a2.252 2.252 0 01-2.25-2.25v-5.25h-9.75a.75.75 0 00-.75.75v19.5c0 .414.336.75.75.75h6a.75.75 0 010 1.5h-6zm11.25-17.25c0 .414.336.75.75.75h4.938l-5.688-5.69v4.94z" />
    </svg>
  );
}

export default SvgFileZip;
