import PropTypes from 'prop-types';
import { theme } from '@embracesbs/helpers';

export const propTypes = {
  /** An array of strings which includs the extra prop keys */
  extraProps: PropTypes.array,
  /** The optional combination of an icon, label along with inputfield in a group */
  children: PropTypes.node.isRequired,
  /** The spacing between the inner elements */
  spacing: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.shape({
      bottom: PropTypes.number,
      left: PropTypes.number,
      right: PropTypes.number,
      top: PropTypes.number,
    }),
  ]),
  /** Positioning of the label */
  isTopLabel: PropTypes.bool,
  /** Text label */
  labelText: PropTypes.string,
  /** If the wrapper should be 100% height */
  isFullHeight: PropTypes.bool,
  /** Whether the wrapped field is a required one */
  isRequired: PropTypes.bool,
};

export const defaultProps = {
  extraProps: [],
  children: null,
  spacing: { bottom: theme.spacing.$4Number },
  isTopLabel: true,
  labelText: '',
  isFullHeight: false,
  isRequired: false,
};
