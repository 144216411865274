import { useEffect } from 'react';

/**
 * Hook for outside click
 *
 * @param {*} ref
 * @param {function} callback
 */
const useOutsideClick = (refs, handler) => {
  useEffect(() => {
    /** Click handler */
    const onClick = (event) => {
      const hasClickedInRefs = refs.some((ref) => {
        return !ref.current || ref.current.contains(event.target);
      });

      if (hasClickedInRefs) {
        return;
      }

      setTimeout(() => {
        handler(event);
      }, 0);
    };

    document.addEventListener('click', onClick, true);
    document.addEventListener('ontouchstart', onClick, true);

    return () => {
      document.removeEventListener('click', onClick, true);
      document.removeEventListener('ontouchstart', onClick, true);
    };
  }, [refs, handler]);
};

export default useOutsideClick;
