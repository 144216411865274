import * as React from 'react';

function SvgPowerToolsDrill(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M19.181 22.5a2.991 2.991 0 01-2.946-2.557l-.601-4.208a3.761 3.761 0 01-3.35-3.001L12.135 12H10.75c-.88 0-1.61-.653-1.732-1.5H6.25c-.965 0-1.75-.785-1.75-1.75V7.5H.75a.75.75 0 010-1.5H4.5V4.75C4.5 3.785 5.285 3 6.25 3h2.768c.122-.847.852-1.5 1.732-1.5h10.5A2.753 2.753 0 0124 4.25v5A2.754 2.754 0 0121.25 12h-.135l1.015 7.102a2.961 2.961 0 01-.566 2.208 2.958 2.958 0 01-2.383 1.19zm-1.461-2.768a1.483 1.483 0 001.671 1.253c.391-.056.736-.26.973-.576.237-.315.336-.705.28-1.095L19.6 12h-2.985l1.105 7.732zm-3.967-7.292a2.243 2.243 0 001.657 1.74L15.099 12h-1.434l.088.44zM10.75 3a.25.25 0 00-.25.25v7c0 .138.112.25.25.25h10.5c.689 0 1.25-.561 1.25-1.25v-5c0-.689-.561-1.25-1.25-1.25h-10.5zm-4.5 1.5a.25.25 0 00-.25.25v4c0 .138.112.25.25.25H9V4.5H6.25z" />
      <path d="M15.75 6a.75.75 0 010-1.5h3a.75.75 0 010 1.5h-3zM15.75 9a.75.75 0 010-1.5h3a.75.75 0 010 1.5h-3z" />
    </svg>
  );
}

export default SvgPowerToolsDrill;
