import * as React from 'react';

function SvgZoomIn(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M9.75 14.999a.75.75 0 01-.75-.75v-3.75H5.25a.75.75 0 010-1.5H9v-3.75a.75.75 0 011.5 0v3.75h3.75a.75.75 0 010 1.5H10.5v3.75a.75.75 0 01-.75.75z" />
      <path d="M23.25 23.999a.743.743 0 01-.53-.22l-6.519-6.52a9.771 9.771 0 01-6.388 2.366c-5.411 0-9.813-4.402-9.814-9.812a9.751 9.751 0 012.873-6.939A9.749 9.749 0 019.811-.001c5.411 0 9.813 4.402 9.814 9.812 0 2.356-.837 4.61-2.364 6.387l6.52 6.521a.744.744 0 010 1.06.75.75 0 01-.531.22zM9.813 1.499a8.26 8.26 0 00-5.88 2.435 8.26 8.26 0 00-2.434 5.879 8.26 8.26 0 002.435 5.878 8.259 8.259 0 005.878 2.434c2.214 0 4.295-.86 5.864-2.42l.031-.031a8.256 8.256 0 002.418-5.863c0-4.583-3.729-8.311-8.312-8.312z" />
    </svg>
  );
}

export default SvgZoomIn;
