import PropTypes from 'prop-types';
import { theme, requireOneOf } from '@embracesbs/helpers';

const oneOfProps = {
  headerTitle: PropTypes.string,
  headerChildren: PropTypes.node,
};

export const propTypes = {
  /** Inner spacing of the accordion */
  spacing: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.shape({
      bottom: PropTypes.number,
      left: PropTypes.number,

      right: PropTypes.number,
      top: PropTypes.number,
    }),
  ]),
  /** Has a top border */
  hasTopBorder: PropTypes.bool,
  /** Unique id */
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  /** Title header */
  headerTitle: (props) => requireOneOf(oneOfProps, props),
  /** Inner content of the accordion */
  children: PropTypes.node,
  /** On toggle callback */
  onArrowToggle: PropTypes.func,
  /** Extra props  */
  extraProps: PropTypes.array,
  /** Whether is open or not */
  isOpen: PropTypes.bool,
  /** Disabled the setOpen click event */
  isDisabled: PropTypes.bool,
  /** Optional second header title */
  rightHeaderTitle: PropTypes.string,
  /** Children content of the header */
  headerChildren: (props) => requireOneOf(oneOfProps, props),
  /** Animated arrow on click */
  isAnimated: PropTypes.bool,
  /** What kind of hover effect */
  hover: PropTypes.oneOf(['none', 'full', 'arrowToggle']),
};

export const defaultProps = {
  spacing: theme.spacing.$6Number,
  hasTopBorder: false,
  id: null,
  headerTitle: null,
  rightHeaderTitle: null,
  children: null,
  onArrowToggle: () => {},
  extraProps: [],
  isOpen: false,
  isDisabled: false,
  headerChildren: null,
  isAnimated: true,
  hover: 'arrowToggle',
};
