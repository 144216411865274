import * as React from 'react';

function SvgLayersFront(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M2.25 19.5A2.252 2.252 0 010 17.25v-15A2.252 2.252 0 012.25 0h15a2.252 2.252 0 012.25 2.25.75.75 0 01-1.5 0 .75.75 0 00-.75-.75h-15a.75.75 0 00-.75.75v15c0 .414.336.75.75.75a.75.75 0 010 1.5z" />
      <path d="M6.75 24a2.252 2.252 0 01-2.25-2.25v-15A2.252 2.252 0 016.75 4.5h15A2.252 2.252 0 0124 6.75v15A2.252 2.252 0 0121.75 24h-15zm0-18a.75.75 0 00-.75.75v15c0 .414.336.75.75.75h15a.75.75 0 00.75-.75v-15a.75.75 0 00-.75-.75h-15z" />
    </svg>
  );
}

export default SvgLayersFront;
