import * as React from 'react';

function SvgStove(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path
        d="M6.594 20.44v.72m2.796 0V19m2.797 1.44v.72m5.593-.72v.72m-2.796 0V19m-9.789 4.32H19.18M6.594 4.6H17.78v10.56c0 .53-.418.96-.933.96H7.526c-.515 0-.932-.43-.932-.96V4.6zm-1.399 0H19.18M3.797 7.48h2.797m13.983 0h-2.796M9.39 4.6c0-1.59 1.252-2.88 2.797-2.88 1.545 0 2.797 1.29 2.797 2.88"
        stroke="#262338"
        strokeWidth={1.5}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgStove;
