import * as React from 'react';

function SvgHouseNature(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M.751 23.999a.75.75 0 010-1.5h.75v-5.625l-.3.225a.753.753 0 01-1.05-.15.75.75 0 01.15-1.05l6-4.5a.745.745 0 01.9 0l6 4.5a.75.75 0 01-.899 1.2l-.301-.225v5.625h6v-1.577a3.743 3.743 0 01-3-3.673c0-4.618 2.953-10.584 3.079-10.835.128-.256.385-.415.671-.415s.543.159.671.415c.126.252 3.079 6.217 3.079 10.835a3.743 3.743 0 01-3 3.673v1.577h3.75a.75.75 0 010 1.5H.751zm9.75-1.5v-6.75l-3.75-2.812-3.75 2.812v6.75h1.5v-2.25c0-1.241 1.009-2.25 2.25-2.25s2.25 1.009 2.25 2.25v2.25h1.5zm-3 0v-2.25a.75.75 0 00-1.5 0v2.25h1.5zm11.25-13.936c-.869 2.037-2.25 5.737-2.25 8.686 0 1.241 1.009 2.25 2.25 2.25s2.25-1.009 2.25-2.25c0-2.951-1.381-6.65-2.25-8.686zM3.743 7.499A3.756 3.756 0 01.137 4.761 3.75 3.75 0 013.75.008c.638 0 1.272.167 1.834.484.565.319 1.03.768 1.36 1.312.409-.2.858-.305 1.317-.305a3.007 3.007 0 013 3.012 3.006 3.006 0 01-3 2.988H3.743zm.005-5.991a2.247 2.247 0 00-2.167 2.849 2.252 2.252 0 002.162 1.642h4.515a1.504 1.504 0 001.5-1.494 1.487 1.487 0 00-.435-1.062 1.485 1.485 0 00-1.057-.444c-.419 0-.811.17-1.093.467a.758.758 0 01-.725.211.752.752 0 01-.542-.527 2.228 2.228 0 00-1.06-1.352 2.242 2.242 0 00-1.098-.29z" />
    </svg>
  );
}

export default SvgHouseNature;
