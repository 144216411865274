import PropTypes from 'prop-types';

const basePropTypes = {
  /** The fit of the image  */
  fit: PropTypes.oneOf(['fit', 'stretch', 'center']),
  /** Alt text of the image */
  alt: PropTypes.string,
  /** An array of strings which includes the extra prop keys */
  extraProps: PropTypes.array,
  /** The shape of the image */
  imageShape: PropTypes.oneOf(['circle', 'rounded', 'default']),
  /** The height of the image */
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Image source */
  src: PropTypes.string.isRequired,
  /** The width of the image */
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** The spacing around the Image */
  spacing: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.shape({
      bottom: PropTypes.number,
      left: PropTypes.number,
      right: PropTypes.number,
      top: PropTypes.number,
    }),
  ]),
  /** onLoad function */
  onLoad: PropTypes.func,
};

const baseDefaultProps = {
  alt: 'image',
  fit: 'fit',
  extraProps: [],
  imageShape: 'default',
  height: '',
  width: '',
  spacing: 0,
  onLoad: () => {},
};

export const webPropTypes = {
  ...basePropTypes,
  /** Fallback image source (optional) */
  fallbackSrc: PropTypes.string,
};

export const nativePropTypes = {
  ...basePropTypes,
};

export const webDefaultProps = {
  ...baseDefaultProps,
  fallbackSrc: null,
};

export const nativeDefaultProps = {
  ...baseDefaultProps,
};
