import * as React from 'react';

function SvgSingleNeutralActionsShare1(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M16.5 9.75c-2.688 0-4.875-2.187-4.875-4.875S13.812 0 16.5 0s4.875 2.187 4.875 4.875S19.188 9.75 16.5 9.75zm0-8.25c-1.861 0-3.375 1.514-3.375 3.375S14.639 8.25 16.5 8.25s3.375-1.514 3.375-3.375S18.361 1.5 16.5 1.5z" />
      <path d="M23.25 18a.75.75 0 01-.75-.75 5.986 5.986 0 00-2.667-4.988 5.962 5.962 0 00-4.504-.897 5.964 5.964 0 00-3.819 2.551.746.746 0 01-1.04.207.747.747 0 01-.207-1.041 7.45 7.45 0 014.773-3.189 7.451 7.451 0 015.631 1.121A7.487 7.487 0 0124 17.25a.75.75 0 01-.75.75zM.75 24a.75.75 0 01-.75-.75v-9a.75.75 0 011.5 0V15H6c1.8 0 3.324 1.268 3.675 3h3.075a3.754 3.754 0 013.75 3.75.75.75 0 01-.75.75H1.5v.75a.75.75 0 01-.75.75zm14.121-3a2.264 2.264 0 00-2.121-1.5H6A.75.75 0 016 18h2.118A2.262 2.262 0 006 16.5H1.5V21h13.371z" />
    </svg>
  );
}

export default SvgSingleNeutralActionsShare1;
