import * as React from 'react';

function SvgLegalCertificate(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M.75 24a.75.75 0 010-1.5 3.754 3.754 0 003.75-3.75v-6a.75.75 0 011.5 0v6a5.222 5.222 0 01-1.576 3.75h8.326a3.754 3.754 0 003.75-3.75v-15c0-.823.267-1.607.752-2.25H9.75a.75.75 0 010-1.5h10.5C22.318 0 24 1.682 24 3.75S22.318 7.5 20.25 7.5A3.725 3.725 0 0118 6.748V18.75A5.256 5.256 0 0112.75 24h-12zm19.5-22.5C19.009 1.5 18 2.509 18 3.75S19.009 6 20.25 6s2.25-1.009 2.25-2.25-1.009-2.25-2.25-2.25z" />
      <path d="M7.731 10.05c-.2 0-.399-.049-.577-.143L5.25 8.913l-1.902.995a1.23 1.23 0 01-1.792-1.294l.364-2.112-1.543-1.5a1.228 1.228 0 01.687-2.094l2.126-.307.952-1.917A1.24 1.24 0 016.354.677L7.309 2.6l2.136.308c.463.067.844.384.994.828.147.447.025.944-.311 1.268L8.583 6.503l.364 2.111a1.233 1.233 0 01-1.216 1.436zM5.25 7.379c.2 0 .4.049.578.143l1.549.809-.297-1.718c-.068-.4.065-.806.355-1.087l1.25-1.212-1.727-.249a1.232 1.232 0 01-.928-.669l-.78-1.57-.778 1.568a1.238 1.238 0 01-.915.67l-1.736.251 1.247 1.213c.289.281.422.686.355 1.084L3.127 8.33l1.548-.81c.176-.092.375-.141.575-.141zM8.25 18a.75.75 0 010-1.5h6a.75.75 0 010 1.5h-6zM8.25 15a.75.75 0 010-1.5h6a.75.75 0 010 1.5h-6zM11.25 12a.75.75 0 010-1.5h3a.75.75 0 010 1.5h-3zM11.25 9a.75.75 0 010-1.5h3a.75.75 0 010 1.5h-3z" />
    </svg>
  );
}

export default SvgLegalCertificate;
