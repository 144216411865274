import * as React from 'react';

function SvgCalendarAdd(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M12 20.25a.75.75 0 01-.75-.75v-2.25H9a.75.75 0 010-1.5h2.25V13.5a.75.75 0 011.5 0v2.25H15a.75.75 0 010 1.5h-2.25v2.25a.75.75 0 01-.75.75z" />
      <path d="M2.25 24A2.252 2.252 0 010 21.75V5.25A2.252 2.252 0 012.25 3H6V.75a.75.75 0 011.5 0V3h9V.75a.75.75 0 011.5 0V3h3.75A2.252 2.252 0 0124 5.25v16.5A2.252 2.252 0 0121.75 24H2.25zm-.75-2.25c0 .414.336.75.75.75h19.5a.75.75 0 00.75-.75V10.5h-21v11.25zM22.5 9V5.25a.75.75 0 00-.75-.75H18V6a.75.75 0 01-1.5 0V4.5h-9V6A.75.75 0 016 6V4.5H2.25a.75.75 0 00-.75.75V9h21z" />
    </svg>
  );
}

export default SvgCalendarAdd;
