import React from 'react';
import { mix } from 'polished';
import { Scrollbars } from 'react-custom-scrollbars';
import { getExtraProps, withTheme } from '@embracesbs/helpers';
import { propTypes, defaultProps, thumbPropTypes } from './ScrollBar.props';

/**
 * Return scrollbar from package
 * @param {array} extraProps - An array of strings which includes the extra prop keys
 * @param {boolean} hideTracksWhenNotNeeded - Auto-hide when needed
 * @param {boolean} universal - Universal mode ensure that the initial markup on client and server are the same
 * @param {boolean} autoHide - Auto-hide setting
 * @param {number} autoHeightMin - Auto-hide minimum
 * @param {function} onScrollToTheBottom - Callback is invoked When the scrollbar reach the bottom of the content
 * @param {node} children - The children of the scrollbar
 * @param {boolean} isDark - Is the scrollbar on dark mode
 */
const ScrollBar = (props) => {
  const {
    extraProps,
    hideTracksWhenNotNeeded,
    universal,
    autoHide,
    autoHeightMin,
    onScrollToTheBottom,
    isDark,
    children,
    theme,
    forwardedRef,
  } = props;

  const color = isDark
    ? mix(0.1, theme.color.dark, theme.color.light)
    : mix(0.2, theme.color.dark, theme.color.light);
  /**
   * Render thumbs
   * @param {*} param0
   */
  const renderThumb = ({ style, ...thumbProps }) => {
    const { style: styleExtraProp, ...otherExtraProps } =
      getExtraProps(null, extraProps, 'Thumb') || {};

    return (
      <div
        ref={forwardedRef}
        className="thumb"
        style={{
          ...style,
          ...{
            backgroundColor: color,
            borderRadius: 'inherit',
            cursor: 'pointer',
          },
          ...styleExtraProp,
        }}
        {...thumbProps}
        {...otherExtraProps}
      />
    );
  };
  renderThumb.propTypes = thumbPropTypes;

  return (
    <Scrollbars
      onScrollFrame={({ scrollHeight, clientHeight, scrollTop }) => {
        if (clientHeight + scrollTop >= scrollHeight) {
          onScrollToTheBottom();
        }
      }}
      renderThumbHorizontal={renderThumb}
      renderThumbVertical={renderThumb}
      renderView={({ style, ...viewProps }) => (
        <div
          ref={forwardedRef}
          className="view"
          style={{ ...style }}
          // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
          tabIndex={0}
          {...viewProps}
          {...getExtraProps(null, extraProps, 'View')}
        />
      )}
      {...getExtraProps(props, extraProps)}
      hideTracksWhenNotNeeded={hideTracksWhenNotNeeded}
      universal={universal}
      ref={forwardedRef}
      autoHide={autoHide}
      autoHeightMin={autoHeightMin}
    >
      {children}
    </Scrollbars>
  );
};

ScrollBar.propTypes = propTypes;
ScrollBar.defaultProps = defaultProps;

export default withTheme(ScrollBar);
