import * as React from 'react';

function SvgMessagingWhatsapp(props) {
  return (
    <svg data-name="Outline Version" viewBox="0 0 24 24" {...props}>
      <path d="M14.26 18.51h.43a4.23 4.23 0 003.87-2.17 2.11 2.11 0 00.15-2.08 1.53 1.53 0 00-.73-.55l-.14-.07s-1.73-.85-2.09-1a1.2 1.2 0 00-1.54.43c-.17.25-.35.51-.48.68a8.27 8.27 0 01-3.57-3l.23-.21a1.52 1.52 0 00.41-.54 1.26 1.26 0 000-1.19c-.06-.12-.3-.72-.54-1.28l-.35-.84a1.65 1.65 0 00-1.29-1 2.18 2.18 0 00-2 .58 5.14 5.14 0 00-1 5.67 11.19 11.19 0 008.64 6.57zM7.74 7.36a.68.68 0 01.45-.14.64.64 0 01.32.07l.35.84c.21.51.43 1 .54 1.28L9 9.8a1.14 1.14 0 00-.25 1.51 9.76 9.76 0 004.45 3.88 1.18 1.18 0 001.53-.25c.18-.21.45-.58.61-.82.33.14 1.26.59 1.84.88l.16.08h.09a1.19 1.19 0 01-.17.5A2.81 2.81 0 0114.37 17a9.67 9.67 0 01-7.31-5.55 3.63 3.63 0 01.68-4.09z" />
      <path d="M.77 22.36a.75.75 0 00.94 1L5.7 22A12 12 0 0024 11.91a12 12 0 00-24 0 11.75 11.75 0 002.06 6.67zm.72-10.45A10.5 10.5 0 1112 22.32a10.5 10.5 0 01-5.78-1.72.75.75 0 00-.64-.09l-2.91.92.93-2.73a.75.75 0 00-.11-.7 10.27 10.27 0 01-2-6.09z" />
    </svg>
  );
}

export default SvgMessagingWhatsapp;
