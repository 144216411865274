import * as React from 'react';

function SvgGraduate(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M12 14.8c-.1 0-.2 0-.3-.1l-11-5C.4 9.6.2 9.3.3 9c0-.3.2-.6.5-.7l11-4c.2-.1.3-.1.5 0l11 4c.3.1.5.4.5.7 0 .3-.2.6-.4.7l-11 5c-.2 0-.3.1-.4.1zM3 9.1l9 4.1 9-4.1-9-3.3-9 3.3z" />
      <path d="M1 15.8c-.4 0-.8-.3-.8-.8V9c0-.4.3-.8.8-.8s.8.4.8.8v6c0 .4-.4.8-.8.8zM12 20.8c-.2 0-.5 0-.7-.1l-4.8-1.9c-.8-.3-1.2-1-1.2-1.8v-5c0-.4.3-.8.8-.8s.8.3.8.8v4.9c0 .2.1.3.3.4l4.8 1.9h.3l4.8-1.9c.2-.1.3-.2.3-.4V12c0-.4.3-.8.8-.8s.8.3.8.8v4.9c0 .8-.5 1.5-1.2 1.8L13 20.6c-.5.1-.8.2-1 .2z" />
    </svg>
  );
}

export default SvgGraduate;
