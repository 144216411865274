import * as React from 'react';

function SvgRealEstateUpdateHouseSync(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M2.251 14.75a.755.755 0 01-.26-.047l-.022-.008a.732.732 0 01-.249-.165l-1.5-1.5a.752.752 0 01.53-1.281c.2 0 .389.078.53.22l.22.22v-.439c0-2.33.79-4.624 2.224-6.46a10.43 10.43 0 016.993-3.96c.432-.053.871-.08 1.304-.08 2.349 0 4.576.77 6.441 2.225a.743.743 0 01.283.499.743.743 0 01-.153.553.746.746 0 01-1.054.13A8.888 8.888 0 0012.02 2.75c-.371 0-.748.023-1.119.069a8.945 8.945 0 00-5.995 3.394 9.043 9.043 0 00-1.905 5.537v.439l.22-.22a.744.744 0 011.06 0 .744.744 0 010 1.06l-1.5 1.5a.763.763 0 01-.246.164l-.027.01a.725.725 0 01-.257.047zM11.979 22.255c-2.349 0-4.576-.769-6.44-2.224a.752.752 0 01.923-1.184 8.89 8.89 0 005.518 1.907c.372 0 .75-.023 1.122-.069a8.939 8.939 0 005.994-3.396 9.039 9.039 0 001.905-5.539v-.44l-.22.22a.744.744 0 01-1.06 0c-.142-.141-.22-.33-.22-.53s.078-.389.22-.53l1.5-1.5a.75.75 0 01.245-.163l.024-.009a.729.729 0 01.554.012.75.75 0 01.238.16l1.5 1.5c.142.141.22.33.22.53s-.078.389-.22.53a.749.749 0 01-1.06 0l-.22-.22v.439c0 2.33-.789 4.625-2.222 6.462a10.432 10.432 0 01-6.993 3.961c-.436.055-.875.083-1.308.083z" />
      <path d="M9.751 17c-.827 0-1.5-.673-1.5-1.5v-2.25a.75.75 0 011.5 0v2.25h4.5v-2.25a.75.75 0 011.5 0v2.25c0 .827-.673 1.5-1.5 1.5h-4.5zM7.501 11.75a.751.751 0 01-.48-1.327l4.5-3.75a.753.753 0 01.96 0l4.5 3.75a.744.744 0 01.267.508.747.747 0 01-1.227.644l-4.02-3.35-4.02 3.35a.743.743 0 01-.48.175z" />
    </svg>
  );
}

export default SvgRealEstateUpdateHouseSync;
