import * as React from 'react';

function SvgVideoFileMp4(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M2.259 24a2.252 2.252 0 01-2.25-2.25V2.25A2.252 2.252 0 012.259 0h10.629c.601 0 1.166.234 1.591.658L20.35 6.53c.425.425.659.99.659 1.59v4.629a.75.75 0 01-1.5 0V9h-5.25a2.252 2.252 0 01-2.25-2.25V1.5h-9.75a.75.75 0 00-.75.75v19.5c0 .414.336.75.75.75a.75.75 0 010 1.5zm11.25-17.25c0 .414.336.75.75.75h4.939l-5.689-5.69v4.94z" />
      <path d="M9.759 24a.75.75 0 01-.75-.75v-4.792l-.857 1.428c-.134.224-.381.364-.643.364s-.509-.14-.643-.364l-.857-1.428v4.792a.75.75 0 01-1.5 0v-7.5A.752.752 0 015.26 15c.262 0 .508.14.643.365l1.607 2.678 1.607-2.678a.749.749 0 011.392.385v7.5a.75.75 0 01-.75.75zM12.759 24a.75.75 0 01-.75-.75v-7.5a.75.75 0 01.75-.75h.75c1.654 0 3 1.346 3 3s-1.346 3-3 3v2.25a.75.75 0 01-.75.75zm.75-4.5c.827 0 1.5-.673 1.5-1.5s-.673-1.5-1.5-1.5v3zM23.241 24a.75.75 0 01-.75-.75v-.75h-3.732a.748.748 0 01-.739-.879 9.04 9.04 0 014.889-6.529.755.755 0 01.728.036.745.745 0 01.354.637v7.485a.75.75 0 01-.75.75zm-.75-3v-3.909A7.57 7.57 0 0019.707 21h2.784z" />
    </svg>
  );
}

export default SvgVideoFileMp4;
