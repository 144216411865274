const baseStyles = {};

export const webStyles = {
  ...baseStyles,
  badge: (theme, unit) => `
    display: inline-flex;
    align-items: center;
    justify-content: center;
    ${theme.textStyle.caption};
    border-radius: ${theme.borderRadius.$6};
    box-sizing: border-box;
    min-height: ${unit(16)};
    min-width: ${unit(16)};
    padding: 0 ${theme.spacing.$2};
  `,
  status: (unit, size, status) => `
    border-radius: 100%;
    border: ${size ? unit(size / 6) : unit(3)} solid #fff;
    min-width: ${size ? unit(size) : unit(16)};
    min-height: ${size ? unit(size) : unit(16)};
    padding: 0;
    background: ${status};
  `,
  toggle: (theme, unit) => `
    border: ${unit(3)} solid ${theme.color.light};
    min-height: ${unit(16)};
    min-width: ${unit(16)};
  `,
  contextual: (theme) => `
    border-radius: ${theme.borderRadius.$2};
    padding: ${theme.spacing.$1} ${theme.spacing.$3};
    color: ${theme.color.light};
    flex-shrink: 0;
  `,
};

export const nativeStyles = {
  ...baseStyles,
  badge: (theme) => `
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom-left-radius: ${theme.borderRadius.$6};
    border-bottom-right-radius: ${theme.borderRadius.$6};
    border-top-left-radius: ${theme.borderRadius.$6};
    border-top-right-radius: ${theme.borderRadius.$6};
    box-sizing: border-box;
    padding-top: 0;
    padding-left: ${theme.spacing.$1};
    padding-bottom: ${theme.spacing.$1};
    padding-right: ${theme.spacing.$1};
    min-height: ${theme.spacing.$5};
    min-width: ${theme.spacing.$5};
    color: ${theme.color.light};
  `,
  status: (theme, unit) => `
    border-width: ${unit(2)};
    border-style: solid;
    border-color: ${theme.color.light};
    width: ${unit(10)};
    max-height: ${unit(10)};
  `,
  contextual: (theme) => `
    border-radius: ${theme.borderRadius.$2};
    color: ${theme.color.light};
    padding-left: ${theme.spacing.$3};
    padding-right: ${theme.spacing.$3};
  `,
  counter: (theme) => `
    padding-left: ${theme.spacing.$3};
    padding-right: ${theme.spacing.$3};
  `,
  text: (theme) => `
    color: ${theme.color.light};
  `,
};
