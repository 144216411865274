import * as React from 'react';

function SvgSchoolTestResults(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M2.25 21A2.253 2.253 0 010 18.75V2.25C0 1.009 1.01 0 2.25 0h10.629c.601 0 1.165.234 1.59.658l2.872 2.872c.425.425.659.99.659 1.591V9.75a.75.75 0 01-1.5 0V5.121c0-.197-.08-.39-.219-.53l-2.872-2.872a.748.748 0 00-.53-.219H2.25a.75.75 0 00-.75.75v16.5c0 .414.336.75.75.75h3a.75.75 0 010 1.5h-3z" />
      <path d="M16.5 24c-.07 0-.14-.01-.208-.029l-3.75-1.083a.755.755 0 01-.542-.721v-4.031l-1.5-.467V20A.75.75 0 019 20v-3.35a.726.726 0 01.04-.238.74.74 0 01.09-.185c.007-.011.016-.022.025-.033a.725.725 0 01.115-.119l.04-.031a.727.727 0 01.18-.098l6.753-2.461a.752.752 0 01.514 0l6.75 2.46a.75.75 0 01-.034 1.421L21 18.135v4.031a.755.755 0 01-.542.721l-3.75 1.083a.732.732 0 01-.208.03zm0-1.531l3-.866v-3.001l-2.777.864a.731.731 0 01-.446 0l-2.777-.864v3.001l3 .866zm0-4.505l4.406-1.371-4.406-1.605-4.406 1.605 4.406 1.371zM3.75 5.25a.75.75 0 010-1.5h1.5a.75.75 0 010 1.5h-1.5zM8.25 5.25a.75.75 0 010-1.5h4.5a.75.75 0 010 1.5h-4.5zM3.75 9a.75.75 0 010-1.5h1.5a.75.75 0 010 1.5h-1.5zM8.25 9a.75.75 0 010-1.5h4.5a.75.75 0 010 1.5h-4.5zM3.75 12.75a.75.75 0 010-1.5h1.5a.75.75 0 010 1.5h-1.5zM3.75 16.5a.75.75 0 010-1.5h1.5a.75.75 0 010 1.5h-1.5zM8.25 12.75a.75.75 0 010-1.5h4.5a.75.75 0 010 1.5h-4.5z" />
    </svg>
  );
}

export default SvgSchoolTestResults;
