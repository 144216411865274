import * as React from 'react';

function SvgScale(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M18.75 24c-.827 0-1.5-.673-1.5-1.5H6.75c0 .827-.673 1.5-1.5 1.5h-3c-.827 0-1.5-.673-1.5-1.5v-3c0-.827.673-1.5 1.5-1.5V6c-.827 0-1.5-.673-1.5-1.5v-3c0-.827.673-1.5 1.5-1.5h3c.827 0 1.5.673 1.5 1.5h10.5c0-.827.673-1.5 1.5-1.5h3c.827 0 1.5.673 1.5 1.5v3c0 .827-.673 1.5-1.5 1.5v12c.827 0 1.5.673 1.5 1.5v3c0 .827-.673 1.5-1.5 1.5h-3zm0-1.5h3v-3h-3v3zm-16.5 0h3v-3h-3v3zm15-1.5v-1.5c0-.827.673-1.5 1.5-1.5h1.5V6h-1.5c-.827 0-1.5-.673-1.5-1.5V3H6.75v1.5c0 .827-.673 1.5-1.5 1.5h-1.5v12h1.5c.827 0 1.5.673 1.5 1.5V21h10.5zm1.5-16.5h3v-3h-3v3zm-16.5 0h3v-3h-3v3z" />
    </svg>
  );
}

export default SvgScale;
