export const baseStyles = {};

export const webStyles = {
  ...baseStyles,
  styledAccordion: (theme, unit) => `
    display: flex;
    flex-direction: column;
    border-bottom: ${unit(1)} solid ${theme.color.dark$6};
  `,
  styledCollapsible: (theme) => `
    background-color: ${theme.color.light};
    border: none;
    text-align: left;
    font-size: ${theme.fontSize.$6};
    padding-left: 0;
    font-family: ${theme.font.book};
    cursor: ${theme.cursor.pointer};
`,
  styledContent: (theme) => `
     overflow: hidden;
     transition: ${theme.transition.accordion};
   `,
  styledChildren: (theme) => `
     transition: ${theme.transition.accordion};
   `,
};
