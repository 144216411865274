import * as React from 'react';

function SvgAlarmBellOff(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M1.501 24a.743.743 0 01-.53-.22.744.744 0 010-1.06l21-21a.744.744 0 011.06 0c.142.141.22.33.22.53s-.078.389-.22.53l-21 21a.743.743 0 01-.53.22zM11.999 24.001a2.835 2.835 0 01-2.718-2.039A.751.751 0 0110 21c.33 0 .626.221.719.538.101.343.329.626.642.797a1.334 1.334 0 001.924-.796.755.755 0 01.929-.509.751.751 0 01.51.931 2.824 2.824 0 01-1.923 1.923 2.81 2.81 0 01-.802.117zM10.5 19.5a.75.75 0 010-1.5h9.21c-.482-1.232-.96-3.469-.96-7.5a6.643 6.643 0 00-.083-.792.748.748 0 111.48-.246c.055.332.09.672.103 1.009 0 6.158 1.155 7.611 1.24 7.71A.752.752 0 0121 19.5H10.5zM4.5 16.5a.75.75 0 01-.75-.75V10.5a8.223 8.223 0 011.424-4.628 8.197 8.197 0 016.078-3.583V.75a.75.75 0 011.5 0v1.538a8.23 8.23 0 013.882 1.392.751.751 0 01-.843 1.242 6.702 6.702 0 00-5.059-1.045 6.698 6.698 0 00-4.317 2.838 6.724 6.724 0 00-1.165 3.786v5.25a.75.75 0 01-.75.749z" />
    </svg>
  );
}

export default SvgAlarmBellOff;
