import PropTypes from 'prop-types';
import { theme } from '@embracesbs/helpers';

export const propTypes = {
  /** An array of strings which includs the extra prop keys */
  extraProps: PropTypes.array,

  /** The child elements to be shown in the card header */
  children: PropTypes.node,

  /** Defines spacing within the component */
  spacing: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.shape({
      bottom: PropTypes.number,
      left: PropTypes.number,
      right: PropTypes.number,
      top: PropTypes.number,
    }),
  ]),

  /** Aligning the content within the card footer */
  align: PropTypes.oneOf(['flex-start', 'flex-end', 'center']),
};

export const defaultProps = {
  extraProps: [],
  children: null,
  spacing: {
    top: theme.spacing.$5Number,
    right: theme.spacing.$6Number,
    bottom: theme.spacing.$5Number,
    left: theme.spacing.$6Number,
  },
  align: 'flex-start',
};
