import * as React from 'react';

function SvgReadEmailAt(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M12 23.999a11.92 11.92 0 01-8.484-3.514 11.92 11.92 0 01-3.515-8.484c0-3.205 1.248-6.218 3.514-8.485C5.782 1.25 8.795.001 12 .001s6.218 1.248 8.485 3.514A11.92 11.92 0 0124 12v2.25A3.754 3.754 0 0120.25 18a3.77 3.77 0 01-3.48-2.365A6.009 6.009 0 0112 18c-3.308 0-6-2.692-6-6s2.692-6 6-6 6 2.692 6 6v2.25c0 1.241 1.009 2.25 2.25 2.25s2.25-1.009 2.25-2.25V12c0-2.804-1.092-5.441-3.075-7.424A10.433 10.433 0 0012 1.501a10.431 10.431 0 00-7.424 3.075A10.431 10.431 0 001.501 12a10.43 10.43 0 003.075 7.424 10.428 10.428 0 007.423 3.074c2.257 0 4.494-.746 6.299-2.1a.749.749 0 11.9 1.199A12.049 12.049 0 0112 23.999zM12 7.5c-2.481 0-4.5 2.019-4.5 4.5s2.019 4.5 4.5 4.5 4.5-2.019 4.5-4.5-2.019-4.5-4.5-4.5z" />
    </svg>
  );
}

export default SvgReadEmailAt;
