import * as React from 'react';

function SvgImageFileEps(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M2.625 24a2.252 2.252 0 01-2.25-2.25V2.25A2.252 2.252 0 012.625 0h10.629c.601 0 1.165.234 1.59.658l5.872 5.872c.425.425.659.99.659 1.59v4.629a.75.75 0 01-1.5 0V9h-5.25a2.252 2.252 0 01-2.25-2.25V1.5h-9.75a.75.75 0 00-.75.75v19.5c0 .414.336.75.75.75h2.25a.75.75 0 010 1.5h-2.25zm11.25-17.25c0 .414.336.75.75.75h4.939l-5.689-5.689V6.75z" />
      <path d="M9.375 24a2.252 2.252 0 01-2.25-2.25v-4.5A2.252 2.252 0 019.375 15h1.5a.75.75 0 010 1.5h-1.5a.75.75 0 00-.75.75v2.25h2.25a.75.75 0 010 1.5h-2.25v.75c0 .414.336.75.75.75h1.5a.75.75 0 010 1.5h-1.5zM13.875 24a.75.75 0 01-.75-.75v-7.5a.75.75 0 01.75-.75h.75c1.654 0 3 1.346 3 3s-1.346 3-3 3v2.25a.75.75 0 01-.75.75zm.75-4.5c.827 0 1.5-.673 1.5-1.5s-.673-1.5-1.5-1.5v3zM19.875 24a.75.75 0 010-1.5h1.5a.75.75 0 00.75-.75c0-.715-.434-1.075-1.2-1.65-.802-.602-1.8-1.35-1.8-2.85a2.252 2.252 0 012.25-2.25h1.5a.75.75 0 010 1.5h-1.5a.75.75 0 00-.75.75c0 .715.434 1.075 1.2 1.65.802.602 1.8 1.35 1.8 2.85a2.252 2.252 0 01-2.25 2.25h-1.5z" />
    </svg>
  );
}

export default SvgImageFileEps;
