import * as React from 'react';

function SvgScaleVertical(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M20.25 23.998a.767.767 0 01-.258-.046l-.016-.006a.748.748 0 01-.256-.167l-3-3a.744.744 0 010-1.06.749.749 0 011.06 0l1.72 1.72V2.559l-1.72 1.72a.744.744 0 01-1.06 0c-.142-.141-.22-.33-.22-.53s.078-.389.22-.53l3-3a.75.75 0 01.245-.163l.024-.009a.738.738 0 01.555.012.74.74 0 01.238.16l3 3c.14.14.218.329.218.529s-.078.389-.22.53a.749.749 0 01-1.06 0L21 2.559v18.879l1.72-1.72a.744.744 0 011.06 0 .749.749 0 010 1.06l-3 3a.74.74 0 01-.245.163l-.026.01a.73.73 0 01-.259.047zM10 24c-.827 0-1.5-.673-1.5-1.5H6c0 .827-.673 1.5-1.5 1.5h-3C.673 24 0 23.327 0 22.5v-3c0-.827.673-1.5 1.5-1.5V6C.673 6 0 5.327 0 4.5v-3C0 .673.673 0 1.5 0h3C5.327 0 6 .673 6 1.5h2.5C8.5.673 9.173 0 10 0h3c.827 0 1.5.673 1.5 1.5v3c0 .827-.673 1.5-1.5 1.5v12c.827 0 1.5.673 1.5 1.5v3c0 .827-.673 1.5-1.5 1.5h-3zm0-1.5h3v-3h-3v3zm-8.5 0h3v-3h-3v3zm7-1.5v-1.5c0-.827.673-1.5 1.5-1.5h1.5V6H10c-.827 0-1.5-.673-1.5-1.5V3H6v1.5C6 5.327 5.327 6 4.5 6H3v12h1.5c.827 0 1.5.673 1.5 1.5V21h2.5zM10 4.5h3v-3h-3v3zm-8.5 0h3v-3h-3v3z" />
    </svg>
  );
}

export default SvgScaleVertical;
