import * as React from 'react';

function SvgHammerWench(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M8.307 11.167a.748.748 0 01-.53-.219l-.693-.69a5.235 5.235 0 01-1.786.313 5.29 5.29 0 01-1.761-.303A5.265 5.265 0 01.521 7.563a5.273 5.273 0 01-.007-4.561.751.751 0 011.206-.209l1.425 1.421c.15.154.338.235.545.237l.039.001a.724.724 0 00.504-.218.76.76 0 00.23-.538.75.75 0 00-.215-.542l-1.44-1.435A.751.751 0 013.014.512 5.248 5.248 0 017.058.296a5.257 5.257 0 013.013 2.707 5.3 5.3 0 01.213 4.055l.615.613a.752.752 0 01.002 1.061.747.747 0 01-1.06.002l-.971-.968a.752.752 0 01-.148-.853 3.776 3.776 0 00-.004-3.264 3.765 3.765 0 00-2.158-1.94 3.79 3.79 0 00-1.815-.177l.574.572a2.25 2.25 0 01.646 1.61 2.25 2.25 0 01-.682 1.593 2.253 2.253 0 01-1.583.645h-.025a2.258 2.258 0 01-1.594-.682l-.541-.54a3.78 3.78 0 00.335 2.185 3.767 3.767 0 002.161 1.938 3.753 3.753 0 002.899-.157.744.744 0 01.853.145l1.048 1.044a.746.746 0 01.002 1.061.744.744 0 01-.531.221zM18.706 23.929a5.288 5.288 0 01-1.765-.304 5.257 5.257 0 01-3.012-2.708 5.295 5.295 0 01-.213-4.053L12.648 15.8a.751.751 0 011.06-1.062l1.422 1.418a.754.754 0 01.148.853 3.775 3.775 0 00.004 3.262 3.765 3.765 0 002.158 1.94 3.79 3.79 0 001.814.177l-.546-.544a2.281 2.281 0 01-.003-3.176 2.247 2.247 0 011.615-.677c.598 0 1.161.23 1.586.648l.551.549a3.774 3.774 0 00-.335-2.181 3.817 3.817 0 00-3.428-2.154c-.567 0-1.116.125-1.632.372a.752.752 0 01-.852-.145l-1.423-1.419a.751.751 0 011.059-1.062l1.067 1.064a5.234 5.234 0 011.78-.31c2.03 0 3.907 1.18 4.781 3.007a5.266 5.266 0 01.007 4.556.747.747 0 01-.679.429.744.744 0 01-.527-.219l-1.425-1.42a.754.754 0 00-.531-.215.755.755 0 00-.546.229.767.767 0 00-.001 1.069l1.417 1.413a.751.751 0 01-.207 1.208 5.232 5.232 0 01-2.276.519z" />
      <path d="M3.033 23.998a2.987 2.987 0 01-2.129-5.091L5.61 14.21a1.49 1.49 0 011.059-.437c.4 0 .776.155 1.059.437l.438.436 6.29-6.083-2.635-2.626a2.242 2.242 0 01-.008-3.166L13.937.653a2.235 2.235 0 011.59-.656c.6 0 1.164.232 1.587.654l6.221 6.2c.424.422.658.983.659 1.581a2.222 2.222 0 01-.652 1.585l-.031.03-2.093 2.09c-.424.423-.988.656-1.589.656s-1.165-.233-1.588-.655l-2.523-2.515-6.29 6.083.618.616c.283.283.439.658.439 1.058a1.49 1.49 0 01-.472 1.091L5.15 23.126a2.98 2.98 0 01-2.117.872zm-1.058-4.04a1.49 1.49 0 001.058 2.54 1.49 1.49 0 001.059-.436l4.695-4.685-1.164-1.154-.954-.951-4.694 4.686zM15.528 1.497a.75.75 0 00-.53.218l-2.117 2.111a.744.744 0 00-.001 1.049l6.221 6.2c.141.141.329.218.53.218s.389-.077.53-.218l2.116-2.113a.734.734 0 00.001-1.048l-6.221-6.2a.746.746 0 00-.529-.217z" />
    </svg>
  );
}

export default SvgHammerWench;
