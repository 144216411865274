import PropTypes from 'prop-types';
import { theme } from '@embracesbs/helpers';

export const propTypes = {
  /** An array of strings which includes the extra prop keys */
  extraProps: PropTypes.array,
  /** Whether the loader is shown on color background */
  hasBgColor: PropTypes.bool,
  /** The size of the progress fill, can be used to create a determinate progress indicator */
  progress: PropTypes.number,
  /** The color of the progress fill */
  progressColor: PropTypes.string,
  /** Variant of the progress indicator */
  variant: PropTypes.oneOf(['circular', 'linear', 'gauge', 'fill']),
  /** Text to show at indicator */
  text: PropTypes.string,
  /** Size of the progress indicator */
  size: PropTypes.number,
  /** Text style */
  textStyle: PropTypes.string,
  /** Text color */
  textColor: PropTypes.string,
  /** Interval */
  interval: PropTypes.number,
};

export const defaultProps = {
  extraProps: [],
  hasBgColor: false,
  progress: null,
  progressColor: theme.color.primary,
  variant: 'linear',
  text: null,
  size: 2,
  textStyle: 'body',
  textColor: '#000000',
  interval: null,
};
