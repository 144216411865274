import * as React from 'react';

function SvgBusinessDealHandshake(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M10.659 19.499a2.09 2.09 0 01-1.585-.725l-3.28-3.676H4.123a1.687 1.687 0 01-1.334.653l-.07-.001H.749a.75.75 0 01.001-1.5h2.001c.095 0 .167-.034.209-.091a.825.825 0 01.04-.115V7.666c-.004-.08-.106-.167-.218-.167L.75 7.5a.75.75 0 010-1.5h2l.052-.001c.802 0 1.497.589 1.662 1.36l3.458-1.383a2.836 2.836 0 012.748.35l.013.01 2.411-1.213a3.108 3.108 0 012.456-.142l4.376 1.601A1.705 1.705 0 0121.283 6h1.967a.75.75 0 010 1.5l-2.027-.001a.219.219 0 00-.224.202L21 14.083c.004.081.098.168.199.168l.035-.001h2.015a.75.75 0 010 1.5h-2l-.053.001c-.486 0-.942-.213-1.264-.579l-4.663.891a.763.763 0 01-.193.01 2.396 2.396 0 01-2.289 1.659l-.063-.001a2.09 2.09 0 01-2.065 1.768zM6.13 13.598a.75.75 0 01.559.25l3.512 3.936a.592.592 0 001.049-.377v-.304l-.758-.758a.752.752 0 01.53-1.281c.2 0 .389.078.53.22l.869.869.032.013a.904.904 0 001.178-.505c.009-.021.016-.044.023-.067l-1.206-1.206a.744.744 0 010-1.06.749.749 0 011.06 0l1.248 1.248h.178a.719.719 0 00.574-1.148l-2.805-3.594-1.52.502a1.907 1.907 0 01-2.321-1.009 1.908 1.908 0 01.278-2.046 1.37 1.37 0 00-.658.087L4.5 8.96v4.638h1.63zm10.57-1.081a2.242 2.242 0 01.428 1.664l2.372-.453V8.025L15.034 6.39a1.588 1.588 0 00-1.265.073l-3.36 1.69a.41.41 0 00-.186.543.403.403 0 00.49.216l3.467-1.146a.75.75 0 11.471 1.425l-.435.144 2.484 3.182z" />
    </svg>
  );
}

export default SvgBusinessDealHandshake;
