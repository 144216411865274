import PropTypes from 'prop-types';

const basePropTypes = {
  /** Indicates if its textarea or input */
  isTextArea: PropTypes.bool,

  /** Placeholder text */
  placeholder: PropTypes.string,

  /** An error text of the input */
  errorMessage: PropTypes.string,

  /** Color of the error message */
  errorMessageColor: PropTypes.oneOf([
    'danger',
    'warning',
    'success',
    'primary',
    'secondary',
  ]),

  /** Indicates if the TextField has an error */
  hasAnError: PropTypes.bool,

  /** An icon name */
  iconName: PropTypes.string,

  /** Indicates wheather the field is disable or not */
  isDisabled: PropTypes.bool,

  /** The value of the input field */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

  /** Function on change value */
  onChange: PropTypes.func,

  /** Function on blur value */
  onBlur: PropTypes.func,

  /** Function on focus value */
  onFocus: PropTypes.func,

  /** An array of strings which includs the extra prop keys */
  extraProps: PropTypes.array,

  /** name of the input */
  name: PropTypes.string,

  /** Height of the component */
  height: PropTypes.number,

  /** Max possible height of the text area */
  maxTextAreaHeight: PropTypes.number,

  /** Indicates wheater is search mode or not */
  isSearchMode: PropTypes.bool,

  /** Max character length for the inputfield */
  maxLength: PropTypes.number,
};

export const nativePropTypes = {
  ...basePropTypes,
  /**
   * the keyboard and the system information about the expected semantic
   * meaning for the content that users enter
   */
  textContentType: PropTypes.string,
  /** Determines which keyboard to open, e.g.numeric. */
  keyboardType: PropTypes.string,
  /** if true, the text input obscures the text entered so that sensitive text like passwords stay secure. */
  secureTextEntry: PropTypes.bool,
  /** The number of lines when using textarea */
  numberOfLines: PropTypes.number,
};

export const webPropTypes = {
  ...basePropTypes,
  /** Type of the input */
  type: PropTypes.string,
  /** Currency type. Yen, usd, eur */
  currencyType: PropTypes.string,
  /** Country code. Used in currency for formatting. */
  countryCode: PropTypes.string,
  /** An unique element target */
  id: PropTypes.string,
  /** Id of the input */
  inputId: PropTypes.string,
  /** On clear callback */
  onClear: () => {},
  /** If in textarea mode the input needs to be 100% height */
  isFullHeight: PropTypes.bool,
  /** Whether the optional icon is on the right */
  hasRightIcon: PropTypes.bool,
  /** Return function when you enter an invalid value for the entered type (password, email, phone) */
  onInvalid: PropTypes.func,
  /** Regex to match the string to */
  regex: PropTypes.instanceOf(RegExp),
  /** Returns true if the regex matches the value, false if it doesn't */
  isRegexValid: PropTypes.func,
};

const baseDefaultProps = {
  extraProps: [],
  isTextArea: false,
  iconName: null,
  hasAnError: false,
  errorMessage: null,
  errorMessageColor: 'danger',
  isSearchMode: false,
  placeholder: null,
  isDisabled: false,
  height: 40,
  maxTextAreaHeight: 140,
  onChange: () => {},
  onBlur: () => {},
  onFocus: () => {},
  name: '',
  value: '',
  maxLength: null,
};

export const webDefaultProps = {
  ...baseDefaultProps,
  id: '',
  type: 'text',
  currencyType: 'EUR',
  countryCode: 'nl-NL',
  inputId: '',
  onClear: () => {},
  onInvalid: () => {},
  isFullHeight: false,
  hasRightIcon: false,
  regex: null,
  isRegexValid: null,
};

export const nativeDefaultProps = {
  ...baseDefaultProps,
  textContentType: 'none',
  keyboardType: 'default',
  secureTextEntry: false,
  numberOfLines: null,
};
