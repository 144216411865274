import * as React from 'react';

function SvgExcel(props) {
  return (
    <svg viewBox="0 0 32 32" {...props}>
      <g data-name="STYLE = COLOR">
        <path
          d="M20 2H9.333A1.333 1.333 0 008 3.333V9l12 7 6 2.532L32 16V9z"
          fill="#21a366"
        />
        <path fill="#107c41" d="M8 9h12v7H8z" />
        <path
          d="M30.667 2H20v7h12V3.333A1.333 1.333 0 0030.667 2z"
          fill="#33c481"
        />
        <path
          d="M20 16H8v12.667A1.333 1.333 0 009.333 30h21.334A1.333 1.333 0 0032 28.667V23z"
          fill="#185c37"
        />
        <path fill="#107c41" d="M20 16h12v7H20z" />
        <path
          d="M16.667 7H8v19h8.667A1.337 1.337 0 0018 24.667V8.333A1.337 1.337 0 0016.667 7z"
          opacity={0.1}
        />
        <path
          d="M15.667 8H8v19h7.667A1.337 1.337 0 0017 25.667V9.333A1.337 1.337 0 0015.667 8z"
          opacity={0.2}
        />
        <path
          d="M15.667 8H8v17h7.667A1.337 1.337 0 0017 23.667V9.333A1.337 1.337 0 0015.667 8z"
          opacity={0.2}
        />
        <path
          d="M14.667 8H8v17h6.667A1.337 1.337 0 0016 23.667V9.333A1.337 1.337 0 0014.667 8z"
          opacity={0.2}
        />
        <path
          data-name="Back Plate"
          d="M1.333 8h13.334A1.333 1.333 0 0116 9.333v13.334A1.333 1.333 0 0114.667 24H1.333A1.333 1.333 0 010 22.667V9.333A1.333 1.333 0 011.333 8z"
          fill="#107c41"
        />
        <path
          d="M3.533 21l3.236-5.014L3.805 11H6.19l1.618 3.187q.223.453.307.676h.021q.16-.362.335-.704L10.2 11h2.189l-3.04 4.958L12.466 21h-2.33l-1.869-3.5a2.922 2.922 0 01-.223-.468h-.028a2.207 2.207 0 01-.216.453L5.877 21z"
          fill="#fff"
        />
        <path fill="none" d="M0 0h32v32H0z" />
      </g>
    </svg>
  );
}

export default SvgExcel;
