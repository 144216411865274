import PropTypes from 'prop-types';
import { webPropTypes as avatarWebPropTypes } from '@embracesbs/component-avatar';

export const propTypes = {
  /** The text the chip can contain */
  text: PropTypes.string,

  /** Defines the avatar properties */
  avatar: PropTypes.shape(avatarWebPropTypes),

  /** An path to a specific Icon, must be related to the Icon component */
  icon: PropTypes.object,

  /** An array of strings which includes the extra prop keys */
  extraProps: PropTypes.array,

  /** Disable the chip */
  isDisabled: PropTypes.bool,

  /** Set the state of the chip to selected */
  isSelected: PropTypes.bool,

  /** Set the state of the chip to errored */
  isError: PropTypes.bool,

  /** onClick function for the chip */
  onClick: PropTypes.func,

  /** Handler when clicked on the button */
  onClose: PropTypes.func,

  /** Optional children */
  children: PropTypes.node,
};

export const defaultProps = {
  text: null,
  avatar: null,
  icon: null,
  extraProps: [],
  isDisabled: false,
  isSelected: false,
  isError: false,
  onClick: null,
  onClose: null,
  children: null,
};
