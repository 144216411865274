import React, { useState, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { getExtraProps, withTheme, unit } from '@embracesbs/helpers';
import Icon from '@embracesbs/component-icon';
import Text from '@embracesbs/component-text';
import Scrollbar from '@embracesbs/component-scrollbar';
import TextFieldStylesHelper from './TextFieldStylesHelper';
import { webPropTypes, webDefaultProps } from './TextField.props';
import { webStyles } from './TextField.styles';

const BorderContainer = styled.div`
  ${({
    inputBorderColor,
    backgroundColor,
    boxShadow,
    isTextArea,
    isFullHeight,
    theme,
  }) => `
    border-radius: ${theme.borderRadius.$1};
    border: ${unit(1)} solid ${inputBorderColor};
    background-color: ${backgroundColor};
    box-shadow: ${boxShadow};
    ${isTextArea && isFullHeight ? `height: 100%;` : ``}
 `}
`;

const TextAreaInput = styled.textarea`
  ${({ theme }) => webStyles.textFieldInput(theme)}
  ${({ color, hasAnIcon, theme, disabled }) => `    
    color: ${color};
    padding-left: ${hasAnIcon ? `0` : theme.spacing.$3};
    height: 100%;
    margin-bottom: -${theme.spacing.$2};
    resize: none;
    ${disabled ? `cursor: ${theme.cursor.notAllowed}` : ''}
  `}
`;

const TextFieldInput = styled.input`
  ${({ theme }) => webStyles.textFieldInput(theme)}
  ${({ color, hasAnIcon, height, theme, hasRightIcon, disabled }) => `
    color: ${color};
    padding-left: ${hasAnIcon && !hasRightIcon ? `0` : theme.spacing.$3};
    height: ${unit(height)};
    ${disabled ? `cursor: ${theme.cursor.notAllowed}` : ''}
  `}
`;

const Container = styled.div`
  ${({ isTextArea, isFullHeight }) => `
    ${isTextArea && isFullHeight ? `height: 100%;` : ``}
  `}
  flex-grow: 1;
`;

const TextFieldContainer = styled.div`
  ${({ theme }) => webStyles.textFieldContainer(theme)}
`;

const ClearTextIconContainer = styled.div`
  ${({ theme }) => `
    background: transparent !important;
    ${`cursor: ${theme.cursor.pointer};`}
  `}
`;

/** Triggers the onChange event of the input element */
const triggerInputChange = (element, value) => {
  const elementTagName = element.tagName.toLowerCase();
  let baseClass = null;

  if (elementTagName === 'input') {
    baseClass = 'HTMLInputElement';
  } else if (elementTagName === 'textarea') {
    baseClass = 'HTMLTextAreaElement';
  }

  if (baseClass) {
    Object.getOwnPropertyDescriptor(
      window[baseClass].prototype,
      'value',
    ).set.call(element, value);

    element.dispatchEvent(new Event('input', { bubbles: true }));
  }
};

/**
 * TextField web component
 *
 * @param {array} extraProps - An array of strings which includs the extra prop keys
 * @param {boolean} isTextArea -  Indicates if its textarea or input
 * @param {string} placeholder - The text of the placeholder in the input
 * @param {boolean} hasAnError - Indicates of an existing error
 * @param {boolean} isSearchMode - Indicates wheater is search mode or not
 * @param {string} errorMessage - The text of the error below the input
 * @param {string} errorMessageColor - error text color
 * @param {boolean} isDisabled - is the input disable
 * @param {string} value - value of the input
 * @param {string} iconName - icon name of optional icon
 * @param {boolean} hasRightIcon - Whether the optional icon is on the right
 * @param {function} onChange - function on change
 * @param {function} onBlur - function on blur
 * @param {function} onFocus - function on focus
 * @param {string} type - Type of the input
 * @param {string} currencyType - Currency type. Yen, usd, eur
 * @param {string} countryCode - Country code. Used in currency for formatting.
 * @param {string} name - name of the input
 * @param {string} id - Unique target for the element
 * @param {string} inputId - Unique target for the inputfield
 * @param {number} height - Height of the input
 * @param {number} maxTextAreaHeight - Max possible height of the text area
 * @param {function} onClear - On deleteing the input text via the closed button
 * @param {number} maxLength - Max character length for the inputfield
 * @param {boolean} isEmptyClearable - is the clear icon apear even when the textfield is empty
 * @param {string} clearIcon - icon name of the clear button
 * @param {bool} isFullHeight - if the textarea needs to be 100% in height
 * @param {regexp} regex - supplied regex to run over the textfield
 * @param {function} isRegexValid - returns whether the regex matches or not
 */
const TextField = (props) => {
  const {
    isTextArea,
    onChange,
    onBlur,
    onFocus,
    extraProps,
    placeholder,
    iconName,
    hasRightIcon,
    isDisabled,
    errorMessage,
    errorMessageColor,
    hasAnError,
    isSearchMode,
    height,
    maxTextAreaHeight,
    type,
    currencyType,
    countryCode,
    name,
    id,
    value,
    inputId,
    onClear,
    maxLength,
    isEmptyClearable,
    clearIcon,
    isFullHeight,
    theme,
    forwardedRef,
    regex,
    isRegexValid,
  } = props;

  const localRef = useRef();

  const [valueCurrent, setValue] = useState(value);
  const [number, setNumber] = useState(value);
  const [isTextFieldOnHover, toggleTextFieldHover] = useState(false);
  const [isInputOnFocus, toggleInputFocus] = useState(false);

  const [textAreaWrapperHeight, setTextAreaWrapperHeight] = useState(height);

  const formatter = new Intl.NumberFormat(countryCode, {
    style: 'currency',
    currency: currencyType,
    minimumFractionDigits: 2,
  });

  /**
   * Updates the text area's height
   * @param {node} textArea - The text area itself
   */
  function updateTextAreaHeight(textArea) {
    // eslint-disable-next-line no-param-reassign
    textArea.style.height = 0;
    // eslint-disable-next-line no-param-reassign
    textArea.style.height = unit(Math.max(textArea.scrollHeight, height));
    setTextAreaWrapperHeight(
      Math.min(Math.max(textArea.scrollHeight, height), maxTextAreaHeight),
    );
  }

  /**
   * regexCheck handler
   * @param {string} eventValue
   */
  function regexCheck(eventValue) {
    if (!eventValue.match(regex)) isRegexValid(false);
    else isRegexValid(true);
  }

  /**
   * currencyCheck handler
   * @param {string} eventValue
   * @param {string} eventType
   */
  function currencyCheck(eventValue, eventType) {
    //  onblur
    if (eventType === 'blur') {
      setNumber(eventValue.replace(',', '.'));
      setValue(formatter.format(Number(eventValue.replace(',', '.'))));
    }

    // onfocus
    if (eventType === 'focus') {
      setValue(number);
    }
  }

  /**
   * onChange handler
   * @param {*} event
   */
  function handleChange(e) {
    if (forwardedRef && forwardedRef.current) {
      if (regex) regexCheck(forwardedRef.current.value);
      if (forwardedRef.current) setValue(forwardedRef.current.value);
      if (onChange) onChange(e);
    } else if (localRef && localRef.current) {
      if (regex) regexCheck(localRef.current.value);
      if (localRef.current) setValue(localRef.current.value);
      if (onChange) onChange(e);
    }
  }
  useEffect(() => {
    setValue(value);
  }, [value]);

  useEffect(() => {
    setTextAreaWrapperHeight(height);
  }, [height]);

  useEffect(() => {
    if (isTextArea) {
      if (forwardedRef && forwardedRef.current)
        updateTextAreaHeight(forwardedRef.current);
      else if (localRef && localRef.current)
        updateTextAreaHeight(localRef.current);
    }
  }, [valueCurrent, isTextArea]);

  /**
   * onFocus handler
   * @param {*} event
   */
  function handleFocus(event) {
    if (forwardedRef && forwardedRef.current) {
      if (type === 'currency')
        currencyCheck(forwardedRef.current.value, 'focus');
    } else if (localRef && localRef.current) {
      if (type === 'currency') currencyCheck(localRef.current.value, 'focus');
    }
    toggleInputFocus(true);
    onFocus(event);
  }

  /**
   * onBlur handler
   * @param {*} event
   */
  function handleBlur(event) {
    if (forwardedRef && forwardedRef.current) {
      if (type === 'currency')
        currencyCheck(forwardedRef.current.value, 'blur');
    } else if (localRef && localRef.current) {
      if (type === 'currency') currencyCheck(localRef.current.value, 'blur');
    }
    toggleInputFocus(false);
    onBlur(event);
  }

  /** Set state to clear the input */
  const clearHandler = () => {
    if (forwardedRef && forwardedRef.current) {
      triggerInputChange(forwardedRef.current, '');
    } else if (localRef && localRef.current) {
      triggerInputChange(localRef.current, '');
    }
    if (forwardedRef && forwardedRef.current) {
      // eslint-disable-next-line no-param-reassign
      forwardedRef.current.value = '';
    }
    onClear();
  };

  const textFieldStylesHelper = TextFieldStylesHelper({
    theme,
    hasAnError,
    isDisabled,
    isInputOnFocus,
    isTextFieldOnHover,
    isSearchMode,
    errorColor: theme.color[errorMessageColor],
  });

  const backgroundColor = textFieldStylesHelper.getBackgroundColor();
  const inputBorderColor = textFieldStylesHelper.getInputBorderColor();
  const boxShadow = textFieldStylesHelper.getBoxShadow();
  const iconColor = textFieldStylesHelper.getIconColor();
  const textColor = textFieldStylesHelper.getTextColor();
  const messageColor = textFieldStylesHelper.getErrorMessageColor();
  const searchIcon = 'Search';
  const hasAnIcon = (iconName || isSearchMode) && !isTextArea;

  const hasCloseButton =
    isSearchMode &&
    ((forwardedRef && forwardedRef.current && forwardedRef.current.value) ||
      valueCurrent ||
      isEmptyClearable);

  /**
   * In the cases when the TextField is not wrapped with TextFieldWrapper component ( which consist the label attribute)
   * It makes the input on focus when a user click on the icon.
   * It uses local ref if external doesn't exist
   */
  const autoFocusInput = () => {
    if (forwardedRef && forwardedRef.current) {
      forwardedRef.current.focus();
    } else if (localRef && localRef.current) {
      localRef.current.focus();
    }
  };

  /** Returns the icon if present */
  const getIcon = () => (
    <Icon
      extraProps={['onClick']}
      onClick={autoFocusInput}
      iconName={isSearchMode ? searchIcon : iconName}
      size={14}
      spacing={12}
      color={iconColor}
    />
  );

  const inputIdValue = inputId || name;

  return (
    <Container
      isTextArea={isTextArea}
      isFullHeight={isFullHeight}
      id={id}
      className="textField"
      currencyType={currencyType}
      countryCode={countryCode}
      regex={regex}
    >
      <BorderContainer
        className="textFieldBorder"
        backgroundColor={backgroundColor}
        inputBorderColor={inputBorderColor}
        boxShadow={boxShadow}
        isTextArea={isTextArea}
        isFullHeight={isFullHeight}
        onMouseEnter={() => toggleTextFieldHover(true)}
        onMouseLeave={() => toggleTextFieldHover(false)}
        {...getExtraProps(null, extraProps, 'BorderContainer')}
      >
        <TextFieldContainer>
          {hasAnIcon && !hasRightIcon && getIcon()}

          {isTextArea ? (
            <Scrollbar
              extraProps={['style', { View: { tabIndex: null } }]}
              style={{ height: unit(textAreaWrapperHeight) }}
            >
              <TextAreaInput
                {...getExtraProps(props, extraProps)}
                onFocus={handleFocus}
                onBlur={handleBlur}
                hasAnIcon={hasAnIcon ? 1 : 0}
                onChange={handleChange}
                color={textColor}
                value={valueCurrent}
                disabled={isDisabled}
                placeholder={placeholder}
                type={type}
                name={name}
                id={inputIdValue}
                aria-label={placeholder || 'textarea'}
                ref={forwardedRef || localRef}
                maxLength={maxLength}
              />
            </Scrollbar>
          ) : (
            <TextFieldInput
              {...getExtraProps(props, extraProps)}
              onFocus={handleFocus}
              onBlur={handleBlur}
              hasAnIcon={hasAnIcon ? 1 : 0}
              hasRightIcon={hasRightIcon}
              onChange={handleChange}
              color={textColor}
              value={valueCurrent}
              disabled={isDisabled}
              placeholder={placeholder}
              type={type}
              name={name}
              id={inputIdValue}
              aria-label={placeholder || 'textfield'}
              height={height}
              ref={forwardedRef || localRef}
              maxLength={maxLength}
            />
          )}

          {hasAnIcon && hasRightIcon && getIcon()}

          {hasCloseButton && (
            <ClearTextIconContainer className="clearText">
              <Icon
                iconName={clearIcon || 'Delete1'}
                size={14}
                spacing={12}
                color={iconColor}
                onMouseDown={clearHandler}
              />
            </ClearTextIconContainer>
          )}
        </TextFieldContainer>
      </BorderContainer>
      {errorMessage && (
        <Text
          content={errorMessage}
          textColor={messageColor}
          textStyle="description"
          align="left"
          spacing={{ top: 4, left: 8 }}
        />
      )}
    </Container>
  );
};

TextField.propTypes = webPropTypes;
TextField.defaultProps = webDefaultProps;

export default withTheme(TextField);
