import * as React from 'react';

function SvgPhoneVibrate(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M8.25 24a3.754 3.754 0 01-3.75-3.75V3.75A3.754 3.754 0 018.25 0h7.5a3.754 3.754 0 013.75 3.75v16.5A3.754 3.754 0 0115.75 24h-7.5zM6 20.25a2.252 2.252 0 002.25 2.25h7.5A2.252 2.252 0 0018 20.25v-.75H6v.75zM18 18V3.75a2.252 2.252 0 00-2.25-2.25h-7.5A2.252 2.252 0 006 3.75V18h12zM.75 18.75a.752.752 0 01-.53-1.281l.97-.97-.97-.97a.752.752 0 010-1.061l.97-.97-.97-.97a.743.743 0 010-1.058l.97-.97-.97-.97C.078 9.389 0 9.2 0 9s.078-.389.22-.53l.97-.97-.97-.97C.078 6.389 0 6.2 0 6s.078-.389.22-.53l.97-.97-.97-.97C.078 3.389 0 3.2 0 3s.078-.389.22-.53c.141-.142.33-.22.53-.22s.389.078.53.22l1.5 1.5c.142.141.22.33.22.53s-.078.389-.22.53L1.811 6l.97.97a.746.746 0 01-.001 1.06L1.811 9l.97.97a.746.746 0 01-.001 1.06l-.969.97.97.97a.752.752 0 010 1.061l-.97.969.97.97a.752.752 0 010 1.061l-1.5 1.5a.75.75 0 01-.531.219zM23.25 18.75a.743.743 0 01-.53-.22l-1.5-1.5a.752.752 0 010-1.061l.97-.97-.97-.97a.752.752 0 010-1.061l.97-.97-.97-.97a.743.743 0 010-1.058l.969-.97-.97-.97a.746.746 0 01.001-1.06l.969-.97-.97-.97a.746.746 0 01.001-1.06l1.5-1.5a.744.744 0 011.06 0c.142.141.22.33.22.53s-.078.389-.22.53l-.97.97.97.97c.142.141.22.33.22.53s-.078.389-.22.53l-.97.97.97.97c.142.141.22.33.22.53s-.078.389-.22.53l-.97.97.97.97a.747.747 0 010 1.061l-.97.969.97.97a.752.752 0 010 1.061l-.97.97.97.97a.75.75 0 01-.53 1.279z" />
    </svg>
  );
}

export default SvgPhoneVibrate;
