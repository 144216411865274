import * as React from 'react';

function SvgBathroomTubTowel(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M7.332 24a6.727 6.727 0 01-6.658-5.641L.01 14.373a.75.75 0 01.74-.873h3.879A2.258 2.258 0 016.75 12h4.5c.96 0 1.808.615 2.121 1.5H21V3.75a2.252 2.252 0 00-2.25-2.25c-.999 0-1.869.667-2.151 1.599A3.751 3.751 0 0119.5 6.75a.75.75 0 01-.75.75h-6a.75.75 0 01-.75-.75 3.742 3.742 0 013.063-3.687A3.74 3.74 0 0118.75 0a3.754 3.754 0 013.75 3.75v9.75h.75a.75.75 0 01.74.873l-.664 3.986A6.73 6.73 0 0116.667 24H7.332zm-5.178-5.887A5.232 5.232 0 007.332 22.5h9.334a5.233 5.233 0 005.179-4.387l.52-3.113H13.5v4.5c0 .827-.673 1.5-1.5 1.5H6c-.827 0-1.5-.673-1.5-1.5V15H1.635l.519 3.113zM6.75 13.5a.75.75 0 00-.75.75v5.25h6v-5.25a.75.75 0 00-.75-.75h-4.5zM17.871 6c-.313-.879-1.159-1.5-2.121-1.5s-1.809.621-2.121 1.5h4.242z" />
    </svg>
  );
}

export default SvgBathroomTubTowel;
