import * as React from 'react';

function SvgMessagesBubbleSquareQuestion(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M5.25 24a.747.747 0 01-.75-.75V19.5H2.25A2.252 2.252 0 010 17.25v-15A2.252 2.252 0 012.25 0h19.5A2.252 2.252 0 0124 2.25v15a2.252 2.252 0 01-2.25 2.25H11.5l-5.8 4.35a.753.753 0 01-.45.15zm-3-22.5a.75.75 0 00-.75.75v15c0 .414.336.75.75.75h3a.75.75 0 01.75.75v3l4.8-3.6a.753.753 0 01.45-.15h10.5a.75.75 0 00.75-.75v-15a.75.75 0 00-.75-.75H2.25z" />
      <path d="M12.75 12a.75.75 0 01-.75-.75v-.257c0-.952.603-1.804 1.5-2.121A2.254 2.254 0 0015 6.751c0-.601-.234-1.166-.659-1.591a2.235 2.235 0 00-1.591-.659c-.601 0-1.166.234-1.591.659a2.231 2.231 0 00-.659 1.59.75.75 0 01-1.5 0c0-1.002.391-1.943 1.099-2.651a3.725 3.725 0 012.651-1.098c1.002 0 1.944.39 2.652 1.099A3.725 3.725 0 0116.5 6.751a3.756 3.756 0 01-2.5 3.535.752.752 0 00-.5.707v.257a.75.75 0 01-.75.75z" />
      <circle cx={12.75} cy={14.625} r={1.125} />
    </svg>
  );
}

export default SvgMessagesBubbleSquareQuestion;
