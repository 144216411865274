import React from 'react';
import styled from '@emotion/styled';
import {
  getExtraProps,
  withTheme,
  unit,
  readableColor,
} from '@embracesbs/helpers';
import { mix } from 'polished';
import Icon from '@embracesbs/component-icon';
import Text from '@embracesbs/component-text';
import { propTypes, defaultProps } from './Badge.props';
import { webStyles } from './Badge.styles';
import handleCounter from './Badge.helpers';

const StyledBadge = styled.div`
  ${({ theme }) => webStyles.badge(theme, unit)}
  ${({ isLowEmphasis, theme, color }) => `
    background: ${isLowEmphasis ? theme.color.dark$6 : color};
  `}
`;

const StyledStatus = styled(StyledBadge)`
  ${({ size, status }) => webStyles.status(unit, size, status)}
`;

const StyledToggle = styled(StyledStatus)`
  ${({ theme, isFilled, color }) => `
    ${webStyles.toggle(theme, unit)}
    background-color: ${isFilled ? color : theme.color.light};
  `}
`;

const StyledContextual = styled(StyledBadge)`
  ${({ theme }) => `
    ${webStyles.contextual(theme)}
    ${theme.textStyle.overline}
  `}
`;

const StyledWrapper = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;

const StyledIcon = styled(Icon)`
  ${({ theme }) => `
    margin-right: ${theme.spacing.$2};
  `};
`;

/**
 * Badge component
 * @param {array} extraProps
 * @param {string|number} text
 * @param {string} color
 * @param {string} variant
 * @param {bool} isFilled
 * @param {number} size
 * @param {string} status
 * @param {bool} hasMention
 * @param {bool} isLowEmphasis
 */
const Badge = (props) => {
  const {
    text,
    color,
    variant,
    isFilled,
    theme,
    extraProps,
    size,
    status,
    hasMention,
    isLowEmphasis,
  } = props;
  const components = {
    status: StyledStatus,
    toggle: StyledToggle,
    contextual: StyledContextual,
    counter: StyledBadge,
  };
  const ComponentName = components[variant || components.counter];

  /** Create the online indicator */
  const handleIndicator = () => {
    switch (status) {
      case 'offline':
        return mix(0.2, theme.color.dark, theme.color.light); // Must be opaque color instead of transparentized
      case 'away':
        return theme.color.warning;
      case 'busy':
        return theme.color.danger;
      case 'online':
      default:
        return theme.color.success;
    }
  };

  return (
    <StyledWrapper {...getExtraProps(null, extraProps, 'Wrapper')}>
      {hasMention && (
        <StyledIcon
          size={10}
          isRounded
          background={theme.color.primary}
          color={theme.color.light}
          iconName="ReadEmailAt"
          spacing={1}
        />
      )}
      <ComponentName
        {...getExtraProps(props, extraProps)}
        color={color}
        variant={variant}
        isFilled={isFilled}
        size={size}
        isLowEmphasis={isLowEmphasis}
        status={handleIndicator()}
      >
        {text && (
          <Text
            content={variant === 'counter' ? handleCounter(text) : text}
            textColor={
              isLowEmphasis
                ? theme.color.dark
                : readableColor(theme.color.light, color)
            }
            textStyle="overline"
          />
        )}
      </ComponentName>
    </StyledWrapper>
  );
};

Badge.propTypes = propTypes;
Badge.defaultProps = defaultProps;

/** @component */
export default withTheme(Badge);
