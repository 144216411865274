import * as React from 'react';

function SvgAlarmBellRing(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M4.344 7.487a.747.747 0 01-.656-1.114A7.095 7.095 0 016.45 3.61a.748.748 0 011.02.292.75.75 0 01-.292 1.02A5.59 5.59 0 005 7.1a.75.75 0 01-.656.387zM1.5 6.726a.752.752 0 01-.721-.955A7.204 7.204 0 015.77.778a.753.753 0 01.86 1.087.743.743 0 01-.45.356 5.716 5.716 0 00-3.959 3.96.753.753 0 01-.721.545zM4.5 18.75a.745.745 0 01-.156-.017l-.04-.011a.737.737 0 01-.241-.114l-.038-.03a.818.818 0 01-.132-.142.634.634 0 01-.096-.179.733.733 0 01-.044-.218c-.003-.016-.003-.022-.003-.039 0-.016.001-.031.003-.046l.006-.06a.677.677 0 01.033-.137.695.695 0 01.06-.131c.014-.023.026-.044.041-.064l.044-.055a.67.67 0 01.045-.047l.029-.028c0-.001 1.239-1.26 1.239-5.431 0-3.997 2.608-7.334 6-7.769V3a.75.75 0 011.5 0v1.231c3.392.435 6 3.772 6 7.769 0 4.154 1.243 5.434 1.255 5.446a.527.527 0 01.058.062.825.825 0 01.083.115.934.934 0 01.035.066c.009.019.016.04.023.06a.49.49 0 01.035.143c.003.026.006.047.007.067a.423.423 0 01-.003.103c0 .005-.002.03-.006.054-.004.027-.01.051-.016.075-.005.021-.013.044-.021.068a1.047 1.047 0 01-.063.131.574.574 0 01-.042.06c-.01.014-.022.031-.031.041l-.031.032-.035.033-.062.051c-.017.013-.037.024-.057.036a.565.565 0 01-.072.038c-.017.008-.038.015-.058.022a.586.586 0 01-.147.035c-.018.002-.039.005-.061.006a.622.622 0 01-.041.002h-15zm13.647-1.5c-.458-1.018-.897-2.668-.897-5.25 0-3.484-2.355-6.318-5.25-6.318S6.75 8.516 6.75 12c0 2.587-.437 4.235-.893 5.25h12.29zM12 23.25A2.252 2.252 0 019.75 21a.75.75 0 011.5 0 .75.75 0 001.5 0 .75.75 0 011.5 0c0 1.24-1.009 2.25-2.25 2.25zM19.656 7.487A.751.751 0 0119 7.101a5.626 5.626 0 00-.937-1.244 5.698 5.698 0 00-1.243-.937.749.749 0 01.367-1.404.75.75 0 01.365.095c.576.322 1.104.72 1.571 1.185.468.469.868 1 1.188 1.576a.747.747 0 01-.655 1.115zM22.5 6.726a.753.753 0 01-.721-.545 5.716 5.716 0 00-3.959-3.96.751.751 0 01.411-1.443 7.206 7.206 0 014.991 4.992.75.75 0 01-.722.956z" />
    </svg>
  );
}

export default SvgAlarmBellRing;
