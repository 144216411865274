import * as React from 'react';

function SvgCommonFileUpload(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M17.2 24c-3.7 0-6.8-3-6.8-6.8s3-6.7 6.8-6.7 6.8 3 6.8 6.7-3 6.8-6.8 6.8zm0-12c-2.9 0-5.2 2.4-5.2 5.2s2.4 5.2 5.2 5.2 5.2-2.4 5.2-5.2-2.3-5.2-5.2-5.2z" />
      <path d="M17.2 13.5h.3c.1 0 .2.1.3.2L20 16c.3.3.3.8 0 1.1-.1.1-.3.2-.5.2s-.4-.1-.5-.2l-1-1v4.2c0 .4-.3.8-.8.8s-.8-.3-.8-.8v-4.2l-1 1c-.1.1-.3.2-.5.2s-.4-.1-.5-.2c-.3-.3-.3-.8 0-1.1l2.2-2.2c.1-.1.2-.1.2-.2.3-.1.4-.1.4-.1zM2.2 21C1 21 0 20 0 18.7V2.2C0 1 1 0 2.2 0h10.6c.6 0 1.2.2 1.6.7l2.9 2.9c.5.4.7.9.7 1.5v3.1c0 .5-.3.8-.8.8s-.8-.3-.8-.8V5.1c0-.2-.1-.4-.2-.5l-2.9-2.9c-.1-.1-.3-.2-.5-.2H2.2c-.4 0-.8.3-.8.8v16.5c0 .4.3.8.8.8h6c.4 0 .8.3.8.8s-.3.6-.8.6h-6z" />
    </svg>
  );
}

export default SvgCommonFileUpload;
