import React from 'react';
import styled from '@emotion/styled';
import {
  getExtraProps,
  withTheme,
  getSpacingString,
} from '@embracesbs/helpers';
import Icon from '@embracesbs/component-icon';
import Text from '@embracesbs/component-text';
import Avatar from '@embracesbs/component-avatar';
import Image from '@embracesbs/component-image';
import Badge from '@embracesbs/component-badge';
import { webPropTypes, webDefaultProps } from './InfoCard.props';

const StyledInfoCard = styled.div`
  ${({ theme, spacing }) => `
    display: flex;
    flex-wrap: nowrap;
    flex-shrink: 0;
    justify-content: flex-start;
    padding: ${spacing};

    .infoCardContent {
      width: calc(${theme.percentage.full} - ${theme.spacing.$6});
      align-self: center;
    }

    :hover {
      > .infoCardIndicator {
        visibility: visible;
      }
    }
  `}
`;

const StyledOuter = styled.div`
  ${({ theme, alignTop }) => `
    display: flex;
    align-self: ${alignTop ? 'flex-start' : 'center'};
    flex-grow: 0;
    margin-right: ${theme.spacing.$4};
    margin-left: ${theme.spacing.$4};
  `}
`;

const StyledContentWrapper = styled.div`
  display: flex;
  align-self: flex-start;
  min-width: 0;
  flex-grow: 1;
  flex-direction: column;
`;

const IndicationWrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
  width: auto;
  ${({ theme, hasActionsVisibleOnHover }) => `
    ${theme.textStyle.body};
    visibility: ${hasActionsVisibleOnHover ? 'hidden' : 'visible'};
  `};
`;

/**
 * InfoCard component
 * @param {array} extraProps - An array of strings which includes the extra prop keys
 * @param {number|object} spacing - A padding of the content
 * @param {string} animation
 * @param {bool} alignTop
 * @param {object} icon
 * @param {string} image
 * @param {string} title
 * @param {string} titleColor
 * @param {boolean} hasSingleLineTitle
 * @param {string} subTitle
 * @param {string} subTitleColor
 * @param {string} label
 * @param {object} avatar
 * @param {node} actions
 * @param {bool} hasActionsVisibleOnHover - Whether the actions are visible only on hover
 * @param {string} badgeText - The text of the optional badge
 * @param {string} badgeColor - The color key of the optional badge
 */
const InfoCard = (props) => {
  const {
    extraProps,
    theme,
    spacing,
    alignTop,
    animation,
    icon,
    image,
    avatar,
    title,
    titleColor,
    hasSingleLineTitle,
    subTitle,
    subTitleColor,
    label,
    actions,
    hasActionsVisibleOnHover,
    badgeText,
    badgeColor,
    ...otherProps
  } = props;

  const currentSpacingString = getSpacingString(
    spacing,
    webDefaultProps.spacing,
  );

  return (
    <StyledInfoCard
      {...otherProps}
      {...getExtraProps(props, extraProps)}
      spacing={currentSpacingString}
    >
      {(avatar || image || icon) && (
        <StyledOuter alignTop={alignTop} className="outerContainer">
          {icon && <Icon {...icon} />}
          {avatar && <Avatar {...avatar} />}
          {image && <Image alt={image.alt} {...image} />}
        </StyledOuter>
      )}
      <StyledContentWrapper className="infoCardContent">
        {label && (
          <Text
            textStyle="description"
            content={label}
            textColor={theme.color[subTitleColor] || theme.color.dark$3}
            {...getExtraProps(null, extraProps, 'Label')}
          />
        )}
        {title && (
          <Text
            content={title}
            singleLine={hasSingleLineTitle}
            textColor={theme.color[titleColor] || theme.color.dark}
            {...getExtraProps(null, extraProps, 'Title')}
          />
        )}
        {subTitle && (
          <Text
            textStyle="description"
            content={subTitle}
            singleLine
            textColor={theme.color[subTitleColor] || theme.color.dark$3}
            {...getExtraProps(null, extraProps, 'SubTitle')}
          />
        )}
        {badgeText && (
          <Badge
            variant="contextual"
            text={badgeText}
            color={theme.color[badgeColor] || theme.color.dark}
            extraProps={[
              { Wrapper: { style: { justifyContent: 'flex-start' } } },
            ]}
          />
        )}
      </StyledContentWrapper>
      {actions && (
        <IndicationWrapper
          className="infoCardIndicator"
          hasActionsVisibleOnHover={hasActionsVisibleOnHover}
        >
          {actions}
        </IndicationWrapper>
      )}
    </StyledInfoCard>
  );
};

InfoCard.propTypes = webPropTypes;
InfoCard.defaultProps = webDefaultProps;

/** @component */
export default withTheme(InfoCard);
