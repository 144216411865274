import * as React from 'react';

function SvgFileIso(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M2.241 24.004a2.252 2.252 0 01-2.25-2.25v-19.5a2.252 2.252 0 012.25-2.25H12.87c.601 0 1.165.234 1.59.658l5.87 5.871c.425.425.659.99.659 1.591v.091a.54.54 0 010 .081v4.457a.75.75 0 01-1.5 0v-3.75h-5.248a2.252 2.252 0 01-2.25-2.25v-5.25h-9.75a.75.75 0 00-.75.75v19.5c0 .414.336.75.75.75h6a.75.75 0 010 1.5h-6zm11.25-17.25c0 .414.336.75.75.75h4.938l-5.688-5.689v4.939z" />
      <path d="M11.241 24.004a.75.75 0 01-.75-.75v-7.5a.75.75 0 011.5 0v7.5a.75.75 0 01-.75.75zM14.241 24.004a.75.75 0 010-1.5h1.5a.75.75 0 00.75-.75c0-.715-.434-1.075-1.2-1.65-.802-.602-1.8-1.35-1.8-2.85a2.252 2.252 0 012.25-2.25h1.5a.75.75 0 010 1.5h-1.5a.75.75 0 00-.75.75c0 .715.434 1.075 1.2 1.65.802.602 1.8 1.35 1.8 2.85a2.252 2.252 0 01-2.25 2.25h-1.5zM21.741 24.004a2.252 2.252 0 01-2.25-2.25v-4.5c0-1.241 1.009-2.25 2.25-2.25s2.25 1.009 2.25 2.25v4.5a2.252 2.252 0 01-2.25 2.25zm0-7.5a.75.75 0 00-.75.75v4.5a.75.75 0 001.5 0v-4.5a.75.75 0 00-.75-.75z" />
    </svg>
  );
}

export default SvgFileIso;
