import * as React from 'react';

function SvgStreetView(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M12 6.7c-1.7 0-3-1.3-3-3s1.3-3 3-3 3 1.3 3 3-1.3 3-3 3zm0-4.5c-.8 0-1.5.7-1.5 1.5s.7 1.5 1.5 1.5 1.5-.7 1.5-1.5-.7-1.5-1.5-1.5zM13.7 19.5h-3.4c-.4 0-.7-.3-.7-.7l-.5-5h-1c-.4 0-.8-.3-.8-.7v-1.7c0-2.6 2.1-4.7 4.7-4.7s4.7 2.1 4.7 4.7v1.7c0 .4-.3.7-.8.7h-1l-.5 5c0 .4-.3.7-.7.7zM11 18h2l.5-5c0-.4.4-.7.7-.7h.9v-.9c0-1.8-1.4-3.2-3.2-3.2-1.8 0-3.2 1.4-3.2 3.2v.9h.9c.4 0 .7.3.7.7l.7 5z" />
      <path d="M12 23.3c-4.9 0-8.6-1.8-8.6-4.1 0-.6.2-1.5 1.4-2.3.3-.2.8-.2 1 .2.2.3.2.8-.2 1-.4.3-.8.7-.8 1.1 0 1.1 2.7 2.6 7.1 2.6s7.1-1.5 7.1-2.6c0-.5-.6-1-1-1.2-.4-.2-.5-.7-.2-1s.7-.5 1-.2c1.4.9 1.7 1.8 1.7 2.5.1 2.2-3.6 4-8.5 4z" />
    </svg>
  );
}

export default SvgStreetView;
