import * as React from 'react';

function SvgComputerBug1(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M12 23.298a8.978 8.978 0 01-6.627-2.919 5.954 5.954 0 01-3.868 1.419.75.75 0 11.017-1.499 4.452 4.452 0 002.93-1.11 9.013 9.013 0 01-1.322-3.39H1.5a.75.75 0 010-1.5H3c0-.878.128-1.746.381-2.587a4.477 4.477 0 00-1.863-.41.756.756 0 01-.769-.748.75.75 0 01.748-.752c.844 0 1.674.177 2.435.513a9.106 9.106 0 013.2-3.582C5.412 5.507 5.21 4.064 5.21 1.5a.75.75 0 011.5 0c0 2.69.186 3.47 1.975 4.431A8.925 8.925 0 0112 5.298c1.126 0 2.224.207 3.267.615 1.76-.953 1.943-1.731 1.943-4.413a.75.75 0 011.5 0c0 2.55-.198 3.985-1.885 5.204a9.11 9.11 0 013.242 3.61 5.986 5.986 0 012.419-.513.754.754 0 01.764.752.752.752 0 01-.74.748h-.016c-.651 0-1.291.141-1.876.41.254.841.382 1.709.382 2.587h1.5a.75.75 0 010 1.5h-1.628a9.013 9.013 0 01-1.322 3.39 4.449 4.449 0 002.946 1.11.752.752 0 01.536 1.281.748.748 0 01-.533.219c-1.43 0-2.79-.501-3.872-1.419A8.978 8.978 0 0112 23.298zm-6.873-12a7.415 7.415 0 00-.627 3 7.486 7.486 0 006.75 7.462V11.298H5.127zM12.75 21.76a7.487 7.487 0 006.75-7.462 7.415 7.415 0 00-.627-3H12.75V21.76zm5.245-11.962a7.505 7.505 0 00-2.841-2.301.754.754 0 01-.347-.152A7.42 7.42 0 0012 6.798a7.45 7.45 0 00-5.996 3h11.991z" />
    </svg>
  );
}

export default SvgComputerBug1;
