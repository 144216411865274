import { unit } from '@embracesbs/helpers';

const baseStyles = {};

export const webStyles = {
  ...baseStyles,
  styledTable: (isTableFixed) => `
    width: 100%;
    border-spacing: 0;
    ${isTableFixed ? `table-layout: fixed;` : ``}

    thead > tr:hover {
      background-color: transparent;
    }
  `,
  tableRow: (isFullWidth, borderOffset, theme, hasHover, onClick) => `
    border-spacing: 0;
    cursor: ${onClick ? theme.cursor.pointer : theme.cursor.default};
    ${
      !isFullWidth
        ? ` > td:first-of-type .tableDivider,
            > th:first-of-type .tableDivider {
            width: calc(100% - ${unit(borderOffset)});
            right: 0;
           }
          > td:last-of-type .tableDivider,
          > th:last-of-type .tableDivider {
            width: calc(100% - ${unit(borderOffset)});
            left: 0;
          }`
        : `.tableDivider { left: 0; }`
    }
    > td:first-of-type, th:first-of-type {
      padding-left: ${unit(borderOffset)};
    }
    > td:last-of-type , th:last-of-type {
      padding-right: ${unit(borderOffset)};
    }
    ${
      hasHover
        ? `:hover {
        background-color: ${theme.color.dark$9};
        .row-tail { visibility: visible; }
      }
    `
        : ``
    }
  `,
  headerItem: (theme) => `
    cursor: pointer;
    text-transform: capitalize;
    padding-bottom: ${theme.spacing.$3};
    font-size: ${theme.fontSize.$3};
    color: ${theme.color.dark$3};
  `,
  styledDivider: (theme) => `
    width: ${theme.percentage.full};
    position: absolute;
    bottom: 0;
  `,
  bodyItem: (theme) => `
    font-size: ${theme.fontSize.$5};
    color: ${theme.color.dark};
  `,
  cell: (width, minWidth, maxWidth) => `
   position: relative;
   padding: 0;
   width: ${width ? unit(width) : 'auto'};
   ${minWidth ? `min-width: ${unit(minWidth)};` : ``}
   ${maxWidth ? `max-width: ${unit(maxWidth)};` : ``}
   .row-tail { visibility: hidden; }
  `,
  cellInner: (
    justify,
    leftSpacing,
    rowHeight,
    align,
    isSingleLine,
    isDropdown,
  ) => `
    overflow: ${isDropdown ? 'visible' : 'hidden'};
    display: flex;
    flex-direction: row;
    ${leftSpacing ? `padding-left: ${unit(leftSpacing)};` : ``}
    justify-content: ${justify || 'left'};
    min-height: ${unit(rowHeight)};
    align-items: ${align || 'center'};
    ${
      isSingleLine
        ? ` div {
            white-space: nowrap;
            overflow: ${isDropdown ? 'visible' : 'hidden'};
            text-overflow: ellipsis;
          }`
        : ``
    }
  `,
  styledTopDivider: (borderOffset, isFullWidth) => `
    ${
      borderOffset && !isFullWidth
        ? `width: calc(100% - ${unit(borderOffset * 2)});`
        : ''
    }
  `,
};

export const nativeStyles = {
  ...baseStyles,
};
