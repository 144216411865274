import React, { Children } from 'react';
import styled from '@emotion/styled';
import {
  getExtraProps,
  unit,
  getSpacingString,
  withTheme,
  removeKeysFromObject,
} from '@embracesbs/helpers';
import { propTypes, defaultProps } from './Group.props';
import { webStyles } from './Group.styles';

const StyledGrid = styled.div`
  ${webStyles.styledGrid}
`;

const StyledWrapper = styled.div`
  ${({ isVertical, isReversed, isWrapping }) =>
    webStyles.styledWrapper(isVertical, isReversed, isWrapping)}
  ${({ isFullHeight, isFullWidth, padding, theme }) => `
    height: ${isFullHeight ? theme.percentage.full : 'auto'};
    width: ${isFullWidth ? theme.percentage.full : 'auto'};
    padding: ${padding};
  `}
  
  ${({ innerSpacingCurrent, hasInnerSpacing, marginDirection }) => `
  ${
    hasInnerSpacing
      ? `> * {
          margin-${marginDirection}: ${innerSpacingCurrent};
        }
        > :last-child {
          margin-${marginDirection}: 0;
        }
      `
      : ``
  }
  `}
`;

const StyledLeft = styled(StyledWrapper)`
  justify-content: ${(props) => (props.justify ? props.justify : 'flex-start')};
  align-items: flex-start;
`;

const StyledCenter = styled(StyledWrapper)`
  justify-content: ${(props) =>
    props.justify ? props.justify : 'space-between'};
  align-items: center;
`;

const StyledRight = styled(StyledWrapper)`
  justify-content: ${(props) => (props.justify ? props.justify : 'flex-end')};
  align-items: flex-end;
`;

/** Get margin Direction */
const marginDirection = (isVertical, isReversed) => {
  if (isVertical && isReversed) return 'top';
  if (isVertical && !isReversed) return 'bottom';
  if (!isVertical && isReversed) return 'left';
  return 'right';
};

/**
 * Group component
 * @param {string} align
 * @param {node} children
 * @param {array} extraProps
 * @param {object|string} spacing
 * @param {bool} isFullHeight
 * @param {bool} isVertical
 * @param {bool} isWrapping
 * @param {string} justify
 * @param {bool} hasDivider
 * @param {number} innerSpacing
 * @param {bool} hasInnerSpacing
 * @param {bool} isGrid
 * @param {string} gridTemplate
 * @param {bool} isReversed
 */
const Group = (props) => {
  const {
    isVertical,
    isFullHeight,
    justify,
    align,
    children,
    spacing,
    isFullWidth,
    isWrapping,
    hasDivider,
    innerSpacing,
    hasInnerSpacing,
    extraProps,
    isGrid,
    gridTemplate,
    forwardedRef,
    isReversed,
  } = props;

  const components = {
    left: StyledLeft,
    center: StyledCenter,
    right: StyledRight,
  };
  const currentSpacingString = getSpacingString(spacing, defaultProps.spacing);

  const ComponentName = isGrid
    ? StyledGrid
    : components[align] || components[align === 'end' ? 'right' : 'left'];

  return (
    <ComponentName
      {...getExtraProps(props, extraProps)}
      ref={forwardedRef}
      justify={justify}
      align={align}
      isVertical={isVertical}
      isFullHeight={isFullHeight}
      isFullWidth={isFullWidth}
      isWrapping={isWrapping}
      hasDivider={hasDivider}
      innerSpacingCurrent={unit(innerSpacing)}
      padding={currentSpacingString}
      hasInnerSpacing={hasInnerSpacing}
      innerSpacing={innerSpacing}
      gridTemplate={gridTemplate}
      isReversed={isReversed}
      marginDirection={marginDirection(isVertical, isReversed)}
      {...removeKeysFromObject(props, ['data-testid'])}
    >
      {isGrid && hasDivider
        ? Children.map(children, (child) => <div>{child}</div>)
        : children}
    </ComponentName>
  );
};

Group.propTypes = propTypes;
Group.defaultProps = defaultProps;

export default withTheme(Group);
