import * as React from 'react';

function SvgSmileySleepy(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M12.038 23.975c-4.63 0-8.894-2.699-10.863-6.876-2.818-5.98-.247-13.138 5.733-15.956A12.072 12.072 0 0112.021 0c1.632 0 3.219.325 4.718.967a.751.751 0 01-.591 1.379 10.43 10.43 0 00-4.127-.846c-1.537 0-3.084.346-4.473 1C2.316 4.966.066 11.228 2.532 16.46c1.723 3.655 5.453 6.016 9.504 6.016h.001c1.548 0 3.047-.336 4.455-1a10.423 10.423 0 005.399-5.941 10.42 10.42 0 00-.383-8.019.75.75 0 111.357-.639 11.91 11.91 0 01.438 9.165 11.908 11.908 0 01-6.171 6.791 11.884 11.884 0 01-5.094 1.142z" />
      <path d="M7.515 12.751a2.53 2.53 0 01-2.129-1.141.748.748 0 01.625-1.164c.253 0 .486.125.626.336a1.04 1.04 0 00.878.469 1.05 1.05 0 00.873-.47.748.748 0 011.039-.21.752.752 0 01.211 1.04c-.189.285-.43.526-.715.715a2.565 2.565 0 01-1.408.425zM16.515 12.751a2.534 2.534 0 01-2.129-1.141.748.748 0 01.625-1.164c.252 0 .486.125.625.335a1.05 1.05 0 001.75-.001.748.748 0 011.039-.21.75.75 0 01.21 1.04 2.54 2.54 0 01-2.12 1.141zM12.011 20.995a2.252 2.252 0 01-2.25-2.25v-1.5a2.252 2.252 0 012.25-2.25 2.252 2.252 0 012.25 2.25v1.5c0 1.24-1.01 2.25-2.25 2.25zm0-4.5a.75.75 0 00-.75.75v1.5a.75.75 0 001.5 0v-1.5a.75.75 0 00-.75-.75zM20.261 4.495a.75.75 0 01-.53-1.281l1.72-1.72h-1.189a.75.75 0 010-1.5h3a.75.75 0 01.53 1.281l-1.72 1.72h1.189a.75.75 0 010 1.5h-3zM14.261 8.995a.75.75 0 01-.53-1.281l1.72-1.72h-1.189a.75.75 0 010-1.5h3a.75.75 0 01.53 1.281l-1.72 1.72h1.189a.75.75 0 010 1.5h-3z" />
    </svg>
  );
}

export default SvgSmileySleepy;
