import * as React from 'react';

function SvgSmileyDisapointed(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M12 24C5.383 24 0 18.617 0 12S5.383 0 12 0s12 5.383 12 12-5.383 12-12 12zm0-22.5C6.21 1.5 1.5 6.21 1.5 12S6.21 22.5 12 22.5 22.5 17.79 22.5 12 17.79 1.5 12 1.5z" />
      <path d="M7.504 12.756a2.53 2.53 0 01-2.129-1.141A.748.748 0 016 10.45c.253 0 .486.125.625.336a1.04 1.04 0 00.878.469c.205 0 .404-.061.577-.176.118-.078.217-.177.295-.295a.75.75 0 011.36.267.747.747 0 01-.11.563 2.541 2.541 0 01-2.121 1.142zM16.504 12.756a2.534 2.534 0 01-2.129-1.141.748.748 0 01.625-1.164c.252 0 .486.125.625.335a1.04 1.04 0 00.878.47 1.05 1.05 0 00.873-.469.748.748 0 011.039-.21.748.748 0 01.211 1.04c-.189.286-.43.527-.716.716-.42.275-.906.423-1.406.423zM9.75 18.75a.75.75 0 010-1.5h4.5a.75.75 0 010 1.5h-4.5zM18.75 9a.745.745 0 01-.279-.054l-3.75-1.5a.745.745 0 01-.411-.401.748.748 0 01.968-.992l3.75 1.5a.745.745 0 01.411.401.742.742 0 01.007.574.744.744 0 01-.696.472zM5.25 9a.75.75 0 01-.279-1.446l3.75-1.5a.747.747 0 01.976.418.751.751 0 01-.418.974l-3.75 1.5A.74.74 0 015.25 9z" />
    </svg>
  );
}

export default SvgSmileyDisapointed;
