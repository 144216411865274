import PropTypes from 'prop-types';

export const propTypes = {
  /** Child components list items */
  children: PropTypes.node,
  /** Extra props */
  extraProps: PropTypes.array,
  /** ClassName of the component */
  className: PropTypes.string,
  /** Defines spacing around the component */
  spacing: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.shape({
      bottom: PropTypes.number,
      left: PropTypes.number,
      right: PropTypes.number,
      top: PropTypes.number,
    }),
  ]),
};

export const defaultProps = {
  children: null,
  extraProps: [],
  className: '',
  spacing: 0,
};
