import * as React from 'react';

function SvgFolderSync(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M2.447 19.502a.725.725 0 01-.231-.037A2.464 2.464 0 010 17.063V2.252A2.252 2.252 0 012.25.002h4.5C7.991.002 9 1.012 9 2.252v.75h9.75A2.252 2.252 0 0121 5.252v2.25h.75a.75.75 0 010 1.5H7.879a.755.755 0 00-.722.542l-2.351 8.154a2.341 2.341 0 01-.109.304H8.25a.75.75 0 010 1.5H2.447zm-.197-18a.75.75 0 00-.75.75v14.8a.95.95 0 001.864.235l2.352-8.158a2.26 2.26 0 012.162-1.626H19.5v-2.25a.75.75 0 00-.75-.75H8.25a.75.75 0 01-.75-.75v-1.5a.75.75 0 00-.75-.75h-4.5zM17.4 24.006a6.33 6.33 0 01-2.052-.344A6.292 6.292 0 0112 20.976v1.525a.75.75 0 01-1.5 0v-3.75a.75.75 0 01.75-.75H15a.75.75 0 010 1.5h-2.067a4.826 4.826 0 004.466 3.003c.735 0 1.448-.167 2.117-.495a4.791 4.791 0 002.44-2.769.751.751 0 011.383-.088c.088.18.101.384.036.573a6.28 6.28 0 01-3.199 3.63 6.216 6.216 0 01-2.776.651z" />
      <path d="M19.5 16.502a.75.75 0 010-1.5h2.067a4.826 4.826 0 00-4.457-3.001 4.827 4.827 0 00-4.567 3.261.75.75 0 11-1.419-.485 6.327 6.327 0 018.028-3.935 6.292 6.292 0 013.348 2.686v-1.525a.75.75 0 011.5-.001v3.75a.75.75 0 01-.75.75H19.5z" />
    </svg>
  );
}

export default SvgFolderSync;
