import * as React from 'react';

function SvgFolderImage(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M8.25 24A2.252 2.252 0 016 21.75v-10.5A2.252 2.252 0 018.25 9H21v-.75a.75.75 0 00-.75-.75h-15a.75.75 0 00-.75.75v12a.75.75 0 01-1.5 0v-12A2.252 2.252 0 015.25 6H18v-.75a.75.75 0 00-.75-.75h-9a.75.75 0 01-.75-.75v-1.5a.75.75 0 00-.75-.75h-4.5a.75.75 0 00-.75.75v16.3a.75.75 0 01-1.5 0V2.25A2.252 2.252 0 012.25 0h4.5A2.252 2.252 0 019 2.25V3h8.25a2.252 2.252 0 012.25 2.25V6h.75a2.252 2.252 0 012.25 2.25v.879A2.258 2.258 0 0124 11.25v10.5A2.252 2.252 0 0121.75 24H8.25zm0-13.5a.75.75 0 00-.75.75v10.5c0 .414.336.75.75.75h13.5a.75.75 0 00.75-.75v-10.5a.75.75 0 00-.75-.75H8.25z" />
      <circle cx={11.25} cy={14.25} r={1.125} />
      <path d="M10.212 21a.75.75 0 01-.624-1.165l1.559-2.348a1.492 1.492 0 011.25-.668c.338 0 .67.116.935.327l.613.491 1.542-2.197a1.489 1.489 0 011.226-.64c.506 0 .974.251 1.252.671l2.909 4.364A.748.748 0 0120.25 21a.749.749 0 01-.624-.334l-2.91-4.365-2.002 2.851a.755.755 0 01-.613.319.752.752 0 01-.469-.164l-1.236-.989-1.558 2.348a.752.752 0 01-.626.334z" />
    </svg>
  );
}

export default SvgFolderImage;
