export const baseStyles = {
  styledLi: ``,
  styledLiBefore: ``,
};

export const webStyles = {
  ...baseStyles,
  styledLi: (theme) => `
    ${baseStyles.styledLi}
    ${theme.textStyle.body};
    height: ${theme.percentage.full};
    width: ${theme.percentage.full};
    flex-direction: column;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style: none;
    z-index: 0;
    box-sizing: border-box;
    `,
  styledLiBefore: (theme) => `
    top: 0;
    left: 0;
    height: ${theme.percentage.full};
    content: '';
    position: absolute;
    z-index: -1;
    `,
};
