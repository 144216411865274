import * as React from 'react';

function SvgSingleManBriefcase(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M12 24c-.8 0-1.5-.7-1.5-1.5V15c0-.8.7-1.5 1.5-1.5h2.2v-.8c0-1.2 1-2.2 2.2-2.2H18c1.2 0 2.2 1 2.2 2.2v.8h2.2c.8 0 1.5.7 1.5 1.5v7.5c0 .8-.7 1.5-1.5 1.5H12zm0-1.5h10.5V18H18v.8c0 .4-.3.8-.8.8s-.8-.3-.8-.8V18H12v4.5zm10.5-6V15H12v1.5h10.5zm-3.7-3v-.8c0-.4-.3-.8-.8-.8h-1.5c-.4 0-.8.3-.8.8v.8h3.1zM.8 18c-.5 0-.8-.3-.8-.8 0-4.1 3.4-7.5 7.5-7.5 1 0 2 .2 2.9.6.2.1.3.2.4.4.1.2.1.4 0 .6-.1.3-.4.5-.7.5-.1 0-.2 0-.3-.1-.7-.3-1.5-.5-2.3-.5-1.6 0-3.1.6-4.2 1.8-1.1 1.1-1.8 2.6-1.8 4.2 0 .5-.3.8-.7.8z" />
      <path d="M7.5 9.8c-2.7 0-4.9-2.2-4.9-4.9S4.8 0 7.5 0s4.9 2.2 4.9 4.9-2.2 4.9-4.9 4.9zm0-8.3C5.6 1.5 4.1 3 4.1 4.9s1.5 3.4 3.4 3.4 3.4-1.5 3.4-3.4-1.5-3.4-3.4-3.4z" />
    </svg>
  );
}

export default SvgSingleManBriefcase;
