import * as React from 'react';

function SvgHouseUser(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M16.5 23.251a.75.75 0 010-1.5h2.25a.75.75 0 00.75-.75v-7.5a.75.75 0 011.5 0v7.5a2.252 2.252 0 01-2.25 2.25H16.5zM5.25 23.251A2.252 2.252 0 013 21.001v-7.5a.75.75 0 011.5 0v7.5c0 .414.336.75.75.75H7.5a.75.75 0 010 1.5H5.25z" />
      <path d="M.75 12.88a.746.746 0 01-.75-.75c0-.201.078-.389.22-.53L10.409 1.41A2.23 2.23 0 0112 .752c.598 0 1.16.231 1.584.651l2.166 2.166v-.568a.75.75 0 01.75-.75h3.75a.75.75 0 01.75.75v5.818l2.78 2.781a.744.744 0 010 1.06.748.748 0 01-1.06 0L12.536 2.476a.75.75 0 00-1.067-.006L1.28 12.661a.748.748 0 01-.53.219zM19.5 7.319V3.751h-2.25v1.318l2.25 2.25z" />
      <path d="M12 12.751c-1.654 0-3-1.346-3-3s1.346-3 3-3 3 1.346 3 3-1.346 3-3 3zm0-4.5c-.827 0-1.5.673-1.5 1.5s.673 1.5 1.5 1.5 1.5-.673 1.5-1.5-.673-1.5-1.5-1.5zM10.5 23.251a.748.748 0 01-.74-.626l-.646-3.874H8.25a.75.75 0 01-.75-.75v-.75c0-2.481 2.019-4.5 4.5-4.5s4.5 2.019 4.5 4.5v.75a.75.75 0 01-.75.75h-.865l-.646 3.873a.747.747 0 01-.74.627H10.5zm2.365-1.5l.646-3.873a.747.747 0 01.74-.627H15c0-1.654-1.346-3-3-3s-3 1.346-3 3h.75c.368 0 .679.263.74.626l.646 3.874h1.729z" />
    </svg>
  );
}

export default SvgHouseUser;
