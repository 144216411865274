import * as React from 'react';

function SvgStudyLightIdea(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M12 3.001a.75.75 0 01-.75-.75v-1.5a.75.75 0 011.5 0v1.5a.75.75 0 01-.75.75zM18.364 5.638a.743.743 0 01-.53-.22.744.744 0 010-1.06l1.061-1.061a.744.744 0 011.06 0 .744.744 0 010 1.06l-1.061 1.061a.743.743 0 01-.53.22zM21 12.001a.75.75 0 010-1.5h1.5a.75.75 0 010 1.5H21zM19.425 19.426a.743.743 0 01-.53-.22l-1.061-1.061a.752.752 0 01.53-1.281c.2 0 .389.078.53.22l1.061 1.061a.752.752 0 01-.53 1.281zM5.636 5.638a.743.743 0 01-.53-.22L4.045 4.357a.743.743 0 01-.22-.53c0-.2.078-.389.22-.53.141-.142.33-.22.53-.22s.389.078.53.22l1.061 1.061c.142.141.22.33.22.53s-.078.389-.22.53a.741.741 0 01-.53.22zM1.5 12.001a.75.75 0 010-1.5H3a.75.75 0 010 1.5H1.5zM4.575 19.426a.752.752 0 01-.53-1.281l1.061-1.061a.744.744 0 011.06 0 .752.752 0 010 1.061l-1.061 1.061a.743.743 0 01-.53.22zM12 24.001a.75.75 0 01-.75-.75v-.829a3.743 3.743 0 01-3-3.673v-1.89a6.715 6.715 0 01-3-5.608c0-3.722 3.028-6.75 6.75-6.75s6.75 3.028 6.75 6.75a6.715 6.715 0 01-3 5.608v1.89a3.743 3.743 0 01-3 3.673v.829a.75.75 0 01-.75.75zm-2.25-5.252a2.252 2.252 0 002.25 2.25 2.252 2.252 0 002.25-2.25v-1.135c-.722.257-1.476.387-2.25.387s-1.528-.13-2.25-.387v1.135zM12 6.001a5.256 5.256 0 00-5.25 5.25 5.256 5.256 0 005.25 5.25 5.256 5.256 0 005.25-5.25A5.256 5.256 0 0012 6.001z" />
    </svg>
  );
}

export default SvgStudyLightIdea;
