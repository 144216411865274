import * as React from 'react';

function SvgSmileyCrazy(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M6.756 22.712a.757.757 0 01-.349-.086C3.571 21.131 1.488 18.621.54 15.559s-.646-6.31.849-9.145A11.983 11.983 0 0112.022.012c1.932 0 3.861.478 5.577 1.384a11.925 11.925 0 015.867 7.067c.948 3.062.646 6.31-.848 9.145a11.947 11.947 0 01-5.018 5.018.748.748 0 11-.699-1.327 10.45 10.45 0 004.39-4.39 10.433 10.433 0 00.743-8.002 10.43 10.43 0 00-5.133-6.183 10.502 10.502 0 00-4.879-1.211 10.486 10.486 0 00-9.306 5.602 10.429 10.429 0 00-.742 8.002A10.43 10.43 0 007.107 21.3a.75.75 0 01-.351 1.412z" />
      <circle cx={8.25} cy={8.256} r={1.125} />
      <path d="M8.25 12.006c-2.068 0-3.75-1.682-3.75-3.75s1.682-3.75 3.75-3.75S12 6.188 12 8.256s-1.682 3.75-3.75 3.75zm0-6C7.01 6.006 6 7.015 6 8.256s1.009 2.25 2.25 2.25 2.25-1.009 2.25-2.25-1.009-2.25-2.25-2.25zM18.493 9.756a.75.75 0 01-.707-.501 1.488 1.488 0 00-.767-.853 1.49 1.49 0 00-1.146-.061c-.43.152-.763.485-.915.915a.752.752 0 01-.707.5.75.75 0 01-.707-1 2.983 2.983 0 011.83-1.829 2.981 2.981 0 012.293.122 2.982 2.982 0 011.535 1.708.745.745 0 01-.03.573.744.744 0 01-.679.426zM12 24.006a3.754 3.754 0 01-3.75-3.75v-.154a8.318 8.318 0 01-4.335-5.695.747.747 0 01.734-.901h14.7a.748.748 0 01.735.9 8.317 8.317 0 01-4.335 5.696v.154A3.752 3.752 0 0112 24.006zm-1.5-6a.75.75 0 00-.75.75v1.5a2.252 2.252 0 002.25 2.25 2.252 2.252 0 002.25-2.25v-1.5a.75.75 0 00-.75-.75h-3zm-4.864-3a6.819 6.819 0 002.644 3.379 2.253 2.253 0 012.219-1.879h3c1.115 0 2.042.814 2.219 1.879a6.819 6.819 0 002.644-3.379H5.636z" />
    </svg>
  );
}

export default SvgSmileyCrazy;
