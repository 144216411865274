import * as React from 'react';

function SvgSatelliteSignal(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M20.694 22.533a2.235 2.235 0 01-1.591-.659l-3.182-3.182a2.253 2.253 0 010-3.182l-1.12-1.12a3.7 3.7 0 01-2.047.612 3.754 3.754 0 01-3.75-3.75c0-.736.21-1.437.612-2.047l-1.121-1.12c-.424.424-.989.658-1.591.658s-1.167-.234-1.591-.658L2.131 4.903a2.253 2.253 0 010-3.182L3.192.66c.424-.424.989-.658 1.591-.658S5.949.236 6.374.66l3.182 3.182c.424.424.658.989.658 1.591 0 .601-.234 1.166-.658 1.59l1.113 1.113a3.754 3.754 0 012.084-.636 3.754 3.754 0 013.75 3.75c0 .741-.224 1.47-.636 2.084l1.113 1.113a2.235 2.235 0 011.591-.658c.602 0 1.167.234 1.591.658l3.182 3.182a2.253 2.253 0 010 3.182l-1.061 1.061a2.22 2.22 0 01-1.589.661zm-2.122-7.242a.748.748 0 00-.531.219l-1.061 1.061a.752.752 0 000 1.061l3.182 3.182c.142.142.33.22.531.22s.389-.078.53-.219l1.061-1.061a.752.752 0 000-1.061l-3.182-3.182a.741.741 0 00-.53-.22zm-5.818-6.29c-1.241 0-2.25 1.009-2.25 2.25s1.009 2.25 2.25 2.25 2.25-1.009 2.25-2.25-1.009-2.25-2.25-2.25zm-7.971-7.5c-.2 0-.389.078-.53.22l-1.061 1.06a.752.752 0 000 1.061l3.182 3.182a.744.744 0 001.06 0l1.061-1.061a.744.744 0 000-1.06L5.313 1.721a.744.744 0 00-.53-.22zM8.111 24.057a8.195 8.195 0 01-5.809-2.479 8.192 8.192 0 01-2.35-5.859 8.19 8.19 0 012.475-5.808.746.746 0 01.525-.214.75.75 0 01.525 1.286 6.759 6.759 0 00-.102 9.546 6.711 6.711 0 004.752 2.028h.071a6.707 6.707 0 004.823-2.028.748.748 0 011.062-.012c.295.29.3.766.01 1.061A8.199 8.199 0 018.2 24.057h-.089z" />
      <path d="M8.347 20.152a4.486 4.486 0 01-4.497-4.336 4.473 4.473 0 011.205-3.226.75.75 0 111.096 1.022 2.982 2.982 0 00-.803 2.151 2.98 2.98 0 00.953 2.089 2.986 2.986 0 002.045.801c.764 0 1.491-.284 2.045-.801a.752.752 0 011.06.038.75.75 0 01-.038 1.06 4.477 4.477 0 01-3.066 1.202z" />
    </svg>
  );
}

export default SvgSatelliteSignal;
