import * as React from 'react';

function SvgEnter(props) {
  return (
    <svg
      id="Enter_svg__Laag_1"
      x={0}
      y={0}
      viewBox="0 0 24 24"
      xmlSpace="preserve"
      {...props}
    >
      <style>{'.Enter_svg__st0{fill:#0c1c46}'}</style>
      <g id="Enter_svg__Artboard">
        <g id="Enter_svg__keyboard-arrow-return-1">
          <path
            className="Enter_svg__st0"
            d="M3 16.8c-.2 0-.4-.1-.5-.2l-3-3c-.3-.3-.3-.8 0-1.1l3-3c.3-.3.8-.3 1.1 0s.3.8 0 1.1L1.1 13l2.5 2.5c.3.3.3.8 0 1.1-.2.1-.4.2-.6.2z"
            transform="translate(4 4)"
          />
          <path
            className="Enter_svg__st0"
            d="M12.8 13.8H0c-.4 0-.8-.3-.8-.8s.3-.8.8-.8h12.8c1.4 0 2.5-1.1 2.5-2.5V3.2c0-1.4-1.1-2.5-2.5-2.5H6.9c-.4.1-.7-.3-.7-.7s.3-.8.8-.8h5.9c2.2 0 4 1.8 4 4v6.5c-.1 2.3-1.9 4.1-4.1 4.1z"
            transform="translate(4 4)"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgEnter;
