const baseStyles = {};

export const webStyles = {
  ...baseStyles,
  styledTooltip: (theme, unit) => `
    ${theme.textStyle.body}
    border-radius: ${theme.borderRadius.$1};
    padding-top: ${theme.spacing.$3};
    padding-bottom: ${theme.spacing.$3};
    filter: drop-shadow(0 0 ${unit(1)} ${theme.color.dark$4});
    max-width: ${unit(200)};
  `,
};

export const nativeStyles = {
  ...baseStyles,
};
