import * as React from 'react';

function SvgEmail(props) {
  return (
    <svg viewBox="0 0 48 48" {...props}>
      <path d="M36.8 36c.889 0 1.644-.31 2.267-.931.622-.62.933-1.356.933-2.205V16.136a3.082 3.082 0 00-.667-1.928l-.016-.016-.017-.016c0-.022-.006-.033-.017-.033-.01 0-.016-.01-.016-.032a3.305 3.305 0 00-1.117-.817A3.24 3.24 0 0036.8 13H11.2c-.489 0-.944.098-1.367.294a3.352 3.352 0 00-1.1.817c0 .022-.005.032-.016.032H8.7v.033l-.033.033A3.082 3.082 0 008 16.136v16.728c0 .85.311 1.584.933 2.205.623.62 1.378.931 2.267.931h25.6zM24 23.455c-.4 0-.789-.066-1.167-.196a4.138 4.138 0 01-1.066-.556l-10.1-7.612h24.666l-10.1 7.612c-.333.24-.689.425-1.066.556-.378.13-.767.196-1.167.196z" />
    </svg>
  );
}

export default SvgEmail;
