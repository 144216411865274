import * as React from 'react';

function SvgModulePuzzle(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M19.5 24a4.518 4.518 0 01-4.229-2.961l-2.302 2.299c-.424.424-.989.657-1.59.657s-1.167-.234-1.591-.658L7.682 21.23a.748.748 0 01.238-1.221 2.986 2.986 0 001.6-1.599c.311-.739.316-1.554.014-2.296s-.875-1.322-1.614-1.634a2.996 2.996 0 00-3.929 1.6.748.748 0 01-1.221.241L.659 14.215a2.253 2.253 0 01-.001-3.182L2.96 8.729A4.518 4.518 0 010 4.5C0 2.019 2.019 0 4.5 0a4.519 4.519 0 014.229 2.961L11.031.659a2.235 2.235 0 011.591-.658c.602 0 1.167.234 1.591.658L16.32 2.77a.749.749 0 01-.24 1.221 2.985 2.985 0 00-1.6 1.6 3.003 3.003 0 002.765 4.166c.387 0 .768-.075 1.131-.223A2.983 2.983 0 0020.01 7.92a.751.751 0 011.22-.24l2.111 2.106a2.253 2.253 0 010 3.182l-2.302 2.302A4.521 4.521 0 0124 19.5c0 2.481-2.019 4.5-4.5 4.5zm-3.75-5.25a.75.75 0 01.75.75c0 1.654 1.346 3 3 3s3-1.346 3-3-1.346-3-3-3a.75.75 0 01-.53-1.28l3.311-3.311a.75.75 0 000-1.06l-1.418-1.414a4.46 4.46 0 01-1.922 1.49 4.478 4.478 0 01-3.444-.021 4.505 4.505 0 01-.93-7.765l-1.415-1.418a.743.743 0 00-.53-.218.744.744 0 00-.53.219L8.78 5.031a.743.743 0 01-.818.163A.75.75 0 017.5 4.5c0-1.654-1.346-3-3-3s-3 1.346-3 3 1.346 3 3 3c.305 0 .577.182.693.463a.747.747 0 01-.163.818l-3.311 3.311a.752.752 0 000 1.061l1.418 1.414a4.454 4.454 0 011.922-1.49 4.474 4.474 0 013.445.022 4.473 4.473 0 012.42 2.45 4.47 4.47 0 01-.021 3.444 4.464 4.464 0 01-1.468 1.87l1.414 1.415a.744.744 0 00.53.219.744.744 0 00.53-.219l3.312-3.308a.749.749 0 01.529-.22z" />
    </svg>
  );
}

export default SvgModulePuzzle;
