import * as React from 'react';

function SvgRealEstateDimensionsHouse(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M3 24a.755.755 0 01-.26-.047l-.022-.008a.732.732 0 01-.249-.165L.22 21.53a.752.752 0 01.53-1.281c.2 0 .389.078.53.22l.97.97V9.311l-.97.97a.744.744 0 01-1.06 0 .747.747 0 010-1.061l2.249-2.25A.74.74 0 013 6.75c.089 0 .177.016.261.048l.021.007a.75.75 0 01.249.166L5.78 9.22c.142.141.22.33.22.53s-.078.389-.22.53c-.141.142-.33.22-.53.22s-.389-.078-.53-.22l-.97-.97v12.129l.97-.97a.744.744 0 011.06 0 .752.752 0 010 1.061l-2.25 2.25a.763.763 0 01-.246.164l-.026.01A.747.747 0 013 24zM21 6a.743.743 0 01-.53-.22c-.142-.141-.22-.33-.22-.53s.078-.389.22-.53l.97-.97H9.311l.97.97c.142.141.22.33.22.53s-.078.389-.22.53a.747.747 0 01-1.061 0L6.97 3.53a.736.736 0 01-.163-.244l-.01-.026a.735.735 0 01.001-.521l.007-.021a.75.75 0 01.166-.249L9.22.22c.141-.142.33-.22.53-.22s.389.078.53.22c.142.141.22.33.22.53s-.078.389-.22.53l-.97.97h12.13l-.97-.97c-.142-.141-.22-.33-.22-.53s.078-.389.22-.53a.749.749 0 011.06 0l2.25 2.25a.74.74 0 01.163.245l.01.026a.748.748 0 010 .517l-.006.016a.764.764 0 01-.168.257L21.53 5.78A.743.743 0 0121 6zM12.75 22.5a2.252 2.252 0 01-2.25-2.25v-5.517l-1.748 1.574a.75.75 0 11-1.003-1.114l6.497-5.85a2.248 2.248 0 013.01-.001l6.498 5.85a.748.748 0 01-.502 1.307.75.75 0 01-.502-.193L21 14.733v5.517a2.252 2.252 0 01-2.25 2.25h-6zM12 20.25c0 .414.336.75.75.75h6a.75.75 0 00.75-.75v-6.867l-3.249-2.925a.75.75 0 00-1.002 0L12 13.383v6.867z" />
    </svg>
  );
}

export default SvgRealEstateDimensionsHouse;
