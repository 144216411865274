import * as React from 'react';

function SvgDiagramSplitHorizontal(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M20.25 24.003a.751.751 0 01-.562-.255L16.72 20.78a.744.744 0 010-1.06.749.749 0 011.06 0l1.72 1.72v-3.429a5.216 5.216 0 00-1.53-3.715 5.22 5.22 0 00-3.706-1.545H.75a.75.75 0 010-1.501h13.512a5.215 5.215 0 003.708-1.545A5.212 5.212 0 0019.5 5.99V2.561l-1.72 1.72a.744.744 0 01-1.06 0c-.142-.141-.22-.33-.22-.53s.078-.389.22-.53l3-3a.75.75 0 01.245-.163l.024-.009a.712.712 0 01.522-.001l.032.012c.085.035.167.09.237.16l3 3c.142.141.22.33.22.53s-.078.389-.22.53a.749.749 0 01-1.06 0L21 2.561v3.426a6.701 6.701 0 01-1.968 4.777A6.703 6.703 0 0117.35 12c.617.319 1.18.733 1.682 1.236A6.71 6.71 0 0121 18.013v3.426l1.72-1.72a.744.744 0 011.06 0 .75.75 0 010 1.061l-2.985 2.985a.732.732 0 01-.545.238z" />
    </svg>
  );
}

export default SvgDiagramSplitHorizontal;
