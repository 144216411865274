import * as React from 'react';

function SvgHumanResourcesOfferMan(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M.749 24a.75.75 0 01-.75-.75v-9a.75.75 0 011.5 0V15H6c1.8 0 3.324 1.268 3.675 3h3.074a3.754 3.754 0 013.75 3.75.75.75 0 01-.75.75H1.499v.75a.75.75 0 01-.75.75zm14.122-3a2.264 2.264 0 00-2.121-1.5H6a.75.75 0 01-.001-1.5h2.119A2.262 2.262 0 006 16.5H1.499V21h13.372zM16.865 9.75c-2.688 0-4.875-2.187-4.875-4.875S14.177 0 16.865 0s4.875 2.187 4.875 4.875-2.187 4.875-4.875 4.875zm0-8.25c-1.861 0-3.375 1.514-3.375 3.375s1.514 3.375 3.375 3.375 3.375-1.514 3.375-3.375S18.726 1.5 16.865 1.5z" />
      <path d="M10.501 15.75a.75.75 0 01-.707-1 7.469 7.469 0 014.568-4.569 7.495 7.495 0 012.509-.434 7.43 7.43 0 013.225.735 7.453 7.453 0 013.841 4.268.745.745 0 01-.03.573.744.744 0 01-.677.427.752.752 0 01-.707-.5 5.961 5.961 0 00-3.073-3.414 5.952 5.952 0 00-4.587-.241 5.975 5.975 0 00-3.655 3.655.754.754 0 01-.707.5z" />
    </svg>
  );
}

export default SvgHumanResourcesOfferMan;
