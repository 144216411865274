import * as React from 'react';

function SvgTableInsertRowBelow(props) {
  return (
    <svg data-name="Layer 1" viewBox="0 0 22 22" {...props}>
      <path d="M18.714 1.25H3.285A2.038 2.038 0 001.25 3.286v15.428a2.038 2.038 0 002.035 2.036h3.452a.75.75 0 100-1.5H3.285a.536.536 0 01-.535-.536V8.75h16.5v9.964a.536.536 0 01-.536.536h-3.451a.75.75 0 100 1.5h3.451a2.038 2.038 0 002.036-2.036V3.286a2.038 2.038 0 00-2.036-2.036zm-15.964 6V3.286a.536.536 0 01.535-.536h6.965v4.5zm9 0v-4.5h6.964a.536.536 0 01.536.536V7.25z" />
      <path d="M15.5 16.25a.75.75 0 000-1.5h-3.75V11a.75.75 0 00-1.5 0v3.75H6.5a.75.75 0 000 1.5h3.75V20a.75.75 0 001.5 0v-3.75z" />
    </svg>
  );
}

export default SvgTableInsertRowBelow;
