import React, { useContext } from 'react';
import styled from '@emotion/styled';
import { SidebarContext } from '@embracesbs/component-sidebar';
import {
  getExtraProps,
  withTheme,
  getSpacingString,
} from '@embracesbs/helpers';
import { propTypes, defaultProps } from './SectionHeader.props';

const StyledSectionHeader = styled.p`
  ${({ theme, color, align, currentSpacing }) => `
    display: flex;
    box-sizing: border-box;
    width: 100%;
    color: ${color};
    text-align: ${align};
    padding: ${currentSpacing};
    margin: 0;
    ${theme.textStyle.overline};
  `};
`;

/**
 * SectionHeader component
 * @param {string} color
 * @param {array} extraProps
 * @param {string} text
 * @param {string} align
 * @param {number|object} spacing
 */
const SectionHeader = (props) => {
  const {
    color,
    extraProps,
    text,
    textTransform,
    align,
    spacing,
    theme,
  } = props;

  const { isCollapsed } = useContext(SidebarContext);
  const currentSpacingString = getSpacingString(spacing, defaultProps.spacing);

  return (
    <StyledSectionHeader
      onClick={(e) => isCollapsed && e.stopPropagation()}
      {...getExtraProps(props, extraProps)}
      theme={theme}
      color={color}
      textTransform={textTransform}
      align={align}
      currentSpacing={currentSpacingString}
    >
      {text}
    </StyledSectionHeader>
  );
};

SectionHeader.propTypes = propTypes;
SectionHeader.defaultProps = defaultProps;

/** @component */
export default withTheme(SectionHeader);
