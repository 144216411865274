import PropTypes from 'prop-types';
import {
  requireOneOf,
  validatePropType,
  iconValidation,
  theme,
} from '@embracesbs/helpers';
import * as InternalIcons from './icons/components';

const oneOfProps = {
  iconName: PropTypes.string,
  text: PropTypes.string,
  imgSource: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

export const allPossibleIconSizes = [
  10,
  12,
  14,
  16,
  18,
  20,
  24,
  32,
  40,
  48,
  56,
  64,
  72,
  76,
];

const basePropTypes = {
  /** An array of strings which includs the extra prop keys */
  extraProps: PropTypes.array,
  /** Background color */
  background: PropTypes.string,
  /** Icon shape */
  isRounded: PropTypes.bool,
  /** Icon color */
  color: PropTypes.string,
  /** Icon size (using pixels as a reference) */
  size: PropTypes.oneOfType([
    PropTypes.oneOf(allPossibleIconSizes),
    PropTypes.string,
  ]),
  spacing: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.shape({
      bottom: PropTypes.number,
      left: PropTypes.number,
      right: PropTypes.number,
      top: PropTypes.number,
    }),
  ]),
  /** Icon border color */
  borderColor: PropTypes.string,
};

export const nativePropTypes = {
  ...basePropTypes,
  iconName: (props, propName) => {
    const iconName = props[propName]; // eslint-disable-line react/destructuring-assignment
    const internalIconsNames = Object.keys(InternalIcons);

    if (!internalIconsNames.includes(iconName)) {
      return new Error(`The iconName value('${iconName}') needs to be from
        these internal icons: ${internalIconsNames.join(', ')}!`);
    }

    return null;
  },
};

export const webPropTypes = {
  ...basePropTypes,
  /** Icon name reference */
  iconName: (props, propName, componentName) =>
    requireOneOf(
      oneOfProps,
      props,
      () => validatePropType(props, propName, oneOfProps, componentName),
      () => iconValidation(props[propName], Object.keys(InternalIcons)),
    ),
  /** Icon text */
  text: (props, propName, componentName) =>
    requireOneOf(oneOfProps, props, () =>
      validatePropType(props, propName, oneOfProps, componentName),
    ),
  /** Source of an img */
  imgSource: (props, propName, componentName) =>
    requireOneOf(oneOfProps, props, () =>
      validatePropType(props, propName, oneOfProps, componentName),
    ),
  /** Is the icon full width (is not valid for a text option) */
  isFullWidth: PropTypes.bool,
  /** The optional bagde component in the top right corner */
  badge: PropTypes.node,
};

const baseDefaultProps = {
  extraProps: [],
  background: 'transparent',
  isRounded: false,
  iconName: null,
  borderColor: 'transparent',
  spacing: 0,
};

export const nativeDefaultProps = {
  ...baseDefaultProps,
  color: theme.color.dark,
  size: 20,
};

export const webDefaultProps = {
  ...baseDefaultProps,
  text: null,
  color: 'inherit',
  size: 40,
  imgSource: null,
  isFullWidth: false,
  badge: null,
};
