import * as React from 'react';

function SvgTableDeleteRow(props) {
  return (
    <svg data-name="Layer 1" viewBox="0 0 22 22" {...props}>
      <path d="M18.65 1.25H3.26a1.991 1.991 0 00-2.01 2.016v15.548a1.97 1.97 0 00.644 1.486 1.8 1.8 0 001.167.456 1.457 1.457 0 00.2-.014H18.74a1.992 1.992 0 002.01-2.015V3.266a2.014 2.014 0 00-2.1-2.016zm.09 17.99l-15.573.006a.328.328 0 01-.28-.073.472.472 0 01-.137-.359V13.25H6a.75.75 0 000-1.5H2.75v-1.5H6a.75.75 0 000-1.5H2.75V3.266a.486.486 0 01.51-.516h15.39c.376 0 .6.193.6.516V8.75H16a.75.75 0 000 1.5h3.25v1.5H16a.75.75 0 000 1.5h3.25v5.475a.485.485 0 01-.51.515z" />
      <path d="M13.531 8.47a.75.75 0 00-1.061 0L11 9.939 9.531 8.47A.75.75 0 008.47 9.53L9.94 11l-1.47 1.47a.75.75 0 101.061 1.06L11 12.061l1.47 1.469a.75.75 0 101.061-1.06L12.061 11l1.47-1.47a.749.749 0 000-1.06z" />
    </svg>
  );
}

export default SvgTableDeleteRow;
