import * as React from 'react';

function SvgColorSpray(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M5.251 24a2.252 2.252 0 01-2.25-2.25v-10.5c0-.051.005-.103.016-.153l.004-.018a.711.711 0 01.044-.13l.015-.034 1.085-2.171A2.236 2.236 0 016.177 7.5H8.25V6h-1.5A.75.75 0 016 5.25V3c0-.827.673-1.5 1.5-1.5h3c.827 0 1.5.673 1.5 1.5v2.25a.75.75 0 01-.75.75h-1.5v1.5h2.073c.858 0 1.629.476 2.013 1.244l1.085 2.171a1.227 1.227 0 01.063.183c.01.051.016.102.016.153v10.5a2.252 2.252 0 01-2.25 2.25H5.251zm-.75-2.25c0 .414.336.75.75.75h7.5a.75.75 0 00.75-.75V12h-9v9.75zm8.536-11.25l-.542-1.086A.747.747 0 0011.824 9H6.177a.747.747 0 00-.671.414L4.964 10.5h8.073zm-2.536-6V3h-3v1.5h3zM20.251 10.5a.745.745 0 01-.279-.054l-3.75-1.5a.745.745 0 01-.411-.401.748.748 0 01.968-.992l3.75 1.5a.745.745 0 01.411.401.742.742 0 01.007.574.746.746 0 01-.696.472zM16.501 3a.744.744 0 01-.696-.472.747.747 0 01.418-.975l3.75-1.5a.75.75 0 01.556 1.394l-3.75 1.5a.759.759 0 01-.278.053zM16.501 6a.75.75 0 010-1.5h3.75a.75.75 0 010 1.5h-3.75z" />
    </svg>
  );
}

export default SvgColorSpray;
