import { unit } from '@embracesbs/helpers';

const imageStyles = {
  shape: {
    default: `
    `,
    circle: `
      border-radius: 50%;
    `,
    rounded: `
      border-radius: ${unit(4)};
    `,
  },
};

export default imageStyles;
