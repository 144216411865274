import * as React from 'react';

function SvgOffice365(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M14.4 21.8l-10.9-4 10.9 1.5V4.6L7.2 6.3v10l-3.7 1.5V5.5l10.9-4.1 6.1 1.8v16.9z" />
    </svg>
  );
}

export default SvgOffice365;
