import * as React from 'react';

function SvgLayersStacked(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M11.993 14.251a2.3 2.3 0 01-1.052-.255L.732 8.714a1.367 1.367 0 01.002-2.427l10.206-5.28a2.307 2.307 0 012.104 0l10.21 5.281a1.365 1.365 0 010 2.427l-10.209 5.281a2.307 2.307 0 01-1.052.255zm-.363-1.588a.792.792 0 00.727 0l9.979-5.162-9.979-5.162a.788.788 0 00-.727 0L1.652 7.501l9.978 5.162z" />
      <path d="M11.996 18.751c-.366 0-.73-.088-1.055-.256L.433 13.426a.746.746 0 01-.349-1.001.752.752 0 011.001-.349l10.527 5.078a.847.847 0 00.385.096c.125 0 .25-.03.361-.088l10.576-5.087a.75.75 0 01.65 1.351l-10.557 5.078c-.309.16-.668.247-1.031.247z" />
      <path d="M11.996 23.251c-.366 0-.73-.088-1.055-.256L.433 17.926a.746.746 0 01-.349-1.001.753.753 0 011.002-.349l10.527 5.078a.834.834 0 00.385.096c.125 0 .25-.03.361-.087l10.576-5.087a.75.75 0 01.65 1.351l-10.557 5.078c-.31.159-.669.246-1.032.246z" />
    </svg>
  );
}

export default SvgLayersStacked;
