import * as React from 'react';

function SvgSmileyDisapointed1(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M12 24C5.383 24 0 18.617 0 12S5.383 0 12 0s12 5.383 12 12-5.383 12-12 12zm0-22.5C6.21 1.5 1.5 6.21 1.5 12S6.21 22.5 12 22.5 22.5 17.79 22.5 12 17.79 1.5 12 1.5z" />
      <circle cx={7.768} cy={9.518} r={1.125} />
      <circle cx={16.5} cy={9.518} r={1.125} />
      <path d="M8.313 19.501a1.512 1.512 0 01-1.459-1.79 5.247 5.247 0 015.148-4.211 5.267 5.267 0 015.142 4.201 1.514 1.514 0 01-1.476 1.8.744.744 0 01-.297-.06c-1.066-.455-2.2-.686-3.371-.686s-2.305.231-3.37.686a.844.844 0 01-.317.06zM12.001 15a3.745 3.745 0 00-3.662 2.938A10.043 10.043 0 0112 17.253c1.261 0 2.488.229 3.651.68A3.743 3.743 0 0012.001 15zM5.25 8.768a.751.751 0 01-.672-1.083 4.314 4.314 0 012.039-1.991 4.29 4.29 0 012.81-.344.751.751 0 01-.16 1.483.774.774 0 01-.159-.017 2.805 2.805 0 00-1.84.23A2.831 2.831 0 005.922 8.35a.745.745 0 01-.672.418zM19.018 8.768a.747.747 0 01-.668-.408 2.97 2.97 0 00-2.621-1.599c-.194 0-.389.02-.58.058a.753.753 0 01-.884-.586.748.748 0 01.586-.884c.289-.059.584-.088.877-.088 1.653 0 3.206.948 3.958 2.415a.746.746 0 01-.668 1.092z" />
    </svg>
  );
}

export default SvgSmileyDisapointed1;
