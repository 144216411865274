import PropTypes from 'prop-types';
import { theme } from '@embracesbs/helpers';

export const propTypes = {
  /** An array of strings which includes the extra prop keys */
  extraProps: PropTypes.array,

  /** Set the checkbox as active */
  isChecked: PropTypes.bool,

  /** Disable the checkbox */
  isDisabled: PropTypes.bool,

  /** The ID of the checkbox */
  id: PropTypes.string.isRequired,

  /** Set the label of the checkbox */
  label: PropTypes.string,

  /** Set the name of the checkbox, checkboxes with the same name belong to the same group */
  name: PropTypes.string.isRequired,

  /** Attach function to the onClick handler */
  onClick: PropTypes.func,

  /** The value of the checkbox */
  value: PropTypes.string.isRequired,

  /** inner spacing of the checkbox */
  innerSpacing: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),

  /** has the checkbox circled shape */
  hasCircledShape: PropTypes.bool,

  /** Optional description */
  description: PropTypes.string,

  /** if the label will stay on one single line and gets truncated */
  isLabelSingleLine: PropTypes.bool,

  /** if the description will stay on one single line and gets truncated */
  isDescriptionSingleLine: PropTypes.bool,

  /**  if the description and the label are crossed off on checked */
  isOnCrossMode: PropTypes.bool,
};

export const defaultProps = {
  extraProps: [],
  isChecked: null,
  isDisabled: false,
  innerSpacing: {
    top: 0,
    bottom: theme.spacing.$5Number,
    right: theme.spacing.$5Number,
    left: theme.spacing.$5Number,
  },
  label: '',
  hasCircledShape: false,
  onClick: () => {},
  description: null,
  isLabelSingleLine: false,
  isDescriptionSingleLine: false,
  isOnCrossMode: false,
};

export const checkboxItemPropTypes = {
  isChecked: PropTypes.bool.isRequired,
  label: PropTypes.string,
  id: PropTypes.string.isRequired,
};

export const checkboxItemDefaultProps = {
  label: '',
};
