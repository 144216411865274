import * as React from 'react';

function SvgMilestoneStart(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M9.1 12.1c-.1-.1-.2-.3-.2-.5s.1-.4.2-.5c.1-.1.3-.2.5-.2h11.7L18 7.4c-.3-.3-.3-.8 0-1.1.3-.3.8-.3 1 0l4.7 4.8c.1.1.1.1.1.2.1.2.1.4 0 .6 0 .1-.1.2-.1.2L19 16.9c-.3.3-.8.3-1 0s-.3-.8 0-1.1l3.4-3.5H9.7c-.2 0-.4-.1-.6-.2zM3.8 15.8C1.7 15.8 0 14.1 0 12s1.7-3.8 3.8-3.8S7.6 9.9 7.6 12s-1.7 3.8-3.8 3.8zm0-6.1c-1.2 0-2.2 1-2.2 2.2s1 2.3 2.2 2.3 2.2-1 2.2-2.3-.9-2.2-2.2-2.2z" />
    </svg>
  );
}

export default SvgMilestoneStart;
