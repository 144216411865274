import * as React from 'react';

function SvgSmileyCryingRainbow(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M14.237 9.754a.742.742 0 01-.677-.427.745.745 0 01-.03-.573 2.983 2.983 0 011.83-1.829 2.985 2.985 0 012.293.122 2.982 2.982 0 011.535 1.708.745.745 0 01-.03.573.744.744 0 01-.677.427.75.75 0 01-.707-.501 1.488 1.488 0 00-.767-.853 1.486 1.486 0 00-1.146-.061c-.43.152-.763.485-.915.915a.757.757 0 01-.709.499zM5.494 9.754a.745.745 0 01-.677-.427.748.748 0 01-.03-.574 2.984 2.984 0 011.829-1.829 3.005 3.005 0 013.828 1.83.751.751 0 01-.707 1 .752.752 0 01-.707-.5 1.504 1.504 0 00-1.914-.914 1.49 1.49 0 00-.915.914.752.752 0 01-.707.5zM12.005 19.504a2.252 2.252 0 01-2.25-2.25v-1.5c0-1.241 1.009-2.25 2.25-2.25s2.25 1.009 2.25 2.25v1.5c0 1.24-1.01 2.25-2.25 2.25zm0-4.5a.75.75 0 00-.75.75v1.5a.75.75 0 001.5 0v-1.5a.75.75 0 00-.75-.75zM3.005 24.004c-.827 0-1.5-.673-1.5-1.5 0-1.372.947-2.567 2.25-2.905v-6.845a.75.75 0 011.5 0v7.5a.75.75 0 01-.75.75c-.827 0-1.5.673-1.5 1.5h6c0-.827-.673-1.5-1.5-1.5a.75.75 0 01-.75-.75v-7.5a.75.75 0 011.5 0v6.845a3.018 3.018 0 012.25 2.905c0 .827-.673 1.5-1.5 1.5h-6zM15.005 24.004c-.827 0-1.5-.673-1.5-1.5 0-1.372.947-2.567 2.25-2.905v-6.845a.75.75 0 011.5 0v7.5a.75.75 0 01-.75.75c-.827 0-1.5.673-1.5 1.5h6c0-.827-.673-1.5-1.5-1.5a.75.75 0 01-.75-.75v-7.5a.75.75 0 011.5 0v6.845a3.018 3.018 0 012.25 2.905c0 .827-.673 1.5-1.5 1.5h-6z" />
      <path d="M1.412 16.504a.75.75 0 01-.706-.498 12.036 12.036 0 010-8.067A12.034 12.034 0 0112.019-.03c1.37 0 2.723.235 4.022.699a11.92 11.92 0 016.806 6.165 11.915 11.915 0 01.463 9.171.753.753 0 01-.96.454.745.745 0 01-.425-.385.745.745 0 01-.029-.573c1.946-5.454-.907-11.474-6.36-13.42a10.452 10.452 0 00-3.518-.611 10.532 10.532 0 00-9.901 6.972 10.532 10.532 0 000 7.059c.067.188.057.392-.029.573s-.237.318-.426.385a.713.713 0 01-.25.045z" />
    </svg>
  );
}

export default SvgSmileyCryingRainbow;
