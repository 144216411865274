import * as React from 'react';

function SvgSchoolBook(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M3 24c-1.654 0-3-1.346-3-3V3.75A3.754 3.754 0 013.75 0h15a.75.75 0 010 1.5h-15A2.252 2.252 0 001.5 3.75v14.651A2.973 2.973 0 013 18h15.75a.75.75 0 010 1.5H18v3h.75a.75.75 0 010 1.5H3zm0-4.5c-.827 0-1.5.673-1.5 1.5s.673 1.5 1.5 1.5h13.5v-3H3z" />
      <path d="M8.25 13.5a.75.75 0 01-.75-.75V10.5H6v2.25a.75.75 0 01-1.5 0v-6c0-1.241 1.009-2.25 2.25-2.25S9 5.509 9 6.75v6a.75.75 0 01-.75.75zM7.5 9V6.75a.75.75 0 00-1.5 0V9h1.5zM12.75 16.5a.75.75 0 01-.75-.75v-7.5a.75.75 0 01.75-.75h1.5a2.252 2.252 0 012.25 2.25v.75c0 .563-.206 1.091-.575 1.5.37.409.575.937.575 1.5v.75a2.252 2.252 0 01-2.25 2.25h-1.5zm1.5-1.5a.75.75 0 00.75-.75v-.75a.75.75 0 00-.75-.75h-.75l.75 2.25zm0-3.75a.75.75 0 00.75-.75v-.75a.75.75 0 00-.75-.75h-.75v2.25h.75zM23.25 13.5a3.754 3.754 0 01-3.75-3.75v-1.5a3.754 3.754 0 013.75-3.75.75.75 0 010 1.5A2.252 2.252 0 0021 8.25v1.5A2.252 2.252 0 0023.25 12a.75.75 0 010 1.5z" />
    </svg>
  );
}

export default SvgSchoolBook;
