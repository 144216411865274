import * as React from 'react';

function SvgFileJava(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M.749 20.25a.75.75 0 01-.75-.75V2.253a2.252 2.252 0 012.25-2.25h10.629c.602 0 1.167.235 1.592.66l5.871 5.87c.425.425.659.99.659 1.592v4.628a.75.75 0 01-1.5 0v-3.75h-5.25A2.252 2.252 0 0112 6.753v-5.25H2.25a.75.75 0 00-.75.75V19.5a.752.752 0 01-.751.75zm12.75-13.497c0 .414.336.75.75.75h4.939l-5.689-5.689v4.939z" />
      <path d="M2.249 24.003a.75.75 0 010-1.5h1.5a.75.75 0 00.75-.75v-6a.75.75 0 011.5 0v6a2.252 2.252 0 01-2.25 2.25h-1.5zM11.249 24.003a.75.75 0 01-.75-.75v-2.25h-1.5v2.25a.75.75 0 01-1.5 0v-6c0-1.241 1.009-2.25 2.25-2.25s2.25 1.009 2.25 2.25v6a.75.75 0 01-.75.75zm-.75-4.5v-2.25a.75.75 0 00-1.5 0v2.25h1.5zM23.249 24.003a.75.75 0 01-.75-.75v-2.25h-1.5v2.25a.75.75 0 01-1.5 0v-6c0-1.241 1.009-2.25 2.25-2.25s2.25 1.009 2.25 2.25v6a.75.75 0 01-.75.75zm-.75-4.5v-2.25a.75.75 0 00-1.5 0v2.25h1.5zM15.749 24.004a.75.75 0 01-.624-.334 9.65 9.65 0 01-1.626-5.37v-2.547a.75.75 0 011.5 0V18.3c0 1.184.258 2.354.75 3.423a8.197 8.197 0 00.75-3.423v-2.547a.75.75 0 011.5 0V18.3a9.65 9.65 0 01-1.626 5.37.75.75 0 01-.624.334z" />
    </svg>
  );
}

export default SvgFileJava;
