import * as React from 'react';

function SvgPhoneActionsMerge(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M15.3 5.7c.1.1.3.2.5.2s.4-.1.5-.2c.3-.3.3-.8 0-1.1L12.5.8c-.3-.3-.8-.3-1.1 0L7.7 4.6c-.3.3-.3.8 0 1.1s.8.3 1.1 0l2.5-2.5v11.7l-8.8 7.2c-.3.3-.4.7-.1 1.1.1.2.4.3.6.3.2 0 .3-.1.5-.2l9.1-7.4c.2-.1.3-.4.3-.6V3.2l2.4 2.5zM22.2 22.1l-7.3-6c-.3-.3-.8-.2-1.1.1-.3.3-.2.8.1 1.1l7.3 6c.1.1.3.2.5.2s.4-.1.6-.3c.2-.4.2-.9-.1-1.1z" />
    </svg>
  );
}

export default SvgPhoneActionsMerge;
