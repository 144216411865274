import * as React from 'react';

function SvgRealEstatePersonSearchHouse(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M15.75 21a.75.75 0 01-.75-.75V16.5h-2.25a.75.75 0 010-1.5h3a.75.75 0 01.75.75v2.689l3.22-3.22c.14-.14.333-.22.53-.22h1.494a.757.757 0 00.756-.756V2.25a.75.75 0 00-.75-.75h-12a.75.75 0 00-.75.75v4.5a.75.75 0 01-1.5 0v-4.5C7.5 1.01 8.509 0 9.75 0h12C22.99 0 24 1.01 24 2.25v11.994a2.259 2.259 0 01-2.256 2.256H20.56l-4.28 4.28a.744.744 0 01-.53.22z" />
      <path d="M6.75 17.25a4.13 4.13 0 01-4.125-4.125C2.625 10.851 4.475 9 6.75 9s4.125 1.85 4.125 4.125A4.13 4.13 0 016.75 17.25zm0-6.75a2.629 2.629 0 00-2.625 2.625c0 1.448 1.178 2.625 2.625 2.625s2.625-1.177 2.625-2.625A2.629 2.629 0 006.75 10.5zM.752 24.001a.745.745 0 01-.599-.295.745.745 0 01-.147-.555 6.819 6.819 0 016.756-5.898c1.495 0 2.918.482 4.114 1.396a6.756 6.756 0 012.616 4.502.75.75 0 11-1.486.199 5.273 5.273 0 00-2.039-3.51 5.252 5.252 0 00-3.924-1.04 5.317 5.317 0 00-4.549 4.55.754.754 0 01-.742.651zM13.5 12.75c-.827 0-1.5-.673-1.5-1.5V7.852l-.27.225a.753.753 0 01-1.056-.096.751.751 0 01.096-1.057l4.5-3.75a.749.749 0 01.96 0l4.5 3.75a.744.744 0 01.267.508.747.747 0 01-1.227.644l-.27-.224v3.399c0 .827-.673 1.5-1.5 1.5h-4.5zm0-1.5H18V6.602l-2.25-1.875-2.25 1.875v4.648z" />
    </svg>
  );
}

export default SvgRealEstatePersonSearchHouse;
