import * as React from 'react';

function SvgLabel(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <g fill="#262338">
        <path d="M2.25 18.25A2.25 2.25 0 010 16V7a2.25 2.25 0 012.25-2.25h16.688c.776 0 1.497.4 1.908 1.058l2.812 4.5a2.25 2.25 0 010 2.384L20.844 17.2a2.25 2.25 0 01-1.907 1.05H2.25zm16.688-1.5c.26 0 .5-.132.636-.347l2.812-4.506a.75.75 0 000-.795l-2.812-4.5a.75.75 0 00-.636-.352H2.25A.75.75 0 001.5 7v9c0 .414.336.75.75.75h16.688z" />
        <path d="M4.5 12.25H9a.75.75 0 000-1.5H4.5a.75.75 0 000 1.5z" />
      </g>
    </svg>
  );
}

export default SvgLabel;
