import * as React from 'react';

function SvgKitchenShelf(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M2.25 9C1.009 9 0 7.991 0 6.75S1.009 4.5 2.25 4.5h2.333A5.21 5.21 0 013 .75.75.75 0 013.75 0h9a.75.75 0 01.75.75 5.21 5.21 0 01-1.583 3.75h9.833C22.991 4.5 24 5.509 24 6.75S22.991 9 21.75 9H2.25zm0-3a.75.75 0 000 1.5h19.5a.75.75 0 000-1.5H2.25zm2.326-4.5c.352 1.712 1.892 3 3.674 3s3.322-1.288 3.674-3H4.576zM6.75 24A3.754 3.754 0 013 20.25a.75.75 0 01.75-.75H9v-5.25A2.252 2.252 0 0111.25 12a2.252 2.252 0 012.25 2.25.75.75 0 01-1.5 0 .75.75 0 00-1.5 0v6A3.754 3.754 0 016.75 24zm-2.121-3c.313.879 1.159 1.5 2.121 1.5s1.808-.621 2.121-1.5H4.629zM17.25 24c-1.654 0-3-1.682-3-3.75 0-1.741.929-3.21 2.25-3.628V12.75a.75.75 0 011.5 0v3.872c1.321.418 2.25 1.888 2.25 3.628 0 2.068-1.346 3.75-3 3.75zm0-6c-.813 0-1.5 1.03-1.5 2.25s.687 2.25 1.5 2.25 1.5-1.03 1.5-2.25-.687-2.25-1.5-2.25z" />
    </svg>
  );
}

export default SvgKitchenShelf;
