import * as React from 'react';

function SvgArrowChild(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path
        d="M21.2 17.1c0-.4-.3-.6-.5-.8L18 13.6c-.3-.3-.8-.3-1.1 0-.3.3-.3.8 0 1.1l1.7 1.6H7.7c-1.4 0-2.5-1.1-2.5-2.5V2.3s0-.7-.8-.7-.8.7-.8.7v11.5c.1 2.3 1.9 4.1 4.1 4.1h10.9l-1.7 1.7c-.3.3-.3.8 0 1.1.2.1.4.2.6.2s.4-.1.5-.2l3-3c.1-.2.2-.3.2-.6z"
        fill="#0c1c46"
      />
    </svg>
  );
}

export default SvgArrowChild;
