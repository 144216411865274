import React, { useContext, useRef } from 'react';
import styled from '@emotion/styled';
import {
  getExtraProps,
  withTheme,
  removeKeysFromObject,
} from '@embracesbs/helpers';
import {
  webStyles as tableWebStyles,
  TableContext,
} from '@embracesbs/component-table';
import Divider from '@embracesbs/component-divider';
import { propTypes, defaultProps } from './TableRow.props';

const StyledTr = styled.tr`
  ${({ isFullWidth, borderOffset, theme, hasHover, onClick }) =>
    tableWebStyles.tableRow(
      isFullWidth,
      borderOffset,
      theme,
      hasHover,
      onClick,
    )}
  ${({ isActive, isSelected, theme }) => `
      background: ${
        isActive || isSelected ? theme.color.secondary$7 : 'inherit'
      };
      > td:first-of-type {
        border-left: ${theme.spacing.$2} solid
          ${isActive ? theme.color.secondary : 'transparent'};
      }
    `};
`;

const StyledDivider = styled(Divider)`
  ${({ theme }) => `
  ${tableWebStyles.styledDivider(theme)}
  `}
`;

const StyledTd = styled.td`
  ${({ width, minWidth, maxWidth }) =>
    tableWebStyles.cell(width, minWidth, maxWidth)}
`;

const StyledAlignWrapper = styled.div`
  ${({ justify, leftSpacing, rowHeight, align, isSingleLine = true }) =>
    tableWebStyles.cellInner(
      justify,
      leftSpacing,
      rowHeight,
      align,
      isSingleLine,
    )}
`;

/**
 * On keyDown callback
 * @param {object} e
 * @param {object} ref
 */
const handleKeyDown = (e, ref) => {
  if (e.keyCode === 13) {
    if (!ref) return;

    ref.current.click();
  }
};

/**
 * TableRow component
 * @param {object} children - Can have all kinds of child nodes
 * @param {bool} hasHover - Defines if the row has hover functionality
 * @param {func} onClick - The onClick handler
 */
const TableRow = (props) => {
  const {
    children,
    hasHover,
    onClick,
    extraProps,
    ariaLabel,
    isActive,
    isSelected,
  } = props;
  const { border, rowHeight, columnStyle } = useContext(TableContext);
  const rowRef = useRef();

  const currentBorder = { ...defaultProps.border, ...border };
  const {
    isFullWidth: isBorderFullWidth,
    hasBorder,
    offsetWidth: borderOffset,
  } = currentBorder;
  const divider = hasBorder && (
    <StyledDivider extraProps={['className']} className="tableDivider" />
  );

  const nodes = React.Children.map(children, (child, i) => {
    if (child) {
      return (
        <StyledTd
          {...getExtraProps(null, extraProps, 'RowTd')}
          showHover={child.props.showHover}
          {...columnStyle[i]}
        >
          <StyledAlignWrapper
            {...getExtraProps(null, extraProps, 'RowTdInner')}
            {...columnStyle[i]}
            rowHeight={rowHeight}
          >
            {child}
          </StyledAlignWrapper>
          {divider}
        </StyledTd>
      );
    }

    return null;
  });

  return (
    <StyledTr
      ref={rowRef}
      rowHeight={rowHeight}
      isFullWidth={isBorderFullWidth}
      borderOffset={borderOffset}
      onClick={onClick}
      onKeyDown={(e) => handleKeyDown(e, rowRef)}
      {...getExtraProps(props, extraProps)}
      {...removeKeysFromObject(props, ['data-testid'])}
      hasHover={hasHover}
      isActive={isActive}
      isSelected={isSelected}
      tabIndex={onClick ? 0 : -1}
      aria-label={ariaLabel || 'Table row'}
    >
      {nodes}
    </StyledTr>
  );
};

TableRow.propTypes = propTypes;
TableRow.defaultProps = defaultProps;

/** @component */
export default withTheme(TableRow);
