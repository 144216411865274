import React, { useRef, forwardRef, useEffect } from 'react';
import { createPortal } from 'react-dom';

/** Returns an instance variable */
const useInstanceVariable = (getValue) => {
  const variable = useRef();

  return (() => {
    if (!variable.current) {
      variable.current = getValue();
    }

    return variable.current;
  })();
};

/**
 * Creates a new absolutely positioned div in the
 * document body and puts the children there
 */
const BodyPortal = forwardRef((portalProps, ref) => {
  const {
    top = 0,
    left = 0,
    paddingTop = 0,
    paddingLeft = 0,
    zIndex = 1000,
    isInAFixedContainer = false,
    children = null,
  } = portalProps;

  const holder = useInstanceVariable(() => document.createElement('div'));

  holder.style = `
    pointer-events: none;
    top: ${top}px;
    left: ${left}px;
    padding-left: ${paddingTop}px;
    padding-top: ${paddingLeft}px;
    z-index: ${zIndex};
    position: ${isInAFixedContainer ? 'fixed' : 'absolute'};
  `;

  useEffect(() => {
    const { body } = document;
    body.appendChild(holder);

    return () => body.removeChild(holder);
  }, []);

  return createPortal(
    <div ref={ref} style={{ pointerEvents: 'auto' }}>
      {children}
    </div>,
    holder,
  );
});

export default BodyPortal;
