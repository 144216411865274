import * as React from 'react';

function SvgOfficeDrawer(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M1.728 23.999A1.73 1.73 0 010 22.271v-8.479c0-.875.655-1.601 1.5-1.713V6.977A1.739 1.739 0 013.233 5.25h8.392a1.745 1.745 0 011.424.743l.962 1.505h6.003c.956 0 1.732.774 1.736 1.726v2.84h.522A1.73 1.73 0 0124 13.792v8.479a1.73 1.73 0 01-1.728 1.728H1.728zm0-10.435a.228.228 0 00-.228.228v8.479c0 .126.102.228.228.228h20.544a.228.228 0 00.228-.228v-8.479a.228.228 0 00-.228-.228H1.728zm18.522-1.5V9.227a.232.232 0 00-.233-.229H13.6a.748.748 0 01-.632-.346L11.803 6.83a.24.24 0 00-.177-.079H3.234A.233.233 0 003 6.982v5.082h17.25z" />
      <path d="M9.065 16.498a.75.75 0 010-1.5h5.87a.75.75 0 010 1.5h-5.87zM19.737 5.998a.75.75 0 01-.75-.75V3.977a.232.232 0 00-.233-.227H13.6a.747.747 0 01-.632-.347l-1.165-1.826a.24.24 0 00-.177-.079H4.5a.235.235 0 00-.237.232v1.04a.75.75 0 01-1.5 0V1.727A1.735 1.735 0 014.49-.002h7.135a1.745 1.745 0 011.42.737l.966 1.515h4.739c.954 0 1.731.773 1.737 1.722v1.276a.75.75 0 01-.75.75z" />
    </svg>
  );
}

export default SvgOfficeDrawer;
