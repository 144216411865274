import * as React from 'react';

function SvgSafetyDangerCliff(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M5.25 23.438a.75.75 0 01-.75-.75v-2.25H.75a.75.75 0 010-1.5h4.5a.75.75 0 01.75.75v3a.75.75 0 01-.75.75zM20.25 7.313c-1.861 0-3.375-1.514-3.375-3.375S18.389.563 20.25.563s3.375 1.514 3.375 3.375-1.514 3.375-3.375 3.375zm0-5.25c-1.034 0-1.875.841-1.875 1.875s.841 1.875 1.875 1.875 1.875-.841 1.875-1.875-.841-1.875-1.875-1.875zM10.499 21.184a2.247 2.247 0 01-1.664-3.763l1.828-2.01-1.265-.158-2.391 1.198-.036.018a2.23 2.23 0 01-1.709.103 2.232 2.232 0 01-1.291-1.139c-.264-.54-.302-1.15-.108-1.719s.599-1.027 1.139-1.292l2.994-1.497a2.313 2.313 0 011.28-.22l4.743.594a13.185 13.185 0 01-3.468-2.732 2.314 2.314 0 01.434-3.47 2.172 2.172 0 012.83.376 8.558 8.558 0 004.184 2.612 8.179 8.179 0 016.002 7.849c0 1.242-1.009 2.251-2.25 2.251s-2.25-1.009-2.25-2.25a3.65 3.65 0 00-.978-2.479l-6.357 6.993a2.26 2.26 0 01-1.667.735zM9.265 13.73c.031 0 .062.002.093.006l2.925.366a.749.749 0 01.462 1.249l-2.8 3.079a.75.75 0 101.11 1.01l6.855-7.541a.754.754 0 011.002-.099 5.174 5.174 0 012.089 4.134.751.751 0 001.5.001 6.676 6.676 0 00-4.898-6.404 10.065 10.065 0 01-4.915-3.069.67.67 0 00-.873-.117.807.807 0 00-.343.515.808.808 0 00.192.702 11.622 11.622 0 004.144 2.933.749.749 0 01.267 1.197l-.86.947a.752.752 0 01-.648.24l-5.472-.685a.762.762 0 00-.433.076l-2.995 1.498a.746.746 0 00-.349 1.006.746.746 0 001.002.344l2.609-1.307a.737.737 0 01.336-.081z" />
    </svg>
  );
}

export default SvgSafetyDangerCliff;
