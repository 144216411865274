import * as React from 'react';

function SvgBusinessDealCash2(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M12.002 9.75a.75.75 0 01-.75-.75v-.354a2.987 2.987 0 01-1.705-1.098.746.746 0 01-.146-.555.745.745 0 01.742-.649c.233 0 .457.111.598.296.276.363.714.58 1.171.58l.058-.001c.775 0 1.343-.421 1.343-.797 0-.376-.562-.796-1.313-.796-1.549 0-2.81-1.031-2.81-2.298 0-1.052.849-1.944 2.062-2.213V.75a.75.75 0 011.5 0v.356a2.987 2.987 0 011.703 1.096.751.751 0 01-.597 1.204.745.745 0 01-.597-.296 1.48 1.48 0 00-1.171-.581l-.059.001c-.774.001-1.341.422-1.341.798 0 .425.612.798 1.31.798 1.551 0 2.813 1.03 2.813 2.296 0 1.051-.849 1.942-2.061 2.212V9a.75.75 0 01-.75.75zM4.502 10.5c-2.068 0-3.75-1.682-3.75-3.75S2.434 3 4.502 3s3.75 1.682 3.75 3.75-1.682 3.75-3.75 3.75zm0-6c-1.241 0-2.25 1.009-2.25 2.25S3.261 9 4.502 9s2.25-1.009 2.25-2.25S5.743 4.5 4.502 4.5zM3.002 24a.751.751 0 01-.744-.657L1.59 18H.752a.75.75 0 01-.75-.75V15c0-2.481 2.019-4.5 4.5-4.5s4.5 2.019 4.5 4.5v2.25a.75.75 0 01-.75.75h-.84l-.668 5.343A.751.751 0 016 24H3.002zm2.336-1.5l.668-5.343a.751.751 0 01.744-.657h.752V15c0-1.654-1.346-3-3-3s-3 1.346-3 3v1.5h.75c.377 0 .697.282.744.657l.668 5.343h1.674zM19.502 10.5c-2.068 0-3.75-1.682-3.75-3.75S17.434 3 19.502 3s3.75 1.682 3.75 3.75-1.682 3.75-3.75 3.75zm0-6c-1.241 0-2.25 1.009-2.25 2.25S18.261 9 19.502 9s2.25-1.009 2.25-2.25-1.009-2.25-2.25-2.25zM18.002 24a.751.751 0 01-.744-.657L16.59 18h-.838a.75.75 0 01-.75-.75V15c0-2.481 2.019-4.5 4.5-4.5s4.5 2.019 4.5 4.5v2.25a.75.75 0 01-.75.75h-.838l-.668 5.343a.751.751 0 01-.744.657h-3zm2.338-1.5l.668-5.343a.752.752 0 01.744-.657h.75V15c0-1.654-1.346-3-3-3s-3 1.346-3 3v1.5h.75c.377 0 .697.282.744.657l.668 5.343h1.676z" />
    </svg>
  );
}

export default SvgBusinessDealCash2;
