import * as React from 'react';

function SvgDiagramAllDirectionExpand(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M12 24a.755.755 0 01-.26-.047l-.022-.008a.732.732 0 01-.249-.165l-2.999-3a.744.744 0 010-1.06c.141-.142.33-.22.53-.22s.389.078.53.22l1.72 1.72v-8.69H2.561l1.72 1.72a.746.746 0 01-.001 1.06.744.744 0 01-1.06 0l-3-3a.74.74 0 01-.163-.245l-.01-.026a.75.75 0 01.001-.52l.007-.021a.754.754 0 01.166-.25L3.22 8.47a.744.744 0 011.06 0c.142.141.22.33.22.53s-.078.389-.22.53l-1.72 1.72h8.689V2.561L9.53 4.28c-.141.142-.33.22-.53.22s-.389-.078-.53-.22c-.142-.141-.22-.33-.22-.53s.078-.389.22-.53L11.469.221a.74.74 0 01.245-.163l.025-.009a.723.723 0 01.522-.001l.021.007c.097.04.179.095.25.166L15.53 3.22c.142.141.22.33.22.53s-.078.389-.22.53a.749.749 0 01-1.06 0l-1.72-1.72v8.689h8.689l-1.72-1.72C19.578 9.389 19.5 9.2 19.5 9s.078-.389.22-.53a.749.749 0 011.06 0l3 3a.74.74 0 01.163.245l.01.026a.73.73 0 010 .517l-.006.016a.755.755 0 01-.168.257L20.78 15.53a.744.744 0 01-1.06 0 .749.749 0 010-1.06l1.72-1.72h-8.69v8.689l1.72-1.72a.744.744 0 011.06 0 .749.749 0 010 1.06l-3 3a.763.763 0 01-.246.164l-.027.01A.725.725 0 0112 24z" />
    </svg>
  );
}

export default SvgDiagramAllDirectionExpand;
