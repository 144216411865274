import * as React from 'react';

function SvgRatingStarFilled(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M18.9 24c-.2 0-.5-.1-.7-.2l-6.2-3-6.2 3.1c-.2 0-.4.1-.7.1-.6 0-1.1-.3-1.4-.9-.2-.3-.2-.7-.1-1.1l1.8-6.7-5-4.9c-.2-.3-.4-.7-.4-1.1 0-.4.2-.8.5-1.1.3-.3.6-.4 1-.5l6-.6L10.7.8c.2-.4.5-.7.9-.8h.4c.2 0 .5.1.7.2.3.2.5.4.7.7l3.2 6.3 6 .6c.8.1 1.5.8 1.4 1.7 0 .4-.2.7-.5 1l-5 4.9 1.8 6.7c.2.8-.3 1.7-1.1 1.9h-.3z" />
    </svg>
  );
}

export default SvgRatingStarFilled;
