import * as React from 'react';

function SvgLayersSelect(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M3.75 24A3.754 3.754 0 010 20.25v-12a.75.75 0 011.5 0v12a2.252 2.252 0 002.25 2.25h12a.75.75 0 010 1.5h-12z" />
      <path d="M6.75 19.5a2.252 2.252 0 01-2.25-2.25v-.75a.75.75 0 011.5 0v.75c0 .414.336.75.75.75h.75a.75.75 0 010 1.5h-.75zM5.25 3.75A.75.75 0 014.5 3v-.75A2.252 2.252 0 016.75 0h.75a.75.75 0 010 1.5h-.75a.75.75 0 00-.75.75V3a.75.75 0 01-.75.75zM23.25 3.75A.75.75 0 0122.5 3v-.75a.75.75 0 00-.75-.75H21A.75.75 0 0121 0h.75A2.252 2.252 0 0124 2.25V3a.75.75 0 01-.75.75zM21 19.5a.75.75 0 010-1.5h.75a.75.75 0 00.75-.75v-.75a.75.75 0 011.5 0v.75a2.252 2.252 0 01-2.25 2.25H21zM10.5 1.5a.75.75 0 010-1.5h2.25a.75.75 0 010 1.5H10.5zM15.75 1.5a.75.75 0 010-1.5H18a.75.75 0 010 1.5h-2.25zM10.5 19.5a.75.75 0 010-1.5h2.25a.75.75 0 010 1.5H10.5zM15.75 19.5a.75.75 0 010-1.5H18a.75.75 0 010 1.5h-2.25zM23.25 9a.75.75 0 01-.75-.75V6A.75.75 0 0124 6v2.25a.75.75 0 01-.75.75zM23.25 14.25a.75.75 0 01-.75-.75v-2.25a.75.75 0 011.5 0v2.25a.75.75 0 01-.75.75zM5.25 9a.75.75 0 01-.75-.75V6A.75.75 0 016 6v2.25a.75.75 0 01-.75.75zM5.25 14.25a.75.75 0 01-.75-.75v-2.25a.75.75 0 011.5 0v2.25a.75.75 0 01-.75.75z" />
    </svg>
  );
}

export default SvgLayersSelect;
