import * as React from 'react';

function SvgColorBrush(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M2.25 24a.746.746 0 01-.638-.355.745.745 0 01-.033-.73A13.533 13.533 0 003 16.9v-.4c-.827 0-1.5-.673-1.5-1.5v-2.25A3.754 3.754 0 015.25 9h2.927a.54.54 0 00.529-.644A23.518 23.518 0 018.25 3.75 3.754 3.754 0 0112 0a3.754 3.754 0 013.75 3.75c0 1.539-.153 3.085-.454 4.595l-.002.013a.54.54 0 00.529.642h2.927a3.754 3.754 0 013.75 3.75V15c0 .827-.673 1.5-1.5 1.5v.4a13.533 13.533 0 001.421 6.015.746.746 0 01-.032.729.757.757 0 01-.639.356H2.25zm18.341-1.5a15.108 15.108 0 01-1.091-5.6v-.4h-15v.4a15.127 15.127 0 01-1.09 5.6H13.5v-2.25a.75.75 0 011.5 0v2.25h1.5v-3a.75.75 0 011.5 0v3h2.591zM5.25 10.5A2.252 2.252 0 003 12.75V15h18v-2.25a2.252 2.252 0 00-2.25-2.25h-2.927a2.042 2.042 0 01-1.999-2.44c.282-1.416.426-2.867.426-4.311 0-1.24-1.009-2.25-2.25-2.25a2.252 2.252 0 00-2.25 2.25c0 1.444.144 2.895.427 4.311a2.024 2.024 0 01-.303 1.53 2.025 2.025 0 01-1.696.909H5.25z" />
    </svg>
  );
}

export default SvgColorBrush;
