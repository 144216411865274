import React from 'react';
import { Lottie } from '@crello/react-lottie';
import styled from '@emotion/styled';
import { getExtraProps, withTheme } from '@embracesbs/helpers';
import { webStyles } from './Animation.styles';
import { propTypes, defaultProps } from './Animation.props';

const StyledWrapper = styled.div`
  ${({ theme, isDark }) => webStyles.styledWrapper(theme, isDark)};
`;

/**
 * Animation web component
 * @param {array} extraProps - An array of strings which includes the extra prop keys
 * @param {string} animation - The name of the animation
 * @param {number} animationWidth - The width of the animation
 * @param {number} animationHeight - The height of the animation
 * @param {boolean} isPaused - Whether the animation is paused
 * @param {boolean} isLoop - Whether the animation is looping
 * @param {boolean} isDark - Whether the component is using dark mode
 */
const Animation = (props) => {
  const {
    extraProps,
    animation,
    animationWidth,
    animationHeight,
    isPaused,
    isLoop,
    isDark,
  } = props;

  const defaultOptions = {
    loop: isLoop,
    autoplay: !isPaused,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  if (typeof animation === 'string') {
    /* eslint-disable-next-line global-require, import/no-dynamic-require */
    defaultOptions.animationData = require(`./animations/${animation}.json`);
  } else {
    defaultOptions.animationData = animation;
  }

  return (
    <StyledWrapper isDark={isDark}>
      <Lottie
        {...getExtraProps(props, extraProps)}
        config={defaultOptions}
        playingState={isPaused ? 'paused' : 'playing'}
        height={animationHeight}
        width={animationWidth}
      />
    </StyledWrapper>
  );
};

Animation.propTypes = propTypes;
Animation.defaultProps = defaultProps;

export default withTheme(Animation);
