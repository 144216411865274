const baseStyles = {};

export const webStyles = {
  ...baseStyles,
  styledCircularProgress: (unit, size) => `
    display: inline-block;
    width: ${unit(size)};
    height: ${unit(size)};
    box-sizing: border-box;
    svg .circle {
      transition: stroke-dashoffset 0.35s;
      transform: rotate(-90deg);
      transform-origin: 50% 50%;
    }
  `,
};

export const nativeStyles = {
  ...baseStyles,
};
