/** webStyles */
const webStyles = (theme) => `
  ${theme.centeredStyles.inlineFlex}
  flex-grow: 0;
  flex-shrink: 0;
  font-family: ${theme.font.medium};
`;

/** nativeStyles */
export const nativeStyles = (unit) => `
  border-width: ${unit(1)};
`;

export default webStyles;
