import * as React from 'react';

function SvgTableInsertRowAfter(props) {
  return (
    <svg data-name="Layer 1" viewBox="0 0 22 22" {...props}>
      <path d="M18.714 1.25H3.286A2.038 2.038 0 001.25 3.286v15.428a2.038 2.038 0 002.036 2.036h15.428a2.038 2.038 0 002.036-2.036v-3.451a.75.75 0 10-1.5 0v3.451a.536.536 0 01-.536.536H8.75V2.75h9.964a.536.536 0 01.536.536v3.451a.75.75 0 101.5 0V3.286a2.038 2.038 0 00-2.036-2.036zm-15.428 18a.536.536 0 01-.536-.536V11.75h4.5v7.5zm3.964-9h-4.5V3.286a.536.536 0 01.536-.536H7.25z" />
      <path d="M15.5 16.25a.75.75 0 00.75-.75v-3.75H20a.75.75 0 000-1.5h-3.75V6.5a.75.75 0 00-1.5 0v3.75H11a.75.75 0 000 1.5h3.75v3.75a.75.75 0 00.75.75z" />
    </svg>
  );
}

export default SvgTableInsertRowAfter;
