import * as React from 'react';

function SvgParagraphRightAlign(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M2 3.748a.75.75 0 010-1.5h21a.75.75 0 010 1.5H2zM5 8.248a.75.75 0 010-1.5h18a.75.75 0 010 1.5H5zM.5 12.748a.75.75 0 010-1.5H23a.75.75 0 010 1.5H.5zM5 17.248a.75.75 0 010-1.5h18a.75.75 0 010 1.5H5zM2 21.748a.75.75 0 010-1.5h21a.75.75 0 010 1.5H2z" />
    </svg>
  );
}

export default SvgParagraphRightAlign;
