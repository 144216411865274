import * as React from 'react';

function SvgMultipleActionsGraduate(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M.751 15.001a.75.75 0 01-.744-.844A6.764 6.764 0 016.711 8.25h.045a6.65 6.65 0 015.905 4.071.75.75 0 01-1.385.577 5.136 5.136 0 00-4.552-3.149h-.031a5.254 5.254 0 00-5.199 4.594.75.75 0 01-.743.658zM6.75 8.249c-2.275 0-4.125-1.85-4.125-4.125S4.475-.001 6.75-.001s4.125 1.85 4.125 4.125-1.85 4.125-4.125 4.125zm0-6.75c-1.447 0-2.625 1.178-2.625 2.625S5.303 6.749 6.75 6.749s2.625-1.178 2.625-2.625S8.197 1.499 6.75 1.499zM16.5 6.749c-1.861 0-3.375-1.514-3.375-3.375S14.639-.001 16.5-.001s3.375 1.514 3.375 3.375-1.514 3.375-3.375 3.375zm0-5.25c-1.034 0-1.875.841-1.875 1.875s.841 1.875 1.875 1.875 1.875-.841 1.875-1.875-.841-1.875-1.875-1.875z" />
      <path d="M20.4 9.751a.75.75 0 01-.649-.374 3.728 3.728 0 00-2.278-1.746 3.748 3.748 0 00-3.792 1.155.748.748 0 11-1.128-.988c.38-.435.826-.801 1.325-1.09a5.235 5.235 0 013.983-.527 5.217 5.217 0 013.189 2.444.751.751 0 01-.65 1.126zM16.499 24.004c-.19 0-.377-.036-.555-.106l-3.002-1.201a1.494 1.494 0 01-.942-1.4v-2.588l-1.5-.549v2.677a.75.75 0 01-1.5 0v-3.772c0-.366.27-.678.625-.738l6.589-2.714a.755.755 0 01.572 0l6.75 2.78c.288.118.47.396.464.708a.755.755 0 01-.493.69L21 18.71v2.59a1.49 1.49 0 01-.943 1.396l-3 1.2a1.486 1.486 0 01-.558.108zM13.5 21.3l2.999 1.204 3-1.2v-2.046l-2.742 1.003a.737.737 0 01-.517 0l-2.74-1.003V21.3zm3-2.541l4.68-1.713-4.68-1.927-4.68 1.927 4.68 1.713z" />
    </svg>
  );
}

export default SvgMultipleActionsGraduate;
