import * as React from 'react';

function SvgMessagesPeopleUserQuestionExclamation(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M14.2 21h-.3c-.2-.1-.3-.2-.4-.4v-4.1h-2.2c-.4 0-.8-.3-.8-.8s.3-.8.8-.8h3c.4 0 .8.3.8.8v2.7l3.2-3.2c.1-.1.2-.1.2-.2h3.3c.4 0 .8-.3.8-.8v-12c0-.4-.3-.8-.8-.8h-12c-.5.1-.8.4-.8.8s-.3.8-.8.8-.7-.3-.7-.8C7.5 1 8.5 0 9.8 0h12C23 0 24 1 24 2.2v12c0 1.2-1 2.2-2.2 2.2h-2.7l-4.3 4.3c-.1.1-.2.1-.2.2-.2.1-.3.1-.4.1z" />
      <path d="M4.5 10.5C2.4 10.5.7 8.8.7 6.7S2.4 3 4.5 3s3.8 1.7 3.8 3.8-1.7 3.7-3.8 3.7zm0-6c-1.2 0-2.2 1-2.2 2.2S3.3 9 4.5 9s2.2-1 2.2-2.2-1-2.3-2.2-2.3zM3 24c-.4 0-.7-.3-.7-.7L1.6 18H.8c-.5 0-.8-.3-.8-.8V15c0-2.5 2-4.5 4.5-4.5S9 12.5 9 15v2.2c0 .5-.3.8-.8.8h-.8l-.7 5.3c0 .4-.3.7-.7.7H3zm2.3-1.5l.7-5.3c0-.4.4-.7.7-.7h.8V15c0-1.7-1.3-3-3-3s-3 1.3-3 3v1.5h.8c.4 0 .7.3.7.7l.7 5.3h1.6zM18.8 12.8c-.6 0-1.1-.5-1.1-1.1s.5-1.1 1.1-1.1c.6 0 1.1.5 1.1 1.1 0 .5-.5 1.1-1.1 1.1zM18.8 9.8c-.5 0-.8-.4-.8-.8V5.2c0-.4.3-.8.8-.8s.8.3.8.8V9c-.1.4-.4.8-.8.8zM13.9 9.8c-.4 0-.8-.3-.8-.8s.3-.8.8-.8c.6 0 1.1-.5 1.1-1.1S14.5 6 13.9 6s-1.1.5-1.1 1.1c0 .4-.3.8-.8.8s-.8-.3-.8-.8c0-1.4 1.2-2.6 2.6-2.6s2.6 1.2 2.6 2.6-1.1 2.7-2.5 2.7zM13.9 12.8c-.6 0-1.1-.5-1.1-1.1 0-.6.5-1.1 1.1-1.1.6 0 1.1.5 1.1 1.1 0 .5-.5 1.1-1.1 1.1z" />
    </svg>
  );
}

export default SvgMessagesPeopleUserQuestionExclamation;
