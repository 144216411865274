import * as React from 'react';

function SvgDataFileGraph(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M2.955 24a2.252 2.252 0 01-2.25-2.25V2.25A2.252 2.252 0 012.955 0h15c.59.001 1.147.228 1.568.639l2.996 2.879a2.27 2.27 0 01.684 1.613l.002 16.619a2.252 2.252 0 01-2.25 2.25h-18zm0-22.5a.75.75 0 00-.75.75v19.5c0 .414.336.75.75.75h18a.75.75 0 00.75-.75l-.002-16.618c0-.2-.083-.396-.227-.536L18.48 1.717a.75.75 0 00-.526-.217H2.955z" />
      <path d="M5.955 19.5a.75.75 0 01-.75-.75V7.5a.75.75 0 011.5 0v6.524l2.1-1.96a2.712 2.712 0 013.667-.007l.733.685a1.201 1.201 0 00.955.303c.349-.037.654-.22.843-.505l1.566-2.445a.745.745 0 011.036-.226.747.747 0 01.227 1.036l-1.572 2.454a2.643 2.643 0 01-1.933 1.178 2.7 2.7 0 01-2.139-.693l-.731-.683a1.208 1.208 0 00-.812-.313c-.303 0-.595.114-.822.32l-3.116 2.909V18h11.25a.75.75 0 010 1.5H5.955z" />
    </svg>
  );
}

export default SvgDataFileGraph;
