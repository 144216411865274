import * as React from 'react';

function SvgSmileyConcerned(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M14.25 8.25a.75.75 0 010-1.5h.667c.3 0 .595-.082.852-.236l1.095-.657a.747.747 0 011.029.257.747.747 0 01-.258 1.029l-1.094.657c-.49.294-1.052.45-1.623.45h-.668zM12 19.5a.754.754 0 01-.71-.51.746.746 0 01.039-.573 7.457 7.457 0 016.719-4.167.75.75 0 11.001 1.5 5.964 5.964 0 00-5.376 3.333.75.75 0 01-.673.417z" />
      <circle cx={15.75} cy={10.5} r={1.125} />
      <circle cx={7.5} cy={10.5} r={1.125} />
      <path d="M6 8.303a.743.743 0 01-.414-.125.747.747 0 01-.21-1.039 2.546 2.546 0 012.128-1.14 2.54 2.54 0 012.121 1.139.751.751 0 01-1.25.83 1.044 1.044 0 00-.873-.47 1.05 1.05 0 00-.877.469.746.746 0 01-.625.336z" />
      <path d="M12 24C5.383 24 0 18.617 0 12S5.383 0 12 0s12 5.383 12 12-5.383 12-12 12zm0-22.5C6.21 1.5 1.5 6.21 1.5 12S6.21 22.5 12 22.5 22.5 17.79 22.5 12 17.79 1.5 12 1.5z" />
    </svg>
  );
}

export default SvgSmileyConcerned;
