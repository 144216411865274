const baseStyles = {};

export const webStyles = {
  ...baseStyles,
  styledLinearProgressWrapper: () => `
    flex-direction: column;
  `,
  styledCircularProgressIndicator: (unit, size) => `
    display: inline-block;
    width: ${unit(size)};
    height: ${unit(size)};
    box-sizing: border-box;
    svg .circle {
      transition: stroke-dashoffset 0.35s;
      transform: rotate(-90deg);
      transform-origin: 50% 50%;
    }
  `,
  styledLinearProgressIndicator: (
    theme,
    size,
    hasBgColor,
    progress,
    progressColor,
  ) => `
    height: ${size}px;
    background: linear-gradient(
      to right,
      ${
        hasBgColor
          ? `${theme.color.light}, ${theme.color.light}`
          : `${progressColor}, ${progressColor}`
      }
    );
    background-size: ${progress ? `${progress}%` : '20%'};
    background-position: ${progress ? '0 0' : '-25% 0'};
    width: 100%;
    border-radius: ${theme.borderRadius.$1};
    background-repeat: repeat-y;
    background-color: ${theme.color.dark$6};
  `,
  styledLinearProgressText: (position) => `
    margin-left: ${position}%;
    > div {
      display: inline-block;
      transform: translate(-50%, 0);
    }
  `,
  styledGaugeProgressIndicator: (unit, progress) => `
    position: relative;
    display: initial;
    img:first-of-type {
      width: ${unit(132)};
    }
    img:last-of-type {
      width: calc(50% - ${unit(3)} + ${unit(7)});
      position: absolute;
      bottom: 0;
      right: ${unit(3)};
      transform-origin: 10% 50%;
      transform: rotate(${progress}deg);
    }
  `,
};

export const nativeStyles = {
  ...baseStyles,
};
