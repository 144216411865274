import * as React from 'react';

function SvgEmailActionRead(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M3 23.5a2.252 2.252 0 01-2.25-2.25V10l.003-.044a.696.696 0 01.026-.158l.015-.045a.69.69 0 01.086-.172.704.704 0 01.132-.151l8.273-7.27A3.74 3.74 0 0112.127.999a3.726 3.726 0 012.586 1.161l8.281 7.277.038.038a.665.665 0 01.118.157.7.7 0 01.096.33.164.164 0 01.004.038v11.25A2.252 2.252 0 0121 23.5H3zm-.75-2.25c0 .413.336.75.75.75h18a.75.75 0 00.75-.75v-9.698l-4.816 3.82 2.347 2.347a.744.744 0 010 1.06.752.752 0 01-1.061.001l-2.469-2.469-1.432 1.135a3.771 3.771 0 01-2.32.805 3.763 3.763 0 01-2.315-.803L8.25 16.31l-2.47 2.47c-.141.142-.33.22-.53.22s-.389-.078-.53-.22a.744.744 0 010-1.06l2.347-2.347-4.817-3.82v9.697zm8.363-4.978c.4.313.88.48 1.386.48.508 0 .989-.167 1.39-.482l7.94-6.299-7.632-6.707a1.227 1.227 0 01-.052-.05 2.24 2.24 0 00-1.569-.715l-.078-.001a2.246 2.246 0 00-1.644.714 1.203 1.203 0 01-.051.05L2.67 9.97l7.943 6.302z" />
    </svg>
  );
}

export default SvgEmailActionRead;
