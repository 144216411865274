import * as React from 'react';

function SvgRealEstateActionHouseKey(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M5.248 20.281a2.252 2.252 0 01-2.25-2.25v-7.5a.75.75 0 011.5 0v7.5c0 .414.336.75.75.75h3a.75.75 0 010 1.5h-3zM.749 9.781a.75.75 0 01-.485-1.323l8.781-7.43a2.255 2.255 0 011.453-.534c.53 0 1.046.19 1.454.534l7.279 6.159a.746.746 0 01.088 1.057.751.751 0 01-1.057.088l-7.279-6.159a.749.749 0 00-.969-.001l-8.781 7.43a.747.747 0 01-.484.179zM18.748 5.281a.75.75 0 01-.75-.75v-2.25h-2.25a.75.75 0 010-1.5h3a.75.75 0 01.75.75v3a.75.75 0 01-.75.75zM15.373 19.596c-.3 0-.583-.117-.795-.329a1.117 1.117 0 01-.33-.796c0-.301.117-.583.329-.796a1.121 1.121 0 011.575-.016l.025.025a1.124 1.124 0 01-.804 1.912z" />
      <path d="M15.303 23.287a4.774 4.774 0 01-1.749-.332c-1.195-.468-2.136-1.374-2.651-2.553s-.541-2.485-.073-3.68a4.847 4.847 0 014.484-3.056c.43 0 .857.058 1.271.172l3.357-3.36c.449-.449 1.046-.696 1.681-.696s1.231.247 1.68.696a2.379 2.379 0 010 3.361l-3.358 3.358a4.819 4.819 0 01-.158 3.033 4.847 4.847 0 01-4.484 3.057zm.009-8.12a3.337 3.337 0 00-3.087 2.103 3.314 3.314 0 003.077 4.517 3.337 3.337 0 003.087-2.103c.3-.766.304-1.61.013-2.379l-.01-.022a.746.746 0 01.161-.817l3.687-3.687A.876.876 0 1021 11.54l-3.684 3.687a.747.747 0 01-.804.168 3.243 3.243 0 00-1.2-.228z" />
    </svg>
  );
}

export default SvgRealEstateActionHouseKey;
