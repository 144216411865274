import React from 'react';
import styled from '@emotion/styled';
import BadgeWrapper from '@embracesbs/component-badgewrapper';
import {
  getExtraProps,
  withTheme,
  unit,
  getSpacingObject,
  getSpacingString,
  removeKeysFromObject,
  reverseUnit,
} from '@embracesbs/helpers';
import {
  webPropTypes,
  webDefaultProps,
  allPossibleIconSizes,
} from './Icon.props';
import webStyles from './Icon.styles';
import * as InternalIcons from './icons/components';

const IconContainer = styled.i`
  ${({ theme }) => webStyles(theme)}
  ${({
    theme,
    background,
    borderColor,
    isRounded,
    color,
    isFullWidth,
    widthPadding,
    heightPadding,
  }) => `
    width:  ${isFullWidth ? theme.percentage.full : unit(widthPadding)};
    height: ${isFullWidth ? `auto` : unit(heightPadding)};
    background-color: ${background};  
    border: ${
      borderColor && isRounded ? `${theme.spacing.$1} solid ${borderColor}` : 0
    };        
    border-radius: ${isRounded ? theme.percentage.full : 0};
    color: ${color};
  `}
`;

const StyledIcon = styled.span`
  ${({ theme, size, isFullWidth, color, padding }) => `
  ${theme.centeredStyles.flex}
    padding: ${padding};
    height: auto;
    box-sizing: content-box;
    width: ${isFullWidth ? `${theme.percentage.full}` : size};
      svg, img {
        color: ${color};
        height: ${theme.percentage.full};
        width: ${theme.percentage.full};
      }

  `}
`;

const StyledText = styled.span`
  ${({ theme, size }) => `
    ${theme.textStyle.h4}
    text-align: ${theme.textAlign.center};
    font-size: ${size};
    text-transform: ${theme.textTransform.capitalize};
    font-style: normal;
    line-height: normal;
  `}
`;

/**
 * Icon component
 *
 * @param {string} text - Icon text
 * @param {string} background - Background color of the icon
 * @param {bool} isRounded - Is icon with circled shape
 * @param {string} iconName - Icon name of one of icon component
 * @param {number|string} size - Size of the icon
 * @param {string} color - Color of the icon
 * @param {number|object} spacing - Spacing of the icon
 * @param {string} borderColor - Color of the icon
 * @param {array} extraProps - An array of strings which includes the extra prop keys
 * @param {string|object} imgSource - Source of an image
 * @param {string} isFullWidth - Is the icon full width (is not valid for a text option)
 * @param {node} badge - The optional bagde component in the top right corner
 */
const Icon = (props) => {
  const {
    text,
    background,
    isRounded,
    iconName,
    size: sizeProp,
    color,
    spacing,
    borderColor,
    extraProps,
    imgSource,
    isFullWidth,
    badge,
    theme,
    forwardedRef,
  } = props;

  let size = sizeProp;
  if (typeof sizeProp === 'number') {
    const sizeIndex = allPossibleIconSizes.indexOf(sizeProp);
    size = theme.iconSize[`$${sizeIndex + 1}`];
  }

  let icon = null;
  const currentSpacing = getSpacingObject(spacing, webDefaultProps.spacing);
  const { top, right, bottom, left } = currentSpacing;
  const currentSpacingString = getSpacingString(currentSpacing);

  if (InternalIcons[iconName]) {
    icon = React.createElement(InternalIcons[iconName], {
      fill: color === 'inherit' ? 'currentColor' : color,
    });
  }

  const hasIconSvg = icon || imgSource;

  const innerComponents = (
    <IconContainer
      {...getExtraProps(props, extraProps)}
      ref={forwardedRef}
      background={background}
      isRounded={isRounded}
      color={color}
      borderColor={borderColor}
      isFullWidth={isFullWidth}
      text={text}
      widthPadding={left + right + reverseUnit(size)}
      heightPadding={top + bottom + reverseUnit(size)}
      {...removeKeysFromObject(props, ['data-testid'])}
    >
      {(hasIconSvg && (
        <StyledIcon
          size={size}
          isFullWidth={isFullWidth}
          color={color}
          padding={currentSpacingString}
        >
          {icon || <img alt="" src={imgSource} />}
        </StyledIcon>
      )) ||
        (text && (
          <StyledText size={size} {...getExtraProps(null, extraProps, 'Text')}>
            {text}
          </StyledText>
        ))}
    </IconContainer>
  );

  return badge ? (
    <BadgeWrapper
      bottomRight
      badge={badge}
      extraProps={[{ Container: { style: { display: 'inline-flex' } } }]}
      {...getExtraProps(null, extraProps, 'BadgeWrapper')}
    >
      {innerComponents}
    </BadgeWrapper>
  ) : (
    innerComponents
  );
};

Icon.propTypes = webPropTypes;
Icon.defaultProps = webDefaultProps;

export default withTheme(Icon);
