import * as React from 'react';

function SvgSleep(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <g
        transform="translate(1 -5)"
        stroke="#000"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M.48 18V6M.48 15h22.08" />
        <ellipse cx={4.32} cy={11} rx={1.92} ry={2} />
        <path d="M18.88 13c.315 0 .6-.192.73-.491a.86.86 0 00-.132-.895C17.504 9.3 13.536 7.871 8.937 8.009c-.433.013-.777.382-.777.833V12.5c0 .276.215.5.48.5h10.24zM22.56 18V8" />
      </g>
    </svg>
  );
}

export default SvgSleep;
