import * as React from 'react';

function SvgEmailActionDisable(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M17.25 24a6.708 6.708 0 01-4.749-1.957l-.024-.022a6.684 6.684 0 01-1.978-4.771 6.758 6.758 0 016.75-6.75 6.706 6.706 0 014.801 2.01A6.691 6.691 0 0124 17.25 6.758 6.758 0 0117.25 24zm-3.142-2.549A5.239 5.239 0 0017.25 22.5a5.256 5.256 0 005.25-5.25c0-1.135-.37-2.236-1.048-3.141l-7.344 7.342zM17.25 12A5.256 5.256 0 0012 17.25c0 1.135.37 2.236 1.048 3.141l7.344-7.343A5.244 5.244 0 0017.25 12z" />
      <path d="M2.25 16.5A2.252 2.252 0 010 14.25v-12A2.22 2.22 0 01.498.839L.521.812A2.245 2.245 0 012.25 0h18c.671 0 1.301.296 1.731.814.01.01.019.022.026.031l.018.026c.311.399.475.875.475 1.379V9A.75.75 0 0121 9V2.562l-7.276 5.596a4.077 4.077 0 01-2.474.841c-.891 0-1.77-.299-2.474-.841L1.5 2.562V14.25c0 .414.336.75.75.75h6a.75.75 0 010 1.5h-6zm7.44-9.531a2.564 2.564 0 003.119 0L19.921 1.5H2.579L9.69 6.969z" />
    </svg>
  );
}

export default SvgEmailActionDisable;
