import * as React from 'react';

function SvgLoginKeys(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M6.752 24.004a2.252 2.252 0 01-2.25-2.25v-5.581a6.714 6.714 0 01-4.5-6.363 6.754 6.754 0 016-6.709V3c0-1.654 1.346-3 3-3s3 1.346 3 3v.099a6.746 6.746 0 014.165.894 6.706 6.706 0 013.105 4.129 6.724 6.724 0 01-.447 4.596l4.517 4.513c.854.884.855 2.26.009 3.136a2.232 2.232 0 01-1.619.686 2.24 2.24 0 01-1.563-.63L15.646 15.9a6.7 6.7 0 01-1.979.594.755.755 0 01-.845-.64c-.028-.199.024-.396.145-.556s.297-.263.495-.29a5.176 5.176 0 001.94-.676.75.75 0 01.912.116l4.907 4.906a.735.735 0 00.51.201.742.742 0 00.541-.229.747.747 0 000-1.042l-4.901-4.896a.75.75 0 01-.116-.912 5.216 5.216 0 00.563-3.979 5.216 5.216 0 00-2.415-3.212 5.237 5.237 0 00-2.664-.726c-.51 0-1.01.072-1.492.215a6.703 6.703 0 012.254 5.033 6.71 6.71 0 01-4.5 6.366v5.581a2.251 2.251 0 01-2.249 2.25zm-.75-19.391a5.262 5.262 0 00-4.5 5.197 5.25 5.25 0 003.938 5.082.75.75 0 01.562.726v6.136a.75.75 0 001.5 0v-6.136c0-.342.231-.641.563-.726a5.25 5.25 0 003.938-5.085 5.239 5.239 0 00-4.501-5.19v2.318a2.26 2.26 0 011.5 2.121c0 1.241-1.009 2.25-2.25 2.25s-2.25-1.009-2.25-2.25c0-.96.615-1.808 1.5-2.121V4.613zm.75 3.694a.75.75 0 100 1.5.75.75 0 000-1.5zm.75-5.207a6.648 6.648 0 012.246.656c.245-.121.497-.227.754-.317V3c0-.827-.673-1.5-1.5-1.5s-1.5.673-1.5 1.5v.1z" />
    </svg>
  );
}

export default SvgLoginKeys;
