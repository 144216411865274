import * as React from 'react';

function SvgLoadingCircle2(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M12 23.997c-6.617 0-12-5.383-12-12s5.383-12 12-12 12 5.383 12 12-5.383 12-12 12zm0-22.5c-5.79 0-10.5 4.71-10.5 10.5s4.71 10.5 10.5 10.5 10.5-4.71 10.5-10.5-4.71-10.5-10.5-10.5z" />
      <path d="M8.252 18.362a.747.747 0 01-.415-.125C4.395 15.94 3.464 11.273 5.76 7.833a7.498 7.498 0 014.031-3.005A2.256 2.256 0 0112 2.997c1.241 0 2.25 1.009 2.25 2.25s-1.009 2.25-2.25 2.25a2.253 2.253 0 01-1.967-1.163 5.99 5.99 0 00-3.026 2.332 6.008 6.008 0 001.661 8.323c.344.23.437.696.208 1.04a.752.752 0 01-.624.333zM12 4.497a.75.75 0 100 1.5.75.75 0 000-1.5zM16.742 17.544a.746.746 0 01-.75-.753.75.75 0 01.222-.53 5.929 5.929 0 001.384-2.132.754.754 0 01.965-.437.752.752 0 01.438.968 7.43 7.43 0 01-1.732 2.667.743.743 0 01-.527.217zM12.005 19.495c-.297 0-.595-.018-.886-.053a.752.752 0 01-.655-.835.752.752 0 01.831-.654 5.957 5.957 0 002.533-.24.747.747 0 01.943.485.745.745 0 01-.485.943 7.493 7.493 0 01-2.281.354z" />
    </svg>
  );
}

export default SvgLoadingCircle2;
