import PropTypes from 'prop-types';
import { theme } from '@embracesbs/helpers';

export const propTypes = {
  /** The children the card can contain */
  children: PropTypes.node,
  /** Property to set the background color of the sidebar */
  backgroundcolor: PropTypes.string,
  /** Custom sidebar width when the show property is set to true */
  width: PropTypes.number,
  /** Property to set the sidebar to be always expanded */
  isExpanded: PropTypes.bool,
  /** An array of strings which includes the extra prop keys */
  extraProps: PropTypes.array,
  /** Indicates if the side bar is auto open on mouse enter and auto closing on mouse leave */
  isBarAutoClosing: PropTypes.bool,
};

export const defaultProps = {
  children: null,
  backgroundcolor: theme.color.support3,
  width: theme.layout.sidebarWidth,
  isExpanded: true,
  extraProps: [],
  isBarAutoClosing: true,
};
