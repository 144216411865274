import * as React from 'react';

function SvgControlsPlay(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M5.4 23c-1.2 0-2.2-1-2.2-2.2V3.3c0-.4.1-.7.3-1.1.3-.5.8-.9 1.3-1.1.2-.1.4-.1.6-.1.4 0 .7.1 1.1.3L22.8 10c.4.2.7.5.9.9.3.5.3 1.1.2 1.7-.2.6-.6 1-1.1 1.3L6.5 22.7c-.3.2-.7.3-1.1.3zm0-20.5h-.2c-.2.1-.3.2-.4.4-.1.1-.1.2-.1.4v17.5c0 .4.3.8.8.8.1 0 .2 0 .4-.1l16.3-8.7c.2-.1.3-.3.4-.4s0-.4-.1-.6l-.3-.3L5.8 2.6c-.1-.1-.2-.1-.4-.1z" />
    </svg>
  );
}

export default SvgControlsPlay;
