import * as React from 'react';

function SvgLayoutColumnOff(props) {
  return (
    <svg
      id="layout-column-off_svg__Laag_1"
      x={0}
      y={0}
      viewBox="0 0 24 24"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {
          '.layout-column-off_svg__st0{fill:none;stroke:#000;stroke-width:1.5;stroke-linecap:round;stroke-linejoin:round}'
        }
      </style>
      <path d="M5.2 22.65a.75.75 0 01-.148-1.485c.111-.022.198-.226.198-.465a.75.75 0 011.5 0c0 .99-.564 1.767-1.402 1.936a.77.77 0 01-.149.014zM2 18.95a.75.75 0 01-.75-.75V3.3c0-1.111.938-2.05 2.05-2.05h1.5c1.039 0 1.95.958 1.95 2.05v11.4a.75.75 0 01-1.5 0V3.3c0-.298-.276-.55-.45-.55H3.3a.572.572 0 00-.55.55v14.9a.75.75 0 01-.75.75zM12.7 22.65h-1.5c-1.111 0-2.05-.94-2.05-2.05V18a.75.75 0 011.5 0v2.6c0 .288.262.55.55.55h1.5c.288 0 .55-.262.55-.55v-6.8a.75.75 0 011.5 0v6.8c0 1.11-.938 2.05-2.05 2.05zM10 10.55a.75.75 0 01-.75-.75V3.3c0-1.112.939-2.05 2.05-2.05h1.5c1.039 0 1.95.958 1.95 2.05v3.5a.75.75 0 01-1.5 0V3.3c0-.299-.276-.55-.45-.55h-1.5a.572.572 0 00-.55.55v6.5a.75.75 0 01-.75.75zM18.1 3.75a.75.75 0 01-.728-.933c.169-.67.777-1.277 1.446-1.444a.75.75 0 11.363 1.455c-.131.033-.32.222-.354.355a.75.75 0 01-.726.567zM20.7 22.65h-1.5c-1.111 0-2.05-.94-2.05-2.05v-11a.75.75 0 011.5 0v11c0 .288.262.55.55.55h1.5c.288 0 .55-.262.55-.55V5.8a.75.75 0 011.5 0v14.8c0 1.11-.939 2.05-2.05 2.05zM2 22.75a.75.75 0 01-.53-1.28l20-20a.75.75 0 111.06 1.06l-20 20a.747.747 0 01-.53.22z" />
    </svg>
  );
}

export default SvgLayoutColumnOff;
