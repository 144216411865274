import * as React from 'react';

function SvgSofa(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <g
        stroke="#000"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M2.5 13v2M21.5 13v2M.5 7v4a2 2 0 002 2h19a2 2 0 002-2V7" />
        <path d="M20.5 7v1a1 1 0 01-1 1h-15a1 1 0 01-1-1V7M21.5 5.563V5a4 4 0 00-4-4h-11a4 4 0 00-4 4v.577M.5 7a1.5 1.5 0 013 0M23.5 7a1.5 1.5 0 00-3 0M12 1v8" />
      </g>
    </svg>
  );
}

export default SvgSofa;
