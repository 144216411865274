import * as React from 'react';

function SvgTwitter(props) {
  return (
    <svg width={24} height={24} {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          d="M7.548 21.501c9.056 0 14.01-7.503 14.01-14.01 0-.213 0-.425-.015-.636A10.019 10.019 0 0024 4.305a9.828 9.828 0 01-2.828.776 4.941 4.941 0 002.165-2.724 9.868 9.868 0 01-3.127 1.195 4.929 4.929 0 00-8.391 4.491A13.98 13.98 0 011.67 2.9a4.928 4.928 0 001.525 6.573A4.887 4.887 0 01.96 8.855v.063a4.926 4.926 0 003.95 4.827 4.916 4.916 0 01-2.223.084 4.93 4.93 0 004.6 3.42A9.88 9.88 0 010 19.289a13.94 13.94 0 007.548 2.209"
          fill="#1DA1F2"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
}

export default SvgTwitter;
