import * as React from 'react';

function SvgCommonFileTextCheck(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M17.25 24c-3.722 0-6.75-3.028-6.75-6.75s3.028-6.75 6.75-6.75S24 13.528 24 17.25 20.972 24 17.25 24zm0-12C14.355 12 12 14.355 12 17.25s2.355 5.25 5.25 5.25 5.25-2.355 5.25-5.25S20.145 12 17.25 12z" />
      <path d="M16.417 20.428a1.484 1.484 0 01-1.06-.439l-1.499-1.499a.752.752 0 01.53-1.281c.2 0 .389.078.53.22l1.5 1.5 2.906-3.874a.753.753 0 011.049-.151c.332.249.399.72.151 1.051l-2.905 3.873c-.283.376-.732.6-1.202.6zM3.75 7.5a.75.75 0 010-1.5h10.5a.75.75 0 010 1.5H3.75zM3.75 12a.75.75 0 010-1.5h6a.75.75 0 010 1.5h-6zM3.75 16.5a.75.75 0 010-1.5H7.5a.75.75 0 010 1.5H3.75z" />
      <path d="M2.25 21A2.252 2.252 0 010 18.75V2.25A2.252 2.252 0 012.25 0h10.629c.601 0 1.165.234 1.59.658l2.872 2.872c.425.425.659.99.659 1.591V7.5a.75.75 0 01-1.5 0V5.121c0-.197-.08-.39-.219-.53l-2.872-2.872a.748.748 0 00-.53-.219H2.25a.75.75 0 00-.75.75v16.5c0 .414.336.75.75.75H7.5a.75.75 0 010 1.5H2.25z" />
    </svg>
  );
}

export default SvgCommonFileTextCheck;
