import * as React from 'react';

function SvgOfficeFileAdobe(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M2.994 23.998a2.252 2.252 0 01-2.25-2.25v-19.5a2.252 2.252 0 012.25-2.25h15c.59 0 1.148.227 1.571.639l2.996 2.879c.435.424.684 1.012.684 1.615v16.617a2.252 2.252 0 01-2.25 2.25H2.994zm0-22.5a.75.75 0 00-.75.75v19.5c0 .414.336.75.75.75h18a.75.75 0 00.75-.75V5.131a.754.754 0 00-.227-.537l-2.996-2.879a.75.75 0 00-.527-.217h-15z" />
      <path d="M6.455 20.614c-.386 0-.71-.111-.965-.329-.289-.248-.451-.611-.469-1.049-.089-1.63.519-3.932 3.885-5.695l.02-.261c.063-.62.101-1.242.115-1.859a43.56 43.56 0 01-2.753-2.889.959.959 0 01-.047-.06 3.511 3.511 0 01-.62-2.409 2.257 2.257 0 011.99-1.947.813.813 0 01.084-.004 2.32 2.32 0 011.928 1.121c.319.543.523 1.129.609 1.745.201 1.247.307 2.527.314 3.8.245.227.492.451.741.671.321.259.666.518 1.021.762a11.502 11.502 0 015.035-.381 3.518 3.518 0 011.587.685 2.07 2.07 0 01.769 1.903.634.634 0 01-.025.114 2.13 2.13 0 01-1.953 1.513l-.098.001a4.563 4.563 0 01-1.879-.409 18.373 18.373 0 01-3.651-1.77 17.52 17.52 0 00-1.763.651c-.149 1.59-.438 3.229-1.386 4.575a3.325 3.325 0 01-2.249 1.509 2.754 2.754 0 01-.24.012zm2.266-5.238c-1.55 1.023-2.272 2.25-2.205 3.733a1.838 1.838 0 001.168-.833l.026-.04c.6-.846.866-1.876 1.011-2.86zm5.445-2.052c.681.35 1.387.654 2.11.909a.736.736 0 01.068.028c.398.186.836.284 1.268.284l.064-.001a.623.623 0 00.542-.39.565.565 0 00-.211-.458 1.986 1.986 0 00-.892-.384 9.999 9.999 0 00-2.949.012zm-3.694-.484l.105-.04-.097-.076a4.226 4.226 0 00-.008.116zM7.447 7.579a42.518 42.518 0 001.54 1.675 23.334 23.334 0 00-.237-2.05A3.235 3.235 0 008.335 6a.814.814 0 00-.615-.385.752.752 0 00-.61.635c-.051.459.068.933.337 1.329z" />
    </svg>
  );
}

export default SvgOfficeFileAdobe;
