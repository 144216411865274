import * as React from 'react';

function SvgAdd(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M12 24a.75.75 0 01-.75-.75v-10.5H.75a.75.75 0 010-1.5h10.5V.75a.75.75 0 011.5 0v10.5h10.5a.75.75 0 010 1.5h-10.5v10.5A.75.75 0 0112 24z" />
    </svg>
  );
}

export default SvgAdd;
