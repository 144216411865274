import * as React from 'react';

function SvgChecklist(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M2.25 23.999A2.252 2.252 0 010 21.749v-19.5a2.252 2.252 0 012.25-2.25h19.5A2.252 2.252 0 0124 2.249v19.5a2.252 2.252 0 01-2.25 2.25H2.25zm0-22.5a.75.75 0 00-.75.75v19.5c0 .414.336.75.75.75h19.5a.75.75 0 00.75-.75v-19.5a.75.75 0 00-.75-.75H2.25z" />
      <path d="M7.5 11.249a.743.743 0 01-.53-.22l-3-3c-.142-.141-.22-.33-.22-.53s.078-.389.22-.53c.141-.142.33-.22.53-.22s.389.078.53.22l2.389 2.388L11.4 4.049a.754.754 0 011.05-.15.747.747 0 01.15 1.05l-4.5 6a.75.75 0 01-.547.298l-.053.002zM14.25 8.999a.75.75 0 010-1.5h4.5a.75.75 0 010 1.5h-4.5zM7.5 20.249a.743.743 0 01-.53-.22l-3-3a.752.752 0 01.53-1.281c.2 0 .389.078.53.22l2.389 2.389 3.981-5.309a.755.755 0 011.05-.15.75.75 0 01.15 1.05l-4.5 6a.75.75 0 01-.6.301zM14.25 17.999a.75.75 0 010-1.5h4.5a.75.75 0 010 1.5h-4.5z" />
    </svg>
  );
}

export default SvgChecklist;
