import * as React from 'react';

function SvgCommonFileDownload(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M17.25 24c-3.722 0-6.75-3.028-6.75-6.75s3.028-6.75 6.75-6.75S24 13.528 24 17.25 20.972 24 17.25 24zm0-12C14.355 12 12 14.355 12 17.25s2.355 5.25 5.25 5.25 5.25-2.355 5.25-5.25S20.145 12 17.25 12z" />
      <path d="M17.25 21a.767.767 0 01-.258-.046l-.017-.006a.748.748 0 01-.256-.167l-2.25-2.25a.752.752 0 01.53-1.281c.2 0 .389.078.53.22l.97.97v-4.19a.75.75 0 011.501 0v4.189l.97-.97a.744.744 0 011.06 0 .752.752 0 010 1.061l-2.25 2.25a.74.74 0 01-.245.163l-.026.01a.73.73 0 01-.259.047zM2.25 21A2.252 2.252 0 010 18.75V2.25A2.252 2.252 0 012.25 0h10.629c.601 0 1.165.234 1.59.658l2.872 2.872c.425.425.659.99.659 1.591V8.25a.75.75 0 01-1.5 0V5.121c0-.197-.08-.39-.219-.53l-2.872-2.872a.748.748 0 00-.53-.219H2.25a.75.75 0 00-.75.75v16.5c0 .414.336.75.75.75h6a.75.75 0 010 1.5h-6z" />
    </svg>
  );
}

export default SvgCommonFileDownload;
