import * as React from 'react';

function SvgCellBorderFull(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M3 23.2c-1.2 0-2.2-1-2.2-2.2V3C.8 1.8 1.8.8 3 .8h18c1.2 0 2.2 1 2.2 2.2v18c0 1.2-1 2.2-2.2 2.2H3zm18-1.5c.4 0 .8-.3.8-.8v-8.2h-9v9H21zM2.2 21c0 .4.3.8.8.8h8.2v-9h-9V21zm19.6-9.8V3c0-.4-.3-.8-.8-.8h-8.2v9h9zm-10.6 0v-9H3c-.4 0-.8.4-.8.8v8.2h9z" />
    </svg>
  );
}

export default SvgCellBorderFull;
