import * as React from 'react';

function SvgImageFileLandscape(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M3.023 24a2.252 2.252 0 01-2.25-2.25V2.25A2.252 2.252 0 013.023 0h15c.59 0 1.147.227 1.571.639l2.955 2.883c.431.422.678 1.009.678 1.611V21.75a2.252 2.252 0 01-2.25 2.25H3.023zm0-22.5a.75.75 0 00-.75.75v19.5c0 .414.336.75.75.75h17.954a.75.75 0 00.75-.75V5.133a.753.753 0 00-.226-.537l-2.955-2.883a.744.744 0 00-.523-.213h-15z" />
      <path d="M8.273 10.5c-1.654 0-3-1.346-3-3s1.346-3 3-3 3 1.346 3 3-1.346 3-3 3zm0-4.5c-.827 0-1.5.673-1.5 1.5S7.446 9 8.273 9s1.5-.673 1.5-1.5S9.1 6 8.273 6zM18.813 18.95a.75.75 0 01-.625-.334l-3.925-5.888a.264.264 0 00-.363-.074.262.262 0 00-.069.068l-2.692 3.846a.75.75 0 01-1.084.155l-1.668-1.338a.257.257 0 00-.191-.055.255.255 0 00-.175.096l-2.125 3.19a.75.75 0 01-1.249-.831l2.111-3.171a1.746 1.746 0 011.471-.786c.401 0 .78.134 1.096.387l1.044.837 2.232-3.189a1.758 1.758 0 012.91.034l3.925 5.887a.748.748 0 01-.623 1.166z" />
    </svg>
  );
}

export default SvgImageFileLandscape;
