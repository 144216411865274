import * as React from 'react';

function SvgEnergyLabel(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <g
        stroke="#000"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M15.515 14.272c-1.7 1.046-2.46 2.683.387 4.453.292 3.343 1.915 3.52 3.8 2.363 1.731-1.061 3.5-4.625 3.415-7.256a.5.5 0 00-.642-.464c-2.596.769-4.975-.314-6.96.904zM12.032 23.345s4.46-6.659 8.268-7.524M6.568 18.8a.5.5 0 00.711 0l11.753-12a.5.5 0 00.143-.35V1.155a.5.5 0 00-.5-.5l-5.551.006a.5.5 0 00-.353.147L1.029 12.551a.5.5 0 000 .707L6.568 18.8z" />
        <circle cx={15.675} cy={4.155} r={1.5} />
      </g>
    </svg>
  );
}

export default SvgEnergyLabel;
