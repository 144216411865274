import PropTypes from 'prop-types';
import { theme } from '@embracesbs/helpers';

export const propTypes = {
  /** Horizontal or Vertical alignment */
  orientation: PropTypes.oneOf(['horizontal', 'vertical']),
  /** Only for 'horizontal' mode */
  align: PropTypes.oneOf(['center', 'flex-start', 'flex-end']),
  /** Color of the divider */
  background: PropTypes.oneOf(['dark$7', 'light$5']),
  /** Indicates the spacing of the separation */
  spacing: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.shape({
      bottom: PropTypes.number,
      left: PropTypes.number,
      right: PropTypes.number,
      top: PropTypes.number,
    }),
  ]),
  /** HTML element tag to wrap around the text */
  tag: PropTypes.string,
  /** An array of strings which includes the extra prop keys */
  extraProps: PropTypes.array,
  /** Is Dark mode */
  isDark: PropTypes.bool,
  /** Min size of the divider */
  minSize: PropTypes.number,
  /** Size of the divider */
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /** If the "align' prop is used, then the Divider component is wrapped in a div.
   *  The div's size can be managed by this props"
   */
  wrapperSize: PropTypes.string,
  /** The optional children of the divider */
  children: PropTypes.node,
  /** The background color of the children */
  childrenBackground: PropTypes.string,
  /** The optional text in the middle of the divider */
  text: PropTypes.string,
  /** The optional timestamp placed before the text */
  timestamp: PropTypes.string,
};

export const defaultProps = {
  orientation: 'horizontal',
  background: 'dark$7',
  spacing: 0,
  tag: 'div',
  extraProps: [],
  isDark: false,
  size: null,
  minSize: 35,
  align: null,
  wrapperSize: null,
  children: null,
  childrenBackground: theme.color.light,
  text: null,
  timestamp: null,
};
