import * as React from 'react';

function SvgParked(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M12 24C5.383 24 0 18.617 0 12S5.383 0 12 0s12 5.383 12 12-5.383 12-12 12zm0-22.5C6.21 1.5 1.5 6.21 1.5 12S6.21 22.5 12 22.5 22.5 17.79 22.5 12 17.79 1.5 12 1.5z" />
      <path d="M10.5 18a.75.75 0 01-.75-.75v-9a.75.75 0 01.75-.75h2.25c1.654 0 3 1.346 3 3s-1.346 3-3 3h-1.5v3.75a.75.75 0 01-.75.75zm2.25-6c.827 0 1.5-.673 1.5-1.5S13.577 9 12.75 9h-1.5v3h1.5z" />
    </svg>
  );
}

export default SvgParked;
