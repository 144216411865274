import PropTypes from 'prop-types';
import { theme } from '@embracesbs/helpers';

export const propTypes = {
  /** Text alignment */
  align: PropTypes.oneOf(['left', 'center', 'right']),

  /** An array of strings which includes the extra prop keys */
  extraProps: PropTypes.array,

  /** HTML element tag to wrap around the text */
  tag: PropTypes.string,

  /** Text to display */
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

  /** Text color */
  textColor: PropTypes.string,

  /** Apply pre-defined text styles to the text */
  textStyle: PropTypes.oneOf(Object.keys(theme.textStyle)),

  /** Spacing */
  spacing: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),

  /** Limit the text to one line */
  singleLine: PropTypes.bool,

  /** Limit the text to a given number of lines */
  numberOfLines: PropTypes.number,
};

export const defaultProps = {
  align: 'left',
  extraProps: [],
  tag: 'div',
  textColor: theme.color.dark,
  textStyle: 'body',
  content: null,
  spacing: 0,
  singleLine: false,
  numberOfLines: null,
};
