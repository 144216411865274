import * as React from 'react';

function SvgOfficeFileKey1(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M2.249 23.998a2.252 2.252 0 01-2.25-2.25v-19.5a2.252 2.252 0 012.25-2.25h10.629c.601 0 1.165.234 1.59.658l5.872 5.872c.425.425.659.99.659 1.59v4.629a.75.75 0 01-1.5 0v-3.75h-5.25a2.252 2.252 0 01-2.25-2.25v-5.25h-9.75a.75.75 0 00-.75.75v19.5c0 .414.336.75.75.75h3a.75.75 0 010 1.5h-3zm11.25-17.25c0 .414.336.75.75.75h4.939l-5.689-5.689v4.939z" />
      <path d="M11.249 23.998a.75.75 0 01-.586-.281l-1.664-2.081v1.612a.75.75 0 01-1.5 0v-7.5a.75.75 0 011.5 0v1.612l1.664-2.081a.748.748 0 011.055-.116.752.752 0 01.117 1.054L9.21 19.498l2.625 3.281a.75.75 0 01-.586 1.219zM15.749 23.998a2.252 2.252 0 01-2.25-2.25v-4.5a2.252 2.252 0 012.25-2.25h1.5a.75.75 0 010 1.5h-1.5a.75.75 0 00-.75.75v2.25h2.25a.75.75 0 010 1.5h-2.25v.75c0 .414.336.75.75.75h1.5a.75.75 0 010 1.5h-1.5zM21.749 23.998a.75.75 0 01-.75-.75v-3.127a2.26 2.26 0 01-1.5-2.121v-2.252a.75.75 0 011.5 0V18c0 .404.325.737.728.749h.044a.75.75 0 00.727-.749v-2.25a.75.75 0 011.5 0V18c0 .96-.615 1.808-1.5 2.121v3.127a.749.749 0 01-.749.75z" />
    </svg>
  );
}

export default SvgOfficeFileKey1;
