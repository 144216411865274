const baseStyles = {
  badge: () => `
  background-color: '#fff';
  z-index: 0;
  position: absolute;
`,
};

export const webStyles = {
  ...baseStyles,
  badge: (theme, bottomLeft, bottomRight, topLeft, topRight) => `
    ${baseStyles.badge(theme)}
    box-sizing: border-box;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    ${bottomLeft || bottomRight ? `bottom: 0;` : ``}
    ${topLeft || topRight ? `top: 0;` : ``}
    ${bottomLeft || topLeft ? `left: 0;` : ``}
    ${bottomRight || topRight ? `right: 0;` : ``}
  `,
};

export const nativeStyles = {};
