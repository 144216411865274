import * as React from 'react';

function SvgColorPaintingPalette(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M14.093 23.988c-3.661 0-7.582-1.628-9.989-4.148-4.421-4.506-4.409-11.835.03-16.334 2.234-2.234 5.31-3.514 8.443-3.514.146 0 .292.003.438.008 2.976.109 5.643 1.359 7.509 3.52 2.136 2.474 3.015 4.496 2.689 6.181-.393 2.03-2.343 3.036-4.063 3.923-1.237.638-2.404 1.24-2.351 1.934.086 1.105.694 1.516 2.245 1.516.379 0 .783-.025 1.092-.047.318-.023.594-.042.843-.042.43 0 .91.052 1.211.442.12.155.297.487.135.968-.963 2.853-3.015 4.703-5.935 5.351-.72.161-1.492.242-2.297.242zM12.586 1.492a10.522 10.522 0 00-7.387 3.071c-3.865 3.916-3.873 10.302-.017 14.233 2.146 2.246 5.633 3.695 8.888 3.695h.001c.701 0 1.371-.07 1.992-.208 1.541-.342 3.548-1.281 4.618-3.787-.149.008-.304.019-.44.028-.365.026-.774.054-1.207.054-2.321 0-3.576-.973-3.73-2.892l-.001-.013c-.129-1.687 1.543-2.549 3.16-3.382 1.487-.767 3.024-1.559 3.278-2.875.227-1.172-.586-2.871-2.352-4.916-1.59-1.842-3.873-2.908-6.428-3.002-.125-.004-.25-.006-.375-.006z" />
      <path d="M7.219 15.571c-1.252 0-2.27-1.018-2.27-2.27s1.018-2.27 2.27-2.27 2.27 1.018 2.27 2.27-1.019 2.27-2.27 2.27zm0-3.04a.77.77 0 100 1.54.77.77 0 000-1.54zM10.979 20.406c-1.252 0-2.27-1.018-2.27-2.27s1.018-2.27 2.27-2.27 2.27 1.018 2.27 2.27-1.019 2.27-2.27 2.27zm0-3.04a.77.77 0 100 1.54.77.77 0 000-1.54zM14.615 8.174c-1.252 0-2.27-1.018-2.27-2.27s1.018-2.27 2.27-2.27 2.27 1.018 2.27 2.27-1.019 2.27-2.27 2.27zm0-3.04a.77.77 0 100 1.54.77.77 0 000-1.54zM8.169 9.786c-1.252 0-2.27-1.018-2.27-2.27s1.018-2.27 2.27-2.27 2.27 1.018 2.27 2.27-1.019 2.27-2.27 2.27zm0-3.04a.77.77 0 100 1.54.77.77 0 000-1.54z" />
    </svg>
  );
}

export default SvgColorPaintingPalette;
