import * as React from 'react';

function SvgFileCPlusPlus(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M20.25 13.503a.75.75 0 01-.75-.75v-3.75h-5.25A2.252 2.252 0 0112 6.753v-5.25H2.25a.75.75 0 00-.75.75v10.5a.75.75 0 01-1.5 0v-10.5A2.252 2.252 0 012.25.003h10.629a2.239 2.239 0 011.591.66l5.872 5.871c.424.425.658.99.658 1.591v4.628a.75.75 0 01-.75.75zm-6.75-6.75c0 .414.336.75.75.75h4.939L13.5 1.815v4.938zM5.25 24.003a3.754 3.754 0 01-3.75-3.75v-1.5a3.754 3.754 0 013.75-3.75.75.75 0 010 1.5A2.252 2.252 0 003 18.753v1.5a2.252 2.252 0 002.25 2.25.75.75 0 010 1.5zM11.25 24.003a.75.75 0 01-.75-.75v-2.25H8.25a.75.75 0 010-1.5h2.25v-2.25a.75.75 0 011.5 0v2.25h2.25a.75.75 0 010 1.5H12v2.25a.75.75 0 01-.75.75zM20.25 24.003a.75.75 0 01-.75-.75v-2.25h-2.25a.75.75 0 010-1.5h2.25v-2.25a.75.75 0 011.5 0v2.25h2.25a.75.75 0 010 1.5H21v2.25a.75.75 0 01-.75.75z" />
    </svg>
  );
}

export default SvgFileCPlusPlus;
