import PropTypes from 'prop-types';

export const propTypes = {
  /** Can have all kind of child nodes */
  children: PropTypes.node,
  /** Defines if the row contains hover functionality */
  hasHover: PropTypes.bool,
  /** To set the active state on a table row */
  isActive: PropTypes.bool,
  /** To set the selected state on a table row */
  isSelected: PropTypes.bool,
  /** The onClick handler */
  onClick: PropTypes.func,
  /** The border of the row */
  border: PropTypes.shape({
    hasBorder: PropTypes.bool,
    isFullWidth: PropTypes.bool,
    offsetWidth: PropTypes.number,
  }),
};

export const defaultProps = {
  children: null,
  hasHover: true,
  onClick: null,
  isActive: null,
  isSelected: null,
  border: { isFullWidth: true, hasBorder: true, offsetWidth: 24 },
};
