import * as React from 'react';

function SvgSingleNeutralIdCardDouble(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M8.25 14.25a3.379 3.379 0 01-3.375-3.375C4.875 9.014 6.389 7.5 8.25 7.5s3.375 1.514 3.375 3.375A3.379 3.379 0 018.25 14.25zm0-5.25c-1.034 0-1.875.841-1.875 1.875s.841 1.875 1.875 1.875 1.875-.841 1.875-1.875S9.284 9 8.25 9z" />
      <path d="M3.75 19.5a.75.75 0 01-.75-.75c0-2.895 2.355-5.25 5.25-5.25s5.25 2.355 5.25 5.25a.75.75 0 01-.75.75h-9zm8.174-1.5c-.352-1.712-1.892-3-3.674-3s-3.322 1.288-3.674 3h7.348zM14.25 12a.75.75 0 010-1.5h4.5a.75.75 0 010 1.5h-4.5zM14.25 15a.75.75 0 010-1.5h6a.75.75 0 010 1.5h-6z" />
      <path d="M18.75 24c-.414 0-.75-.336-.75-.75a.75.75 0 00-1.5 0 .75.75 0 01-.75.75h-7.5c-.414 0-.75-.336-.75-.75a.75.75 0 00-1.5 0 .75.75 0 01-.75.75h-3A2.252 2.252 0 010 21.75v-15A2.252 2.252 0 012.25 4.5h19.5A2.252 2.252 0 0124 6.75v15A2.252 2.252 0 0121.75 24h-3zm3-1.5a.75.75 0 00.75-.75v-15a.75.75 0 00-.75-.75H2.25a.75.75 0 00-.75.75v15c0 .414.336.75.75.75h2.379C4.942 21.615 5.79 21 6.75 21s1.808.615 2.121 1.5h6.257c.314-.885 1.162-1.5 2.122-1.5s1.808.615 2.122 1.5h2.378zM23.25 3c-.414 0-.75-.336-.75-.75s-.336-.75-.75-.75H2.25c-.414 0-.75.336-.75.75a.75.75 0 01-1.5 0A2.252 2.252 0 012.25 0h19.5A2.252 2.252 0 0124 2.25a.75.75 0 01-.75.75z" />
    </svg>
  );
}

export default SvgSingleNeutralIdCardDouble;
