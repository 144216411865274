import * as React from 'react';

function SvgArrowUp1(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M.75 17.939a.752.752 0 01-.53-1.281L10.94 5.94c.283-.284.659-.44 1.059-.44.401 0 .777.156 1.06.438l10.721 10.72a.752.752 0 01-.53 1.281.743.743 0 01-.53-.22L12 7 1.28 17.719a.743.743 0 01-.53.22z" />
    </svg>
  );
}

export default SvgArrowUp1;
