import React from 'react';
import styled from '@emotion/styled';
import { getExtraProps } from '@embracesbs/helpers';
import { propTypes, defaultProps } from './SidebarHeader.props';

const StyledHeader = styled.div`
  width: 100%;
`;

/**
 * SidebarHeader component
 * @param {*} props
 */
const SidebarHeader = (props) => {
  const { children, extraProps } = props;

  return (
    <StyledHeader {...getExtraProps(props, extraProps)}>
      {children}
    </StyledHeader>
  );
};

SidebarHeader.propTypes = propTypes;
SidebarHeader.defaultProps = defaultProps;

/** @component */
export default SidebarHeader;
