import * as React from 'react';

function SvgHumanResourcesSearchTeam(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M8.249 15.75a4.88 4.88 0 01-4.875-4.875A4.88 4.88 0 018.249 6c2.361 0 4.42 1.752 4.805 4.081a.759.759 0 01.037.247c.022.198.033.377.033.547a4.88 4.88 0 01-4.875 4.875zm-3.268-5.717a3.379 3.379 0 003.268 4.217 3.38 3.38 0 003.339-2.884 7.628 7.628 0 01-6.607-1.333zm.712-1.356c1.1.93 2.49 1.438 3.952 1.438.62 0 1.229-.093 1.814-.276A3.394 3.394 0 008.249 7.5c-.983 0-1.916.435-2.556 1.177zM17.624 16.5a3.379 3.379 0 01-3.375-3.375c0-1.861 1.514-3.375 3.375-3.375s3.375 1.514 3.375 3.375a3.379 3.379 0 01-3.375 3.375zm0-5.25c-1.034 0-1.875.841-1.875 1.875S16.59 15 17.624 15s1.875-.841 1.875-1.875-.841-1.875-1.875-1.875z" />
      <path d="M11.999 24c-3.686 0-7.115-1.665-9.412-4.568a.754.754 0 01-.141-.18A11.88 11.88 0 01-.001 12c0-6.617 5.383-12 12-12s12 5.383 12 12c0 2.41-.715 4.736-2.069 6.728a.736.736 0 01-.099.14 12.048 12.048 0 01-6.694 4.712c-1.025.279-2.081.42-3.138.42zm-7.896-5.087a10.465 10.465 0 0010.066 3.359 5.985 5.985 0 00-6.274-5.015 6.067 6.067 0 00-3.792 1.656zm10.337.107a7.498 7.498 0 011.177 2.835 10.626 10.626 0 004.7-3.459 3.743 3.743 0 00-2.704-1.145 3.716 3.716 0 00-3.173 1.769zm-6.182-3.273c1.924 0 3.786.756 5.178 2.088.316-.42.691-.786 1.118-1.094a5.203 5.203 0 013.91-.923 5.186 5.186 0 012.697 1.303A10.453 10.453 0 0022.499 12c0-5.79-4.71-10.5-10.5-10.5S1.499 6.21 1.499 12c0 2.041.585 4.006 1.696 5.71a7.483 7.483 0 015.063-1.963z" />
    </svg>
  );
}

export default SvgHumanResourcesSearchTeam;
