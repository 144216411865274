import PropTypes from 'prop-types';

export const propTypes = {
  /** Alignment of the child components */
  align: PropTypes.oneOf(['left', 'center', 'right', 'start', 'end']),

  /** This will contain content for the button group */
  children: PropTypes.node,

  /** An array of strings which includes the extra prop keys */
  extraProps: PropTypes.array,

  /** Defines spacing around the component */
  spacing: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.shape({
      bottom: PropTypes.number,
      left: PropTypes.number,
      right: PropTypes.number,
      top: PropTypes.number,
    }),
  ]),

  /** vertically align the child components */
  isVertical: PropTypes.bool,

  /** Set the element to full height */
  isFullHeight: PropTypes.bool,

  /** Set the element to full height */
  isFullWidth: PropTypes.bool,

  /** Set the component to wrap the elements on new lines if they don't fit */
  isWrapping: PropTypes.bool,

  /** Sets the flex justify-content property */
  justify: PropTypes.oneOf([
    'flex-start',
    'flex-end',
    'center',
    'space-between',
    'space-around',
    'space-evenly',
    'start',
    'end',
  ]),

  /** Show a divider between the components */
  hasDivider: PropTypes.bool,

  /** Spacing between grouped components */
  innerSpacing: PropTypes.number,

  /**
   * Leave the Group component to manage the spacing or individually
   * manage it by your self for each component separately.
   * The problem that resolves: Sometimes the 'innerSpacing' overrides the spacing of the inner components.
   */
  hasInnerSpacing: PropTypes.bool,

  /** Whether the group should use the grid system */
  isGrid: PropTypes.bool,

  /** The grid template string */
  gridTemplate: PropTypes.string,

  /** Is displayed in reverse */
  isReversed: PropTypes.bool,
};

export const defaultProps = {
  align: 'center',
  children: null,
  extraProps: [],
  spacing: 0,
  isVertical: false,
  isFullHeight: false,
  isFullWidth: true,
  isWrapping: false,
  justify: null,
  hasDivider: false,
  innerSpacing: 8,
  hasInnerSpacing: true,
  isGrid: false,
  gridTemplate: null,
  isReversed: false,
};
