import * as React from 'react';

function SvgBuildingHouse(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M2.251 24a.75.75 0 01-.75-.75V19.5h-.75a.751.751 0 01-.576-1.23l3.75-4.5a.747.747 0 01.576-.27h6.75a.734.734 0 01.474.174c.02.014.041.031.056.046l4.5 4.5a.752.752 0 01-.53 1.281.743.743 0 01-.53-.22l-.22-.22v4.189a.75.75 0 01-.75.75h-12zm11.25-1.5v-4.939l-2.2-2.2-2.3 2.76V22.5h1.5v-2.25a.75.75 0 011.5 0v2.25h1.5zm-6 0v-3h-4.5v3h4.5zM7.149 18l2.5-3H4.852l-2.5 3h4.797z" />
      <path d="M18.751 19.5a.75.75 0 010-1.5h2.25V5.561l-3.715-3.715-3.785 4.299v5.106a.75.75 0 01-1.5 0V7.499l-4.5.001v3.75a.75.75 0 01-1.5 0V7.5h-.783a.75.75 0 01-.563-1.245l5.283-6A.75.75 0 0110.501 0h6.75a.754.754 0 01.473.172.397.397 0 01.057.047l6 6c.142.141.22.33.22.53s-.078.389-.22.53a.749.749 0 01-1.06 0l-.22-.22V18.75a.75.75 0 01-.75.75h-3zM11.629 6l3.962-4.5H10.84L6.877 6h4.752z" />
      <path d="M15.751 9a.75.75 0 01-.75-.75V7.5a.75.75 0 011.5 0v.75a.75.75 0 01-.75.75zM18.751 9a.75.75 0 01-.75-.75V7.5a.75.75 0 011.5 0v.75a.75.75 0 01-.75.75zM15.751 12.75a.75.75 0 01-.75-.75v-.75a.75.75 0 011.5 0V12a.75.75 0 01-.75.75zM18.751 12.75a.75.75 0 01-.75-.75v-.75a.75.75 0 011.5 0V12a.75.75 0 01-.75.75zM18.751 16.5a.75.75 0 01-.75-.75V15a.75.75 0 011.5 0v.75a.75.75 0 01-.75.75z" />
    </svg>
  );
}

export default SvgBuildingHouse;
