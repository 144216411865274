import PropTypes from 'prop-types';

export const propTypes = {
  /** Available main content */
  content: PropTypes.node,
  /** Available collapsed content */
  collapsedContent: PropTypes.node,

  /** An array of strings which includes the extra prop keys */
  extraProps: PropTypes.array,
};

export const defaultProps = {
  content: null,
  collapsedContent: null,
  extraProps: [],
};
