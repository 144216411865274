import * as React from 'react';

function SvgPresentationAudience(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M7.793 15.001a.745.745 0 01-.74-.676L6.821 12H4.5a.75.75 0 010-1.5h15a.75.75 0 010 1.5h-2.321l-.233 2.325a.747.747 0 01-.827.671c-.194-.019-.373-.116-.5-.271s-.186-.35-.166-.55L15.671 12H8.329l.218 2.175a.746.746 0 01-.672.821.644.644 0 01-.082.005zM12 6.75c-1.861 0-3.375-1.514-3.375-3.375S10.139 0 12 0s3.375 1.514 3.375 3.375S13.861 6.75 12 6.75zm0-5.25c-1.034 0-1.875.841-1.875 1.875S10.966 5.25 12 5.25s1.875-.841 1.875-1.875S13.034 1.5 12 1.5z" />
      <path d="M15.249 9a.748.748 0 01-.571-.264A3.517 3.517 0 0011.996 7.5 3.517 3.517 0 009.32 8.737a.75.75 0 01-1.142-.973 5.013 5.013 0 014.221-1.749 4.98 4.98 0 013.42 1.749.746.746 0 01-.57 1.236zM3.999 21.75c-1.861 0-3.375-1.514-3.375-3.375S2.138 15 3.999 15s3.375 1.514 3.375 3.375S5.86 21.75 3.999 21.75zm0-5.25c-1.034 0-1.875.841-1.875 1.875s.841 1.875 1.875 1.875 1.875-.841 1.875-1.875S5.033 16.5 3.999 16.5z" />
      <path d="M.748 24.001a.751.751 0 01-.571-1.237 5.009 5.009 0 014.222-1.748 4.986 4.986 0 013.42 1.749A.746.746 0 017.248 24a.748.748 0 01-.571-.264 3.521 3.521 0 00-5.358-.001.746.746 0 01-.571.266zM12 21.75c-1.861 0-3.375-1.514-3.375-3.375S10.139 15 12 15s3.375 1.514 3.375 3.375S13.861 21.75 12 21.75zm0-5.25c-1.034 0-1.875.841-1.875 1.875S10.966 20.25 12 20.25s1.875-.841 1.875-1.875S13.034 16.5 12 16.5z" />
      <path d="M8.749 24.001a.751.751 0 01-.57-1.236 5.009 5.009 0 014.222-1.748 4.984 4.984 0 013.42 1.749.746.746 0 01-.572 1.234.748.748 0 01-.571-.264 3.521 3.521 0 00-5.358-.001.746.746 0 01-.571.266zM20.001 21.75c-1.861 0-3.375-1.514-3.375-3.375S18.14 15 20.001 15s3.375 1.514 3.375 3.375-1.514 3.375-3.375 3.375zm0-5.25c-1.034 0-1.875.841-1.875 1.875s.841 1.875 1.875 1.875 1.875-.841 1.875-1.875-.841-1.875-1.875-1.875z" />
      <path d="M23.25 24.001a.748.748 0 01-.571-.264 3.521 3.521 0 00-5.358-.001.748.748 0 01-1.318-.426.746.746 0 01.176-.547 5.009 5.009 0 014.222-1.748 4.984 4.984 0 013.42 1.749.746.746 0 01-.084 1.057.75.75 0 01-.487.18z" />
    </svg>
  );
}

export default SvgPresentationAudience;
