import * as React from 'react';

function SvgAnalyticsGraph(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M.75 24a.75.75 0 01-.75-.75V.75a.75.75 0 011.5 0v9.176l4.546-2.728A2.252 2.252 0 018.25 4.5a2.252 2.252 0 012.243 2.426l2.876 1.278A2.256 2.256 0 0115 7.5c.254 0 .501.042.739.126l3.839-4.798a2.187 2.187 0 01-.078-.578C19.5 1.009 20.509 0 21.75 0S24 1.009 24 2.25a2.252 2.252 0 01-3.524 1.854L16.91 8.562a2.25 2.25 0 11-4.153 1.011L9.882 8.296A2.257 2.257 0 018.25 9a2.253 2.253 0 01-1.43-.517L1.5 11.675v7.362l3.018-1.509A2.252 2.252 0 016.75 15c.96 0 1.808.615 2.121 1.5h3.257a2.26 2.26 0 012.121-1.5c.551 0 1.078.203 1.488.565l3.763-2.09a2.253 2.253 0 012.25-2.225c1.241 0 2.25 1.009 2.25 2.25s-1.009 2.25-2.25 2.25a2.257 2.257 0 01-1.749-.837l-3.533 1.962a2.252 2.252 0 01-2.219 2.625c-.96 0-1.808-.615-2.121-1.5H8.871a2.26 2.26 0 01-2.121 1.5 2.229 2.229 0 01-1.56-.632L1.5 20.714V22.5h21.75a.75.75 0 010 1.5H.75zm13.5-7.5a.75.75 0 100 1.5.75.75 0 000-1.5zm-7.5 0a.75.75 0 100 1.5.75.75 0 000-1.5zm15-3.75a.75.75 0 100 1.5.75.75 0 000-1.5zM15 9a.75.75 0 100 1.5.75.75 0 000-1.5zM8.25 6a.75.75 0 100 1.5.75.75 0 000-1.5zm13.5-4.5a.75.75 0 100 1.5.75.75 0 000-1.5z" />
    </svg>
  );
}

export default SvgAnalyticsGraph;
