import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { getExtraProps, withTheme } from '@embracesbs/helpers';
import TextField from '@embracesbs/component-textfield';
import Group from '@embracesbs/component-group';
import Button from '@embracesbs/component-button';
import Text from '@embracesbs/component-text';
import { propTypes, defaultProps } from './SidebarSearch.props';
import { webStyles } from './SidebarSearch.styles';

/** Styled elements */
const ContentWrapper = styled.div`
  ${({ theme }) => webStyles.default(theme)}
  ${({ theme }) => webStyles.mainContent(theme)}
`;
const StyledResultsWrapper = styled.div`
  ${({ theme }) => webStyles.results(theme)}
`;
const StyledResultsFilter = styled.div`
  ${({ theme }) => webStyles.filter(theme)}
`;
const StyledSearchResults = styled.div`
  ${() => webStyles.searchresultswrapper()}
`;
const StyledResults = styled.div`
  ${() => webStyles.searchresults()}
`;
const StyledRecentSearches = styled.div`
  ${({ theme }) => webStyles.recentsearches(theme)}
`;
const StyledResultsContent = styled.div`
  ${({ theme }) => webStyles.resultswrapper(theme)}
`;

const StyledHeader = styled.div``;

/**
 * @param {nodes} recentSearchChildren - The nodes that contains the recents searches
 * @param {nodes} searchResultsChildren - The nodes containing the search results
 * @param {nodes} searchFilterChildren - The nodes containing the search filters
 * @param {nodes} onChange - Attach function to the onChange handler
 * @param {nodes} extraProps - An array of strings which includes the extra prop keys
 * @param {nodes} searchTerm - The search term
 * @param {nodes} searchPlaceholder - The search input placeholder
 * @param {nodes} labelNoRecentSearches - Label for no recent searches
 * @param {nodes} labelNoSearchResults - Label for no search results
 * @param {nodes} labelNoFilterAvailable - Label for no filter available
 * @param {nodes} labelSearchHeader - Label for the search header
 */
const SidebarSearch = (props) => {
  const {
    recentSearchChildren,
    searchResultsChildren,
    searchFilterChildren,
    onChange,
    extraProps,
    searchTerm,
    searchPlaceholder,
    labelNoRecentSearches,
    labelNoSearchResults,
    labelNoFilterAvailable,
    labelSearchHeader,
  } = props;
  const [isStartSearch] = useState(false);
  const [closeSearch, setCloseSearch] = useState(false);

  useEffect(() => {
    const element = document.getElementsByClassName('mainSidebar')[0];
    // Remove the extra generated classes during rerender of the component
    element.classList.remove('activeSearch', 'activeSearchResults');
    // Set the closeSearch state back to false, this is used for the onclick handler
    setCloseSearch(false);
  }, [closeSearch]);

  useEffect(() => {
    if (searchTerm) {
      const element = document.getElementsByClassName('mainSidebar')[0];

      if (element.classList.contains('activeSearch'))
        element.classList.remove('activeSearch');

      if (!element.classList.contains('activeSearchResults'))
        element.classList.add('activeSearchResults');
    }
  }, [searchTerm]);

  /** Triggerd when clicking on the textfield */
  const onClickHandler = () => {
    const element = document.getElementsByClassName('mainSidebar')[0];
    // Check if the user is not clicking on the close button and the user is not on the search results mode
    if (!closeSearch && !element.classList.contains('activeSearchResults')) {
      // Add a activeSearch class for styling purposes
      element.classList.add('activeSearch');
    }
  };

  /** Triggerd when a change is made in the textfield */
  const onChangeHandler = () => {
    const element = document.getElementsByClassName('mainSidebar')[0];
    const input = document.getElementById('mainSearchInput');

    onChange(input.value);

    // Check if the target contains de activeSearch class if so remove it
    if (element.classList.contains('activeSearch'))
      element.classList.remove('activeSearch');

    // Check if the input of the textfield is empty if so return to the recent search mode
    if (input.value === '') {
      element.classList.add('activeSearch');
      element.classList.remove('activeSearchResults');
      return;
    }

    // Set the new results class the is related to styling
    if (!element.classList.contains('activeSearchResults'))
      element.classList.add('activeSearchResults');
  };

  /** Triggered when clearing the search field */
  const onClearHandler = () => {
    const element = document.getElementsByClassName('mainSidebar')[0];

    onChange('');

    element.classList.add('activeSearch');
    element.classList.remove('activeSearchResults');
  };

  /** triggerd when closing the search and this wil rerender the component */
  const hideSearch = () => {
    onChange('');
    setCloseSearch(true);
  };

  // Showing the header with the close button and text
  const header = (
    <StyledHeader className="searchHeader">
      <Group align="center" justify="flex-start">
        <Button
          id="closeSidebarSearch"
          variant="icon"
          iconName="ArrowLeft"
          iconSize={18}
          onClick={hideSearch}
          aria-label="Close search"
        />
        <Text content={labelSearchHeader} textColor="#000" />
      </Group>
    </StyledHeader>
  );
  // Showing the recent search
  const recentSearch = (
    <StyledRecentSearches id="recentSearches">
      {recentSearchChildren || <Text content={labelNoRecentSearches} />}
    </StyledRecentSearches>
  );
  // Showing all the results
  const searchResults = (
    <StyledResultsContent>
      {searchResultsChildren || <Text content={labelNoSearchResults} />}
    </StyledResultsContent>
  );
  // Showing the search filter
  const searchFilter = (
    <StyledResultsFilter>
      {searchFilterChildren || <Text content={labelNoFilterAvailable} />}
    </StyledResultsFilter>
  );
  // Showing all the content put together
  const content = (
    <StyledResultsWrapper>
      <StyledSearchResults>
        {recentSearch}
        <StyledResults id="searchresults">
          {searchResults}
          {searchFilter}
        </StyledResults>
      </StyledSearchResults>
    </StyledResultsWrapper>
  );

  return (
    <ContentWrapper
      isStartSearch={isStartSearch}
      onClick={onClickHandler}
      role="search"
    >
      {header}
      <TextField
        {...getExtraProps(props, extraProps)}
        value={searchTerm}
        isSearchMode
        inputId="mainSearchInput"
        id="mainSearch"
        placeholder={searchPlaceholder}
        onChange={onChangeHandler}
        onClear={onClearHandler}
        extraProps={['autoComplete']}
        autoComplete="off"
      />
      {content}
    </ContentWrapper>
  );
};

SidebarSearch.propTypes = propTypes;
SidebarSearch.defaultProps = defaultProps;

/** @component */
export default withTheme(SidebarSearch);
