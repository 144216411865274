import React, { useState, useRef, useEffect } from 'react';
import styled from '@emotion/styled';
import FocusLock from 'react-focus-lock';
import { getExtraProps, withTheme, unit } from '@embracesbs/helpers';
import { propTypes, defaultProps } from './ContextModal.props';
import { webStyles } from './ContextModal.styles';
import useOutsideClick from './useOutsideClick';
import BodyPortal from './BodyPortal';

const popupJustifyAlignment = {
  left: () => `
    left: 0;
  `,
  center: (width) => `
    left: 50%;
    margin-left: -${unit(width / 2)} !important;
  `,
  right: () => `
    right: 0;
  `,
};

const popupAlignAlignment = {
  top: () => `
    bottom: 100%;
  `,
  centerUp: (justify) => `
    bottom: 0;
    ${justify}: 100%;
  `,
  centerDown: (justify) => `
    top: 0;
    ${justify}: 100%;
  `,
  bottom: () => `
    top: 100%;
  `,
};

const StyledContextModal = styled.div`
  ${({ width, theme, justify, hasArrow, align, shadowDepth, zIndex }) => `
    width: ${unit(width)};
    box-shadow: ${theme.boxShadow.dp[shadowDepth]};
    z-index: ${zIndex};
    ${popupJustifyAlignment[justify](width)}
    ${popupAlignAlignment[align](justify)}
    ${hasArrow ? webStyles.popupArrow(theme, unit, justify, align) : ``}
    ${webStyles.contextModal(theme, unit)}
    @keyframes fadeIn { from { opacity: 0; } }
    animation: fadeIn ${theme.transition.accordion};
  `};
`;

const Container = styled.div`
  position: relative;
`;
const TriggerContainer = styled.div`
  cursor: pointer;
`;

/** Returns the current overflows of the element */
const getElementOverflows = (element) => {
  const { top, right, bottom, left } = element.getBoundingClientRect();
  const { innerWidth, innerHeight } = window;

  return {
    top: top < 0,
    right: right > innerWidth,
    bottom: bottom > innerHeight,
    left: left < 0,
  };
};

/**
 * ContextModal component
 *
 * @param {array} extraProps - An array of strings which includes the extra prop keys
 * @param {node} children - The child elements to be shown inside the contextmodal
 * @param {node} trigger - the trigger to open the contextmodal
 * @param {number} width - the width of the contextmodal
 * @param {boolean} isOpen - Indicated if its open
 * @param {function} toggleOpen - callback for the open state
 * @param {object} popupProps - Characteristics for the Popup
 * @param {boolean} isCollapsedWhenClicked - Whether the popup is collapsed when clicked
 * @param {boolean} isCollapsedWhenScrolled - Whether the popup is collapsed when anything is scrolled
 * @param {boolean} isInAFixedContainer - Whether the contextmodal is inside a fixed container
 * @param {boolean} isPositionedInRoot - Whether the contextmodal is in the root element
 * @param {object} absoluteCoords - Optional absolute left and top coordinates
 */
const ContextModal = (props) => {
  const {
    extraProps,
    children,
    trigger,
    isOpen: isOpenProp,
    toggleOpen,
    popupProps,
    isCollapsedWhenClicked,
    isCollapsedWhenScrolled,
    isInAFixedContainer,
    forwardedRef,
    isPositionedInRoot,
    absoluteCoords,
  } = props;
  const [isOpen, setOpen] = useState(isOpenProp);
  const [portalProps, setPortalProps] = useState(null);
  const [modalOverflows, setModalOverflows] = useState(null);
  const containerRef = useRef(null);
  const triggerContainerRef = useRef(null);
  const modalRef = useRef(null);

  /** Toggle the aria-Expanded attribute on the context trigger */
  const toggleAriaExpanded = (openState) => {
    if (triggerContainerRef.current) {
      const tabbableElements = triggerContainerRef.current.querySelectorAll(
        'a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled]), *[tabindex]:not([tabindex="-1"]',
      );

      if (tabbableElements.length) {
        tabbableElements[0].setAttribute('aria-expanded', `${openState}`);
      }
    }
  };

  useEffect(() => {
    setOpen(isOpenProp);
    toggleAriaExpanded(isOpenProp);
  }, [isOpenProp]);

  useEffect(() => {
    const { top, left, width, height } =
      (containerRef.current && containerRef.current.getBoundingClientRect()) ||
      {};
    const { scrollTop, scrollLeft } = document.documentElement;

    if (absoluteCoords) {
      const { top: absTop, left: absLeft } = absoluteCoords;
      setPortalProps({ top: absTop + scrollTop, left: absLeft + scrollLeft });
    } else {
      setPortalProps({
        top: top + scrollTop,
        left: left + scrollLeft,
        paddingTop: width,
        paddingLeft: height,
      });
    }
  }, [containerRef.current, isOpen]);

  useEffect(() => {
    const newModalOverflows =
      modalRef.current && getElementOverflows(modalRef.current);

    setModalOverflows(newModalOverflows);
  }, [modalRef.current]);

  /** Collapses the modal */
  const collapseModal = () => {
    if (isOpen) {
      if (toggleOpen) {
        toggleOpen(false);
      } else {
        setOpen(false);
      }
    }
    toggleAriaExpanded(false);
  };

  useOutsideClick(
    (() => {
      if (!isPositionedInRoot && isCollapsedWhenClicked) return [];
      return isCollapsedWhenClicked ? [containerRef] : [containerRef, modalRef];
    })(),
    collapseModal,
  );

  useEffect(() => {
    window.addEventListener('resize', collapseModal);

    return () => window.removeEventListener('resize', collapseModal);
  }, [collapseModal]);

  useEffect(() => {
    if (isCollapsedWhenScrolled) {
      /** Collapses if any container is scrolled */
      const collapseIfScrolled = ({ target }) => {
        if (containerRef.current && target.contains(containerRef.current)) {
          collapseModal();
        }
      };

      window.addEventListener('scroll', collapseIfScrolled, true);

      return () => {
        window.removeEventListener('scroll', collapseIfScrolled, true);
      };
    }

    return () => {};
  }, [isCollapsedWhenScrolled, containerRef.current, collapseModal]);

  /** Toggle expanding of the context modal */
  const toggleExpand = (() => {
    // Used due to the function being called multiple times from a single click
    // The timeout makes sure that the isOpen variable holds the correct value
    let timeout;

    return () => {
      if (timeout) clearTimeout(timeout);
      toggleAriaExpanded(!isOpen);

      timeout = setTimeout(() => {
        if (toggleOpen) {
          toggleOpen(!isOpen);
        } else {
          setOpen(!isOpen);
        }
      }, 50);
    };
  })();

  useEffect(() => {
    let handleKeyboardNavigation;

    if (isOpen) {
      /** Handle keyboard navigation for the opened modal */
      handleKeyboardNavigation = (event) => {
        if (event.keyCode === 27) {
          collapseModal();
        }
      };

      // Add the event when the modal is open
      window.addEventListener('keydown', handleKeyboardNavigation);
    }

    // Remove the event when the modal is closed
    return () => {
      if (isOpen) {
        window.removeEventListener('keydown', handleKeyboardNavigation);
      }
    };
  }, [isOpen]);

  /** Handle expanding with keyDown events */
  const handleKeyDown = (event) => {
    if (!event) return;
    if (isOpen) event.preventDefault();

    if (event.keyCode === 13) {
      // Toggle expanding of the modal when you press the enter key
      toggleExpand();
    }
  };

  const currentPopupProps = { ...defaultProps.popupProps, ...popupProps };

  if (popupProps.width === 'auto') {
    currentPopupProps.width = modalRef.current?.scrollWidth ?? 0;
  }

  if (modalOverflows && Object.values(modalOverflows).some((value) => value)) {
    const oppositePositioningPairs = {
      top: 'bottom',
      bottom: 'top',
      centerUp: 'centerDown',
      centerDown: 'centerUp',
      left: 'right',
      right: 'left',
    };

    const { align, justify } = currentPopupProps;
    const { top, right, bottom, left } = modalOverflows;

    if (top || bottom) {
      currentPopupProps.align = oppositePositioningPairs[align];
    }
    if (right || left) {
      if (justify === 'center') {
        if (right) currentPopupProps.justify = oppositePositioningPairs.left;
        if (left) currentPopupProps.justify = oppositePositioningPairs.right;
      } else {
        currentPopupProps.justify = oppositePositioningPairs[justify];
      }
    }
  }

  /** Returns the ContextModal content */
  const getContextModalContent = () => {
    const contextModalContent = (
      <FocusLock disabled={!isOpen} returnFocus>
        <StyledContextModal
          ref={modalRef}
          {...currentPopupProps}
          {...getExtraProps(null, extraProps, 'Popup')}
        >
          {children}
        </StyledContextModal>
      </FocusLock>
    );

    if (isPositionedInRoot) {
      return (
        <BodyPortal
          ref={forwardedRef}
          {...portalProps}
          zIndex={currentPopupProps.zIndex}
          isInAFixedContainer={isInAFixedContainer}
        >
          {contextModalContent}
        </BodyPortal>
      );
    }

    return contextModalContent;
  };

  return (
    <Container
      {...getExtraProps(props, extraProps)}
      ref={containerRef}
      className="contextModal"
    >
      <TriggerContainer
        ref={triggerContainerRef}
        onClick={toggleExpand}
        onKeyDown={(e) => handleKeyDown(e)}
        {...getExtraProps(null, extraProps, 'Trigger')}
      >
        {trigger}
      </TriggerContainer>
      {isOpen && getContextModalContent()}
    </Container>
  );
};

ContextModal.propTypes = propTypes;
ContextModal.defaultProps = defaultProps;

/** @component */
export default withTheme(ContextModal);
