import React, { Suspense, lazy } from 'react';
import PropTypes from 'prop-types';
import ProgressIndicator from '@embracesbs/component-progressindicator';
import { theme } from '@embracesbs/helpers';
import {
  propTypes as InfoCardProps,
  defaultProps as InfoCardDefaultProps,
} from '@embracesbs/component-infocard';

const NavLink = lazy(() => import('./NavLink'));

/** NavLink default prop */
const NavLinkDefault = (props) => (
  <Suspense fallback={<ProgressIndicator />}>
    <NavLink {...props} />
  </Suspense>
);

export const propTypes = {
  ...InfoCardProps,
  /** List item ID */
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  /** The background color when the item is set to active */
  activeTabBackgroundColor: PropTypes.string,
  /** The color when the item is set to active */
  activeTabColor: PropTypes.string,
  /** The background color on hover */
  hoverBackgroundColor: PropTypes.string,
  /** Indicates if the inactive state should have an opacity of 0.5 */
  hasOpacity: PropTypes.bool,
  /** Indicates if the item is active */
  isActive: PropTypes.bool,
  /** Indicates if the item is selected */
  isSelected: PropTypes.bool,
  /** Indicates if it has divider */
  hasDivider: PropTypes.bool,
  /** Indicates if the list is disabled so that no futher interaction is allowed */
  isDisabled: PropTypes.bool,
  /** The childrens of the component */
  children: PropTypes.node,
  /** Navigation optional router */
  navLink: PropTypes.elementType,
  /** Props for the navigation link */
  navLinkProps: PropTypes.object,
  /** Handler for clicking on a list item */
  onItemClick: PropTypes.func,
  /** Extra props */
  extraProps: PropTypes.array,
  /** Whether the actions are visible only on hover */
  hasActionsVisibleOnHover: PropTypes.bool,
  /** The main text of the list item */
  title: PropTypes.string,
  /** The sub text of the list item */
  subTitle: PropTypes.string,
  /** The label of the list item */
  label: PropTypes.string,
};

export const defaultProps = {
  ...InfoCardDefaultProps,
  id: 1,
  activeTabBackgroundColor: theme.color.secondary$7,
  hoverBackgroundColor: theme.color.dark$9,
  activeTabColor: theme.color.secondary,
  hasOpacity: false,
  isActive: false,
  isSelected: false,
  hasDivider: false,
  isDisabled: false,
  children: null,
  navLink: NavLinkDefault,
  navLinkProps: {},
  extraProps: [],
  onItemClick: () => {},
  hasActionsVisibleOnHover: false,
  title: null,
  subTitle: null,
  label: null,
};
