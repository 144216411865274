import * as React from 'react';

function SvgLegalScale1(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M7.5 24a.75.75 0 010-1.5h3.75V5.872A2.243 2.243 0 019.878 4.5H6.464l3.207 6.415a.751.751 0 01-1.342.671L5.25 5.427l-3.079 6.158a.746.746 0 01-1.006.336.75.75 0 01-.336-1.006l3.75-7.5a1.671 1.671 0 01.068-.109.717.717 0 01.025-.033l.025-.028a.655.655 0 01.075-.071l.033-.025a.72.72 0 01.105-.066c.011-.007.025-.013.038-.019a.848.848 0 01.044-.017l.038-.013.045-.013.055-.01.04-.006L5.25 3h4.629A2.258 2.258 0 0112 1.5c.96 0 1.808.615 2.121 1.5h4.629a1.483 1.483 0 01.123.012l.048.009.037.01a1.387 1.387 0 01.128.049 1.084 1.084 0 01.133.087c.02.016.03.025.04.035l.036.035a.778.778 0 01.038.043l.023.03a.722.722 0 01.034.05l.026.044 3.756 7.512a.751.751 0 01-1.343.669L18.75 5.427l-3.079 6.158a.746.746 0 01-1.005.336.75.75 0 01-.336-1.006L17.536 4.5h-3.415a2.243 2.243 0 01-1.372 1.372V22.5h3.75A.75.75 0 0116.5 24h-9zM12 3a.75.75 0 100 1.5.75.75 0 000-1.5z" />
      <path d="M5.25 18.75a4.505 4.505 0 01-4.5-4.5.75.75 0 01.75-.75H9a.75.75 0 01.75.75c0 2.481-2.019 4.5-4.5 4.5zM2.344 15c.335 1.308 1.518 2.25 2.906 2.25s2.571-.942 2.906-2.25H2.344zM18.75 18.75a4.505 4.505 0 01-4.5-4.5.75.75 0 01.75-.75h7.5a.75.75 0 01.75.75c0 2.481-2.019 4.5-4.5 4.5zM15.844 15c.335 1.308 1.518 2.25 2.906 2.25s2.571-.942 2.906-2.25h-5.812z" />
    </svg>
  );
}

export default SvgLegalScale1;
