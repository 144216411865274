import React from 'react';
import styled from '@emotion/styled';
import Scrollbar from '@embracesbs/component-scrollbar';
import {
  getExtraProps,
  withTheme,
  unit,
  getSpacingString,
} from '@embracesbs/helpers';
import { webStyles } from './CardContent.styles';
import { propTypes, defaultProps } from './CardContent.props';

const StyledCardContent = styled.div`
  ${({ theme, padding }) => `
    ${webStyles.styledCardContent(theme, unit)};
    padding: ${padding};
  `}
`;

/** trackV */
const trackV = (args) => <div className="track track-vertical" {...args} />;

/**
 * Card content web component
 * @param {array} extraProps - An array of strings which includs the extra prop keys
 * @param {node} children - The child elements to be shown in the card content
 * @param {boolean} hasScrollbar - Whether the scroll bar should be present
 * @param {number|object} spacing - The padding around the card content
 */
const CardContent = (props) => {
  const { extraProps, children, hasScrollbar, spacing } = props;
  const spacingCurrent = getSpacingString(spacing, defaultProps.spacing);

  return (
    <StyledCardContent
      {...getExtraProps(props, extraProps)}
      hasScrollbar={hasScrollbar}
      padding={spacingCurrent}
    >
      {hasScrollbar && (
        <Scrollbar
          extraProps={['className', 'renderTrackVertical']}
          className="scrollbar-tracks"
          renderTrackVertical={trackV}
          {...getExtraProps(null, extraProps, 'ScrollBar')}
        >
          {children}
        </Scrollbar>
      )}

      {!hasScrollbar && children}
    </StyledCardContent>
  );
};

CardContent.propTypes = propTypes;
CardContent.defaultProps = defaultProps;

export default withTheme(CardContent);
