import * as React from 'react';

function SvgSocialMediaTwitter(props) {
  return (
    <svg data-name="Outline Version" viewBox="0 0 24 24" {...props}>
      <path d="M.09 18.91a.75.75 0 00.43.84 20.55 20.55 0 008.29 1.93 13.13 13.13 0 007.26-2.06 12.73 12.73 0 005.76-10.56v-.45l1.79-1.29A.75.75 0 0023.47 6l-1.39-.6.78-1.7a.75.75 0 00-.92-1l-2.17.72a5.2 5.2 0 00-3.2-1.11 5.26 5.26 0 00-5.25 5.25v.36C8.6 8.24 6 6.85 3.14 3.57a.75.75 0 00-1.3.35A7.8 7.8 0 002.33 9L.94 8.82a.75.75 0 00-.85.89 5.38 5.38 0 002.63 3.92l-.65.24a.75.75 0 00-.41 1 4.78 4.78 0 003.56 2.61 11.82 11.82 0 01-4.39.8.75.75 0 00-.74.63zM8 17.67a.75.75 0 00-.38-1.35c-2.12-.17-3.37-.43-4.17-1.37l1.87-.68a.75.75 0 00-.17-1.45 3.69 3.69 0 01-3.29-2.33l2.14.32a.75.75 0 00.61-1.3 4.55 4.55 0 01-1.47-3.74c3 3 6 4.17 9.09 3.53a.75.75 0 00.6-.73v-1a3.73 3.73 0 016.27-2.75.75.75 0 00.75.16l.93-.31-.38.83a.75.75 0 000 .59c.09.23.11.28 1.28.79l-1 .74a.75.75 0 00-.31.61v.83a11.21 11.21 0 01-5.07 9.3c-3 2-6.95 2.33-11.22 1.09A11.11 11.11 0 008 17.67z" />
    </svg>
  );
}

export default SvgSocialMediaTwitter;
