import * as React from 'react';

function SvgRealEstateActionHouseDollar(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M5.25 20.498A2.252 2.252 0 013 18.248v-7.5a.75.75 0 011.5 0v7.5c0 .414.336.75.75.75h3a.75.75 0 010 1.5h-3zM.75 9.998a.75.75 0 01-.484-1.323l8.781-7.43A2.255 2.255 0 0110.5.711c.53 0 1.046.19 1.454.534l7.279 6.159a.746.746 0 01.088 1.057.751.751 0 01-1.057.088L10.985 2.39a.749.749 0 00-.969-.001L1.234 9.82a.747.747 0 01-.484.178zM18.75 5.498a.75.75 0 01-.75-.75v-2.25h-2.25a.75.75 0 010-1.5h3a.75.75 0 01.75.75v3a.75.75 0 01-.75.75zM17.25 21.248a.75.75 0 01-.75-.75h-.75a.75.75 0 010-1.5h2.031a.589.589 0 00.548-.371.594.594 0 00-.329-.771l-2.062-.825a2.083 2.083 0 01-1.312-1.94c0-.559.217-1.084.612-1.479a2.079 2.079 0 011.262-.603v-.011a.75.75 0 011.5 0h.75a.75.75 0 010 1.5h-2.033a.588.588 0 00-.591.593.59.59 0 00.371.549l2.063.825a2.094 2.094 0 011.161 2.722 2.08 2.08 0 01-1.721 1.3v.011a.75.75 0 01-.75.75z" />
      <path d="M17.25 23.498c-3.722 0-6.75-3.028-6.75-6.75s3.028-6.75 6.75-6.75S24 13.026 24 16.748s-3.028 6.75-6.75 6.75zm0-12c-2.895 0-5.25 2.355-5.25 5.25s2.355 5.25 5.25 5.25 5.25-2.355 5.25-5.25-2.355-5.25-5.25-5.25z" />
    </svg>
  );
}

export default SvgRealEstateActionHouseDollar;
