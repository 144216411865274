import PropTypes from 'prop-types';
import { webPropTypes as avatarPropTypes } from '@embracesbs/component-avatar';
import { webPropTypes as iconPropTypes } from '@embracesbs/component-icon';
import { webPropTypes as imagePropTypes } from '@embracesbs/component-image';
import { theme } from '@embracesbs/helpers';

const basePropTypes = {
  /** Force to align all child components to the top */
  alignTop: PropTypes.bool,
  /** Adds an image to the left side of the infocard */
  image: PropTypes.shape({ ...imagePropTypes }),
  /** Adds an avatar to the left side of the infocard */
  avatar: PropTypes.shape({ ...avatarPropTypes }),
  /** Adds an icon to the left side of the infocard */
  icon: PropTypes.shape({ ...iconPropTypes }),
  /** Adds a title to the center of the infocard */
  title: PropTypes.string,
  /** The color of the title */
  titleColor: PropTypes.oneOf(Object.keys(theme.color)),
  /** Whether the title should be on a single line */
  hasSingleLineTitle: PropTypes.bool,
  /** Adds a subtitle to the center of the infocard */
  subTitle: PropTypes.string,
  /** The color of the subtitle */
  subTitleColor: PropTypes.oneOf(Object.keys(theme.color)),
  /** Adds a label to the center of the infocard */
  label: PropTypes.string,
  /** Node for generating action item on the right side of the item */
  action: PropTypes.node,
  /** The text of the optional badge */
  badgeText: PropTypes.string,
  /** The color key of the optional badge */
  badgeColor: PropTypes.string,
};

export const webPropTypes = {
  ...basePropTypes,
  /** Defines padding around the component */
  spacing: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.shape({
      bottom: PropTypes.number,
      left: PropTypes.number,
      right: PropTypes.number,
      top: PropTypes.number,
    }),
  ]),
  /** Add's an animation to the component */
  animation: PropTypes.string,
  /** Whether the actions are visible only on hover */
  hasActionsVisibleOnHover: PropTypes.bool,
};

export const nativePropTypes = {
  ...basePropTypes,
};

const baseDefaultProps = {
  alignTop: false,
  icon: null,
  image: null,
  avatar: null,
  title: null,
  titleColor: 'dark',
  hasSingleLineTitle: true,
  subTitle: null,
  subTitleColor: 'dark$3',
  label: null,
  action: null,
  badgeText: null,
  badgeColor: null,
};

export const webDefaultProps = {
  spacing: 12,
  animation: null,
  hasActionsVisibleOnHover: false,
  ...baseDefaultProps,
};

export const nativeDefaultProps = {
  ...baseDefaultProps,
};
