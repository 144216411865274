import * as React from 'react';

function SvgSharepoint(props) {
  return (
    <svg viewBox="0 0 32 32" {...props}>
      <g data-name="STYLE = COLOR">
        <circle cx={15} cy={9.5} r={9.5} fill="#036c70" />
        <circle cx={23.875} cy={17.875} r={8.125} fill="#1a9ba1" />
        <circle cx={16} cy={25.5} r={6.5} fill="#37c6d0" />
        <path
          d="M16.667 7H5.833A9.506 9.506 0 0015 19c.277 0 .551-.013.823-.036l.005.038A6.5 6.5 0 009.5 25.5q0 .252.019.5h7.148A1.337 1.337 0 0018 24.667V8.333A1.337 1.337 0 0016.667 7z"
          opacity={0.1}
        />
        <path
          d="M15.667 8H5.617A9.505 9.505 0 0015 19c.277 0 .551-.013.823-.036l.005.038A6.505 6.505 0 009.674 27h5.993A1.337 1.337 0 0017 25.667V9.333A1.337 1.337 0 0015.667 8z"
          opacity={0.2}
        />
        <path
          d="M15.667 8H5.617A9.505 9.505 0 0015 19c.277 0 .551-.013.823-.036l.005.038A6.5 6.5 0 009.518 25h6.149A1.337 1.337 0 0017 23.667V9.333A1.337 1.337 0 0015.667 8z"
          opacity={0.2}
        />
        <path
          d="M14.667 8h-9.05A9.505 9.505 0 0015 19c.277 0 .551-.013.823-.036l.005.038A6.5 6.5 0 009.518 25h5.149A1.337 1.337 0 0016 23.667V9.333A1.337 1.337 0 0014.667 8z"
          opacity={0.2}
        />
        <path
          data-name="Back Plate"
          d="M1.333 8h13.334A1.333 1.333 0 0116 9.333v13.334A1.333 1.333 0 0114.667 24H1.333A1.333 1.333 0 010 22.667V9.333A1.333 1.333 0 011.333 8z"
          fill="#03787c"
        />
        <path
          d="M5.67 15.825a2.645 2.645 0 01-.822-.87 2.361 2.361 0 01-.287-1.19 2.29 2.29 0 01.533-1.541A3.142 3.142 0 016.51 11.3a5.982 5.982 0 011.935-.3 7.354 7.354 0 012.549.357v1.8a3.986 3.986 0 00-1.153-.471 5.596 5.596 0 00-1.349-.162 2.926 2.926 0 00-1.386.293.91.91 0 00-.549.833.844.844 0 00.233.59 2.122 2.122 0 00.627.448q.394.196 1.176.52a1.232 1.232 0 01.169.067 9.697 9.697 0 011.483.732 2.654 2.654 0 01.877.883 2.558 2.558 0 01.317 1.332 2.48 2.48 0 01-.499 1.605 2.789 2.789 0 01-1.335.896A6.049 6.049 0 017.703 21a10.028 10.028 0 01-1.722-.142 5.912 5.912 0 01-1.4-.404v-1.902a4.5 4.5 0 001.416.675 5.513 5.513 0 001.558.25 2.68 2.68 0 001.413-.3.947.947 0 00.475-.847.904.904 0 00-.266-.648 2.704 2.704 0 00-.735-.512q-.469-.236-1.386-.62a7.86 7.86 0 01-1.386-.725z"
          fill="#fff"
        />
        <path fill="none" d="M0 0h32v32H0z" />
      </g>
    </svg>
  );
}

export default SvgSharepoint;
