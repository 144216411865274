import PropTypes from 'prop-types';

export const propTypes = {
  /** The nodes that contains the recents searches */
  recentSearchChildren: PropTypes.node,
  /** The nodes containing the search results */
  searchResultsChildren: PropTypes.node,
  /** The nodes containing the search filters */
  searchFilterChildren: PropTypes.node,
  /** Attach function to the onChange handler */
  onChange: PropTypes.func,
  /** An array of strings which includes the extra prop keys */
  extraProps: PropTypes.array,
  /** The search term */
  searchTerm: PropTypes.string,
  /** The search input placeholder */
  searchPlaceholder: PropTypes.string,
  /** Label for no recent searches */
  labelNoRecentSearches: PropTypes.string,
  /** Label for no search results */
  labelNoSearchResults: PropTypes.string,
  /** Label for no filter available */
  labelNoFilterAvailable: PropTypes.string,
  /** Label for the search header */
  labelSearchHeader: PropTypes.string,
};

export const defaultProps = {
  recentSearchChildren: null,
  searchResultsChildren: null,
  searchFilterChildren: null,
  onChange: () => {},
  extraProps: [],
  searchTerm: '',
  searchPlaceholder: 'Search',
  labelNoRecentSearches: 'You have no recent searches..',
  labelNoSearchResults: 'No search results found..',
  labelNoFilterAvailable: 'Oops.. No search filter available',
  labelSearchHeader: 'Search',
};
