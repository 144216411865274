import * as React from 'react';

function SvgColorBrush3(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M4.385 23.992a7.709 7.709 0 01-3.328-.771 1.29 1.29 0 01-.667-.74 1.295 1.295 0 01.052-.996c.14-.294.384-.524.686-.646 1.362-.552 1.508-1.76 1.678-3.16.094-.773.182-1.502.493-2.138a4.728 4.728 0 014.212-2.603c.7 0 1.375.153 2.007.456 2.472 1.208 3.286 3.609 2.075 6.12-1.074 2.225-3.894 4.478-7.208 4.478zM2.098 22.04c.749.3 1.519.452 2.295.452 2.69 0 4.979-1.826 5.849-3.63.854-1.772.352-3.273-1.378-4.118a3.117 3.117 0 00-1.355-.307 3.21 3.21 0 00-2.863 1.766c-.198.406-.272 1.013-.35 1.657-.168 1.374-.392 3.222-2.198 4.18zM13.887 14.872a.749.749 0 01-.589-1.215l8.778-11.116a.605.605 0 00-.07-.862.594.594 0 00-.382-.137.598.598 0 00-.436.184l-9.903 9.877a.748.748 0 01-1.061-.002.748.748 0 01.001-1.06L20.117.675a2.108 2.108 0 012.846-.152c.432.358.699.863.752 1.423a2.086 2.086 0 01-.473 1.538l-8.767 11.103a.743.743 0 01-.588.285z" />
    </svg>
  );
}

export default SvgColorBrush3;
