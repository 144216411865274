import * as React from 'react';

function SvgLayersOff(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M20.781 19.65a.746.746 0 01-.714-.979.745.745 0 01.371-.438l1.903-.98-3.438-1.781-.569.294a.75.75 0 11-.689-1.333l.855-.442a.7.7 0 01.115-.06l3.726-1.927L18.9 10.22l-3.357 1.735a.75.75 0 11-.689-1.333s3.701-1.914 3.721-1.923l3.766-1.947-9.979-5.165a.807.807 0 00-.728.001L8.956 2.974a.75.75 0 11-.689-1.332L10.946.256A2.301 2.301 0 0113.05.255L23.26 5.54c.252.13.454.332.585.584a1.37 1.37 0 01-.584 1.842l-2.729 1.411 2.729 1.415a1.37 1.37 0 010 2.426l-2.726 1.41 2.726 1.412a1.37 1.37 0 010 2.426l-2.137 1.101a.76.76 0 01-.343.083zM11.998 23.997a2.3 2.3 0 01-1.052-.255L.737 18.46a1.36 1.36 0 01-.585-.585 1.359 1.359 0 01-.089-1.042 1.36 1.36 0 01.674-.8l2.729-1.409-2.728-1.411a1.36 1.36 0 01-.585-.585 1.357 1.357 0 01-.089-1.041 1.36 1.36 0 01.674-.8l2.731-1.413L.737 7.96a1.355 1.355 0 01-.585-.585 1.351 1.351 0 01-.088-1.041c.11-.348.35-.632.673-.8l2.136-1.101a.747.747 0 011.01.324.745.745 0 01-.323 1.01l-1.903.98 5.807 3.007a.746.746 0 01.321 1.011.747.747 0 01-1.011.321L5.1 10.219 1.656 12l9.979 5.162a.79.79 0 00.667.027.746.746 0 01.981.404.744.744 0 01.002.573.742.742 0 01-.405.407 2.268 2.268 0 01-.883.177c-.363 0-.727-.089-1.051-.256l-5.848-3.025-3.442 1.778 9.978 5.162a.79.79 0 00.727 0l2.679-1.386a.75.75 0 11.689 1.332l-2.679 1.386a2.295 2.295 0 01-1.052.256zM20.249 24a.752.752 0 01-.605-.307l-16.5-22.5A.747.747 0 013.748 0c.238 0 .464.115.605.306l16.5 22.5A.751.751 0 0120.249 24z" />
    </svg>
  );
}

export default SvgLayersOff;
