import * as React from 'react';

function SvgLocationOffTarget(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M18.983 15.491a.746.746 0 01-.698-1.024 6.74 6.74 0 00.469-2.398c-.002-.025-.004-.047-.004-.069a.6.6 0 01.005-.074 6.725 6.725 0 00-.563-2.624 6.703 6.703 0 00-3.719-3.586 6.735 6.735 0 00-2.414-.469.583.583 0 01-.115-.001 6.719 6.719 0 00-2.411.469.745.745 0 01-.972-.424.747.747 0 01.423-.972 8.234 8.234 0 012.265-.539V.75a.75.75 0 011.501 0v3.029c.777.071 1.54.252 2.271.54a8.195 8.195 0 014.545 4.383c.355.815.575 1.67.655 2.548h3.029a.75.75 0 010 1.5h-3.03a8.262 8.262 0 01-.539 2.265.745.745 0 01-.698.476zM21.75 22.5a.743.743 0 01-.53-.22L1.72 2.78c-.142-.141-.22-.33-.22-.53s.078-.389.22-.53c.141-.142.33-.22.53-.22s.389.078.53.22l19.5 19.5a.744.744 0 010 1.06.749.749 0 01-.53.22zM12 24a.75.75 0 01-.75-.75v-3.029a8.232 8.232 0 01-2.271-.54 8.267 8.267 0 01-5.194-6.931H.75a.75.75 0 010-1.5h3.033c.07-.778.25-1.538.536-2.265a.746.746 0 01.973-.424.746.746 0 01.423.972A6.7 6.7 0 005.25 12l-.001.025a6.717 6.717 0 004.278 6.259 6.724 6.724 0 002.412.469c.021-.002.04-.004.06-.004l.056.003a6.71 6.71 0 002.41-.469.745.745 0 01.972.424.744.744 0 01-.423.971c-.73.287-1.49.468-2.265.539v3.03A.75.75 0 0112 24z" />
    </svg>
  );
}

export default SvgLocationOffTarget;
