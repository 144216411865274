import styled from '@emotion/styled';
import Text from '@embracesbs/component-text';
import Image from '@embracesbs/component-image';
import { transparentize } from 'polished';
/** @jsx jsx */
import { jsx, css, keyframes } from '@emotion/core';
import { getExtraProps, withTheme, unit } from '@embracesbs/helpers';
import { propTypes, defaultProps } from './ProgressIndicator.props';
import { webStyles } from './ProgressIndicator.styles';
import scale from './assets/scale.svg';
import pointer from './assets/pointer.svg';

const StyledLinearProgressWrapper = styled.div`
  ${() => `
    ${webStyles.styledLinearProgressWrapper()}
  `}
`;

const StyledLinearProgressIndicator = styled.div`
  ${({ theme, size, hasBgColor, progress, progressColor, interval }) => `
    ${webStyles.styledLinearProgressIndicator(
      theme,
      size,
      hasBgColor,
      progress,
      progressColor,
      interval,
    )}
  `}
`;

const StyledLinearProgressText = styled.div`
  ${({ position }) => `
    ${webStyles.styledLinearProgressText(position)}
  `}
`;

const StyledGaugeProgressIndicator = styled.div`
  ${({ progress }) => `
    ${webStyles.styledGaugeProgressIndicator(unit, progress)}
  `}
`;

const StyledCircularProgressIndicator = styled.div`
  ${({ size }) => webStyles.styledCircularProgressIndicator(unit, size)}
`;

const rotate = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

const cssRotation = css`
  animation: ${rotate} 1.2s ease-in-out infinite;
`;

const animation = keyframes`
  50% { background-size: 75%; }
  100% { background-position: 125%; }
`;

const cssAnimation = css`
  animation: ${animation} 2s ease-in-out infinite;
`;

const fillAnimation = keyframes`
  0% { background-size: 0%; }
  100% { background-size: 100%; }
`;

/** Fill Animation */
const cssFillAnimation = (interval) => css`
  animation: ${fillAnimation} ${interval}s linear infinite;
  background-position: 0 0;
`;

/** Returns the CircularProgressIndicatorStyles */
const getCircularProgressIndicatorStyles = (theme, progressColor) => ({
  default: {
    progress: progressColor,
    background: transparentize(0.8, theme.color.dark),
  },
  hasBgColor: {
    progress: theme.color.light,
    background: 'rgba(255, 255, 255, 0.4)',
  },
});

/**
 * ProgressIndicator web component
 * @param {array} extraProps - An array of strings which includes the extra prop keys
 * @param {boolean} hasBgColor - Whether the loader is shown on color background
 * @param {number} progress - The size of the progress fill, can be used to create a determinate progress indicator
 * @param {string} progressColor - The color of the progress fill
 * @param {number} size - Size of the progress indicator
 * @param {string} variant - Variant of the progress indicator
 * @param {string} text - Text to show as an indication of progress (linear)
 * @param {string} textStyle - Text style (linear)
 * @param {string} textColor - Text color (linear)
 * @param {string} interval - Time between filling the progressbar from 0 to 100%
 */
const ProgressIndicator = (props) => {
  const {
    theme,
    extraProps,
    hasBgColor,
    progress,
    progressColor,
    size,
    variant,
    text,
    textStyle,
    textColor,
    interval,
  } = props;

  if (variant === 'linear') {
    return (
      <StyledLinearProgressWrapper>
        <StyledLinearProgressIndicator
          {...getExtraProps(props, extraProps)}
          hasBgColor={hasBgColor}
          progress={progress}
          size={size}
          progressColor={progressColor}
          css={progress || progress === 0 ? `` : cssAnimation}
        />
        {text && (
          <StyledLinearProgressText position={progress}>
            <Text
              content={text}
              textColor={textColor}
              textStyle={textStyle}
              spacing={{ top: theme.spacing.$2Number }}
            />
          </StyledLinearProgressText>
        )}
      </StyledLinearProgressWrapper>
    );
  }

  if (variant === 'fill') {
    return (
      <StyledLinearProgressWrapper>
        <StyledLinearProgressIndicator
          {...getExtraProps(props, extraProps)}
          hasBgColor={hasBgColor}
          progress={progress}
          size={size}
          progressColor={progressColor}
          interval={interval}
          css={cssFillAnimation(interval || 10)}
        />
      </StyledLinearProgressWrapper>
    );
  }

  if (variant === 'gauge') {
    const rotation = 180 + progress * 1.8;
    return (
      <StyledGaugeProgressIndicator
        {...getExtraProps(props, extraProps)}
        progress={rotation}
        size={size}
      >
        <Image src={scale} alt="" />
        <Image src={pointer} alt="" />
      </StyledGaugeProgressIndicator>
    );
  }
  const radius = size / 2;
  const stroke = radius / 8;
  const normalizedRadius = radius - stroke * 2;
  const circumference = normalizedRadius * 2 * Math.PI;

  const strokeDashoffset =
    circumference - ((progress === null ? 30 : progress) / 100) * circumference;
  const circularProgressIndicatorStyles = getCircularProgressIndicatorStyles(
    theme,
    progressColor,
  );

  return (
    <StyledCircularProgressIndicator
      {...getExtraProps(props, extraProps)}
      css={cssRotation}
      size={size}
    >
      <svg height={radius * 2} width={radius * 2}>
        <circle
          className="circle"
          stroke={
            hasBgColor
              ? circularProgressIndicatorStyles.hasBgColor.progress
              : circularProgressIndicatorStyles.default.progress
          }
          fill="transparent"
          strokeWidth={stroke}
          strokeDasharray={`${circumference} ${circumference}`}
          style={{ strokeDashoffset }}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
        />
        <circle
          stroke={
            hasBgColor
              ? circularProgressIndicatorStyles.hasBgColor.background
              : circularProgressIndicatorStyles.default.background
          }
          fill="transparent"
          strokeWidth={stroke}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
        />
      </svg>
    </StyledCircularProgressIndicator>
  );
};

ProgressIndicator.propTypes = propTypes;
ProgressIndicator.defaultProps = defaultProps;

export default withTheme(ProgressIndicator);
