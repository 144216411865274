import * as React from 'react';

function SvgTeamIdea(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M6.581 23.95a.746.746 0 01-.535-.224A3.72 3.72 0 003.377 22.6c-.786 0-1.543.245-2.19.709a.753.753 0 01-1.047-.172.75.75 0 01.172-1.046 5.27 5.27 0 011.36-.705A3.388 3.388 0 010 18.478c0-1.861 1.514-3.375 3.375-3.375s3.375 1.514 3.375 3.375a3.383 3.383 0 01-1.67 2.906 5.243 5.243 0 012.036 1.29.748.748 0 01-.535 1.276zm-3.206-7.347c-1.034 0-1.875.841-1.875 1.875s.841 1.875 1.875 1.875 1.875-.841 1.875-1.875-.841-1.875-1.875-1.875zM20.625 21.853c-1.861 0-3.375-1.514-3.375-3.375s1.514-3.375 3.375-3.375S24 16.617 24 18.478s-1.514 3.375-3.375 3.375zm0-5.25c-1.034 0-1.875.841-1.875 1.875s.841 1.875 1.875 1.875 1.875-.841 1.875-1.875-.841-1.875-1.875-1.875z" />
      <path d="M17.511 23.855a.756.756 0 01-.541-.23.752.752 0 01.022-1.061 5.227 5.227 0 013.634-1.459c1.105 0 2.164.341 3.062.986.163.117.27.29.302.488a.747.747 0 01-.74.871.75.75 0 01-.438-.141 3.732 3.732 0 00-2.187-.704c-.972 0-1.894.37-2.595 1.042a.748.748 0 01-.519.208zM12 21.853c-2.275 0-4.125-1.851-4.125-4.125s1.85-4.125 4.125-4.125c2.274 0 4.125 1.851 4.125 4.125s-1.85 4.125-4.125 4.125zm0-6.75c-1.447 0-2.625 1.177-2.625 2.625s1.178 2.625 2.625 2.625c1.448 0 2.625-1.177 2.625-2.625S13.448 15.103 12 15.103z" />
      <path d="M14.723 24a.745.745 0 01-.336-.08 5.332 5.332 0 00-4.725-.024.747.747 0 01-1.004-.343A.75.75 0 019 22.549a6.842 6.842 0 016.06.031.752.752 0 01.333 1.007.746.746 0 01-.67.413zM10.5 10.603a.75.75 0 01-.75-.75V8.496A4.477 4.477 0 017.5 4.598c0-1.202.469-2.332 1.319-3.182A4.473 4.473 0 0112 .099c1.203 0 2.333.469 3.183 1.319.85.85 1.318 1.98 1.317 3.182a4.477 4.477 0 01-2.25 3.897v1.356a.75.75 0 01-.75.75h-3zm2.25-1.5V8.036c0-.298.177-.568.45-.688a2.997 2.997 0 00.922-4.87 2.978 2.978 0 00-2.121-.879L12 1.224v.375c-.801 0-1.554.312-2.121.878a3 3 0 00.921 4.871c.273.12.45.389.45.688v1.067h1.5zM10.5 12.853a.75.75 0 010-1.5h3a.75.75 0 010 1.5h-3zM19.811 6a.75.75 0 010-1.5h1.5a.75.75 0 010 1.5h-1.5zM20.561 10.5a.743.743 0 01-.53-.22L18.97 9.219c-.142-.141-.22-.33-.22-.53s.078-.389.22-.53a.749.749 0 011.06 0l1.061 1.061c.142.141.22.33.22.53s-.078.389-.22.53-.33.22-.53.22zM19.5 2.56a.75.75 0 01-.531-1.28L20.03.22a.748.748 0 011.062 0 .75.75 0 010 1.06L20.03 2.34a.743.743 0 01-.53.22zM2.689 6a.75.75 0 010-1.5h1.5a.75.75 0 010 1.5h-1.5zM3.439 10.5a.743.743 0 01-.53-.22c-.142-.141-.22-.33-.22-.53s.078-.389.22-.53L3.97 8.159a.744.744 0 011.06 0c.142.141.22.33.22.53s-.078.389-.22.53L3.969 10.28a.743.743 0 01-.53.22zM4.5 2.56a.743.743 0 01-.53-.22L2.909 1.28A.75.75 0 113.969.22L5.03 1.28a.75.75 0 01-.53 1.28z" />
    </svg>
  );
}

export default SvgTeamIdea;
