import * as React from 'react';

function SvgOfficeFileTxt1(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M2.247 23.998a2.252 2.252 0 01-2.25-2.25v-19.5a2.252 2.252 0 012.25-2.25h10.629c.601 0 1.165.234 1.59.658l5.872 5.872c.425.425.659.99.659 1.59v4.629a.75.75 0 01-1.5 0v-3.75h-5.25a2.252 2.252 0 01-2.25-2.25v-5.25h-9.75a.75.75 0 00-.75.75v19.5c0 .414.336.75.75.75h3a.75.75 0 010 1.5h-3zm11.25-17.25c0 .414.336.75.75.75h4.939l-5.689-5.689v4.939zM21.747 23.998a.75.75 0 01-.75-.75v-6.75h-.75a.75.75 0 010-1.5h3a.75.75 0 010 1.5h-.75v6.75a.75.75 0 01-.75.75z" />
      <path d="M9.747 23.998a.75.75 0 01-.75-.75v-6.75h-.75a.75.75 0 010-1.5h3a.75.75 0 010 1.5h-.75v6.75a.75.75 0 01-.75.75zM17.247 23.998a.744.744 0 01-.696-.472l-.804-2.009-.804 2.009a.745.745 0 01-.975.418.74.74 0 01-.41-.401.742.742 0 01-.007-.574l1.389-3.471-1.389-3.472a.747.747 0 01.418-.975.747.747 0 01.975.418l.803 2.009.804-2.009a.747.747 0 01.975-.418.745.745 0 01.411.401.742.742 0 01.007.574l-1.389 3.471 1.389 3.472a.747.747 0 01-.418.975.74.74 0 01-.279.054z" />
    </svg>
  );
}

export default SvgOfficeFileTxt1;
