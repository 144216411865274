import { css, keyframes } from '@emotion/core';
import { unit } from '@embracesbs/helpers';

const baseStyles = {};

/**
 * Animations
 */
const SlideElementDown = keyframes`
  0%{
    opacity: 0;
    transform: translate3d(0, -${unit(20)}, 0);
  }
  100%{
    opacity: 1;
    visibility: visible;
    transform: translate3d(0);
  }
`;
const colorFade = keyframes`
  0%{
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;
const showAnimation = keyframes`
  0%{
    opacity: 0;
  }
  100% {
    opacity: 0.4;
  }
`;

export const webStyles = {
  default: (theme) => css`
    #mainSearch {
      .activeSearch &,
      .activeSearchResults & {
        &:before {
          content: '';
          width: ${theme.layout.sidebarWidth};
          height: 200vh;
          top: 0;
          position: fixed;
          z-index: -1;
        }
        &:after {
          content: '';
          background: #000;
          opacity: 0;
          width: 0;
          height: 200vh;
          position: fixed;
          transform: translate3d(0, -${unit(8)}, 0);
          top: 0;
          left: 0;
          z-index: -2;
        }
      }
    }
  `,

  filter: (theme) => css`
    animation: ${colorFade} 0.4s ease;
    animation-delay: 0.2s;
    animation-fill-mode: forwards;
    width: ${unit(300)};
    margin-top: ${unit(-104)};
    background: ${theme.color.dark$9};
    padding: ${unit(10)};
    opacity: 0;
    border-left: ${unit(1)} solid ${theme.color.dark$6};
    .activeSearchResults & {
      p {
        color: ${theme.color.dark$3};
        user-select: none;
      }
      span {
        color: ${theme.color.dark};
        user-select: none;
      }
      > div > div > div,
      button {
        background: #f5f5f6;
      }
    }
  `,

  resultswrapper: (theme) => css`
    animation: ${SlideElementDown} 0.4s ease;
    animation-delay: 0.4s;
    animation-fill-mode: forwards;
    opacity: 0;
    flex-grow: 1;
    margin: 0 ${unit(10)} ${unit(10)} 0;
    .activeSearchResults & {
      p {
        color: ${theme.color.dark$3};
        user-select: none;
      }
      li .infoCardContent div {
        color: ${theme.color.dark};
        user-select: none;
        + div {
          color: ${theme.color.dark$3};
        }
      }
      li {
        &:hover {
          &:before {
            background: ${theme.color.dark$10};
          }
        }
        &:hover {
          .infoCardContent div {
            color: ${theme.color.dark};
            + div {
              color: ${theme.color.dark$3};
            }
          }
        }
      }
    }
  `,

  searchresultswrapper: () => css`
    display: flex;
    flex-grow: 1;
    padding: 0 0 0 ${unit(10)};
    margin-top: ${unit(160)};
  `,

  searchresults: () => css`
    display: none;
    flex-grow: 1;
    flex-wrap: wrap;
  `,

  recentsearches: (theme) => css`
    display: none;
    flex-grow: 1;
    animation: ${SlideElementDown} 0.4s ease;
    animation-delay: 0.4s;
    animation-fill-mode: forwards;
    opacity: 0;
    .activeSearch & {
      p {
        color: ${theme.color.dark$3};
        user-select: none;
      }
      li .infoCardContent div {
        color: ${theme.color.dark};
        user-select: none;
      }
      li {
        &:hover {
          &:before {
            background: ${theme.color.dark$10};
          }
        }
        &:hover {
          .infoCardContent div {
            color: ${theme.color.dark};
          }
        }
      }
    }
  `,

  mainContent: (theme) => css`
    margin: 0;
    padding: 0;
    border: none;

    .searchHeader {
      display: none;
      align-items: flex-start;
      justify-content: flex-start;
      z-index: 3;
      position: absolute;
      top: ${unit(8)};
      left: ${unit(4)};
      width: 100%;
      height: ${unit(48)};
      border-bottom: ${unit(1)} solid ${theme.color.dark$6};

      .activeSearch &,
      .activeSearchResults & {
        display: flex;
      }
    }

    .activeSearch & {
      #recentSearches {
        display: flex;
      }
    }

    #mainSearch {
      max-width: ${unit(745)};

      .activeSearch &,
      .activeSearchResults & {
        div {
          background: ${theme.color.dark$9};
        }

        > * {
          z-index: 3;
          position: relative;
          background: ${theme.color.dark$9};
        }
        svg {
          color: ${theme.color.dark$2};
          fill: ${theme.color.dark$2};
        }
        input {
          background: none;
          max-width: ${unit(705)};
          color: ${theme.color.dark$2};
          &::placeholder {
            color: ${theme.color.dark$2};
          }
        }
        &:after {
          animation: ${showAnimation} 0.4s ease;
          animation-fill-mode: forwards;
          width: calc(100vw + ${unit(8)});
          opacity: 0;
        }
      }
    }
  `,
  results: (theme) => css`
    display: flex;
    visibility: hidden;
    width: ${theme.layout.sidebarWidth};
    height: 100%;
    animation: ${colorFade} 0.4s ease;
    animation-fill-mode: forwards;
    opacity: 0;
    background: #fff;
    top: 0;
    left: 0;
    position: absolute;
    z-index: 1;

    .activeSearchResults & {
      #recentSearches {
        display: none;
      }
      #searchresults {
        display: flex;
      }
    }

    .activeSearch &,
    .activeSearchResults & {
      transition: 0.4s;
      width: ${unit(396)};
      visibility: visible;
    }
    .activeSearchResults & {
      min-width: ${unit(396)};
      width: ${unit(1080)};
      &:before {
        min-width: ${unit(396)};
        width: ${unit(1080)};
      }
    }
  `,
};

export const nativeStyles = {
  ...baseStyles,
};
