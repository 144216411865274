import * as React from 'react';

function SvgHierarchy(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M3.75 24a2.252 2.252 0 01-2.25-2.25v-1.5A2.252 2.252 0 013.75 18h10.5a2.252 2.252 0 012.25 2.25V21h3.75a.75.75 0 00.75-.75V15h-4.629a2.26 2.26 0 01-2.121 1.5H3.75a2.252 2.252 0 01-2.25-2.25v-1.5a2.252 2.252 0 012.25-2.25h10.5a2.252 2.252 0 012.25 2.25v.75H21v-6h-4.629A2.258 2.258 0 0114.25 9H3.75A2.252 2.252 0 011.5 6.75v-1.5A2.252 2.252 0 013.75 3h10.5a2.252 2.252 0 012.25 2.25V6H21V.75a.75.75 0 011.5 0v19.5a2.252 2.252 0 01-2.25 2.25h-3.879A2.258 2.258 0 0114.25 24H3.75zm0-4.5a.75.75 0 00-.75.75v1.5c0 .414.336.75.75.75h10.5a.75.75 0 00.75-.75v-1.5a.75.75 0 00-.75-.75H3.75zm0-7.5a.75.75 0 00-.75.75v1.5c0 .414.336.75.75.75h10.5a.75.75 0 00.75-.75v-1.5a.75.75 0 00-.75-.75H3.75zm0-7.5a.75.75 0 00-.75.75v1.5c0 .414.336.75.75.75h10.5a.75.75 0 00.75-.75v-1.5a.75.75 0 00-.75-.75H3.75z" />
    </svg>
  );
}

export default SvgHierarchy;
