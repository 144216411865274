import * as React from 'react';

function SvgFolderAdd(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M2.447 19.5a.725.725 0 01-.231-.037A2.465 2.465 0 010 17.06V2.25A2.252 2.252 0 012.25 0h4.5C7.99 0 9 1.009 9 2.25V3h9.75C19.99 3 21 4.009 21 5.25V7.5h.75c.601 0 1.166.234 1.591.658a2.235 2.235 0 01.583 2.171.752.752 0 01-.919.531.747.747 0 01-.53-.918A.746.746 0 0021.75 9H7.879a.755.755 0 00-.722.542l-2.351 8.154a2.337 2.337 0 01-.108.304H8.25a.75.75 0 010 1.5H2.447zm-.197-18a.75.75 0 00-.75.75v14.8a.95.95 0 001.864.235l2.352-8.158A2.261 2.261 0 017.878 7.5H19.5V5.25a.75.75 0 00-.75-.75H8.25a.75.75 0 01-.75-.75v-1.5a.75.75 0 00-.75-.75h-4.5z" />
      <path d="M17.25 24c-3.722 0-6.75-3.028-6.75-6.75s3.028-6.75 6.75-6.75S24 13.528 24 17.25 20.972 24 17.25 24zm0-12C14.355 12 12 14.355 12 17.25s2.355 5.25 5.25 5.25 5.25-2.355 5.25-5.25S20.145 12 17.25 12z" />
      <path d="M17.25 21a.75.75 0 01-.75-.75V18h-2.25a.75.75 0 010-1.5h2.25v-2.25a.75.75 0 011.5 0v2.25h2.25a.75.75 0 010 1.5H18v2.25a.75.75 0 01-.75.75z" />
    </svg>
  );
}

export default SvgFolderAdd;
