import * as React from 'react';

function SvgCheckThick(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M6.6 23.9c-.6 0-1.3-.2-1.8-.6-.3-.2-.5-.4-.7-.7L.4 17.7c-.4-.5-.3-1.2.2-1.5.5-.4 1.2-.3 1.5.2l3.7 4.9c.1.1.1.2.2.2.2.2.5.2.8.2.3 0 .5-.2.6-.4L21.9.6c.3-.5 1-.6 1.5-.3s.6 1 .3 1.5L9.2 22.5c-.5.7-1.2 1.2-2.1 1.3-.1.1-.3.1-.5.1z" />
    </svg>
  );
}

export default SvgCheckThick;
