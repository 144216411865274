import * as React from 'react';

function SvgScissors2(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M4.5 23.999a4.505 4.505 0 01-4.5-4.5c0-1.389.631-2.678 1.732-3.541a.753.753 0 01.129-.101l.028-.017c.183-.131.378-.249.58-.352l5.748-3.49-5.748-3.49a4.547 4.547 0 01-.58-.352l-.028-.016a.735.735 0 01-.129-.1A4.474 4.474 0 010 4.499c0-2.481 2.019-4.5 4.5-4.5S9 2.018 9 4.499c0 1.998-1.33 3.723-3.172 4.295l3.834 2.328 13.199-8.014a.751.751 0 011.03.252.75.75 0 01-.252 1.03l-12.533 7.609 12.532 7.609c.171.104.292.268.339.463s.017.396-.087.567a.754.754 0 01-1.03.252L9.662 12.876l-3.834 2.328C7.67 15.776 9 17.501 9 19.499c0 2.481-2.018 4.5-4.5 4.5zm-1.785-6.905A3.015 3.015 0 001.5 19.499c0 1.654 1.346 3 3 3s3-1.346 3-3-1.346-3-3-3c-.451 0-.889.102-1.302.302l-.483.293zm.483-9.897c.413.2.851.302 1.302.302 1.654 0 3-1.346 3-3s-1.346-3-3-3-3 1.346-3 3c0 .941.454 1.838 1.215 2.405l.483.293z" />
    </svg>
  );
}

export default SvgScissors2;
