import { darken } from 'polished';
import React, { useState, useRef, useEffect, useLayoutEffect } from 'react';
import styled from '@emotion/styled';
import {
  getExtraProps,
  getSpacingObject,
  getSpacingString,
  withTheme,
  unit,
} from '@embracesbs/helpers';
import Button from '@embracesbs/component-button';
import Group from '@embracesbs/component-group';
import Text from '@embracesbs/component-text';
import { propTypes, defaultProps } from './Accordion.props';
import { webStyles } from './Accordion.styles';

const StyledAccordion = styled.div`
  ${({ theme }) => webStyles.styledAccordion(theme, unit)}
  ${({ hasTopBorder, theme, padding }) => `
    ${hasTopBorder ? `border-top: ${unit(1)} solid ${theme.color.dark$6};` : ''}
    padding: ${padding};
 `}
 ${({ hover, theme, isOpen }) => `
    ${
      hover === 'full' && !isOpen
        ? `
          &:hover {
            background-color: ${darken(0.01, theme.color.light)};
            div,
            canvas {
              background-color: ${darken(0.01, theme.color.light)};
            }
          }
        `
        : ``
    }
 `}
`;

const StyledCollapsible = styled.div`
  ${({ theme }) => webStyles.styledCollapsible(theme)}
`;

const StyledContent = styled.div`
  ${({ theme }) => webStyles.styledContent(theme)}
  ${({ isOpen }) => `
    padding-top: ${isOpen ? unit(24) : 0};
  `}
`;

const StyledChildren = styled.div`
  ${({ theme }) => webStyles.styledChildren(theme)}
  ${({ isOpen, height }) => `
    height: ${isOpen ? unit(height) : 0};
 `}
`;

const StyledButton = styled(Button)`
  ${({ theme, isOpen, isAnimated, hover }) => `
  ${
    hover === 'none' || hover === 'full'
      ? `
        &:hover {
          background-color: transparent;
        }
      `
      : ``
  }

  i {
    transition: ${theme.transition.arrow};
    transition-property: transform;
    ${
      isOpen && isAnimated
        ? `transform: rotate(180deg);`
        : 'transform: rotate(0deg);'
    }
  }
  `}
`;

/**
 * Accordion component
 *
 * @param {array} extraProps - An array of strings which includes the extra prop keys
 * @param {function} onArrowToggle - Callback function for toggle with 2 arguments:
 * toggled state and id of the component
 * @param {boolean} hasTopBorder - Whether has a top border or not
 * @param {boolean} isOpen - Whether is open or not
 * @param {string|number} id - Unique id
 * @param {string} headerTitle - Header title
 * @param {string} rightHeaderTitle - Optional second header title
 * @param {boolean} isDisabled - Disable the clickevent
 * @param {number|object} spacing - Spacing of the accordion
 * @param {node} children - Children of the component
 * @param {node} headerChildren - Children of the header
 * @param {boolean} isAnimated - Whether the arrow is animated or not
 * @param {string} hover - The type of hover effect
 */
const Accordion = (props) => {
  const {
    onArrowToggle,
    hasTopBorder,
    rightHeaderTitle,
    isOpen: isOpenProp,
    id,
    headerTitle,
    spacing,
    children,
    isDisabled,
    extraProps,
    headerChildren,
    isAnimated,
    hover,
  } = props;

  const [isOpen, setOpen] = useState(isOpenProp);
  const [childrenHeight, setChildrenHeight] = useState(0);
  const refChildren = useRef(null);
  const arrowRef = useRef(null);

  const currentSpacing = getSpacingObject(spacing, defaultProps.spacing);
  const currentSpacingString = getSpacingString(currentSpacing);

  /**
   * Get height of the children for smooth transition
   */
  const getChildrenHeight = () => {
    const defaultHeight = 250;
    if (children && refChildren.current && refChildren.current.children) {
      return refChildren.current.scrollHeight;
    }
    if (children) {
      return children.scrollHeight;
    }
    return defaultHeight;
  };

  useEffect(() => {
    setOpen(isOpenProp);
  }, [isOpenProp]);

  useLayoutEffect(() => {
    setChildrenHeight(getChildrenHeight());
  }, [children]);

  return (
    <StyledAccordion
      key={id}
      id={id}
      padding={currentSpacingString}
      hasTopBorder={hasTopBorder}
      {...getExtraProps(props, extraProps)}
      isOpen={isOpen}
      hover={hover}
    >
      <StyledCollapsible
        onClick={() => {
          if (!isDisabled) setOpen(!isOpen);
          onArrowToggle(!isOpen, id);
          setTimeout(() => {
            arrowRef.current.focus();
          }, 0);
        }}
      >
        <Group
          justify="space-between"
          {...getExtraProps(null, extraProps, 'Group')}
        >
          {!headerChildren ? (
            <Text
              content={headerTitle}
              {...getExtraProps(null, extraProps, 'Text')}
            />
          ) : (
            headerChildren
          )}
          <Group isFullWidth={false}>
            {rightHeaderTitle && <Text content={rightHeaderTitle} />}
            <StyledButton
              ref={arrowRef}
              variant="icon"
              iconSize={18}
              iconName={isAnimated || !isOpen ? 'ArrowDown1' : 'ArrowUp1'}
              isOpen={isOpen}
              aria-expanded={isOpen || false}
              isAnimated={isAnimated}
              extraProps={['style']}
              style={{ margin: `${unit(-10)} ${unit(-10)} ${unit(-10)} 0` }}
              {...getExtraProps(null, extraProps, 'StyledButton')}
              hover={hover}
            />
          </Group>
        </Group>
      </StyledCollapsible>
      <StyledContent isOpen={isOpen}>
        <StyledChildren isOpen={isOpen} height={childrenHeight}>
          <div ref={refChildren}>{children}</div>
        </StyledChildren>
      </StyledContent>
    </StyledAccordion>
  );
};

Accordion.propTypes = propTypes;
Accordion.defaultProps = defaultProps;

/** @component */
export default withTheme(Accordion);
