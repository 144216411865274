import * as React from 'react';

function SvgWhatsapp(props) {
  return (
    <svg viewBox="0 0 48 48" {...props}>
      <path d="M31.33 27.176c-.397-.198-2.354-1.157-2.72-1.289-.364-.132-.63-.198-.895.198-.266.397-1.028 1.29-1.26 1.554-.233.264-.465.298-.863.1-.398-.2-1.681-.618-3.202-1.967-1.183-1.051-1.982-2.348-2.215-2.745-.232-.397-.024-.611.175-.809.18-.177.398-.463.597-.694.2-.231.266-.397.398-.66.133-.265.067-.497-.033-.695-.1-.198-.896-2.149-1.227-2.942-.323-.772-.652-.668-.896-.68a16.044 16.044 0 00-.763-.014c-.265 0-.697.1-1.062.496-.365.396-1.393 1.355-1.393 3.305 0 1.95 1.426 3.834 1.626 4.099.199.264 2.807 4.266 6.8 5.982.95.409 1.692.652 2.27.835.954.302 1.822.259 2.508.157.764-.114 2.355-.958 2.687-1.884.331-.926.331-1.719.232-1.884-.1-.165-.365-.265-.763-.463m-7.264 9.871h-.005c-2.377 0-4.707-.636-6.74-1.837l-.484-.286-5.012 1.309 1.337-4.864-.315-.498a13.102 13.102 0 01-2.024-7.014c.003-7.267 5.943-13.179 13.248-13.179a13.185 13.185 0 019.363 3.865 13.069 13.069 0 013.875 9.324c-.003 7.268-5.944 13.18-13.243 13.18m11.27-24.397A15.868 15.868 0 0024.068 8c-8.782 0-15.93 7.113-15.933 15.856a15.77 15.77 0 002.126 7.927L8 40l8.446-2.205a15.98 15.98 0 007.614 1.93h.007c8.781 0 15.93-7.114 15.933-15.857a15.723 15.723 0 00-4.662-11.218" />
    </svg>
  );
}

export default SvgWhatsapp;
