import * as React from 'react';

function SvgBrainHead1(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M19.223 24a.75.75 0 01-.75-.75v-6.265c0-.208.088-.409.24-.55a8.558 8.558 0 002.75-5.991 8.556 8.556 0 00-2.285-6.183 8.64 8.64 0 00-6.32-2.76c-6.012 0-7.638 4.319-9.889 10.298-.211.56-.425 1.129-.646 1.702h1.9a.75.75 0 01.75.75v3a2.252 2.252 0 002.25 2.25h1.5a.75.75 0 01.75.75v3a.75.75 0 01-1.5 0V21h-.75a3.754 3.754 0 01-3.75-3.75V15h-1.15a1.504 1.504 0 01-1.401-2.038c.218-.565.428-1.123.635-1.673C3.842 5.223 5.809 0 12.848 0a10.15 10.15 0 017.431 3.241c3.691 3.984 3.542 10.259-.306 14.064v5.945a.75.75 0 01-.75.75z" />
      <path d="M17.502 12.65a1.5 1.5 0 01-.695-.172L8.944 8.365a1.505 1.505 0 01-.504-2.234 5.963 5.963 0 014.791-2.376c1.317 0 2.568.421 3.618 1.216a6.003 6.003 0 012.08 6.643 1.496 1.496 0 01-1.427 1.036zM13.23 5.255a4.47 4.47 0 00-3.593 1.78l7.866 4.114a4.502 4.502 0 00-1.56-4.983 4.458 4.458 0 00-2.713-.911z" />
    </svg>
  );
}

export default SvgBrainHead1;
