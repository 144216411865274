import React from 'react';
import styled from '@emotion/styled';
import {
  getExtraProps,
  withTheme,
  getSpacingString,
  getSpacingObject,
  unit,
} from '@embracesbs/helpers';
import { propTypes, defaultProps } from './List.props';

const StyledList = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 0;
  ${({ spacing, horizontalSpacing }) => `
    width: ${
      horizontalSpacing ? `calc(100% - ${unit(horizontalSpacing)})` : '100%'
    };
    margin: ${spacing};
  `}
`;

/**
 * List component
 * @param {nodes} children - The childrens of the List
 * @param {array} extraProps - Extra props
 * @param {number|object} spacing - Spacing around the List
 */
const List = (props) => {
  const { children, extraProps, className, spacing } = props;
  const currentSpacingObject = getSpacingObject(spacing, defaultProps.spacing);
  const currentSpacingString = getSpacingString(currentSpacingObject);
  const horizontalSpacing =
    currentSpacingObject.left + currentSpacingObject.right;
  return (
    <StyledList
      hasAnimation
      className={`listContent ${className}`}
      spacing={currentSpacingString}
      horizontalSpacing={horizontalSpacing}
      {...getExtraProps(props, extraProps)}
    >
      {children}
    </StyledList>
  );
};

List.propTypes = propTypes;
List.defaultProps = defaultProps;

/** @component */
export default withTheme(List);
