import * as React from 'react';

function SvgTouchId1(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M3.749 9.75a.75.75 0 01-.75-.75c0-4.963 4.037-9 9-9 2.085 0 4.119.731 5.727 2.057a.745.745 0 01.102 1.056.75.75 0 01-1.056.101A7.524 7.524 0 0011.999 1.5c-4.135 0-7.5 3.365-7.5 7.5a.75.75 0 01-.75.75zM12 24c-.547 0-1.097-.05-1.635-.148a.75.75 0 11.27-1.476c.449.082.908.124 1.365.124 4.135 0 7.499-3.365 7.499-7.5V9a7.445 7.445 0 00-.511-2.728.75.75 0 01.698-1.022c.312 0 .586.187.699.478a8.943 8.943 0 01.614 3.273V15c0 4.962-4.037 9-8.999 9zM6.751 22.115a.753.753 0 01-.476-.171 8.959 8.959 0 01-3.274-6.945V12.75a.75.75 0 011.5 0V15a7.46 7.46 0 002.727 5.786.751.751 0 01-.477 1.329z" />
      <path d="M15.749 15.75a.75.75 0 01-.75-.75V9c0-1.654-1.346-3-3-3-.645 0-1.284.213-1.8.6a.753.753 0 01-1.193-.706.75.75 0 01.293-.494c.774-.58 1.733-.9 2.7-.9 2.481 0 4.5 2.019 4.5 4.5v6a.75.75 0 01-.75.75zM12.002 19.5A4.51 4.51 0 017.499 15V9a.75.75 0 011.5 0v6c.001.802.314 1.555.881 2.122a2.985 2.985 0 002.122.878c.417 0 .819-.084 1.199-.25a.748.748 0 11.598 1.374 4.464 4.464 0 01-1.793.374c0 .002-.004.002-.004.002z" />
      <path d="M11.999 15a.75.75 0 01-.75-.75v-4.5a.75.75 0 011.5 0v4.5a.75.75 0 01-.75.75z" />
    </svg>
  );
}

export default SvgTouchId1;
