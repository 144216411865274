import React from 'react';
import styled from '@emotion/styled';
import ReactTooltip from 'react-tooltip';
import { getExtraProps, withTheme, unit } from '@embracesbs/helpers';
import { propTypes, defaultProps } from './Tooltip.props';
import { webStyles } from './Tooltip.styles';

const StyledTooltip = styled(ReactTooltip)`
  ${({ theme }) => webStyles.styledTooltip(theme, unit)}
  color: ${({ invertedColor }) => invertedColor} !important;
  background-color: ${({ color }) => color} !important;
  &:after {
    ${({ place, color }) => `border-${place}-color: ${color} !important;`}
  }
`;

/**
 * Tooltip component
 * @param {array} extraProps - An array of strings which includes the extra prop keys
 * @param {string} id - The id used when pointing a certain element via its data-for prop
 * @param {string} place - The tooltip position
 * @param {bool} isLight - Whether the tooltip should be light
 */
const Tooltip = (props) => {
  const { theme, extraProps, id, place, isLight } = props;

  return (
    <StyledTooltip
      {...getExtraProps(props, extraProps)}
      id={id}
      place={place}
      color={isLight ? theme.color.light : theme.color.dark$2}
      invertedColor={!isLight ? theme.color.light : theme.color.dark}
    />
  );
};

Tooltip.propTypes = propTypes;
Tooltip.defaultProps = defaultProps;

export default withTheme(Tooltip);
