import * as React from 'react';

function SvgSmileySmirkGlasses(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M12 19.498a.75.75 0 010-1.5h.001a5.965 5.965 0 005.375-3.333.745.745 0 011.005-.339c.18.089.314.243.378.433s.05.393-.039.573a7.457 7.457 0 01-6.719 4.167H12z" />
      <path d="M12.018 23.996a12.09 12.09 0 01-3.217-.439 11.917 11.917 0 01-7.241-5.649A11.934 11.934 0 01.361 9.081 6.504 6.504 0 010 6.725a.748.748 0 01.75-.727h.866a11.958 11.958 0 014.778-4.607 12.021 12.021 0 015.592-1.396c1.199 0 2.395.182 3.555.54a11.93 11.93 0 016.848 5.463h.861c.407 0 .737.319.75.727a6.508 6.508 0 01-.362 2.36c.502 2.01.478 4.118-.072 6.105a11.921 11.921 0 01-5.649 7.241 11.993 11.993 0 01-5.899 1.565zM1.53 11.14a10.353 10.353 0 001.334 6.028A10.433 10.433 0 009.2 22.111c.923.255 1.87.385 2.816.385a10.49 10.49 0 005.16-1.37 10.43 10.43 0 004.943-6.336c.323-1.17.438-2.411.34-3.636a6.548 6.548 0 01-4.81 2.344c-2.429-.079-4.556-1.433-5.649-3.519a6.543 6.543 0 01-5.601 3.519A6.605 6.605 0 011.53 11.14zm.368-2.247a5.084 5.084 0 004.489 3.105c2.505-.09 4.558-2.033 4.834-4.5H1.529c.041.359.122.716.242 1.065a.742.742 0 01.127.33zm10.881-1.395a5.087 5.087 0 004.859 4.5c1.951-.07 3.696-1.285 4.464-3.104a.758.758 0 01.128-.332c.119-.349.2-.705.241-1.064h-9.692zm7.836-1.5a10.582 10.582 0 00-8.634-4.495c-1.693 0-3.382.42-4.886 1.214A10.494 10.494 0 003.39 5.998h17.225z" />
    </svg>
  );
}

export default SvgSmileySmirkGlasses;
