import * as React from 'react';

function SvgCrane(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <g
        stroke="#000"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M13.5 22.426H.5M8.5 22.426h-3M2.68 22.426v-4.8c.018-.302.151-.586.374-.8l2.446-2.08h3l2.315 2.072c.218.219.348.505.365.808v4.8M8 5.146c.275 0 .724.02 1 .048l14.5 1.392v.96a.49.49 0 01-.5.48H1a.49.49 0 01-.5-.48v-.96L5.021 5.28A4.26 4.26 0 016 5.146h2z" />
        <path d="M.5 6.586L6.767.999A1.77 1.77 0 018.426.711L23.5 6.586M20.5 8.026v6.44a.96.96 0 00.6.88c.687.287 1.047 1.017.844 1.71-.203.692-.907 1.134-1.649 1.036-.742-.098-1.295-.707-1.295-1.426M5.5 9.946l3 2.88M8.5 17.626l-3 2.88M12.5 5.53v2.496M7.5.655v4.491M8.5 5.16v17.266M5.5 22.426V5.187" />
      </g>
    </svg>
  );
}

export default SvgCrane;
