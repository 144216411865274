import * as React from 'react';

function SvgCalendarEdit1(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M11.242 23.916a.743.743 0 01-.53-.22.745.745 0 01-.205-.677l.75-3.75a.748.748 0 01.205-.384l7.63-7.63c.541-.54 1.26-.838 2.026-.838s1.486.297 2.026.838c1.128 1.106 1.154 2.92.056 4.039a.873.873 0 01-.142.115l-7.537 7.537a.744.744 0 01-.384.205l-3.749.75a.716.716 0 01-.146.015zm.956-1.706l2.424-.485 7.469-7.469a.717.717 0 01.113-.093c.44-.528.397-1.339-.107-1.833a1.38 1.38 0 00-.983-.411 1.35 1.35 0 00-.962.398l-7.469 7.469-.485 2.424zM2.242 18a2.252 2.252 0 01-2.25-2.25V3.765a2.252 2.252 0 012.25-2.25h2.243V.75a.75.75 0 011.5 0v.765h6V.75a.75.75 0 011.5 0v.765h2.257a2.252 2.252 0 012.25 2.25V8.25a.75.75 0 01-1.5 0V7.5h-15v8.25c0 .414.336.75.75.75h6.75a.75.75 0 010 1.5h-6.75zm14.25-12V3.765a.75.75 0 00-.75-.75h-2.257v.735a.75.75 0 01-1.5 0v-.735h-6v.735a.75.75 0 01-1.5 0v-.735H2.242a.75.75 0 00-.75.75V6h15z" />
    </svg>
  );
}

export default SvgCalendarEdit1;
