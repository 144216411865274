import * as React from 'react';

function SvgFolderSearch1(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M2.447 23.25a.731.731 0 01-.223-.034A2.464 2.464 0 010 20.81V3A2.252 2.252 0 012.25.75h4.5C7.99.75 9 1.759 9 3v.75h11.25A2.252 2.252 0 0122.5 6v2.377a2.247 2.247 0 011.5 2.122c0 .195-.025.391-.076.58l-2.191 10.46c-.269 1.015-1.165 1.71-2.183 1.71H2.447zm17.103-1.5a.75.75 0 00.725-.557l2.191-10.461a.856.856 0 00.034-.231.746.746 0 00-.75-.751H7.879a.755.755 0 00-.714.52L4.82 21.393l-.011.046c-.03.107-.067.211-.111.312H19.55zM2.25 2.25A.75.75 0 001.5 3v17.8a.95.95 0 00.95.927.96.96 0 00.908-.672L5.704 9.929A2.26 2.26 0 017.879 8.25H21V6a.75.75 0 00-.75-.75h-12a.75.75 0 01-.75-.75V3a.75.75 0 00-.75-.75h-4.5z" />
      <path d="M17.25 20.25a.743.743 0 01-.53-.22l-1.689-1.689a3.88 3.88 0 01-2.156.659C10.738 19 9 17.262 9 15.125s1.738-3.875 3.875-3.875 3.875 1.738 3.875 3.875c0 .766-.232 1.52-.658 2.156l1.689 1.689a.752.752 0 010 1.061.752.752 0 01-.531.219zm-4.375-7.5c-1.31 0-2.375 1.065-2.375 2.375s1.065 2.375 2.375 2.375 2.375-1.065 2.375-2.375-1.066-2.375-2.375-2.375z" />
    </svg>
  );
}

export default SvgFolderSearch1;
