import * as React from 'react';

function SvgSmileyDevastated1(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M12 24C5.383 24 0 18.617 0 12S5.383 0 12 0s12 5.383 12 12-5.383 12-12 12zm0-22.5C6.21 1.5 1.5 6.21 1.5 12S6.21 22.5 12 22.5 22.5 17.79 22.5 12 17.79 1.5 12 1.5z" />
      <path d="M7.504 12.756a2.53 2.53 0 01-2.129-1.141A.748.748 0 016 10.45c.253 0 .486.125.625.336a1.04 1.04 0 00.878.469c.205 0 .404-.061.577-.176.118-.078.217-.177.295-.295a.75.75 0 011.36.267.747.747 0 01-.11.563 2.541 2.541 0 01-2.121 1.142zM16.504 12.756a2.534 2.534 0 01-2.129-1.141.748.748 0 01.625-1.164c.252 0 .486.125.625.335a1.04 1.04 0 00.878.47 1.05 1.05 0 00.873-.469.748.748 0 011.039-.21.748.748 0 01.211 1.04c-.189.286-.43.527-.716.716-.42.275-.906.423-1.406.423zM15.756 19.65a.74.74 0 01-.294-.06c-1.066-.455-2.2-.686-3.371-.686s-2.305.231-3.37.686a.748.748 0 01-.295.06 1.512 1.512 0 01-1.483-1.788 5.247 5.247 0 015.157-4.215c1.025 0 2.023.302 2.887.873a5.215 5.215 0 012.247 3.331 1.494 1.494 0 01-.41 1.352 1.5 1.5 0 01-1.067.447h-.001zm-3.657-4.503a3.744 3.744 0 00-3.67 2.942 10.023 10.023 0 013.662-.685c1.262 0 2.49.229 3.653.681a3.68 3.68 0 00-1.583-2.314 3.735 3.735 0 00-2.062-.624zM6.003 8.997c-.307 0-.614-.033-.912-.098a.751.751 0 01.158-1.483.73.73 0 01.161.018 2.808 2.808 0 001.839-.23A2.83 2.83 0 008.596 5.9.746.746 0 019.6 5.56a.75.75 0 01.34 1.005 4.3 4.3 0 01-2.04 1.99 4.268 4.268 0 01-1.897.442zM18.106 8.989a4.485 4.485 0 01-3.958-2.415.746.746 0 01.668-1.092c.283 0 .539.156.668.408a2.968 2.968 0 002.621 1.599c.194 0 .389-.02.58-.058a.753.753 0 01.884.586.748.748 0 01-.586.884 4.378 4.378 0 01-.877.088z" />
    </svg>
  );
}

export default SvgSmileyDevastated1;
