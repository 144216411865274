import * as React from 'react';

function SvgSearchRemove(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M12.932 13.682a.743.743 0 01-.53-.22L9.75 10.811l-2.652 2.652a.744.744 0 01-1.06 0 .752.752 0 010-1.061L8.69 9.75 6.038 7.098c-.142-.141-.22-.33-.22-.53s.078-.389.22-.53a.743.743 0 01.53-.22c.2 0 .389.078.53.22L9.75 8.689l2.652-2.652a.744.744 0 01.53-.22c.2 0 .389.078.53.22.142.141.22.33.22.53s-.078.389-.22.53L10.811 9.75l2.652 2.652a.75.75 0 01-.531 1.28z" />
      <path d="M23.25 24a.743.743 0 01-.53-.22l-6.519-6.52a9.771 9.771 0 01-6.388 2.366c-5.411 0-9.813-4.402-9.814-9.812C-.001 4.403 4.4.001 9.811 0c5.411 0 9.814 4.402 9.814 9.812 0 2.356-.837 4.61-2.364 6.387l6.52 6.521a.744.744 0 010 1.06.75.75 0 01-.531.22zM9.813 1.5a8.26 8.26 0 00-5.88 2.435 8.26 8.26 0 00-2.434 5.879 8.26 8.26 0 002.435 5.878 8.259 8.259 0 005.878 2.434c2.214 0 4.295-.86 5.864-2.42l.031-.031a8.256 8.256 0 002.418-5.863c0-4.583-3.728-8.312-8.312-8.312z" />
    </svg>
  );
}

export default SvgSearchRemove;
