import * as React from 'react';

function SvgSingleNeutralProfilePicture(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M3.75 24a2.252 2.252 0 01-2.25-2.25V2.25A2.252 2.252 0 013.75 0h16.5a2.252 2.252 0 012.25 2.25v19.5A2.252 2.252 0 0120.25 24H3.75zm0-22.5a.75.75 0 00-.75.75v19.5c0 .414.336.75.75.75h16.5a.75.75 0 00.75-.75V2.25a.75.75 0 00-.75-.75H3.75z" />
      <path d="M12 13.943c-2.68 0-4.86-2.18-4.86-4.86s2.18-4.86 4.86-4.86 4.86 2.18 4.86 4.86-2.18 4.86-4.86 4.86zm0-8.22a3.363 3.363 0 00-3.36 3.36 3.363 3.363 0 003.36 3.36 3.363 3.363 0 003.36-3.36A3.363 3.363 0 0012 5.723zM5.25 19.777a.75.75 0 01-.663-1.099 8.392 8.392 0 017.404-4.477c.84 0 1.677.128 2.489.379a8.323 8.323 0 014.934 4.098.75.75 0 01-.663 1.099.748.748 0 01-.664-.401 6.834 6.834 0 00-4.051-3.364 6.89 6.89 0 00-2.044-.311 6.88 6.88 0 00-3.199.797 6.857 6.857 0 00-2.879 2.879.75.75 0 01-.664.4z" />
    </svg>
  );
}

export default SvgSingleNeutralProfilePicture;
