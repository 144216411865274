import * as React from 'react';

function SvgRealEstateActionHouseWrench(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M5.249 23.498a2.252 2.252 0 01-2.25-2.25v-10.5a.75.75 0 011.5 0v10.5c0 .414.336.75.75.75h13.5a.75.75 0 00.75-.75v-10.5a.75.75 0 011.5 0v10.5a2.252 2.252 0 01-2.25 2.25h-13.5z" />
      <path d="M.75 9.998a.754.754 0 01-.742-.865.747.747 0 01.298-.491l10.363-7.6a2.243 2.243 0 012.661 0l10.363 7.6a.752.752 0 01-.443 1.355.74.74 0 01-.444-.146l-10.362-7.6a.744.744 0 00-.887 0l-10.363 7.6a.746.746 0 01-.444.147zM21.749 5.497a.75.75 0 01-.75-.75v-2.25h-2.25a.75.75 0 010-1.5h3a.75.75 0 01.75.75v3a.75.75 0 01-.75.75z" />
      <path d="M13.499 20.498a.75.75 0 01-.75-.75v-2.526a.75.75 0 01.507-.71 3.695 3.695 0 002.408-4.279c-.775.8-1.317 1.37-1.395 1.47a2.11 2.11 0 01-1.677.819 2.098 2.098 0 01-1.497-.619 2.113 2.113 0 01-.62-1.504 2.105 2.105 0 01.819-1.672c.101-.079.673-.622 1.473-1.398a3.586 3.586 0 00-2.15.191 3.737 3.737 0 00-2.119 4.839 3.723 3.723 0 002.248 2.159.75.75 0 01.504.708v2.522a.75.75 0 01-1.5 0v-2.019a5.247 5.247 0 01-2.647-2.823 5.236 5.236 0 012.955-6.779 5.066 5.066 0 011.937-.384 5.12 5.12 0 012.497.656.752.752 0 01.158 1.19c-.354.346-2.136 2.087-2.432 2.319a.623.623 0 00-.005.983.622.622 0 00.875-.109c.205-.263 1.539-1.634 2.315-2.429a.76.76 0 01.645-.225.75.75 0 01.553.38 5.189 5.189 0 01-2.35 7.219v2.02a.754.754 0 01-.752.751z" />
    </svg>
  );
}

export default SvgRealEstateActionHouseWrench;
