import * as React from 'react';

function SvgPhoneActionsOff(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M17.657 22.906a5.9 5.9 0 01-3.169-.93 35.201 35.201 0 01-4.164-3.104.751.751 0 01.983-1.133 33.515 33.515 0 003.976 2.965 4.42 4.42 0 002.376.7 4.348 4.348 0 003.091-1.29l.72-.72a.964.964 0 000-1.362l-3.028-3.028c-.181-.181-.423-.28-.68-.28s-.499.1-.681.281a2.445 2.445 0 01-1.74.72 2.444 2.444 0 01-1.74-.719.375.375 0 01-.001-.53l.529-.531a.372.372 0 01.53 0 .96.96 0 00.681.281c.257 0 .499-.1.68-.281a2.448 2.448 0 011.741-.719c.658 0 1.276.255 1.74.719l3.028 3.028c.96.961.96 2.523 0 3.484l-.72.72a5.85 5.85 0 01-4.152 1.729zM5.228 13.433a.747.747 0 01-.572-.265 35.222 35.222 0 01-2.887-3.926C.291 6.923.624 3.918 2.59 1.952l.719-.719A2.449 2.449 0 015.05.513c.658 0 1.276.255 1.74.719L9.818 4.26c.959.96.959 2.521 0 3.481a.959.959 0 000 1.36.746.746 0 01-.529 1.28.743.743 0 01-.53-.22 2.43 2.43 0 01-.723-1.738c0-.657.255-1.275.72-1.74a.966.966 0 00.001-1.363L5.73 2.293a.955.955 0 00-.68-.28c-.258 0-.499.1-.68.281l-.719.718a4.358 4.358 0 00-.578 5.49 33.622 33.622 0 002.726 3.696.75.75 0 01-.571 1.235zM.751 24a.752.752 0 01-.53-1.281l22.5-22.5c.141-.141.329-.219.53-.219s.389.078.53.22c.142.141.22.33.22.53s-.078.389-.22.53l-22.5 22.5a.744.744 0 01-.53.22z" />
    </svg>
  );
}

export default SvgPhoneActionsOff;
