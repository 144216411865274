import React from 'react';
import styled from '@emotion/styled';
import Text from '@embracesbs/component-text';
import {
  getExtraProps,
  withTheme,
  unit,
  getSpacingString,
} from '@embracesbs/helpers';
import { propTypes, defaultProps } from './Divider.props';

const styles = {
  vertical: (dimension, minSize) => `
    width: 0;
    padding: 0 ${unit(0.5)} !important;
    height: ${dimension};
    min-height: ${unit(minSize)};
  `,
  horizontal: (dimension, minSize) => `
    display: flex;
    justify-content: center;
    align-items: center;
    height: 0;
    padding: ${unit(0.5)} 0 !important;
    width: ${dimension};
    min-width: ${unit(minSize)};
  `,
};

/** StyledDividerTag */
const StyledDividerTag = (tag) => styled(tag)`
  ${({
    orientation,
    backgroundColorCurrent,
    currentSpacing,
    dimension,
    minSize,
  }) => `
    background: ${backgroundColorCurrent};
    border: none;
    margin: ${currentSpacing} !important;
    ${styles[orientation](dimension, minSize)}
 `}
`;

const StyledWrapper = styled.div`
  ${({ align, orientation, wrapperSize }) => `
    display: flex;
    ${
      orientation === 'vertical'
        ? `
          height: ${wrapperSize || 'auto'};
          align-items: ${align};
          width: auto;
        `
        : `
          justify-content: ${align};
          width: ${wrapperSize || 'auto'};
        `
    }
  `}
`;

const StyledChildrenWrapper = styled.div`
  ${({ theme, backgroundColor }) => `
    background-color: ${backgroundColor};
    padding: 0 ${theme.spacing.$3};
  `}
`;

/**
 * Set default value of the size based on the orientation
 * @param {string|number} size
 * @param {string} orientation
 */
const getDimension = (size, orientation) => {
  if (!size) {
    return orientation !== 'vertical' ? 'auto' : '100%';
  }

  return !Number.isNaN(Number(size)) ? unit(size) : size;
};

/**
 * Divider component
 * @param {string|number} size
 * @param {number} minSize
 * @param {string} orientation
 * @param {string} background
 * @param {number|object} spacing
 * @param {string} tag
 * @param {array} extraProps
 * @param {boolean} isDark
 * @param {string} align
 * @param {string} wrapperSize
 * @param {node} children
 * @param {string} childrenBackground
 * @param {string} text
 * @param {string} timestamp
 */
const Divider = (props) => {
  const {
    size,
    minSize,
    orientation,
    background,
    spacing,
    tag,
    theme,
    extraProps,
    isDark,
    align,
    wrapperSize,
    children,
    childrenBackground,
    text,
    timestamp,
  } = props;

  const currentSpacing = getSpacingString(spacing, defaultProps.spacing);
  const StyledDivider = StyledDividerTag(tag);
  const backgroundColor = isDark ? 'light$5' : background;
  const dimension = getDimension(size, orientation);

  const StyledDividerCurrent = (
    <StyledDivider
      {...getExtraProps(props, extraProps)}
      tag={tag}
      orientation={orientation}
      backgroundColorCurrent={theme.color[backgroundColor]}
      currentSpacing={currentSpacing}
      dimension={dimension}
      minSize={minSize}
    >
      {(text || children) && orientation === 'horizontal' && (
        <StyledChildrenWrapper backgroundColor={childrenBackground}>
          {text ? (
            <Text
              content={`${timestamp ? `${timestamp} - ` : ''}${text}`}
              textStyle="overline"
              textColor={isDark ? theme.color.light$2 : theme.color.dark$3}
              {...getExtraProps(null, extraProps, 'Text')}
            />
          ) : (
            children
          )}
        </StyledChildrenWrapper>
      )}
    </StyledDivider>
  );

  if (align) {
    return (
      <StyledWrapper
        align={align}
        orientation={orientation}
        wrapperSize={wrapperSize}
        {...getExtraProps(null, extraProps, 'Wrapper')}
      >
        {StyledDividerCurrent}
      </StyledWrapper>
    );
  }

  return StyledDividerCurrent;
};

Divider.propTypes = propTypes;
Divider.defaultProps = defaultProps;

export default withTheme(Divider);
