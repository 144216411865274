import * as React from 'react';

function SvgKnowledge(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M12 14h-.3l-11-4c-.3-.2-.5-.4-.5-.8 0-.3.2-.6.5-.7l11-4.5c.2-.1.4-.1.6 0l11 4.5c.3.1.5.4.5.7s-.2.6-.5.7l-11 4c-.1.1-.2.1-.3.1zM3 9.2l9 3.3 9-3.3-9-3.7-9 3.7z" />
      <path d="M1 16.1c-.4 0-.8-.3-.8-.8V9.4c0-.4.3-.8.8-.8s.8.3.8.8v6c-.1.4-.4.7-.8.7zM12 20.1c-.2 0-.5 0-.7-.1l-4.9-2c-.8-.3-1.2-1-1.2-1.8v-4.7c0-.4.3-.8.8-.8s.8.3.8.8v4.6c0 .2.1.4.3.4l4.9 2h.4l4.9-2c.2-.1.3-.2.3-.4v-4.9c0-.4.3-.8.8-.8s.8.3.8.8v4.9c0 .8-.5 1.5-1.2 1.8l-4.9 2c-.6.1-.9.2-1.1.2z" />
    </svg>
  );
}

export default SvgKnowledge;
