import * as React from 'react';

function SvgOnedrive(props) {
  return (
    <svg viewBox="0 0 32 32" {...props}>
      <g data-name="STYLE = COLOR">
        <path
          d="M12.202 11.193v-.001l6.718 4.024 4.003-1.685A6.477 6.477 0 0125.5 13c.148 0 .294.007.439.016a10 10 0 00-18.041-3.013L8 10a7.96 7.96 0 014.202 1.193z"
          fill="#0364b8"
        />
        <path
          d="M12.203 11.192A7.96 7.96 0 008 10l-.102.003a7.997 7.997 0 00-6.46 12.57L7.36 20.08l2.634-1.108 5.863-2.468 3.062-1.288z"
          fill="#0078d4"
        />
        <path
          d="M25.939 13.016A6.577 6.577 0 0025.5 13a6.477 6.477 0 00-2.576.532l-4.004 1.684 1.161.695 3.805 2.279 1.66.994 5.677 3.4a6.5 6.5 0 00-5.284-9.568z"
          fill="#1490df"
        />
        <path
          d="M25.546 19.184l-1.66-.994-3.805-2.28-1.16-.694-3.063 1.288-5.863 2.468L7.36 20.08l-5.924 2.493A7.989 7.989 0 008 26h17.5a6.498 6.498 0 005.723-3.416z"
          fill="#28a8ea"
        />
      </g>
    </svg>
  );
}

export default SvgOnedrive;
