import PropTypes from 'prop-types';

export const propTypes = {
  /** Can have all kind of child nodes */
  children: PropTypes.node,
  /** Defines the rows of the grid with a space-separated list of values. */
  rows: PropTypes.string,
  /** Defines the columns of the grid with a space-separated list of values. */
  columns: PropTypes.string,
  /** Background color */
  bgColor: PropTypes.string,
  /** Defines padding around the component */
  spacing: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.shape({
        bottom: PropTypes.number,
        left: PropTypes.number,
        right: PropTypes.number,
        top: PropTypes.number,
      }),
    ]),
  ),
  /** Sets border around the component */
  border: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.shape({
        bottom: PropTypes.number,
        left: PropTypes.number,
        right: PropTypes.number,
        top: PropTypes.number,
      }),
    ]),
  ),
  /** Set scrollbars */
  hasScrollbar: PropTypes.bool,
  /** Show or hide the horizontal scrollbar */
  horizontalScroll: PropTypes.bool,
  /** Add a custom classname(s) */
  className: PropTypes.string,
};

export const defaultProps = {
  children: null,
  rows: null,
  columns: null,
  bgColor: null,
  spacing: [],
  border: [],
  hasScrollbar: true,
  horizontalScroll: false,
  className: null,
};
