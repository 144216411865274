import * as React from 'react';

function SvgLayoutModule1(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M2.25 10.497A2.252 2.252 0 010 8.247v-6a2.252 2.252 0 012.25-2.25h6a2.252 2.252 0 012.25 2.25v6a2.252 2.252 0 01-2.25 2.25h-6zm0-9a.75.75 0 00-.75.75v6c0 .414.336.75.75.75h6a.75.75 0 00.75-.75v-6a.75.75 0 00-.75-.75h-6zM2.25 23.997A2.252 2.252 0 010 21.747v-6a2.252 2.252 0 012.25-2.25h6a2.252 2.252 0 012.25 2.25v6a2.252 2.252 0 01-2.25 2.25h-6zm0-9a.75.75 0 00-.75.75v6c0 .414.336.75.75.75h6a.75.75 0 00.75-.75v-6a.75.75 0 00-.75-.75h-6zM15.75 10.497a2.252 2.252 0 01-2.25-2.25v-6a2.252 2.252 0 012.25-2.25h6A2.252 2.252 0 0124 2.247v6a2.252 2.252 0 01-2.25 2.25h-6zm0-9a.75.75 0 00-.75.75v6c0 .414.336.75.75.75h6a.75.75 0 00.75-.75v-6a.75.75 0 00-.75-.75h-6zM15.75 23.997a2.252 2.252 0 01-2.25-2.25v-6a2.252 2.252 0 012.25-2.25h6a2.252 2.252 0 012.25 2.25v6a2.252 2.252 0 01-2.25 2.25h-6zm0-9a.75.75 0 00-.75.75v6c0 .414.336.75.75.75h6a.75.75 0 00.75-.75v-6a.75.75 0 00-.75-.75h-6z" />
    </svg>
  );
}

export default SvgLayoutModule1;
