import * as React from 'react';

function SvgAwardTrophyStarCrossed(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M12 19.5c-.4 0-.8-.3-.8-.8v-3c0-.4.3-.8.8-.8s.8.3.8.8v3c0 .5-.4.8-.8.8z" />
      <path d="M12 16.5c-.4 0-.8-.3-.8-.8s.4-.7.8-.7c5 0 9-4.1 9-9v-.6c0-.4.3-.8.8-.8s.8.3.8.8V6c-.1 5.8-4.8 10.5-10.6 10.5zM18.4 24H5.7c-.2 0-.5-.1-.6-.3s-.2-.5-.1-.8c.8-2.1 2.4-3.8 4.5-4.5 1.9-.7 3.9-.6 5.7.3 1.8.9 3.2 2.4 3.9 4.3.1.2 0 .5-.1.7-.1.1-.4.3-.6.3zM6.9 22.5h10.4c-.6-1.1-1.5-1.9-2.6-2.4-1.5-.7-3.1-.8-4.5-.2-1.5.4-2.6 1.3-3.3 2.6zM1.7 22.2c-.2 0-.4-.1-.5-.2-.3-.3-.3-.8 0-1.1L22.6.5c.3-.3.8-.3 1.1 0 .3.3.3.8 0 1.1L2.2 22c-.1.2-.3.2-.5.2zM6 14.5c-.2 0-.3 0-.4-.2-.8-.6-1.6-1.5-2.3-2.5C2.1 10 1.5 8 1.5 6V2.2C1.5 1 2.5 0 3.7 0h3.8c.4 0 .8.3.8.8s-.4.7-.8.7H3.7c-.4 0-.7.3-.7.7V6c0 1.7.6 3.5 1.6 5 .6.9 1.2 1.6 1.9 2.1.3.2.4.7.1 1-.1.2-.4.4-.6.4zM19.3 1.5h-2.8c-.4 0-.8-.3-.8-.8s.4-.7.8-.7h2.8c.4 0 .7.4.7.8s-.3.7-.7.7z" />
      <path d="M9.1 12.1c-.4 0-.8-.2-1.1-.6-.3-.5-.2-1 0-1.3l.9-1.9-1.7-1.8c-.3-.2-.4-.5-.4-.9s.1-.7.4-.9c.1-.1.4-.4.9-.4h1.6l1-2.1c.2-.7 1.1-.9 1.7-.7.1 0 .2.1.3.2.1 0 .3.2.4.5l1.1 2.1h1c.5-.1.8.3.8.7s-.3.8-.8.8h-1.5c-.3 0-.5-.2-.7-.4l-1-2.3-1.1 2.3c-.1.3-.4.4-.7.4H8.5l1.7 1.7c.2.2.3.5.2.8l-.9 2 .2-.1c.4-.2.8-.1 1 .3.2.4.1.8-.3 1l-.7.4c-.1.1-.4.2-.6.2zm0-1.1zm-.1-.4zm3.1-7.9c0 .1 0 .1 0 0z" />
    </svg>
  );
}

export default SvgAwardTrophyStarCrossed;
