import * as React from 'react';

function SvgSingleNeutralPhoneBook(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M12.75 12.75c-1.861 0-3.375-1.514-3.375-3.375S10.889 6 12.75 6s3.375 1.514 3.375 3.375-1.514 3.375-3.375 3.375zm0-5.25c-1.034 0-1.875.841-1.875 1.875s.841 1.875 1.875 1.875 1.875-.841 1.875-1.875S13.784 7.5 12.75 7.5z" />
      <path d="M17.25 18a.75.75 0 01-.75-.75 3.754 3.754 0 00-3.75-3.75A3.754 3.754 0 009 17.25a.75.75 0 01-1.5 0A5.256 5.256 0 0112.75 12 5.256 5.256 0 0118 17.25a.75.75 0 01-.75.75zM23.25 6.75A.75.75 0 0122.5 6V3A.75.75 0 0124 3v3a.75.75 0 01-.75.75zM23.25 12.75a.75.75 0 01-.75-.75V9A.75.75 0 0124 9v3a.75.75 0 01-.75.75zM23.25 18.75a.75.75 0 01-.75-.75v-3a.75.75 0 011.5 0v3a.75.75 0 01-.75.75z" />
      <path d="M2.25 24A2.252 2.252 0 010 21.75V2.25A2.252 2.252 0 012.25 0h16.5A2.252 2.252 0 0121 2.25v19.5A2.252 2.252 0 0118.75 24H2.25zm16.5-1.5a.75.75 0 00.75-.75V2.25a.75.75 0 00-.75-.75H6v21h12.75zm-16.5-21a.75.75 0 00-.75.75v19.5c0 .414.336.75.75.75H4.5v-21H2.25z" />
    </svg>
  );
}

export default SvgSingleNeutralPhoneBook;
