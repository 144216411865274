import React, { useState, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { keyframes, css } from '@emotion/core';
import { getExtraProps, withTheme, unit } from '@embracesbs/helpers';
import { propTypes, defaultProps } from './Sidebar.props';
import { webStyles } from './Sidebar.styles';

export const SidebarContext = React.createContext({
  isCollapsed: false,
  isExpanded: false,
  setCollapsed: () => {},
});

const visibilityElementsSelector = `p,
  .section-header,
  input,
  .sidebarHeaderIcon,
  .infoCardIndicator,
  .sidebar-app-context div,
  .infoCardContent > div`;

const StyledSidebar = styled.nav`
  ${({ theme }) => webStyles.sidebar(theme)}
  ${({ theme, isExpanded, width, backgroundcolor, isCollapsed }) => css`
    width: ${isExpanded ? width : unit(theme.layout.sidebarWidthSmall)};
    background: ${backgroundcolor};
    ${visibilityElementsSelector} {
      visibility: ${isExpanded ? 'visible' : 'hidden !important'};
    }
    &.activeSearch,
    &.activeSearchResults {
      ${visibilityElementsSelector} {
        visibility: visible !important;
      }
    }
    .clearText {
      display: ${isExpanded ? 'block' : 'none'};
    }
    ${isCollapsed
      ? css`
          .collapsed-panel {
            .infoCardContent,
            li,
            i,
            p {
              ${theme.transition.forwards(
                keyframes`${theme.keyFrames.vertical}`,
              )}
            }
          }
        `
      : ``}
  `}
`;

/**
 * Custom hook for hover-intent functionality
 * @param {boolean} isHoverIntentEnabled - Whether it is active
 * @param {number} timeoutDuration - Timeout duration in ms
 * @param {function} onEnterFunction - Code to be executed after entering
 * @param {function} onLeaveFunction - Code to be executed after leaving
 */
const useHoverIntent = (
  isHoverIntentEnabled,
  timeoutDuration,
  onEnterFunction,
  onLeaveFunction,
) => {
  const timeoutRef = useRef({ enter: null, leave: null });

  if (!isHoverIntentEnabled)
    return { onMouseEnter: () => {}, onMouseLeave: () => {} };

  /** Sets and configures the timeout */
  const setAndConfigureTimeout = (timeout, functionToExecute) => {
    timeoutRef.current[timeout] = setTimeout(() => {
      functionToExecute();
      timeoutRef.current[timeout] = null;
    }, timeoutDuration);
  };

  /** Clears and removes the timeout */
  const clearAndRemoveTimeout = (timeout) => {
    clearTimeout(timeoutRef.current[timeout]);
    timeoutRef.current[timeout] = null;
  };

  return {
    onMouseEnter: () => {
      if (!timeoutRef.current.enter)
        setAndConfigureTimeout('enter', onEnterFunction);
      if (timeoutRef.current.leave) clearAndRemoveTimeout('leave');
    },
    onMouseLeave: () => {
      if (timeoutRef.current.enter) clearAndRemoveTimeout('enter');
      if (!timeoutRef.current.leave)
        setAndConfigureTimeout('leave', onLeaveFunction);
    },
  };
};

/**
 * Sidebar component
 *
 * @param {nodes} children - Children of the Sidebar
 * @param {string} backgroundcolor - Background color Of the Sidebar
 * @param {array} extraProps - Extra props of the component
 * @param {number} width - Width of the expanded Sidebar
 * @param {boolean} isExpanded - Indicates wheather the Sidebar is expanded or not
 * @param {boolean} isBarAutoClosing - is The side bar is closing/opening on hover or not
 */
const Sidebar = (props) => {
  const {
    children,
    backgroundcolor,
    extraProps,
    width,
    isExpanded: isExpandedProp,
    isBarAutoClosing,
  } = props;

  const [isCollapsed, setCollapsed] = useState(false);
  const [isExpanded, setExpanded] = useState(isExpandedProp);

  useEffect(() => {
    if (isExpandedProp !== isExpanded) {
      setExpanded(isExpandedProp);
    }
  }, [isExpandedProp]);

  const { onMouseEnter, onMouseLeave } = useHoverIntent(
    isBarAutoClosing,
    150,
    () => {
      if (!isExpanded) setExpanded(true);
    },
    () => {
      if (isExpanded) setExpanded(false);
    },
  );

  return (
    <StyledSidebar
      {...getExtraProps(props, extraProps)}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className="mainSidebar"
      backgroundcolor={backgroundcolor}
      width={unit(width)}
      isExpanded={isExpanded}
      isCollapsed={isCollapsed}
      aria-label="Sidebar"
    >
      <SidebarContext.Provider
        value={{ isCollapsed, setCollapsed, isExpanded }}
      >
        {children}
      </SidebarContext.Provider>
    </StyledSidebar>
  );
};

Sidebar.propTypes = propTypes;
Sidebar.defaultProps = defaultProps;

/** @component */
export default withTheme(Sidebar);
