import { unit } from '@embracesbs/helpers';

const baseStyles = {
  loadingContainer: () => `
    z-index: 3;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    justify-content: center;
  `,
  onlineIndicator: () => `
    background-color: '#fff';
    z-index: 0;
    position: absolute;
    bottom: 0;
    right: 0;
  `,
};

export const webStyles = {
  ...baseStyles,
  avatarContainer: (size, isDisabled, margin) => `
    position: relative;
    width: ${unit(size)};
    height: ${unit(size)};
    opacity: ${isDisabled ? '0.2' : '1'};
    margin: ${margin};
  `,
  loadingContainer: () => `
    ${baseStyles.loadingContainer()}
    display: flex;
    align-items: center;
  `,
  onlineIndicator: (theme) => `
    ${baseStyles.onlineIndicator(theme)}
    box-sizing: border-box;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  avatarSizeAndBorder: (
    theme,
    size,
    isRound,
    isDisabled,
    iconBackground,
    variant,
    content,
  ) => `
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: ${unit(size / (content && content.length > 1 ? 2.5 : 2))};
    background: ${variant !== 'image' ? iconBackground : 'transparent'};
    width: ${unit(size)};
    height: ${unit(size)};
    border-radius: ${isRound ? unit(size) : theme.borderRadius.$1};

    &:hover,
    &:focus {
      filter: ${!isDisabled && `brightness(90%)`};
    }
  `,
  avatarButton: (theme, iconBackground) => `
    border-radius: 100%;
    background: ${iconBackground || 'transparent'};
    position: absolute;
    top: 0;
    left: 0;
    color: ${theme.color.light};

    &:hover,
    &:focus {
      color: ${theme.color.light};
      background-color: ${iconBackground || theme.color.dark$5};
      filter: brightness(90%);
    }
  `,
  iconButton: () => `
    border: none;
    &:hover,
    &:focus {
      filter: brightness(90%);
    }
  `,
  avatarSkeleton: (theme) => `
    border-radius: 50%;
    overflow: hidden;
    width: 100%;
    height: 100%;
    background: ${theme.color.dark$7}
  `,
};

export const nativeStyles = {
  ...baseStyles,
};
