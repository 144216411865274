import React from 'react';
import styled from '@emotion/styled';
import {
  getExtraProps,
  withTheme,
  getSpacingString,
} from '@embracesbs/helpers';
import { propTypes, defaultProps } from './CardHeader.props';
import { webStyles } from './CardHeader.styles';

const StyledCardHeader = styled.div`
  ${({ theme, spacing, align }) => ` 
    ${webStyles.styledCardHeader(theme)}
    padding: ${spacing};
    justify-content: ${align || 'flex-start'};
`}
`;

/**
 * Card header web component
 * @param {array} extraProps - An array of strings which includs the extra prop keys
 * @param {node} children - The child elements to be shown in the card header
 */
const CardHeader = (props) => {
  const { extraProps, children, align, spacing } = props;
  const currentSpacingString = getSpacingString(spacing, defaultProps.spacing);

  return (
    <StyledCardHeader
      {...getExtraProps(props, extraProps)}
      align={align}
      spacing={currentSpacingString}
    >
      {children}
    </StyledCardHeader>
  );
};

CardHeader.propTypes = propTypes;
CardHeader.defaultProps = defaultProps;

export default withTheme(CardHeader);
