import PropTypes from 'prop-types';
import { requireOneOf } from '@embracesbs/helpers';

const oneOfProps = {
  trigger: PropTypes.node,
  toggleOpen: PropTypes.func,
  isOpen: PropTypes.bool,
};

export const propTypes = {
  /** An array of strings which includs the extra prop keys */
  extraProps: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  ),
  /** Children in the contextmodal */
  children: PropTypes.node,
  /** Trigger for the contextmodal */
  trigger: (props) => requireOneOf(oneOfProps, props),
  /** Callback for on open/closed popup */
  toggleOpen: (props) => requireOneOf(oneOfProps, props),
  /** Indicates wheather is open or not */
  isOpen: (props) => requireOneOf(oneOfProps, props),
  /** Popup characteristics */
  popupProps: PropTypes.shape({
    align: PropTypes.oneOf(['bottom', 'top', 'centerUp', 'centerDown']),
    hasArrow: PropTypes.bool,
    justify: PropTypes.oneOf(['left', 'center', 'right']),
    shadowDepth: PropTypes.oneOf([2, 4, 8, 16]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(['auto'])]),
    zIndex: PropTypes.number,
  }),
  /** Whether the popup is collapsed when clicked */
  isCollapsedWhenClicked: PropTypes.bool,
  /** Whether the popup is collapsed when anything is scrolled */
  isCollapsedWhenScrolled: PropTypes.bool,
  /** Whether the contextmodal is inside a fixed container */
  isInAFixedContainer: PropTypes.bool,
  /** Whether the contextmodal is in the root element */
  isPositionedInRoot: PropTypes.bool,
  /** Optional absolute left and top coordinates */
  absoluteCoords: PropTypes.shape({
    left: PropTypes.number,
    top: PropTypes.number,
  }),
};

export const defaultProps = {
  extraProps: [],
  children: null,
  toggleOpen: null,
  isOpen: false,
  popupProps: {
    align: 'bottom',
    justify: 'center',
    hasArrow: true,
    shadowDepth: 8,
    zIndex: 1000,
    width: 260,
  },
  isCollapsedWhenClicked: false,
  isCollapsedWhenScrolled: false,
  isInAFixedContainer: false,
  isPositionedInRoot: true,
  absoluteCoords: null,
};
