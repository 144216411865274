import * as React from 'react';

function SvgEarth1(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M11.996 24c-2.732 0-5.309-.901-7.453-2.605l-.017-.012-.031-.025a12.68 12.68 0 01-.238-.196c-.071-.06-.142-.122-.213-.184l-.143-.126c-.13-.118-.257-.24-.381-.363l-.018-.018a11.904 11.904 0 01-3.445-9.695l.001-.007c.018-.174.041-.348.067-.523l.019-.125c.028-.174.058-.348.093-.521l.003-.013C1.379 4.031 6.321 0 11.996 0c6.617 0 12 5.383 12 12 0 1.083-.148 2.164-.44 3.214l-.009.032a12.172 12.172 0 01-.671 1.809s-.072.155-.1.212a11.896 11.896 0 01-.189.368A11.987 11.987 0 0111.996 24zm-6.181-3.519a10.388 10.388 0 006.181 2.019c3.471 0 6.642-1.669 8.611-4.497h-.59a2.244 2.244 0 01-2.22-1.879L17.111 12h-.944a2.252 2.252 0 01-2.182-2.796l.75-3A2.245 2.245 0 0116.917 4.5h2.416A10.49 10.49 0 001.934 9h4.391c1.034 0 1.932.701 2.183 1.704l.75 3a2.235 2.235 0 01-.592 2.137c-.425.425-.99.659-1.591.659h-.464l-.796 3.981zm-4.248-9.687l-.015.116a9.092 9.092 0 00-.053.96l-.002.17c.001.145.005.29.011.435l.006.127c.009.154.02.308.035.461l.005.041c.071.68.209 1.354.411 2.003l.009.028a10.457 10.457 0 002.537 4.222l.75-3.751A.75.75 0 015.996 15h1.079a.75.75 0 00.728-.932l-.75-3a.751.751 0 00-.729-.568H1.606l-.039.294zM16.917 6a.75.75 0 00-.728.568l-.75 3a.752.752 0 00.728.932h1.579c.368 0 .679.264.74.627l.791 4.75a.748.748 0 00.74.626h1.461c.171-.358.321-.727.449-1.1l.021-.062A10.486 10.486 0 0020.598 6h-3.681z" />
    </svg>
  );
}

export default SvgEarth1;
