import * as React from 'react';

function SvgEmailActionForward(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M.5 21v-6c0-4.5 3.7-8.2 8.2-8.2h5.2V4.6c0-.6.2-1.2.7-1.6.4-.4 1-.7 1.6-.7.6 0 1.2.3 1.6.7l5.5 5.9c.8.9.8 2.2 0 3.1l-5.5 5.9c-.4.5-1 .7-1.7.7-.6 0-1.1-.2-1.5-.6-.5-.4-.7-1-.7-1.6v-2.2H8.8C5 14.2 2 17.2 2 21c0 .4-.3.8-.8.8s-.7-.4-.7-.8zM15.7 4c-.1.1-.2.3-.2.5v2.9c0 .2-.1.4-.2.5-.1.1-.3.2-.5.2h-6C5 8.2 2 11.2 2 15v1.3c1.5-2.2 4.1-3.5 6.7-3.5h6c.4 0 .8.3.8.8v2.9c0 .2.1.4.2.5.1.1.3.2.5.2s.4-.1.5-.2l5.5-5.9c.3-.3.3-.7 0-1L16.8 4c-.1-.2-.3-.2-.5-.2s-.4.1-.6.2z" />
    </svg>
  );
}

export default SvgEmailActionForward;
