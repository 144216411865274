import * as React from 'react';

function SvgArrowLeft1(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M16.25 24a.743.743 0 01-.53-.22L5 13.06a1.488 1.488 0 01-.439-1.059c0-.4.155-.777.438-1.06L15.72.22c.141-.142.33-.22.53-.22s.389.078.53.22c.142.141.22.33.22.53s-.078.389-.22.53L6.06 12l10.72 10.72a.744.744 0 010 1.06.749.749 0 01-.53.22z" />
    </svg>
  );
}

export default SvgArrowLeft1;
