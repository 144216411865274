import * as React from 'react';

function SvgLogin1(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M11.249 16.504a.743.743 0 01-.53-.22l-3.75-3.75a.712.712 0 01-.205-.382.729.729 0 01.092-.531l.021-.035a.74.74 0 01.092-.112l3.75-3.75a.744.744 0 011.06 0c.142.141.22.33.22.53s-.078.389-.22.53l-2.47 2.47h13.939a.75.75 0 010 1.5H9.309l2.47 2.47a.752.752 0 010 1.061.748.748 0 01-.53.219z" />
      <path d="M2.093 23.25C.885 23.189-.055 22.165 0 20.966L-.001 3C-.056 1.828.875.811 2.075.751L14.385.75c1.228.06 2.167 1.085 2.113 2.284l.001 4.466a.75.75 0 01-1.5 0V3c.018-.403-.275-.732-.652-.751L2.112 2.25c-.341.018-.631.346-.614.716L1.499 21c-.018.403.275.732.651.751l12.235-.001c.341-.018.632-.346.615-.716l-.001-4.534a.75.75 0 011.5 0V21c.054 1.173-.878 2.189-2.077 2.249l-12.31.001h-.019z" />
    </svg>
  );
}

export default SvgLogin1;
