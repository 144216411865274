import * as React from 'react';

function SvgFileHtml(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M20.25 13.503a.75.75 0 01-.75-.75v-3.75h-5.25A2.252 2.252 0 0112 6.753v-5.25H2.25a.75.75 0 00-.75.75v10.5a.75.75 0 01-1.5 0v-10.5A2.252 2.252 0 012.25.003h10.629a2.239 2.239 0 011.591.66l5.872 5.871c.424.425.658.99.658 1.591v4.628a.75.75 0 01-.75.75zm-6.75-6.75c0 .414.336.75.75.75h4.939L13.5 1.815v4.938zM3.75 24.003a.75.75 0 01-.75-.75v-2.25H1.5v2.25a.75.75 0 01-1.5 0v-7.5a.75.75 0 011.5 0v3.75H3v-3.75a.75.75 0 011.5 0v7.5a.75.75 0 01-.75.75zM8.25 24.003a.75.75 0 01-.75-.75v-6.75h-.75a.75.75 0 010-1.5h3a.75.75 0 010 1.5H9v6.75a.75.75 0 01-.75.75zM17.25 24.003a.75.75 0 01-.75-.75V18.46l-.857 1.428a.752.752 0 01-1.286-.001L13.5 18.46v4.792a.75.75 0 01-1.5.001v-7.5a.752.752 0 01.75-.75c.262 0 .509.14.643.365L15 18.045l1.607-2.678a.753.753 0 01.844-.337c.323.09.549.387.549.723v7.5a.75.75 0 01-.75.75zM21.75 24.003a2.252 2.252 0 01-2.25-2.25v-6a.75.75 0 011.5 0v6c0 .414.336.75.75.75h1.5a.75.75 0 010 1.5h-1.5z" />
    </svg>
  );
}

export default SvgFileHtml;
