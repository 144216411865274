import * as React from 'react';

function SvgSchoolBuilding(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M.75 24a.75.75 0 01-.75-.75V15a2.252 2.252 0 012.25-2.25h4.5v-1.632c.039-2.576 1.987-4.736 4.5-5.07V.75A.75.75 0 0112 0h4c.689 0 1.25.561 1.25 1.25v2c0 .689-.561 1.25-1.25 1.25h-3.25v1.549c2.513.333 4.461 2.49 4.5 5.058v1.643h4.5A2.252 2.252 0 0124 15v8.25a.75.75 0 01-.75.75H.75zm21.75-1.5V15a.75.75 0 00-.75-.75h-4.5v8.25h5.25zm-6.75 0V11.121C15.719 9.127 14.066 7.5 12.066 7.5h-.122c-2.007 0-3.665 1.628-3.694 3.63V22.5h1.5v-3.187l.002-.052A2.172 2.172 0 0112 17.25l.094-.002a2.17 2.17 0 012.156 2.066V22.5h1.5zm-3 0v-3.156a.672.672 0 00-.667-.597l-.083.002-.082-.002a.673.673 0 00-.668.597V22.5h1.5zm-6 0v-8.25h-4.5a.75.75 0 00-.75.75v7.5h5.25zm9-19.5V1.5h-3V3h3z" />
      <path d="M12 13.5c-1.223 0-2.217-.995-2.217-2.217S10.777 9.066 12 9.066c1.222 0 2.217.995 2.217 2.217S13.222 13.5 12 13.5zm0-2.934a.718.718 0 00-.717.717c0 .395.322.717.717.717a.718.718 0 00.717-.717.718.718 0 00-.717-.717zM3.75 17.25a.75.75 0 010-1.5h.75a.75.75 0 010 1.5h-.75zM3.75 21a.75.75 0 010-1.5h.75a.75.75 0 010 1.5h-.75zM19.5 17.25a.75.75 0 010-1.5h.75a.75.75 0 010 1.5h-.75zM19.5 21a.75.75 0 010-1.5h.75a.75.75 0 010 1.5h-.75z" />
    </svg>
  );
}

export default SvgSchoolBuilding;
