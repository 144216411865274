import * as React from 'react';

function SvgDisable(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M5.568 16.558a.756.756 0 01-.645-.368C2.608 12.285 3.902 7.224 7.808 4.909a8.237 8.237 0 018.396 0 .746.746 0 01.147 1.174L6.098 16.338a.741.741 0 01-.53.22zm6.438-11.3a6.736 6.736 0 00-3.434.941c-2.883 1.709-4.05 5.291-2.799 8.343l8.783-8.783a6.742 6.742 0 00-2.535-.501l-.014-.375v.375z" />
      <path d="M12.04 24.019a11.919 11.919 0 01-8.419-3.452.787.787 0 01-.153-.153c-4.527-4.647-4.506-12.175.051-16.795A11.904 11.904 0 0112.058.054a11.92 11.92 0 018.378 3.407.816.816 0 01.129.129c4.626 4.674 4.612 12.254-.037 16.91a11.916 11.916 0 01-8.477 3.52l-.011-.001zm-7.438-4.591c2.002 1.997 4.639 3.091 7.438 3.091a10.426 10.426 0 007.426-3.08c4.085-4.092 4.082-10.746-.007-14.834l-.032-.033a10.43 10.43 0 00-7.369-3.018 10.416 10.416 0 00-7.471 3.119C.581 8.733.578 15.342 4.58 19.406a.281.281 0 00.017.018l.005.004z" />
      <path d="M12.021 20.258a8.206 8.206 0 01-4.183-1.153.756.756 0 01-.361-.552.746.746 0 01.214-.624L17.945 7.678a.745.745 0 01.624-.214.744.744 0 01.552.362 8.18 8.18 0 01.891 6.24 8.183 8.183 0 01-3.782 5.043 8.241 8.241 0 01-4.197 1.149h-.012zm-2.534-2.002a6.745 6.745 0 002.536.502 6.753 6.753 0 003.444-.94 6.694 6.694 0 003.093-4.124 6.723 6.723 0 00-.289-4.22l-8.784 8.782z" />
    </svg>
  );
}

export default SvgDisable;
