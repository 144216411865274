const baseStyles = {};

export const webStyles = {
  ...baseStyles,
  svg: (unit) => `
    fill: none;
    stroke: white;
    stroke-width: ${unit(2)};
  `,
  checkbox: (isChecked, unit, theme) => `
    display: inline-block;
    width: ${unit(16)};
    height: ${unit(16)};
    background: ${isChecked ? theme.color.primary : 'white'};
    border: ${unit(1)} solid
      ${isChecked ? theme.color.primary : theme.color.dark$6};
    border-radius: ${unit(3)};
    transition: all 150ms;
    input[type='checkbox']:hover + &,
    input[type='checkbox']:focus + & {
      box-shadow: ${theme.boxShadow.solidThin(theme.color.primary$5)};
      border-color: ${isChecked ? theme.color.primary : theme.color.primary$4};
    }
    input[type='checkbox']:disabled + &,
    input[type='checkbox']:disabled:hover + & {
      background-color: ${theme.color.dark$6};
      box-shadow: none;
      border-color: ${isChecked ? theme.color.dark$6 : '#d5d5d5'};
    }
    svg {
      transition: all 150ms;
      visibility: ${isChecked ? 'visible' : 'hidden'};
      transform: ${isChecked ? 'scale(1)' : 'scale(0)'};
    }
  `,
  label: (unit, theme) => `
    display: flex;
    align-items: center;
    span {
      margin-left: ${unit(8)};
      margin-right: ${unit(16)};
      ${theme.textStyle.body};
      &:empty {
        margin-right: 0;
      }
    }
  `,
};

export const nativeStyles = {
  ...baseStyles,
  wrapper: () => `
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 16px;
  `,
  checkbox: (theme) => `
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid ${theme.color.primary};
    border-radius: 3px;
    width: 18px;
    height: 18px;
    flex-grow: 0;
  `,
  label: () => `
    flex-grow: 1;
    margin-left: 8px;
  `,
};
