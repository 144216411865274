import * as React from 'react';

function SvgHeadphonesCustomerSupportHuman1(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M17.575 23.999a.75.75 0 01-.75-.75v-4.2c0-.267.144-.517.376-.65a8.287 8.287 0 003.874-5.098 8.185 8.185 0 00-.892-6.242 8.218 8.218 0 00-4.109-3.483v4.323a4.484 4.484 0 011.5 3.351c0 2.481-2.019 4.5-4.5 4.5-.186 0-.373-.012-.564-.038A8.306 8.306 0 015.574 19.5h-1.5a.75.75 0 010-1.5h1.5a6.724 6.724 0 005.436-2.755 4.472 4.472 0 01-2.436-3.995c0-1.285.544-2.496 1.5-3.351V3.427c-3.16.974-5.249 3.47-5.249 6.334v.75a.745.745 0 01-.126.416l-1.82 2.729a.744.744 0 00-.019.802.747.747 0 00.461.342l.936.234a.745.745 0 01.545.909.748.748 0 01-.908.546l-.937-.234a2.235 2.235 0 01-1.384-1.026 2.254 2.254 0 01.057-2.405l1.694-2.54v-.523c0-3.635 2.76-6.844 6.749-7.894V1.5c0-.827.673-1.5 1.5-1.5h3c.827 0 1.5.673 1.5 1.5v.488a9.686 9.686 0 015.399 4.307 9.675 9.675 0 011.054 7.379 9.829 9.829 0 01-4.203 5.798v3.778a.747.747 0 01-.748.749zM13.074 10.5a.75.75 0 01.75.75 8.151 8.151 0 01-.563 2.994 3.004 3.004 0 002.813-2.994c0-1.654-1.346-3-3-3s-3 1.346-3 3c0 1.151.666 2.195 1.688 2.693a6.66 6.66 0 00.562-2.693.75.75 0 01.75-.75zm0-3.75c.516 0 1.019.087 1.5.26V1.5h-3v5.51c.481-.173.984-.26 1.5-.26z" />
      <path d="M8.575 24a.75.75 0 01-.75-.75v-1.354l-.134.022a3.713 3.713 0 01-1.554-.066.751.751 0 01.188-1.476c.063 0 .126.008.187.024a2.24 2.24 0 00.931.04l1.01-.167a.747.747 0 01.873.74v2.239a.751.751 0 01-.751.748z" />
    </svg>
  );
}

export default SvgHeadphonesCustomerSupportHuman1;
