import * as React from 'react';

function SvgTeamMeeting(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M4.5 21c-1.861 0-3.375-1.514-3.375-3.375S2.639 14.25 4.5 14.25s3.375 1.514 3.375 3.375S6.361 21 4.5 21zm0-5.25c-1.034 0-1.875.841-1.875 1.875S3.466 19.5 4.5 19.5s1.875-.841 1.875-1.875S5.534 15.75 4.5 15.75z" />
      <path d="M.749 24a.75.75 0 01-.662-1.102 4.983 4.983 0 012.059-2.059 5 5 0 016.765 2.059A.75.75 0 018.25 24a.749.749 0 01-.662-.397 3.498 3.498 0 00-4.736-1.442 3.487 3.487 0 00-1.441 1.441.747.747 0 01-.662.398zM23.25 24a.748.748 0 01-.662-.397 3.48 3.48 0 00-2.069-1.701 3.503 3.503 0 00-1.023-.153 3.504 3.504 0 00-3.084 1.855.75.75 0 01-1.324-.707 4.977 4.977 0 012.723-2.354 3.38 3.38 0 01-1.686-2.919c0-1.861 1.514-3.375 3.375-3.375s3.375 1.514 3.375 3.375a3.38 3.38 0 01-1.686 2.919 4.964 4.964 0 012.723 2.353A.75.75 0 0123.25 24zm-3.75-8.25c-1.034 0-1.875.841-1.875 1.875S18.466 19.5 19.5 19.5s1.875-.841 1.875-1.875-.841-1.875-1.875-1.875zM12 6.75c-1.861 0-3.375-1.514-3.375-3.375S10.139 0 12 0s3.375 1.514 3.375 3.375S13.861 6.75 12 6.75zm0-5.25c-1.034 0-1.875.841-1.875 1.875S10.966 5.25 12 5.25s1.875-.841 1.875-1.875S13.034 1.5 12 1.5z" />
      <path d="M15.249 9a.748.748 0 01-.571-.264 3.521 3.521 0 00-5.358 0 .748.748 0 01-1.319-.426.743.743 0 01.177-.546 5.013 5.013 0 014.221-1.749 4.98 4.98 0 013.42 1.749.746.746 0 01-.57 1.236zM11.999 21.001a8.982 8.982 0 01-3.215-.593.743.743 0 01-.417-.394.753.753 0 01.952-1.007 7.498 7.498 0 005.404-.017.754.754 0 01.97.428.75.75 0 01-.428.971 8.996 8.996 0 01-3.266.612zM3.786 13.5a.745.745 0 01-.746-.682A8.8 8.8 0 012.999 12a8.996 8.996 0 012.837-6.547.748.748 0 011.262.571.749.749 0 01-.237.523A7.494 7.494 0 004.499 12c0 .206.011.423.035.682a.75.75 0 01-.748.818zM20.212 13.5a.743.743 0 01-.575-.269.743.743 0 01-.172-.548c.024-.271.035-.488.035-.683a7.485 7.485 0 00-2.363-5.452.751.751 0 01.512-1.297c.191 0 .373.072.512.202A8.989 8.989 0 0121 12c0 .242-.013.501-.041.816a.747.747 0 01-.747.684z" />
    </svg>
  );
}

export default SvgTeamMeeting;
