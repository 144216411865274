import * as React from 'react';

function SvgRealEstateDimensionsBlock(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M9.75 24a2.252 2.252 0 01-2.25-2.25v-12A2.252 2.252 0 019.75 7.5h12A2.252 2.252 0 0124 9.75v12A2.252 2.252 0 0121.75 24h-12zm12-1.5a.75.75 0 00.75-.75V19.5h-3v3h2.25zm-3.75 0v-6a.75.75 0 011.5 0V18h3v-5.25H18a.75.75 0 01-.75-.75V9h-1.5v7.5a.75.75 0 01-.75.75h-3.75a.75.75 0 010-1.5h3V9h-4.5a.75.75 0 00-.75.75V12h3a.75.75 0 010 1.5H9v6h3.75a.75.75 0 01.75.75v2.25H18zm-9-.75c0 .414.336.75.75.75H12V21l-3 .75zm13.5-10.5v-1.5a.75.75 0 00-.75-.75h-3v2.25h3.75zM3 24a.755.755 0 01-.26-.047l-.022-.008a.732.732 0 01-.249-.165L.22 21.53a.752.752 0 01.53-1.281c.2 0 .389.078.53.22l.97.97V7.061l-.97.969c-.141.142-.33.22-.53.22s-.389-.078-.53-.22C.078 7.889 0 7.7 0 7.5s.078-.389.22-.53l2.249-2.25A.74.74 0 013 4.5c.089 0 .177.016.261.048l.021.007a.74.74 0 01.249.166L5.78 6.97c.142.141.22.33.22.53s-.078.389-.22.53c-.141.142-.33.22-.53.22s-.389-.078-.53-.22l-.97-.97v14.379l.97-.97a.744.744 0 011.06 0 .752.752 0 010 1.061l-2.25 2.25a.763.763 0 01-.246.164l-.026.01A.747.747 0 013 24zM21 6a.743.743 0 01-.53-.22c-.142-.141-.22-.33-.22-.53s.078-.389.22-.53l.97-.97H7.061l.97.97a.744.744 0 01-.001 1.06c-.141.142-.33.22-.53.22s-.389-.078-.53-.22L4.72 3.53A.74.74 0 014.5 3c0-.089.016-.176.048-.261l.007-.021a.74.74 0 01.166-.249L6.97.22C7.111.078 7.3 0 7.5 0s.389.078.53.22c.142.141.22.33.22.53s-.078.389-.22.53l-.97.97h14.379l-.97-.97c-.142-.141-.22-.33-.22-.53s.078-.389.22-.53a.75.75 0 011.061 0l2.25 2.25a.74.74 0 01.163.245l.01.026a.748.748 0 010 .517l-.006.016a.764.764 0 01-.168.257L21.53 5.78A.743.743 0 0121 6z" />
    </svg>
  );
}

export default SvgRealEstateDimensionsBlock;
