import * as React from 'react';

function SvgToolsSaw(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M5.696 23.149a1.74 1.74 0 01-1.238-.513L.569 18.747c-.33-.33-.513-.77-.513-1.237s.182-.907.513-1.238l3.533-3.533a.696.696 0 01.108-.091L21.18.983a.752.752 0 01.955.088l1.591 1.59a.748.748 0 01.181.768L22.846 6.61a.751.751 0 01-.564.498l-2.225.446-.923 2.769a.753.753 0 01-.474.474l-2.826.942-.942 2.826a.75.75 0 01-.474.474l-2.827.942-.941 2.826c-.004.011-.008.023-.014.035a.726.726 0 01-.058.119l-.011.018a.772.772 0 01-.099.123l-3.535 3.534c-.331.331-.77.513-1.237.513zm-4.067-5.816a.245.245 0 00-.073.177c0 .068.025.129.073.177l3.889 3.889a.249.249 0 00.177.067.246.246 0 00.176-.067l3.005-3.005-4.243-4.242-3.004 3.004zm7.981-.151l.676-2.03a.756.756 0 01.474-.475l2.827-.942.942-2.826a.753.753 0 01.474-.474l2.826-.942.942-2.826a.751.751 0 01.564-.498l2.225-.446.776-2.329-.822-.821L5.803 13.375l3.807 3.807z" />
      <path d="M6.225 19.851a.743.743 0 01-.53-.22L3.573 17.51a.752.752 0 01.53-1.281c.2 0 .389.078.53.22l2.121 2.121a.752.752 0 01-.529 1.281z" />
    </svg>
  );
}

export default SvgToolsSaw;
