import * as React from 'react';

function SvgTableDelete(props) {
  return (
    <svg data-name="Layer 1" viewBox="0 0 22 22" {...props}>
      <path d="M18.65 1.25H3.26a1.991 1.991 0 00-2.01 2.016v15.549a1.969 1.969 0 00.644 1.485 1.8 1.8 0 001.167.456 1.457 1.457 0 00.2-.014H18.74a1.99 1.99 0 002.01-2.015V3.266a2.014 2.014 0 00-2.1-2.016zm.6 17.475a.485.485 0 01-.51.515l-15.573.006a.328.328 0 01-.28-.073.471.471 0 01-.137-.358V3.266a.486.486 0 01.51-.516h15.39c.376 0 .6.193.6.516z" />
      <path d="M14.531 7.47a.75.75 0 00-1.061 0L11 9.94 8.531 7.47A.75.75 0 007.47 8.53L9.94 11l-2.47 2.47a.75.75 0 101.061 1.06L11 12.061l2.47 2.469a.75.75 0 101.061-1.06L12.061 11l2.47-2.47a.749.749 0 000-1.06z" />
    </svg>
  );
}

export default SvgTableDelete;
