import * as React from 'react';

function SvgChat(props) {
  return (
    <svg viewBox="0 0 48 48" {...props}>
      <path d="M15 42c.104 0 .208-.016.313-.047a.891.891 0 00.28-.14L23.344 36H37c.833 0 1.542-.292 2.125-.875A2.893 2.893 0 0040 33V13c0-.833-.292-1.542-.875-2.125A2.893 2.893 0 0037 10H11c-.833 0-1.542.292-2.125.875A2.893 2.893 0 008 13v20c0 .833.292 1.542.875 2.125A2.893 2.893 0 0011 36h3v5c0 .188.052.365.156.531.104.167.24.292.406.375.063.021.13.042.204.063A.85.85 0 0015 42z" />
    </svg>
  );
}

export default SvgChat;
