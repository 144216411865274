import * as React from 'react';

function SvgTerrace(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <g
        stroke="#000"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M14 1a.5.5 0 00-.8-.4l-1.2.9-1.2-.9a.5.5 0 00-.8.4v2a2 2 0 104 0V1zM18 10a8.968 8.968 0 001.5-5" />
        <path d="M17.5 1a.5.5 0 01.8-.4l1.2.9 1.2-.9a.5.5 0 01.8.4v2a2 2 0 11-4 0V1zM6 10a8.968 8.968 0 01-1.5-5" />
        <path d="M6.5 1a.5.5 0 00-.8-.4l-1.2.9L3.3.6a.5.5 0 00-.8.4v2a2 2 0 104 0V1zM12 5v5M21.1 17.621a.5.5 0 01-.49.379H3.39a.5.5 0 01-.485-.379L1.5 12h21l-1.4 5.621zM23.5 11a1 1 0 01-1 1h-21a1 1 0 010-2h21a1 1 0 011 1z" />
      </g>
    </svg>
  );
}

export default SvgTerrace;
