import PropTypes from 'prop-types';
import { theme } from '@embracesbs/helpers';

export const propTypes = {
  /** An array of strings which includes the extra prop keys */
  extraProps: PropTypes.array,
  /** Whether the loader is shown on color background */
  hasBgColor: PropTypes.bool,
  /** The size of the progress fill, can be used to create a determinate progress indicator */
  progress: PropTypes.number,
  /** The color of the progress fill */
  progressColor: PropTypes.string,
  /** Size of the progress indicator */
  size: PropTypes.number,
};

export const defaultProps = {
  extraProps: [],
  hasBgColor: false,
  progress: 30,
  progressColor: theme.color.primary,
  size: 24,
};
