import React, { useContext } from 'react';
import styled from '@emotion/styled';
import { getExtraProps, withTheme } from '@embracesbs/helpers';
import Divider from '@embracesbs/component-divider';
import InfoCard from '@embracesbs/component-infocard';
import { SidebarContext } from '@embracesbs/component-sidebar';
import { propTypes, defaultProps } from './ListItem.props';
import { webStyles } from './ListItem.styles';

const StyledLi = styled.li`
  ${({ isDisabled, theme, hasOpacity }) => `
    ${webStyles.styledLi(theme)}
    cursor: ${
      !isDisabled ? `${theme.cursor.pointer}` : `${theme.cursor.default}`
    };
    opacity: ${isDisabled && !hasOpacity ? '0.5' : '1'};
  `};
  &:before {
    ${({ theme }) => webStyles.styledLiBefore(theme)}
    ${({
      isActive,
      isSelected,
      isDisabled,
      activeTabBackgroundColor,
      activeTabColor,
      theme,
    }) => `
      width: ${
        isActive
          ? `calc(${theme.percentage.full} - ${theme.spacing.$2})`
          : `${theme.percentage.full}`
      };
      background: ${
        (isActive || isSelected) && !isDisabled
          ? activeTabBackgroundColor
          : 'none'
      };
      border-left: ${theme.spacing.$2} solid
        ${isActive && !isDisabled ? activeTabColor : 'none'};
    `};
  }
  &:hover {
    &:before {
      ${({ isDisabled, hoverBackgroundColor }) => `
        background: ${!isDisabled ? hoverBackgroundColor : 'none'};
      `};
    }
  }
`;

const StyledContent = styled.div`
  ${({ theme }) => `
    width: ${theme.percentage.full};
  `}
`;

/**
 *
 * @param {*} Component
 */
const StyledLink = (Component) => styled(Component)`
  ${({ theme }) => `
    height: ${theme.percentage.full};
    width: ${theme.percentage.full};
    text-decoration: none;
  `}
`;

/**
 * ListItem component
 * @param {object} avatar - The avatar component properties
 * @param {string} image - giving a rounded image on the left side
 * @param {string} title - The main text of the list item
 * @param {boolean} hasSingleLineTitle - Whether the title should be on a single line
 * @param {string} titleColor - The main text of the list item
 * @param {object} icon - The icon component properties
 * @param {string} subTitle - The sub text of the list item
 * @param {string} subTitleColor - The sub text of the list item
 * @param {string} label - The label of the list item
 * @param {number|string} id - An id of the @{LinkItem} component
 * @param {string} activeTabBackgroundColor - The background color when the item is set to active
 * @param {string} hoverBackgroundColor - The background color on hover
 * @param {string} activeTabColor - The color when the item is set to active, indicated as a vertical line
 * @param {bool} hasOpacity - Indicates if the inactive state should have an opacity of 0.5
 * @param {bool} isActive - Indicates if the item is active
 * @param {bool} isSelected - Indicates if the item is active
 * @param {bool} hasDivider - Indicates if it has divider
 * @param {bool} isDisabled - Indicates if the list is disabled so that no futher interaction is allowed
 * @param {string} navLink - Navigation optional router
 * @param {object} navLinkProps - Props for the navigation link
 * @param {array} extraProps - Extra props
 * @param {function} onItemClick - Handler for clicking on a list item
 * @param {node} actions - Node for generating action item on the right side of the item
 * @param {number|object} spacing - Spacing of the list item
 * @param {bool} hasActionsVisibleOnHover - Whether the actions are visible only on hover
 */
const ListItem = (props) => {
  const {
    avatar,
    image,
    icon,
    title,
    titleColor,
    hasSingleLineTitle,
    subTitle,
    subTitleColor,
    label,
    actions,
    id,
    navLink,
    extraProps,
    theme,
    navLinkProps,
    activeTabBackgroundColor,
    activeTabColor,
    hoverBackgroundColor,
    isDisabled,
    onItemClick,
    isActive,
    isSelected,
    hasDivider,
    hasOpacity,
    spacing,
    hasActionsVisibleOnHover,
  } = props;

  let NavLinkComponent;
  const { isCollapsed } = useContext(SidebarContext);

  /**
   * On Click item callback
   *
   * @param {object} e
   */
  const onClickItem = (e) => {
    if (!isDisabled) {
      if (!isCollapsed) {
        e.stopPropagation();
      }
      onItemClick(id);
    } else {
      e.preventDefault();
    }
  };

  /**
   * On keyDown callback
   *
   * @param {object} e
   */
  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      onItemClick(id);
    }
  };

  if (navLink) {
    NavLinkComponent = StyledLink(navLink);
  }

  const avatarObjectProps = avatar ? { ...avatar, isDisabled } : avatar;

  const infoCard = (
    <InfoCard
      avatar={avatarObjectProps}
      icon={icon}
      image={image}
      title={title}
      titleColor={titleColor}
      hasSingleLineTitle={hasSingleLineTitle}
      subTitle={subTitle}
      subTitleColor={subTitleColor}
      label={label}
      actions={actions}
      spacing={spacing}
      hasActionsVisibleOnHover={hasActionsVisibleOnHover}
      {...getExtraProps(null, extraProps, 'InfoCard')}
    />
  );

  return (
    <>
      <StyledLi
        {...getExtraProps(props, extraProps)}
        isActive={isActive}
        isSelected={isSelected}
        className={`listItem ${isActive ? `activeItem` : ``}`}
        id={id}
        key={id}
        onClick={(e) => onClickItem(e)}
        onKeyDown={(e) => handleKeyDown(e)}
        activeTabBackgroundColor={activeTabBackgroundColor}
        hoverBackgroundColor={hoverBackgroundColor}
        activeTabColor={activeTabColor}
        isDisabled={isDisabled}
        hasOpacity={hasOpacity}
        theme={theme}
        tabIndex={0}
        aria-label={title || 'List Item'}
        aria-selected={isActive}
      >
        <StyledContent>
          {navLink && Object.keys(navLinkProps).length ? (
            <NavLinkComponent {...navLinkProps}>{infoCard}</NavLinkComponent>
          ) : (
            infoCard
          )}
        </StyledContent>
        {hasDivider && <Divider size="100%" wrapperSize="100%" />}
      </StyledLi>
    </>
  );
};

ListItem.propTypes = propTypes;
ListItem.defaultProps = defaultProps;

/** @component */
export default withTheme(ListItem);
