import * as React from 'react';

function SvgDisabilityCane(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M13.5 6.75c-1.861 0-3.375-1.514-3.375-3.375S11.639 0 13.5 0s3.375 1.514 3.375 3.375S15.361 6.75 13.5 6.75zm0-5.25c-1.034 0-1.875.841-1.875 1.875S12.466 5.25 13.5 5.25s1.875-.841 1.875-1.875S14.534 1.5 13.5 1.5zM4.5 24a.75.75 0 01-.75-.75V16.5c0-1.241 1.009-2.25 2.25-2.25s2.25 1.009 2.25 2.25a.75.75 0 01-1.5 0 .75.75 0 00-1.5 0v6.75a.75.75 0 01-.75.75z" />
      <path d="M15 24a2.257 2.257 0 01-1.5-.575A2.257 2.257 0 0112 24a2.252 2.252 0 01-2.25-2.25V15a.75.75 0 011.5 0v6.75a.75.75 0 001.5 0V16.5a.75.75 0 011.5 0v5.25a.75.75 0 001.5 0V12a.75.75 0 011.5 0v3a.75.75 0 001.5 0v-3A3.754 3.754 0 0015 8.25h-3A3.754 3.754 0 008.25 12a.75.75 0 01-1.5 0A5.256 5.256 0 0112 6.75h3A5.256 5.256 0 0120.25 12v3a2.252 2.252 0 01-3 2.122v4.628A2.252 2.252 0 0115 24z" />
    </svg>
  );
}

export default SvgDisabilityCane;
