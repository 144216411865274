import React from 'react';
import styled from '@emotion/styled';
import { getExtraProps } from '@embracesbs/helpers';
import { propTypes, defaultProps } from './SidebarFooter.props';

const StyledFooter = styled.div`
  width: 100%;
  div {
    white-space: nowrap;
  }
`;

/**
 * SidebarFooter component
 * @param {*} props
 */
const SidebarFooter = (props) => {
  const { children, extraProps } = props;

  return (
    <StyledFooter {...getExtraProps(props, extraProps)}>
      {children}
    </StyledFooter>
  );
};

SidebarFooter.propTypes = propTypes;
SidebarFooter.defaultProps = defaultProps;

/** @component */
export default SidebarFooter;
