import * as React from 'react';

function SvgEmailActionReply(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M22.9 21.6c-.4 0-.8-.3-.8-.8 0-3.7-3-6.7-6.8-6.7h-5.2v2.2c0 .6-.3 1.2-.7 1.6-.4.4-1 .6-1.5.6-.6 0-1.2-.3-1.7-.7L.7 11.9C0 11 0 9.7.7 8.8l5.5-5.9c.4-.5 1-.7 1.6-.7.6 0 1.2.2 1.6.7.4.4.7 1 .7 1.6v2.2h5.2c4.5 0 8.2 3.7 8.2 8.2v6c.1.3-.2.7-.6.7zM7.9 3.7c-.2 0-.4.1-.6.2L1.8 9.8c-.3.3-.3.7 0 1l5.5 5.9c.1.2.3.3.6.3.2 0 .4-.1.5-.2.2-.1.2-.3.2-.5v-2.9c0-.4.3-.8.8-.8h6c2.7 0 5.2 1.3 6.7 3.5v-1.3c0-3.7-3-6.8-6.8-6.8h-6c-.1.1-.3 0-.5-.1s-.2-.4-.2-.6V4.4c0-.2-.1-.4-.2-.5-.2-.1-.3-.2-.5-.2z" />
    </svg>
  );
}

export default SvgEmailActionReply;
