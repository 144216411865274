import * as React from 'react';

function SvgECommerceAppStore(props) {
  return (
    <svg data-name="Outline Version" viewBox="0 0 24 24" {...props}>
      <path d="M6 19.12a1.28 1.28 0 001.1-.64l5.2-9.07a1.27 1.27 0 00-.47-1.73l-1.33-.77a1.27 1.27 0 00-1.74.47l-5.2 9.06A1.27 1.27 0 004 18.17l1.35.78a1.26 1.26 0 00.65.17zm4-10.8l1 .55-5 8.67L5 17zM2.21 22.5a.87.87 0 00.46.13 1.08 1.08 0 00.6-.2l1.52-1a1 1 0 00-.07-1.75L3.55 19a1 1 0 00-1.55.75l-.2 1.78a1 1 0 00.41.97z" />
      <path d="M20.36 15.29C14.94 3.84 13.05 1.29 11.89 1a1.07 1.07 0 00-.89.1 1.4 1.4 0 00-.51 1.36C10.68 5.3 17 18.12 18.1 18.69a1.68 1.68 0 001.64 0h.1A2.08 2.08 0 0021 17.54a1.42 1.42 0 00-.1-1.16c-.07-.11-.25-.5-.54-1.09zm-1.1 2l-.12.05-.18.07h-.09c-1.19-1.45-6-11.23-6.79-14.41C13 4.15 15 7.39 19 15.93c.28.6.48 1 .55 1.14a.79.79 0 01-.29.23zM20.46 19.92a1.32 1.32 0 00-.24 2 2.45 2.45 0 001 .74l.18.09a.75.75 0 00.63.35h.07a.75.75 0 00.68-.65c.08-.62.17-2.12-.69-2.66a1.37 1.37 0 00-1.55.11zM10.81 15.37h3.09a.75.75 0 000-1.5h-3.09a.75.75 0 100 1.5zM4.88 11.45a.75.75 0 100-1.5H.75a.75.75 0 00-.75.75v3.91a.75.75 0 00.75.75H3.2a.75.75 0 000-1.5H1.5v-2.41zM23.25 10h-3.57a.75.75 0 000 1.5h2.82v2.41h-1a.75.75 0 000 1.5h1.78a.75.75 0 00.75-.75V10.7a.75.75 0 00-.78-.7z" />
    </svg>
  );
}

export default SvgECommerceAppStore;
