import * as React from 'react';

function SvgCakeBirthday(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M1.473 24a.75.75 0 010-1.5h21a.75.75 0 010 1.5h-21zM4.473 21a2.252 2.252 0 01-2.25-2.25v-7.5A2.252 2.252 0 014.473 9h.75V7.5a.75.75 0 011.5 0V9h4.5V7.5a.75.75 0 011.5 0V9h4.5V7.5a.75.75 0 011.5 0V9h.75a2.252 2.252 0 012.25 2.25v7.5a2.252 2.252 0 01-2.25 2.25h-15zm-.75-2.25c0 .414.336.75.75.75h15a.75.75 0 00.75-.75v-2.817a3.356 3.356 0 01-2.714.459 3.354 3.354 0 01-1.41-.758 3.347 3.347 0 01-2.245.866c-.84 0-1.642-.312-2.256-.866a3.33 3.33 0 01-2.245.866h-.057a3.323 3.323 0 01-1.825-.57c-.554.37-1.21.571-1.875.571a3.359 3.359 0 01-1.872-.566v2.815zm3.75-4.542c.202 0 .398.083.538.228.346.356.81.556 1.307.564h.057a1.82 1.82 0 001.267-.526c.12-.117.224-.25.31-.395a.752.752 0 011.295.001c.331.568.947.92 1.606.92a1.854 1.854 0 001.598-.92.753.753 0 01.647-.372c.266 0 .514.142.648.37.334.568.952.921 1.614.921a1.864 1.864 0 001.864-1.872V11.25a.75.75 0 00-.75-.75h-15a.75.75 0 00-.75.75v1.875c-.001.5.193.971.547 1.325.352.354.822.55 1.322.55.504 0 .993-.206 1.343-.566a.757.757 0 01.537-.226zM11.973 5.25A2.252 2.252 0 019.723 3c0-1.067 1.327-2.409 1.734-2.794a.746.746 0 011.032 0c.407.385 1.734 1.726 1.734 2.794a2.252 2.252 0 01-2.25 2.25zm0-3.414c-.51.585-.75 1.028-.75 1.164a.75.75 0 001.5 0c0-.136-.24-.58-.75-1.164zM17.973 5.25A2.252 2.252 0 0115.723 3c0-1.068 1.327-2.409 1.734-2.794a.75.75 0 011.032-.001c.407.386 1.734 1.727 1.734 2.795a2.252 2.252 0 01-2.25 2.25zm0-3.415c-.51.585-.75 1.028-.75 1.165a.75.75 0 001.5 0c0-.137-.24-.58-.75-1.165zM5.973 5.25A2.252 2.252 0 013.723 3C3.723 1.933 5.05.591 5.457.206a.746.746 0 011.032 0C6.896.591 8.223 1.933 8.223 3a2.252 2.252 0 01-2.25 2.25zm0-3.415c-.51.585-.75 1.029-.75 1.165a.75.75 0 001.5 0c0-.137-.24-.58-.75-1.165z" />
    </svg>
  );
}

export default SvgCakeBirthday;
