import * as React from 'react';

function SvgOfficeFilePdf1(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M2.246 23.998a2.252 2.252 0 01-2.25-2.25v-19.5a2.252 2.252 0 012.25-2.25h10.629c.602 0 1.167.234 1.592.66l5.871 5.871c.425.425.659.99.659 1.592v4.628a.75.75 0 01-1.5 0v-3.75h-5.25a2.252 2.252 0 01-2.25-2.25v-5.25h-9.75a.75.75 0 00-.75.75v19.5c0 .414.336.75.75.75h3a.75.75 0 010 1.5H2.246zm11.25-17.25c0 .414.336.75.75.75h4.939l-5.689-5.689v4.939z" />
      <path d="M8.246 23.998a.75.75 0 01-.75-.75v-7.5a.75.75 0 01.75-.75H9c1.654 0 3 1.346 3 3s-1.346 3-3 3h-.004v2.25a.75.75 0 01-.75.75zm.754-4.5c.827 0 1.5-.673 1.5-1.5s-.673-1.5-1.5-1.5h-.004v3H9zM14.246 23.998a.75.75 0 01-.75-.75v-7.5a.75.75 0 01.75-.75 3.754 3.754 0 013.75 3.75v1.5a3.754 3.754 0 01-3.75 3.75zm.75-1.629a2.264 2.264 0 001.5-2.121v-1.5c0-.962-.621-1.808-1.5-2.121v5.742zM20.246 23.998a.75.75 0 01-.75-.75v-6a2.252 2.252 0 012.25-2.25h1.5a.75.75 0 010 1.5h-1.5a.75.75 0 00-.75.75v2.25h1.5a.75.75 0 010 1.5h-1.5v2.25a.75.75 0 01-.75.75z" />
    </svg>
  );
}

export default SvgOfficeFilePdf1;
