import * as React from 'react';

function SvgBedroom(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <g
        transform="translate(1)"
        stroke="#262338"
        strokeWidth={1.5}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <ellipse cx={5.992} cy={16.5} rx={2.098} ry={2.25} />
        <path d="M10.187 14.25h7.924c1.03 0 1.865.895 1.865 2v2.5h-9.789v-4.5zm-9.789 4.5h19.577v3H.398zm0 4.5v-9m19.578 9v-1.5M17.78 5.907a.786.786 0 01-.17.59.679.679 0 01-.524.253h-4.009a.679.679 0 01-.525-.253.786.786 0 01-.17-.59l.525-4.5c.044-.375.341-.657.694-.657h2.96c.353 0 .65.282.694.657l.525 4.5zm-2.699.843v4.5" />
      </g>
    </svg>
  );
}

export default SvgBedroom;
