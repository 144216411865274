import * as React from 'react';

function SvgFileApk(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M2.251 24.003a2.252 2.252 0 01-2.25-2.25v-19.5a2.252 2.252 0 012.25-2.25H12.88a2.239 2.239 0 011.591.66l5.872 5.871c.425.425.659.99.659 1.592v4.627a.75.75 0 01-1.5 0v-3.75h-5.25a2.252 2.252 0 01-2.25-2.25v-5.25h-9.75a.75.75 0 00-.75.75v19.5c0 .414.336.75.75.75h3a.75.75 0 010 1.5H2.251zm11.25-17.25c0 .414.336.75.75.75h4.939l-5.689-5.688v4.938z" />
      <path d="M11.251 24.003a.75.75 0 01-.75-.75v-2.25h-1.5v2.25a.75.75 0 01-1.5 0v-6c0-1.241 1.009-2.25 2.25-2.25s2.25 1.009 2.25 2.25v6a.75.75 0 01-.75.75zm-.75-4.5v-2.25a.75.75 0 00-1.5 0v2.25h1.5zM14.251 24.003a.75.75 0 01-.75-.75v-2.962a.54.54 0 010-.08v-4.458a.75.75 0 01.75-.75h.748c1.654 0 3 1.346 3 3a3.003 3.003 0 01-2.998 3v2.25a.75.75 0 01-.75.75zm.75-4.5c.826-.001 1.498-.674 1.498-1.5s-.672-1.499-1.498-1.5v3zM23.252 24.003a.75.75 0 01-.586-.281l-1.664-2.08v1.612a.75.75 0 01-1.5 0v-7.5a.75.75 0 011.5 0v1.612l1.665-2.081a.751.751 0 011.172.938l-2.625 3.281 2.625 3.281a.75.75 0 01-.587 1.218z" />
    </svg>
  );
}

export default SvgFileApk;
