import * as React from 'react';

function SvgFileDmg(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M2.24 24.004a2.252 2.252 0 01-2.25-2.25v-19.5A2.252 2.252 0 012.24.004h10.629c.601 0 1.166.234 1.591.658l5.87 5.871c.425.425.659.99.659 1.591l.001.129-.001.026v4.474a.75.75 0 01-1.5 0v-3.75H14.24a2.252 2.252 0 01-2.25-2.25v-5.25H2.24a.75.75 0 00-.75.75v19.5c0 .414.336.75.75.75h1.5a.75.75 0 010 1.5h-1.5zm11.25-17.25c0 .414.336.75.75.75h4.939l-5.689-5.69v4.94zM21.74 24.004a2.252 2.252 0 01-2.25-2.25v-4.5a2.252 2.252 0 012.25-2.25h1.5a.75.75 0 010 1.5h-1.5a.75.75 0 00-.75.75v4.5a.75.75 0 001.5 0v-.75a.75.75 0 010-1.5h.75a.75.75 0 01.75.75v1.5a2.252 2.252 0 01-2.25 2.25z" />
      <path d="M17.24 24.004a.75.75 0 01-.75-.75v-4.792l-.857 1.428a.752.752 0 01-1.286-.001l-.857-1.428v4.792a.75.75 0 01-1.5 0v-7.5a.752.752 0 01.751-.751c.262 0 .508.14.643.365l1.607 2.678 1.607-2.678a.754.754 0 01.844-.337.75.75 0 01.549.722v7.5a.753.753 0 01-.751.752zM6.74 24.004a.75.75 0 01-.75-.75v-7.5a.75.75 0 01.75-.75 3.754 3.754 0 013.75 3.75v1.5a3.754 3.754 0 01-3.75 3.75zm.75-1.629a2.264 2.264 0 001.5-2.121v-1.5c0-.962-.621-1.808-1.5-2.121v5.742z" />
    </svg>
  );
}

export default SvgFileDmg;
