import * as React from 'react';

function SvgPhoneActionsNext(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M14.785 24.021a5.26 5.26 0 01-2.826-.829l-.038-.026A41.144 41.144 0 01.792 12.017C-.531 9.94-.234 7.257 1.52 5.503l.63-.629a2.233 2.233 0 011.59-.657c.602 0 1.167.234 1.591.658l2.652 2.651c.425.425.659.99.658 1.592 0 .602-.234 1.167-.659 1.591a.75.75 0 000 1.06l4.242 4.243c.141.141.329.219.53.219s.389-.078.53-.219c.425-.425.99-.658 1.592-.658s1.167.234 1.591.658l2.645 2.644c.433.433.667.998.667 1.599s-.235 1.166-.66 1.591l-.628.629a5.207 5.207 0 01-3.706 1.546zM3.741 5.716a.744.744 0 00-.53.219l-.631.63a3.728 3.728 0 00-.511 4.667 39.666 39.666 0 0010.703 10.699l.023.016a3.705 3.705 0 001.984.575c.996 0 1.938-.394 2.653-1.108l.629-.629a.745.745 0 00.22-.531.745.745 0 00-.215-.526l-2.658-2.656c-.142-.142-.33-.219-.531-.219s-.389.078-.53.219c-.425.425-.99.658-1.592.658a2.234 2.234 0 01-1.59-.658l-4.242-4.243a2.253 2.253 0 010-3.182.75.75 0 00.001-1.061l-2.653-2.65a.743.743 0 00-.53-.22zM14.232 9.762a.75.75 0 01-.75-.75c0-3.308 2.692-6 6-6h1.939l-1.72-1.72c-.142-.141-.22-.33-.22-.53s.078-.389.22-.53a.749.749 0 011.06 0l3 3a.74.74 0 01.163.245l.01.026a.73.73 0 010 .517l-.006.016a.764.764 0 01-.168.257l-2.999 2.999a.744.744 0 01-1.06 0c-.142-.141-.22-.33-.22-.53s.078-.389.22-.53l1.72-1.72h-1.939a4.505 4.505 0 00-4.5 4.5.75.75 0 01-.75.75z" />
    </svg>
  );
}

export default SvgPhoneActionsNext;
