import * as React from 'react';

function SvgPin(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M.75 23.999a.743.743 0 01-.53-.22c-.142-.141-.22-.33-.22-.53s.078-.389.22-.53l7.474-7.474-3.575-3.575a2.248 2.248 0 01-.588-2.16c.151-.582.52-1.07 1.039-1.374a8.266 8.266 0 015.564-1.002l3.877-6.094c.089-.139.192-.268.308-.383.424-.425.989-.659 1.59-.659s1.166.234 1.591.659L23.343 6.5a2.236 2.236 0 01.605 2.079 2.238 2.238 0 01-.988 1.41l-6.092 3.877a8.257 8.257 0 01-1 5.562 2.234 2.234 0 01-1.942 1.114 2.239 2.239 0 01-1.593-.661l-3.576-3.577L1.28 23.78a.746.746 0 01-.53.219zM8.72 8.513c-1.186 0-2.36.318-3.394.919a.746.746 0 00-.148 1.175l8.214 8.214c.142.142.331.22.532.22a.743.743 0 00.646-.369 6.737 6.737 0 00.728-4.985.75.75 0 01.326-.808l6.529-4.155a.748.748 0 00.128-1.163L16.44 1.718a.745.745 0 00-.531-.22.743.743 0 00-.633.348l-4.155 6.53a.746.746 0 01-.808.326 6.809 6.809 0 00-1.593-.189z" />
    </svg>
  );
}

export default SvgPin;
