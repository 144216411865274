import * as React from 'react';

function SvgMessagesBubbleSquareQuotation(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M5.25 24a.747.747 0 01-.75-.75V19.5H2.25A2.252 2.252 0 010 17.25v-15A2.252 2.252 0 012.25 0h19.5A2.252 2.252 0 0124 2.25v15a2.252 2.252 0 01-2.25 2.25H11.5l-5.8 4.35a.753.753 0 01-.45.15zm-3-22.5a.75.75 0 00-.75.75v15c0 .414.336.75.75.75h3a.75.75 0 01.75.75v3l4.8-3.6a.753.753 0 01.45-.15h10.5a.75.75 0 00.75-.75v-15a.75.75 0 00-.75-.75H2.25z" />
      <path d="M14.415 13.006a.751.751 0 01-.314-1.431 2.032 2.032 0 001.181-1.738c0-.127-.016-.289-.052-.46a1.496 1.496 0 01-.856.363.74.74 0 01-.248 0 1.5 1.5 0 01-1.366-1.367.712.712 0 010-.246 1.486 1.486 0 011.241-1.352.717.717 0 01.228-.023 1.141 1.141 0 01.146.009h.005c1.575.122 2.401 1.66 2.401 3.117-.073 1.345-.859 2.509-2.052 3.059a.74.74 0 01-.314.069zM9.165 13.006a.751.751 0 01-.314-1.431 2.032 2.032 0 001.181-1.738c0-.127-.016-.289-.052-.46a1.503 1.503 0 01-.856.363.74.74 0 01-.248 0A1.503 1.503 0 017.51 8.374a.74.74 0 010-.248 1.486 1.486 0 011.24-1.351.73.73 0 01.229-.023 1.324 1.324 0 01.146.009h.005c1.575.122 2.401 1.66 2.401 3.117-.073 1.345-.859 2.509-2.052 3.059a.74.74 0 01-.314.069z" />
    </svg>
  );
}

export default SvgMessagesBubbleSquareQuotation;
