import * as React from 'react';

function SvgMessagesHide(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M5.2 24c-.1 0-.2 0-.3-.1-.3-.1-.4-.4-.4-.6l-.2-3.1c0-.4.3-.8.7-.8.4 0 .8.3.8.7l.1 1.7 4.9-3.7c.1-.1.2-.1.4-.1h10.5c.4 0 .8-.4.8-.8v-12c0-.4.3-.8.8-.8s.7.4.7.8v12c0 1.2-1 2.2-2.2 2.2H11.5l-5.8 4.3c-.2.2-.3.3-.5.3zM.8 16.8c-.4 0-.8-.4-.8-.8V2.2C0 1 1.1 0 2.3 0H18c.4 0 .8.3.8.8s-.4.7-.8.7H2.3c-.4 0-.8.4-.8.8V16c0 .4-.3.8-.7.8zM.9 20.8c-.2 0-.4-.1-.6-.3-.3-.3-.2-.8.1-1.1L22.5.4c.3-.3.8-.2 1.1.1.3.3.2.8-.1 1.1L1.4 20.7c-.2.1-.3.1-.5.1z" />
    </svg>
  );
}

export default SvgMessagesHide;
