import * as React from 'react';

function SvgMediaUpload(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M1.46 6.895l11.987-2.169a.769.769 0 00.62-.892l-.434-2.4A1.581 1.581 0 0011.797.163l-10.39 1.88a1.566 1.566 0 00-1.017.655c-.24.348-.332.767-.257 1.182l.434 2.397a.767.767 0 00.893.62zM5.351 4.63l.115-1.745 3.235-.571-.114 1.73-3.236.586zm6.77-2.921l.297 1.642-2.271.41.113-1.723 1.86-.33zM3.908 3.16l-.115 1.75-1.837.333-.275-1.69 2.227-.393zM5.197 12.911l-1.815.284-.9-4.972a.767.767 0 00-.893-.62.768.768 0 00-.62.893l.899 4.973a1.584 1.584 0 001.836 1.273l1.764-.32a.769.769 0 00-.27-1.51zM23.53 11.095L22.184 8.9a1.694 1.694 0 00-1.032-.758L12.088 5.85a1.692 1.692 0 00-1.287.187c-.39.233-.666.604-.778 1.043l-2.98 11.784c-.112.441-.045.898.188 1.287.232.39.603.666 1.042.777l10.851 2.745a1.672 1.672 0 001.287-.188c.39-.232.666-.603.777-1.044L23.729 12.4c.111-.438.039-.914-.199-1.304zm-3.824 10.973c-.02.076-.12.143-.207.123l-10.85-2.746a.168.168 0 01-.123-.206l2.98-11.784c.017-.066.096-.122.167-.127.014 0 .027 0 .04.004l9.065 2.292a.17.17 0 01.103.076l1.344 2.192a.18.18 0 01.022.133l-2.541 10.043z" />
      <path d="M13.51 13.574a2.171 2.171 0 002.633-1.57 2.172 2.172 0 00-1.57-2.632 2.171 2.171 0 00-2.632 1.57c-.142.56-.057 1.143.24 1.64s.77.85 1.33.992zm-.087-2.257a.639.639 0 111.238.313.639.639 0 01-1.238-.313zM18.026 15.137a1.393 1.393 0 00-2.23-.59l-1.594 1.38-.312-.414a1.398 1.398 0 00-2.05-.193l-1.76 1.594a.765.765 0 101.027 1.133l1.652-1.495.715.947a.763.763 0 001.109.117l2.063-1.793 1.41 3.983a.765.765 0 001.442-.511l-1.473-4.158z" />
    </svg>
  );
}

export default SvgMediaUpload;
