import getExtraProps from './getExtraProps';
import {
  iconValidation,
  validatePropType,
  requireOneOf,
} from './propsValidation';
import theme, { extendThemeColorsWithExtraShades } from './theme';
import unit, { reverseUnit } from './unitMeasurement';
import withTheme from './withTheme';
import withMemo from './withMemo';
import { getSpacingObject, getSpacingString } from './spacing';
import { isObject, mergeDeep } from './mergeDeep';
import fontStyles from './fontFaces';
import {
  removeKeysFromObject,
  getValueFromObject,
  setValueInObject,
} from './objectManipulations';
import readableColor, { useIsDarkTheme } from './readableColor';
import ThemeController, { constructThemeObject } from './ThemeController';

export {
  getExtraProps,
  iconValidation,
  validatePropType,
  requireOneOf,
  theme,
  extendThemeColorsWithExtraShades,
  unit,
  reverseUnit,
  withTheme,
  withMemo,
  isObject,
  mergeDeep,
  getSpacingObject,
  getSpacingString,
  fontStyles,
  removeKeysFromObject,
  getValueFromObject,
  setValueInObject,
  readableColor,
  useIsDarkTheme,
  ThemeController,
  constructThemeObject,
};
