import * as React from 'react';

function SvgRatingStar(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M18.894 24.004c-.242 0-.485-.058-.702-.167L12 20.771l-6.187 3.064a1.557 1.557 0 01-2.094-.696 1.561 1.561 0 01-.114-1.103l1.85-6.721L.462 10.37a1.554 1.554 0 01.966-2.655l6.018-.596L10.602.862a1.559 1.559 0 011.4-.858 1.577 1.577 0 011.395.855l3.162 6.265 6.03.598a1.552 1.552 0 01.954 2.653l-4.989 4.944 1.849 6.715a1.568 1.568 0 01-1.108 1.919c-.13.033-.265.051-.401.051zM12 19.184c.115 0 .231.027.334.079l6.528 3.232a.07.07 0 00.032.009c.05-.011.07-.046.061-.079l-1.963-7.131a.752.752 0 01.195-.732l5.3-5.253a.054.054 0 00.016-.034.057.057 0 00-.05-.06l-6.452-.639a.75.75 0 01-.596-.408L12.06 1.539a.071.071 0 00-.029-.028.064.064 0 00-.031-.007c-.04.009-.053.02-.062.037L8.6 8.163a.749.749 0 01-.596.409l-6.44.638c-.032.002-.043.013-.046.016a.053.053 0 00-.016.039.05.05 0 00.016.038l5.304 5.253a.75.75 0 01.195.732l-1.965 7.138a.061.061 0 00.006.039.065.065 0 00.084.028l6.524-3.231a.758.758 0 01.334-.078z" />
    </svg>
  );
}

export default SvgRatingStar;
