import * as React from 'react';

function SvgFilePhp(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M2.25 24.004A2.252 2.252 0 010 21.754v-19.5A2.252 2.252 0 012.25.004h10.629c.601 0 1.165.234 1.59.658l5.872 5.871c.425.426.659.991.659 1.592v4.629a.75.75 0 01-1.5 0v-3.75h-5.25A2.252 2.252 0 0112 6.754v-5.25H2.25a.75.75 0 00-.75.75v19.5c0 .414.336.75.75.75H4.5a.75.75 0 010 1.5H2.25zM13.5 6.754c0 .414.336.75.75.75h4.94l-5.69-5.69v4.94z" />
      <path d="M7.5 24.004a.75.75 0 01-.75-.75v-7.5a.75.75 0 01.75-.75h.75c1.654 0 3 1.346 3 3s-1.346 3-3 3v2.25a.75.75 0 01-.75.75zm.75-4.5c.827 0 1.5-.673 1.5-1.5s-.673-1.5-1.5-1.5v3zM19.5 24.004a.75.75 0 01-.75-.75v-7.5a.75.75 0 01.75-.75h.75c1.654 0 3 1.346 3 3s-1.346 3-3 3v2.25a.75.75 0 01-.75.75zm.75-4.5c.827 0 1.5-.673 1.5-1.5s-.673-1.5-1.5-1.5v3zM16.5 24.004a.75.75 0 01-.75-.75v-2.25h-1.5v2.25a.75.75 0 01-1.5 0v-7.5a.75.75 0 011.5 0v3.75h1.5v-3.75a.75.75 0 011.5 0v7.5a.75.75 0 01-.75.75z" />
    </svg>
  );
}

export default SvgFilePhp;
