import * as React from 'react';

function SvgVipCrownQueen1(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M3 21.931c-1.654 0-3-1.346-3-3 0-.97.48-1.885 1.269-2.446L.014 7.029a1.483 1.483 0 01.144-.876 1.491 1.491 0 011.343-.829c.23 0 .462.055.67.159a.764.764 0 01.119.074l4.281 3.259-.204.317.224-.3 4.225-6.18a.758.758 0 01.052-.068 1.496 1.496 0 012.312.067l4.227 6.182 4.301-3.276a.835.835 0 01.119-.074 1.507 1.507 0 01.883-.143c.397.057.748.265.988.585.24.32.342.716.284 1.112l-1.253 9.449A3.006 3.006 0 0124 18.931c0 1.654-1.346 3-3 3H3zm-.639-4.355a1.5 1.5 0 00-.861 1.355c0 .827.673 1.5 1.5 1.5h18c.827 0 1.5-.673 1.5-1.5 0-.582-.342-1.114-.871-1.36h-.001a1.582 1.582 0 00-.308-.105l-.008-.001a1.777 1.777 0 00-.311-.034H3c-.213 0-.418.045-.612.134l-.027.011zm.349-1.63c.096-.01.187-.014.275-.015h18.014c.093 0 .188.005.29.015l1.207-9.104-4.159 3.167a1.472 1.472 0 01-1.08.315 1.488 1.488 0 01-1.091-.649L12 3.58 7.83 9.679a1.485 1.485 0 01-1.241.653 1.495 1.495 0 01-.948-.34L1.503 6.843l1.207 9.103z" />
    </svg>
  );
}

export default SvgVipCrownQueen1;
