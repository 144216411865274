import PropTypes from 'prop-types';

export const propTypes = {
  /** An array of strings which includes the extra prop keys */
  extraProps: PropTypes.array,
  /** The name of the animation */
  animation: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /** The width of the animation */
  animationWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /** The height of the animation */
  animationHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /** Whether the animation is paused */
  isPaused: PropTypes.bool,
  /** Whether the animation is looping */
  isLoop: PropTypes.bool,
  /** Whether the component is using dark mode */
  isDark: PropTypes.bool,
};

export const defaultProps = {
  extraProps: [],
  animation: 'voice',
  animationWidth: 200,
  animationHeight: 200,
  isPaused: false,
  isLoop: true,
  isDark: false,
};
