import * as React from 'react';

function SvgRuler(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M6.696 23.794a2.235 2.235 0 01-1.591-.658L.862 18.893c-.425-.425-.659-.99-.659-1.591s.234-1.166.659-1.591L15.712.862c.425-.425.99-.659 1.591-.659s1.166.234 1.591.659l4.243 4.243c.425.425.659.99.659 1.591s-.234 1.166-.659 1.591L8.287 23.136a2.235 2.235 0 01-1.591.658zm-4.773-7.022a.747.747 0 000 1.061l4.243 4.242c.141.141.33.219.53.219s.389-.078.53-.219l14.85-14.849a.744.744 0 000-1.06l-4.243-4.243a.747.747 0 00-1.061 0l-2.121 2.121 1.591 1.591c.142.141.22.33.22.53s-.078.389-.22.53a.744.744 0 01-1.06 0l-1.591-1.591-2.121 2.121 1.591 1.591c.142.141.22.33.22.53s-.078.389-.22.53a.749.749 0 01-1.06 0L10.41 8.285l-2.121 2.121 1.591 1.591a.752.752 0 01-.53 1.281.743.743 0 01-.53-.22l-1.591-1.591-2.123 2.123 1.591 1.591a.744.744 0 010 1.06.743.743 0 01-.53.22.743.743 0 01-.53-.22L4.045 14.65l-2.122 2.122z" />
    </svg>
  );
}

export default SvgRuler;
