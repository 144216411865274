import * as React from 'react';

function SvgTableInsertRowAbove(props) {
  return (
    <svg data-name="Layer 1" viewBox="0 0 22 22" {...props}>
      <path d="M18.714 1.25h-3.451a.75.75 0 100 1.5h3.451a.537.537 0 01.536.536v10.047H2.75V3.286a.536.536 0 01.536-.536h3.451a.75.75 0 000-1.5H3.286A2.038 2.038 0 001.25 3.286v15.428a2.038 2.038 0 002.036 2.036h15.428a2.038 2.038 0 002.036-2.036V3.286a2.038 2.038 0 00-2.036-2.036zM2.75 18.714v-3.881h7.5v4.417H3.286a.536.536 0 01-.536-.536zm15.964.536H11.75v-4.417h7.5v3.881a.537.537 0 01-.536.536z" />
      <path d="M6.213 5.75a.75.75 0 000 1.5h3.75V11a.75.75 0 001.5 0V7.25h3.75a.75.75 0 100-1.5h-3.75V2a.75.75 0 00-1.5 0v3.75z" />
    </svg>
  );
}

export default SvgTableInsertRowAbove;
