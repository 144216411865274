import React, { useState } from 'react';
import styled from '@emotion/styled';
import {
  getExtraProps,
  getSpacingObject,
  getSpacingString,
  withTheme,
} from '@embracesbs/helpers';
import { propTypes, defaultProps } from './AccordionList.props';

const StyledAccordionList = styled.div``;

/**
 * AccordionList component
 *
 * @param {array} extraProps - An array of strings which includes the extra prop keys
 * @param {number|object} spacing - Spacing of the accordionlist
 * @param {string} selected - Opened accorddion by id
 * @param {boolean} isDisabled - Disable click events to open/collapse accordions
 * @param {node} children - Children of the component
 */
const AccordionList = ({
  spacing,
  children,
  isDisabled,
  extraProps,
  selected,
}) => {
  const currentSpacing = getSpacingObject(spacing, defaultProps.spacing);
  const currentSpacingString = getSpacingString(currentSpacing);
  const [state, setState] = useState(selected);

  /**
   * onArrowToggle
   *
   * @param {string} id - id of the clicked accordion
   */
  const onArrowToggle = (id) => {
    if (!isDisabled) setState(id);
  };

  const accordions = [];
  let mutatedChildren;
  if (children) {
    // Make sure children is an array of jsx elements
    if (!children.length) mutatedChildren = [children];
    else mutatedChildren = children;
    // For each child, disabled if disabled, set isOpen based on state(selected)
    if (mutatedChildren.forEach) {
      mutatedChildren.forEach((child) => {
        const isOpen = child.props.id === state;
        accordions.push(
          React.cloneElement(child, {
            onArrowToggle,
            key: child.props.id,
            isOpen,
            isDisabled,
          }),
        );
      });
    }
  }

  return (
    <StyledAccordionList
      padding={currentSpacingString}
      {...getExtraProps(
        { spacing, children, isDisabled, extraProps, selected },
        extraProps,
      )}
    >
      {accordions}
    </StyledAccordionList>
  );
};

AccordionList.propTypes = propTypes;
AccordionList.defaultProps = defaultProps;

/** @component */
export default withTheme(AccordionList);
