import * as React from 'react';

function SvgParagraphCenterAlign(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M2.241 3.748a.75.75 0 010-1.5h19.5a.75.75 0 010 1.5h-19.5zM5.241 8.248a.75.75 0 010-1.5h13.5a.75.75 0 010 1.5h-13.5zM.741 12.748a.75.75 0 010-1.5h22.5a.75.75 0 010 1.5H.741zM5.241 17.248a.75.75 0 010-1.5h13.5a.75.75 0 010 1.5h-13.5zM2.241 21.748a.75.75 0 010-1.5h19.5a.75.75 0 010 1.5h-19.5z" />
    </svg>
  );
}

export default SvgParagraphCenterAlign;
