import * as React from 'react';

function SvgLinkFixed2(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M12 14.6c-.4 0-.7-.3-.7-.8 0-.4.3-.7.8-.7.4 0 .8-.1 1.2-.3 1.1-.5 1.8-1.6 1.8-2.8V4.9c0-1.6-1.4-3-3-3s-3 1.4-3 3v4.5c0 .4-.3.8-.8.8s-.8-.4-.8-.8V4.9c0-2.5 2-4.5 4.5-4.5s4.5 2 4.5 4.5v5.3c0 1.8-1.1 3.4-2.7 4.1-.6.2-1.2.3-1.8.3z" />
      <path d="M12 23.6c-2.5 0-4.5-2-4.5-4.5v-4.5c0-2.5 2-4.5 4.5-4.5.4 0 .8.3.8.7 0 .2-.1.4-.2.5-.1.1-.3.2-.5.2-1.6 0-3 1.4-3 3V19c0 1.6 1.4 3 3 3s3-1.4 3-3v-4.5c0-.4.3-.8.8-.8s.8.3.8.8V19c-.2 2.6-2.2 4.6-4.7 4.6z" />
    </svg>
  );
}

export default SvgLinkFixed2;
