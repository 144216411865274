import * as React from 'react';

function SvgCursorSelectCircle(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M21.104 19.488a.751.751 0 01-.605-1.193 10.35 10.35 0 001.1-1.895.752.752 0 01.991-.379.752.752 0 01.38.991 11.867 11.867 0 01-1.26 2.17.759.759 0 01-.606.306zM14.73 23.788a.755.755 0 01-.732-.588.752.752 0 01.57-.895c.344-.076.685-.18 1.012-.31a6.915 6.915 0 001.011-.426.746.746 0 011.012.317.745.745 0 01-.318 1.012c-.381.199-.783.37-1.194.506a8.124 8.124 0 01-1.199.365.702.702 0 01-.162.019zM9.293 23.788a.791.791 0 01-.16-.017 8.263 8.263 0 01-1.24-.379 7.846 7.846 0 01-1.155-.492.747.747 0 01-.317-1.012.748.748 0 011.012-.317c.31.162.637.3.971.411a6.9 6.9 0 001.051.323.75.75 0 01-.162 1.483zM2.918 19.489a.752.752 0 01-.605-.307 11.92 11.92 0 01-1.259-2.17.75.75 0 111.37-.611c.298.668.668 1.306 1.1 1.896a.75.75 0 01-.606 1.192zM.748 12.096a.748.748 0 01-.697-.798c.054-.835.198-1.667.427-2.471a.754.754 0 01.928-.515.744.744 0 01.449.356.741.741 0 01.066.57c-.2.702-.325 1.429-.373 2.159a.752.752 0 01-.8.699zM3.924 5.073a.751.751 0 01-.536-1.275 13.175 13.175 0 011.909-1.61.749.749 0 011.044.19c.235.34.15.808-.19 1.044-.606.42-1.175.9-1.691 1.426a.746.746 0 01-.536.225zM10.834 1.695a.75.75 0 01-.039-1.499l1.176-.062.04-.001.041.001 1.175.062a.75.75 0 01-.04 1.499l-.04-.001-1.136-.06-1.136.06-.041.001zM20.1 5.073a.747.747 0 01-.536-.225 11.772 11.772 0 00-1.691-1.427.746.746 0 01-.189-1.044.75.75 0 011.043-.19 13.114 13.114 0 011.908 1.61c.14.143.216.332.214.533a.745.745 0 01-.225.528.738.738 0 01-.524.215zM23.219 12.098a.752.752 0 01-.748-.701 10.288 10.288 0 00-.373-2.158.741.741 0 01.066-.57.745.745 0 01.656-.386c.333 0 .63.224.721.545.229.805.373 1.637.427 2.472a.752.752 0 01-.7.797l-.049.001zM12.011 16.633a4.505 4.505 0 01-4.5-4.5c0-2.481 2.019-4.5 4.5-4.5s4.5 2.019 4.5 4.5-2.019 4.5-4.5 4.5zm0-7.5c-1.654 0-3 1.346-3 3s1.346 3 3 3 3-1.346 3-3-1.345-3-3-3z" />
    </svg>
  );
}

export default SvgCursorSelectCircle;
