import * as React from 'react';

function SvgControlsRecord(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M12 22.5C6.21 22.5 1.5 17.79 1.5 12S6.21 1.5 12 1.5 22.5 6.21 22.5 12 17.79 22.5 12 22.5zM12 3c-4.963 0-9 4.037-9 9s4.037 9 9 9 9-4.037 9-9-4.037-9-9-9z" />
    </svg>
  );
}

export default SvgControlsRecord;
