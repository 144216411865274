import * as React from 'react';

function SvgSmileyDrop(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M12.001 23.981C5.39 23.976.016 18.593.021 11.982A11.912 11.912 0 013.54 3.506 11.913 11.913 0 0112.011.001c1.676.001 3.295.342 4.822 1.012a.75.75 0 01-.603 1.373 10.433 10.433 0 00-4.21-.886A10.426 10.426 0 004.6 4.567a10.42 10.42 0 00-3.078 7.415 10.423 10.423 0 003.07 7.417 10.418 10.418 0 007.411 3.082c5.789 0 10.494-4.702 10.499-10.482a9.6 9.6 0 00-.009-.417.751.751 0 01.72-.779h.001c.438 0 .762.309.778.72.006.158.01.317.01.477-.005 6.607-5.384 11.981-11.99 11.981h-.011z" />
      <path d="M5.949 18.75a.749.749 0 01-.711-.511.746.746 0 01.039-.573 7.543 7.543 0 013.391-3.39 7.423 7.423 0 013.324-.784c.814 0 1.623.134 2.405.397a7.45 7.45 0 014.324 3.778.746.746 0 01-.339 1.005.75.75 0 01-1.005-.339 5.96 5.96 0 00-3.46-3.022 5.945 5.945 0 00-4.583.309 6.032 6.032 0 00-2.713 2.713.745.745 0 01-.672.417zM18.494 11.25a.752.752 0 01-.707-.5 1.503 1.503 0 00-1.914-.915c-.43.152-.763.485-.915.915a.75.75 0 01-1.415-.5 2.981 2.981 0 011.829-1.829c.323-.114.658-.172.997-.172 1.27 0 2.408.804 2.831 2.001a.745.745 0 01-.03.573.744.744 0 01-.676.427zM5.508 11.25a.751.751 0 01-.708-1 2.984 2.984 0 011.829-1.829 3.007 3.007 0 013.829 1.83.751.751 0 01-.707 1 .752.752 0 01-.707-.5 1.502 1.502 0 00-1.914-.915 1.49 1.49 0 00-.915.914.752.752 0 01-.707.5zM20.96 8.999a3.14 3.14 0 01-2.958-3.287C18.001 4.231 19.97.988 20.364.354c.137-.221.376-.354.637-.354s.499.132.637.354c.393.632 2.353 3.861 2.363 5.384.08 1.697-1.248 3.167-2.959 3.261L21.001 9l-.041-.001zm.041-6.77c-.922 1.637-1.5 2.987-1.5 3.522-.045.907.626 1.678 1.5 1.748a1.64 1.64 0 001.501-1.711l-.001-.038c0-.535-.579-1.885-1.5-3.521z" />
    </svg>
  );
}

export default SvgSmileyDrop;
