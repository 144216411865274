import PropTypes from 'prop-types';
import { requireOneOf, validatePropType, theme } from '@embracesbs/helpers';
import { subStyleEnums } from './Button.styles';

const oneOfProps = { iconName: PropTypes.string, text: PropTypes.string };

const basePropTypes = {
  /** An array of strings which includs the extra prop keys */
  extraProps: PropTypes.array,
  /** Setting disabled to true will render a disabled button */
  isDisabled: PropTypes.bool,
  /** When provided, the name attribute will be set to identify the button */
  name: PropTypes.string,
  /** Gets called when the user clicks on the button */
  onClick: PropTypes.func,
  /** The variant of the button */
  variant: PropTypes.oneOf(Object.values(subStyleEnums)),
  /** Indicates if the button has a full width of its parent */
  isFullWidth: PropTypes.bool,
  /** Set the max width of the button with this value */
  maxWidth: PropTypes.string,
  /** Text of the button */
  text: (props, propName, componentName) =>
    requireOneOf(oneOfProps, props, () =>
      validatePropType(props, propName, oneOfProps, componentName),
    ),
  /** An icon inside of the button */
  iconName: (props) => requireOneOf(oneOfProps, props),
  /** The size of the icon inside of the button */
  iconSize: PropTypes.number,
  /** Whether the component is using dark mode */
  isDark: PropTypes.bool,
};

export const webPropTypes = {
  ...basePropTypes,
  /** The type of button */
  type: PropTypes.oneOf(['submit', 'button', 'reset', 'default']),
  /** Indicates if there should be a ripple effect onclick */
  isRipple: PropTypes.bool,
  /** The theme key of the color of the icon */
  iconColor: PropTypes.string,
  /** Indicates if there is a loading process and shows a spinner inside the button */
  isLoading: PropTypes.bool,
  /** Indicates if the button is toggled on/off */
  isToggled: PropTypes.bool,
  /** The color of the spinner in cases when it is provided */
  spinnerColor: PropTypes.string,
  /** An optional href attribute used in the link variant */
  href: PropTypes.string,
  /** A tooltip to display on the button / icon */
  tooltip: PropTypes.string,
};

export const nativePropTypes = {
  ...basePropTypes,
};

const baseDefaultProps = {
  extraProps: [],
  isDisabled: false,
  name: null,
  onClick: () => {},
  variant: subStyleEnums.CONTAINED,
  isFullWidth: false,
  maxWidth: 'none',
  text: '',
  iconName: '',
  iconSize: 20,
  isDark: false,
};

export const webDefaultProps = {
  ...baseDefaultProps,
  isLoading: false,
  type: 'submit',
  isRipple: true,
  iconColor: null,
  isToggled: false,
  spinnerColor: theme.color.primary,
  href: null,
  tooltip: null,
};

export const nativeDefaultProps = {
  ...baseDefaultProps,
};
