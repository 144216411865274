import * as React from 'react';

function SvgFileExe(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M2.244 24.004a2.252 2.252 0 01-2.25-2.25v-19.5a2.252 2.252 0 012.25-2.25h10.628c.601 0 1.166.234 1.591.658l5.867 5.872c.425.424.659.989.659 1.591v.065a.468.468 0 01.004.064c0 .022-.002.043-.004.065v4.435a.75.75 0 01-1.5 0v-3.75h-5.246a2.252 2.252 0 01-2.25-2.25v-5.25h-9.75a.75.75 0 00-.75.75v19.5c0 .414.336.75.75.75h3a.75.75 0 010 1.5H2.244zm11.25-17.25c0 .414.336.75.75.75h4.935l-5.685-5.689v4.939z" />
      <path d="M9.744 24.004a2.252 2.252 0 01-2.25-2.25v-4.5a2.252 2.252 0 012.25-2.25h1.5a.75.75 0 010 1.5h-1.5a.75.75 0 00-.75.75v2.25h2.25a.75.75 0 010 1.5h-2.25v.75c0 .414.336.75.75.75h1.5a.75.75 0 010 1.5h-1.5zM21.744 24.004a2.252 2.252 0 01-2.25-2.25v-4.5a2.252 2.252 0 012.25-2.25h1.5a.75.75 0 010 1.5h-1.5a.75.75 0 00-.75.75v2.25h2.25a.75.75 0 010 1.5h-2.25v.75c0 .414.336.75.75.75h1.5a.75.75 0 010 1.5h-1.5zM17.244 24.004a.744.744 0 01-.696-.472l-.804-2.009-.804 2.009a.745.745 0 01-.975.418.74.74 0 01-.41-.401.742.742 0 01-.007-.574l1.389-3.471-1.389-3.472a.747.747 0 01.418-.975.747.747 0 01.975.418l.803 2.009.804-2.009a.747.747 0 01.975-.418.745.745 0 01.411.401.742.742 0 01.007.574l-1.389 3.471 1.389 3.472a.747.747 0 01-.418.975.74.74 0 01-.279.054z" />
    </svg>
  );
}

export default SvgFileExe;
