import * as React from 'react';

function SvgScrewdriver(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M3.786 23.975a3.753 3.753 0 01-2.696-6.359l6.367-6.368a2.24 2.24 0 011.596-.664c.602 0 1.167.234 1.592.659l.53.53 5.464-5.464a2.684 2.684 0 01.119-2.791l1.649-2.472A2.312 2.312 0 0120.348.008a2.317 2.317 0 011.645.683l1.323 1.323c.44.44.683 1.025.683 1.648a2.313 2.313 0 01-1.038 1.939L20.489 7.25a2.673 2.673 0 01-1.49.452c-.451 0-.902-.117-1.299-.334l-5.466 5.466.53.53a2.252 2.252 0 010 3.181L6.4 22.909a3.74 3.74 0 01-2.614 1.066zm5.268-11.892a.746.746 0 00-.527.216L2.16 18.667a2.248 2.248 0 00.064 3.172 2.238 2.238 0 003.126 0l6.355-6.355a.75.75 0 000-1.06l-1.044-1.044a.29.29 0 01-.038-.04l-1.038-1.037a.747.747 0 00-.531-.22zm9.126-6.205a1.198 1.198 0 001.479.124l2.472-1.649a.825.825 0 00.369-.691.824.824 0 00-.243-.587l-1.324-1.324a.823.823 0 00-.749-.227.82.82 0 00-.528.354L18.007 4.35a1.189 1.189 0 00.173 1.528z" />
    </svg>
  );
}

export default SvgScrewdriver;
