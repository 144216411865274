import * as React from 'react';

function SvgRealEstateActionHouseCheck(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M5.25 20.248A2.252 2.252 0 013 17.998v-7.5a.75.75 0 011.5 0v7.5c0 .414.336.75.75.75h3a.75.75 0 010 1.5h-3zM.75 9.748a.75.75 0 01-.485-1.323L9.046.995A2.256 2.256 0 0110.5.461c.53 0 1.046.19 1.454.534l7.284 6.164a.75.75 0 01-.485 1.323.751.751 0 01-.484-.177L10.985 2.14a.749.749 0 00-.969-.001L1.234 9.57a.747.747 0 01-.484.178zM18.75 5.248a.75.75 0 01-.75-.75v-2.25h-2.25a.75.75 0 010-1.5h3a.75.75 0 01.75.75v3c0 .413-.337.75-.75.75zM17.25 23.248c-3.722 0-6.75-3.028-6.75-6.75s3.028-6.75 6.75-6.75S24 12.776 24 16.498s-3.028 6.75-6.75 6.75zm0-12c-2.895 0-5.25 2.355-5.25 5.25s2.355 5.25 5.25 5.25 5.25-2.355 5.25-5.25-2.355-5.25-5.25-5.25z" />
      <path d="M16.416 19.676a1.483 1.483 0 01-1.057-.437l-1.502-1.502a.744.744 0 010-1.06.749.749 0 011.06 0l1.5 1.5 2.906-3.874a.753.753 0 011.049-.151c.332.249.399.72.151 1.051l-2.905 3.874a1.493 1.493 0 01-1.202.599z" />
    </svg>
  );
}

export default SvgRealEstateActionHouseCheck;
