export const baseStyles = {};

const popupArrowJustifyAlignment = {
  left: (unit) => `
    left: ${unit(24)};
  `,
  center: () => `
    left: 50%;
  `,
  right: (unit) => `
    right: ${unit(24)};
  `,
};

/** Returns the align styles for the given justify value */
const getCenteredAlignStyles = (unit, justify) => `
  ${
    justify === 'left'
      ? `
        left: -${unit(10)};
        transform: rotate(270deg);
      `
      : ''
  }
  ${
    justify === 'center'
      ? `
        display: none;
      `
      : ''
  }
  ${
    justify === 'right'
      ? `
        right: -${unit(20)};
        transform: rotate(90deg);
      `
      : ''
  }
`;

const popupArrowAlignAlignment = {
  top: () => `
    top: 100%;
    transform: rotate(180deg);
  `,
  centerUp: (unit, justify) => `
    bottom: ${unit(24)};
    ${getCenteredAlignStyles(unit, justify)}
  `,
  centerDown: (unit, justify) => `
    top: ${unit(24)};
    ${getCenteredAlignStyles(unit, justify)}
  `,
  bottom: () => `
    bottom: 100%;
  `,
};

export const webStyles = {
  ...baseStyles,
  contextModal: (theme, unit) => `
    background: ${theme.color.light};
    position: absolute;
    border-radius: ${unit(2)};
  `,
  popupArrow: (theme, unit, justify, align) => `
  margin: ${align.includes('center') ? `0 ${unit(10)}` : `${unit(10)} 0`};
  &:after,
    &:before {
      ${popupArrowJustifyAlignment[justify](unit)}
      ${popupArrowAlignAlignment[align](unit, justify)}
      border: solid transparent;
      content: ' ';
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }
    &:after {
      border-color: transparent;
      border-bottom-color: ${theme.color.light};
      border-width: ${unit(10)};
      margin-left: ${unit(-10)};
    }
    &:before {
      border-color: transparent;
      border-bottom-color: ${
        align.includes('center') ? 'transparent' : theme.color.dark$6
      };
      border-width: ${unit(11)};
      margin-left: ${unit(-11)};
      margin-right: -${unit(1)};
    }
  `,
};
