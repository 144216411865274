import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import Group from '@embracesbs/component-group';
import Text from '@embracesbs/component-text';
import { hideVisually } from 'polished';
import {
  getExtraProps,
  withTheme,
  unit,
  getSpacingObject,
} from '@embracesbs/helpers';
import {
  propTypes,
  defaultProps,
  checkboxItemPropTypes,
  checkboxItemDefaultProps,
} from './Checkbox.props';
import { webStyles } from './Checkbox.styles';

const StyledSvg = styled.svg`
  ${webStyles.svg(unit)}
`;

const HiddenCheckbox = styled.input`
  ${hideVisually()}
`;

const StyledCheckbox = styled.div`
  ${({ isChecked, theme }) => webStyles.checkbox(isChecked, unit, theme)}
  ${({ hasCircledShape }) => `${hasCircledShape ? 'border-radius: 50%' : ``}`}
`;

const StyledLabel = styled.label`
  ${({ theme }) => webStyles.label(unit, theme)}
  ${({ topSpacing, bottomSpacing }) =>
    `margin: ${unit(topSpacing)} 0 ${unit(bottomSpacing)} 0;`}
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledTitle = styled(Text)`
  ${({ isCrossedOff }) =>
    `${isCrossedOff ? 'text-decoration: line-through;' : ``}`}
  align-self: flex-start;
`;

const StyledDescription = styled(Text)`
  ${({ isCrossedOff }) =>
    `${isCrossedOff ? 'text-decoration: line-through;' : ``}`}
`;

/**
 * Checkbox component
 * @param {bool} isChecked
 * @param {object} props
 */
const CheckboxComponent = ({
  isChecked,
  label,
  id,
  hasCircledShape,
  ...props
}) => (
  <CheckboxContainer>
    <HiddenCheckbox
      type="checkbox"
      checked={isChecked}
      aria-label={label || 'Checkbox'}
      aria-labelledby={id}
      id={id}
      {...props}
    />
    <StyledCheckbox isChecked={isChecked} hasCircledShape={hasCircledShape}>
      <StyledSvg viewBox="0 0 24 24">
        <polyline points="20 6 9 17 4 12" />
      </StyledSvg>
    </StyledCheckbox>
  </CheckboxContainer>
);

CheckboxComponent.propTypes = checkboxItemPropTypes;
CheckboxComponent.defaultProps = checkboxItemDefaultProps;

/**
 * Checkbox component
 * @param {array} extraProps - An array of strings which includes the extra prop keys
 * @param {bool} isChecked - Set the checkbox as active
 * @param {bool} isDisabled - Disable the checkbox
 * @param {string} id - The ID of the checkbox
 * @param {string} label - Set the label of the checkbox
 * @param {string} name - Set the name of the checkbox, checkboxes with the same name belong to the same group
 * @param {func} onClick - Attach function to the onClick handler
 * @param {string} value - The value of the checkbox
 * @param {string} description - Optional description
 * @param {bool} isLabelSingleLine - if the label will stay on one single line and gets truncated
 * @param {bool} isDescriptionSingleLine - if the description will stay on one single line and gets truncated
 * @param {bool} isOnCrossMode - if the description and the label are crossed off on checked
 * @param {bool} hasCircledShape -  has the checkbox circled shape
 * @param {object|number} innerSpacing - inner spacing of the checkbox
 */
const Checkbox = (props) => {
  const {
    isChecked,
    isDisabled,
    id,
    extraProps,
    label,
    name,
    onClick,
    innerSpacing,
    theme,
    description,
    isLabelSingleLine,
    isDescriptionSingleLine,
    isOnCrossMode,
    hasCircledShape,
    value,
  } = props;
  const [checked, setChecked] = useState(isChecked || false);

  const currentSpacing = getSpacingObject(
    innerSpacing,
    defaultProps.innerSpacing,
  );

  const { top, right, bottom, left } = currentSpacing;

  useEffect(() => {
    setChecked(isChecked || false);
  }, [isChecked]);

  const isCrossedOff = isOnCrossMode && checked && !isDisabled;
  const textColorTitle = !isCrossedOff ? theme.color.dark : theme.color.dark$5;
  const textColorDescription = !isCrossedOff
    ? theme.color.dark$3
    : theme.color.dark$5;
  return (
    <StyledLabel
      onClick={onClick}
      bottomSpacing={bottom}
      topSpacing={top}
      {...getExtraProps(null, extraProps, 'LabelWrapper')}
    >
      <CheckboxComponent
        isChecked={checked}
        label={label}
        id={id}
        hasCircledShape={hasCircledShape}
        name={name}
        value={value}
        disabled={isDisabled}
        onChange={isChecked === null ? () => setChecked(!checked) : () => {}}
        onClick={(e) => e.stopPropagation()}
        {...getExtraProps(props, extraProps)}
      />
      {(label || description) && (
        <Group
          spacing={{
            left,
            right,
          }}
          jystify="flex-start"
          innerSpacing={0}
          isVertical
          isFullWidth={false}
        >
          {label && (
            <StyledTitle
              content={label}
              textColor={textColorTitle}
              singleLine={isLabelSingleLine}
              isCrossedOff={isCrossedOff}
            />
          )}
          {description && (
            <StyledDescription
              content={description}
              textStyle="description"
              textColor={textColorDescription}
              singleLine={isDescriptionSingleLine}
              isCrossedOff={isCrossedOff}
            />
          )}
        </Group>
      )}
    </StyledLabel>
  );
};

Checkbox.propTypes = propTypes;
Checkbox.defaultProps = defaultProps;

/** @component */
export default withTheme(Checkbox);
