import PropTypes from 'prop-types';
import { theme } from '@embracesbs/helpers';

export const propTypes = {
  /** Inner spacing of the accordionlist */
  spacing: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.shape({
      bottom: PropTypes.number,
      left: PropTypes.number,

      right: PropTypes.number,
      top: PropTypes.number,
    }),
  ]),
  /** Inner content of the accordionlist */
  children: PropTypes.node,
  /** Extra props  */
  extraProps: PropTypes.array,
  /** Selected accordion to be open  */
  selected: PropTypes.string,
  /** Disable toggling of accordions  */
  isDisabled: PropTypes.bool,
};

export const defaultProps = {
  spacing: theme.spacing.$6Number,
  children: null,
  extraProps: [],
  selected: null,
  isDisabled: false,
};
