import * as React from 'react';

function SvgWrench(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M3.386 23.997a2.278 2.278 0 01-1.622-.671L.678 22.238a2.278 2.278 0 01-.676-1.627c0-.612.239-1.188.672-1.62l8.482-8.476a7.754 7.754 0 01.406-6.508A7.65 7.65 0 0114.149.31a7.739 7.739 0 012.179-.315c1.274 0 2.547.327 3.678.945a.752.752 0 01.171 1.188l-3.6 3.6a.747.747 0 000 1.061l.8.8c.141.141.33.219.53.219s.389-.078.53-.219l3.55-3.546a.754.754 0 01.651-.209.75.75 0 01.55.404 7.687 7.687 0 01-1.44 8.894 7.652 7.652 0 01-5.449 2.253c-.972 0-1.917-.18-2.815-.534l-8.497 8.496a2.27 2.27 0 01-1.601.65zm12.94-22.502c-.59 0-1.181.085-1.755.254a6.154 6.154 0 00-3.693 2.976 6.215 6.215 0 00-.155 5.637.752.752 0 01-.147.853l-8.843 8.837a.787.787 0 00-.232.56c0 .21.081.408.229.557l1.095 1.096c.149.15.348.232.561.232s.411-.082.561-.232l8.837-8.836a.741.741 0 01.853-.147c.838.4 1.734.603 2.663.603a6.16 6.16 0 004.388-1.814 6.184 6.184 0 001.557-6.164L19.497 8.65c-.424.424-.989.658-1.59.658s-1.167-.234-1.591-.658l-.8-.8a2.232 2.232 0 01-.659-1.591c0-.601.234-1.166.659-1.591l2.83-2.831a6.109 6.109 0 00-2.02-.342z" />
    </svg>
  );
}

export default SvgWrench;
