import * as React from 'react';

function SvgOfficeFileDoc1(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M2.247 23.998a2.252 2.252 0 01-2.25-2.25v-19.5a2.252 2.252 0 012.25-2.25h10.629c.602 0 1.166.234 1.591.658l5.871 5.872c.425.425.659.99.659 1.59v4.629a.75.75 0 01-1.5 0v-3.75h-5.25a2.252 2.252 0 01-2.25-2.25v-5.25h-9.75a.75.75 0 00-.75.75v19.5c0 .414.336.75.75.75h3a.75.75 0 010 1.5h-3zm11.25-17.25c0 .414.336.75.75.75h4.939l-5.689-5.69v4.94z" />
      <path d="M8.247 23.998a.75.75 0 01-.75-.75v-7.5a.75.75 0 01.75-.75 3.754 3.754 0 013.75 3.75v1.5a3.754 3.754 0 01-3.75 3.75zm.75-1.629a2.264 2.264 0 001.5-2.121v-1.5c0-.962-.621-1.808-1.5-2.121v5.742zM15.747 23.998a2.252 2.252 0 01-2.25-2.25v-4.5c0-1.241 1.009-2.25 2.25-2.25s2.25 1.009 2.25 2.25v4.5a2.252 2.252 0 01-2.25 2.25zm0-7.5a.75.75 0 00-.75.75v4.5a.75.75 0 001.5 0v-4.5a.75.75 0 00-.75-.75zM23.247 23.998a3.754 3.754 0 01-3.75-3.75v-1.5a3.754 3.754 0 013.75-3.75.75.75 0 010 1.5 2.252 2.252 0 00-2.25 2.25v1.5a2.252 2.252 0 002.25 2.25.75.75 0 010 1.5z" />
    </svg>
  );
}

export default SvgOfficeFileDoc1;
