import React from 'react';
import styled from '@emotion/styled';
import { getExtraProps, withTheme } from '@embracesbs/helpers';
import { propTypes, defaultProps } from './BadgeWrapper.props';
import { webStyles } from './BadgeWrapper.styles';

const StyledBadgeWrapper = styled.div`
  ${({ theme, bottomLeft, bottomRight, topLeft, topRight }) =>
    webStyles.badge(theme, bottomLeft, bottomRight, topLeft, topRight)}
`;

const Container = styled.div`
  position: relative;
`;

/**
 * BadgeWrapper component
 * @param {array} extraProps
 * @param {node} children
 * @param {object} bottomLeft
 * @param {object} bottomRight
 * @param {object} topLeft
 * @param {object} topRight
 * @param {node} badge
 */
const BadgeWrapper = (props) => {
  const {
    extraProps,
    children,
    badge,
    bottomLeft,
    bottomRight,
    topLeft,
    topRight,
  } = props;

  return (
    <Container {...getExtraProps(null, extraProps, 'Container')}>
      <StyledBadgeWrapper
        {...getExtraProps(props, extraProps)}
        bottomLeft={bottomLeft}
        bottomRight={bottomRight}
        topLeft={topLeft}
        topRight={topRight}
      >
        {badge}
      </StyledBadgeWrapper>
      {children}
    </Container>
  );
};

BadgeWrapper.propTypes = propTypes;
BadgeWrapper.defaultProps = defaultProps;

/** @component */
export default withTheme(BadgeWrapper);
