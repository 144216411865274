import * as React from 'react';

function SvgCellBorderBottom(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M22.5 3.8c-.4 0-.8-.3-.8-.8s-.3-.8-.7-.8-.8-.3-.8-.7.3-.8.8-.8c1.2 0 2.2 1 2.2 2.2 0 .5-.3.9-.7.9zM1.5 3.8c-.4 0-.7-.4-.7-.8C.8 1.8 1.8.8 3 .8c.4 0 .8.3.8.8s-.4.6-.8.6-.8.4-.8.8-.3.8-.7.8zM3 23.2c-1.2 0-2.2-1-2.2-2.2 0-.4.3-.8.8-.8s.8.3.8.8.3.8.8.8h8.2V21c0-.4.3-.8.8-.8s.8.3.8.8v.8h8c.4 0 .8-.3.8-.8s.3-.8.8-.8.8.3.8.8c0 1.2-1 2.2-2.2 2.2H3zM6 2.2c-.4 0-.8-.3-.8-.7s.4-.7.8-.7h1.5c.4 0 .8.3.8.8s-.4.6-.8.6H6zM12 3.8c-.4 0-.8-.3-.8-.8v-.8h-.8c-.4 0-.8-.3-.8-.7s.3-.8.8-.8h3c.4 0 .8.3.8.8s-.3.7-.8.7h-.8V3c.2.4-.2.8-.6.8zM16.5 2.2c-.4 0-.8-.3-.8-.7s.3-.8.8-.8H18c.4 0 .8.3.8.8s-.4.7-.8.7h-1.5zM1.5 8.2c-.4 0-.8-.3-.8-.7V6c0-.4.3-.8.8-.8s.7.4.7.8v1.5c0 .4-.3.7-.7.7zM1.5 14.2c-.4 0-.8-.3-.8-.8v-3c0-.4.3-.8.8-.8s.8.3.8.8v.8H3c.4 0 .8.3.8.8s-.4.8-.8.8h-.8v.8c0 .3-.3.6-.7.6zM1.5 18.8c-.4 0-.8-.3-.8-.8v-1.5c0-.4.3-.8.8-.8s.8.3.8.8V18c-.1.4-.4.8-.8.8zM22.5 8.2c-.4 0-.8-.3-.8-.7V6c0-.4.3-.8.8-.8s.8.3.8.8v1.5c-.1.4-.4.7-.8.7zM22.5 14.2c-.4 0-.8-.3-.8-.8v-.8H21c-.4 0-.8-.3-.8-.8s.3-.8.8-.8h.8v-.8c0-.4.3-.8.8-.8s.8.3.8.8v3c-.2.7-.5 1-.9 1zM22.5 18.8c-.4 0-.8-.3-.8-.8v-1.5c0-.4.3-.8.8-.8s.8.3.8.8V18c-.1.4-.4.8-.8.8zM6 12.8c-.4 0-.8-.3-.8-.8s.3-.8.8-.8h1.5c.4 0 .8.3.8.8s-.3.8-.8.8H6zM12 14.2c-.4 0-.8-.3-.8-.8v-.8h-.8c-.4 0-.8-.3-.8-.8s.3-.8.8-.8h.8v-.8c0-.4.3-.8.8-.8s.8.3.8.8v.8h.8c.4 0 .8.3.8.8s-.3.8-.8.8h-.8v.8c0 .5-.4.8-.8.8zM16.5 12.8c-.4 0-.8-.3-.8-.8s.3-.8.8-.8H18c.4 0 .8.3.8.8s-.3.8-.8.8h-1.5zM12 8.2c-.4 0-.8-.3-.8-.7V6c0-.4.3-.8.8-.8s.8.3.8.8v1.5c0 .4-.4.7-.8.7zM12 18.8c-.4 0-.8-.3-.8-.8v-1.5c0-.4.3-.8.8-.8s.8.3.8.8V18c0 .4-.4.8-.8.8z" />
    </svg>
  );
}

export default SvgCellBorderBottom;
