import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import Ink from 'react-ink';
import CircularProgress from '@embracesbs/component-circularprogress';
import Text from '@embracesbs/component-text';
import Tooltip from '@embracesbs/component-tooltip';
import {
  getExtraProps,
  removeKeysFromObject,
  withTheme,
} from '@embracesbs/helpers';
import Icon from '@embracesbs/component-icon';
import Group from '@embracesbs/component-group';
import { webPropTypes, webDefaultProps } from './Button.props';
import { webStyles } from './Button.styles';

/** Returns the StyledButton with the corresponding tag */
const getStyledButton = (isLink) => styled(isLink ? 'a' : 'button')`
  ${({ theme, variant, isDisabled, iconSize, text, isDark }) =>
    webStyles.btnStyle(theme, variant, isDisabled, iconSize, text, isDark)}
  ${({ isFullWidth, isDisabled, theme }) => `
    width: ${isFullWidth ? theme.percentage.full : theme.percentage.auto};
    cursor: ${isDisabled ? theme.cursor.notAllowed : theme.cursor.pointer};
  `}
  ${({ theme, isToggled, isDisabled }) => `
    ${
      isToggled && !isDisabled
        ? `
          background-color: ${theme.color.primary};
          border-color: ${theme.color.primary};
          color: ${theme.color.light};
          &:hover {
            color: ${theme.color.light};
          }
        `
        : ``
    };
  `}
`;

const LoadingContainer = styled.div`
  ${({ theme }) => `
     ${theme.centeredStyles.flex}
  `}
`;

const TextLinkWrapper = styled.span`
  ${({ maxWidth }) => `
    justify-content: flex-start;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    min-width: 0;
    ${maxWidth ? `max-width: ${maxWidth};` : ``}
  `}
`;

/**
 * Button web component
 * @param {bool} isDisabled - Shows the disable or enable mode of the button
 * @param {array} extraProps - An array of strings which includes the extra prop keys
 * @param {bool} isRipple - Ripple effect for the button
 * @param {bool} isFullWidth - Whether width of the button is 100% or auto
 * @param {string} maxWidth Set the max width of the button with this value
 * @param {string} variant - Style of the button
 * @param {string} name - Name of the button
 * @param {function} onClick - Onclick function
 * @param {string} type - Type of the button
 * @param {string} text - Inner text of the button
 * @param {string} iconName - Icon name from the provided libraries
 * @param {number} iconSize - The size of the icon in the button
 * @param {string} iconColor - The theme key of the color of the icon
 * @param {bool} isLoading - Whether button is on loding mode with spinner or not
 * @param {bool} isToggled - Indicates if the button is toggled on/off
 * @param {string} spinnerColor - Color of the optional spinner
 * @param {string} href - An optional href attribute used in the link variant
 * @param {string} tooltip - A tooltip to display on the button / icon
 * @param {bool} isDark - Whether the component is using dark mode
 */
const Button = (props) => {
  const {
    isDisabled,
    isLoading,
    isRipple,
    isToggled,
    name,
    text,
    iconName,
    iconSize,
    iconColor,
    extraProps,
    isFullWidth,
    maxWidth,
    variant,
    onClick,
    type,
    spinnerColor,
    href,
    tooltip,
    theme,
    isDark,
    forwardedRef,
  } = props;
  const [toggled, setToggled] = useState(isToggled);

  useEffect(() => {
    setToggled(isToggled);
  }, [isToggled]);

  /**
   * onClickHandler
   */
  const onClickHandler = (e) => {
    if (variant === 'link' && !href) e.preventDefault();
    if (variant === 'toggle') setToggled(!toggled);
    onClick(e);
  };

  /** iconSize - depending on variant provided */
  let buttonIconSize = iconSize;
  if (
    [
      'contained',
      'outlined',
      'text',
      'terminated',
      'toggle',
      'choice',
    ].includes(variant)
  )
    buttonIconSize = 16;
  else if (variant === 'link') buttonIconSize = 12;

  /** iconSpacing - depending on variant provided */
  const buttonIconSpacing = {
    right: [
      'contained',
      'outlined',
      'text',
      'terminated',
      'choice',
      'link',
    ].includes(variant)
      ? theme.spacing.$3Number
      : 0,
    bottom: variant === 'icon' && text ? theme.spacing.$3Number : 0,
  };

  const isLink = variant === 'link';
  const buttonHref = href || '#';

  const StyledButton = getStyledButton(isLink);
  const isTooltipVariant = [
    'icon',
    'round',
    'roundOutlined',
    'toggle',
  ].includes(variant);

  const tooltipProps =
    isTooltipVariant && tooltip
      ? {
          'data-tip': tooltip,
          'data-for': `Tooltip${iconName}`,
        }
      : null;

  const isButtonRound = ['round', 'roundOutlined'].includes(variant);
  let buttonText = null;

  /** Get the correct button text markup */
  const getButtonText = () => {
    if (!isButtonRound) {
      buttonText =
        variant === 'link' ? (
          <TextLinkWrapper maxWidth={maxWidth}>{text}</TextLinkWrapper>
        ) : (
          text
        );
    }
    return buttonText;
  };

  const buttonInternals = (
    <>
      {isTooltipVariant && tooltip ? (
        <Tooltip id={`Tooltip${iconName}`} />
      ) : null}
      <StyledButton
        {...getExtraProps(props, extraProps)}
        role={isLink ? 'link' : 'button'}
        disabled={isDisabled}
        isDisabled={isDisabled}
        isLoading={isLoading}
        isToggled={variant === 'toggle' && toggled}
        name={name}
        isFullWidth={isFullWidth}
        maxWidth={maxWidth}
        variant={variant}
        onClick={(e) => onClickHandler(e)}
        type={type}
        aria-label={text || (isLink ? 'Link' : 'Button')}
        iconSize={iconSize}
        text={Boolean(text)}
        isDark={isDark}
        href={isLink && !isDisabled ? buttonHref : null}
        ref={forwardedRef}
        {...tooltipProps}
        {...removeKeysFromObject(props, [
          'data-testid',
          'href',
          'onClick',
          'isToggled',
        ])}
      >
        {isLoading ? (
          <LoadingContainer>
            <CircularProgress progressColor={spinnerColor} size={iconSize} />
          </LoadingContainer>
        ) : (
          <>
            {isRipple && !isDisabled && <Ink background={false} />}
            {iconName && (
              <Icon
                iconName={iconName}
                size={buttonIconSize}
                {...(theme.color[iconColor] && !isDisabled
                  ? { color: theme.color[iconColor] }
                  : null)}
                spacing={buttonIconSpacing}
              />
            )}
            {getButtonText()}
          </>
        )}
      </StyledButton>
    </>
  );

  /** Get the text color */
  const getTextColor = () => {
    if (!isDark && !isDisabled) return theme.color.primary;
    if (isDark && !isDisabled) return theme.color.dark;
    return theme.color.dark$5;
  };

  return isButtonRound && text ? (
    <Group isFullWidth={false} isVertical>
      {buttonInternals}
      <Text
        content={text}
        textStyle="caption"
        align="center"
        spacing={{ top: theme.spacing.$2Number }}
        textColor={getTextColor()}
        {...getExtraProps(null, extraProps, 'Text')}
      />
    </Group>
  ) : (
    buttonInternals
  );
};

Button.propTypes = webPropTypes;
Button.defaultProps = webDefaultProps;

export default withTheme(Button);
