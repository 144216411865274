import * as React from 'react';

function SvgFlipVerticalDown(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M23.25 10.498a.75.75 0 01-.75-.75v-7.5a.75.75 0 00-.75-.75H2.25a.75.75 0 00-.75.75v7.5a.75.75 0 01-1.5 0v-7.5a2.252 2.252 0 012.25-2.25h19.5A2.252 2.252 0 0124 2.248v7.5a.75.75 0 01-.75.75zM.75 13.498a.75.75 0 010-1.5h4.5a.75.75 0 010 1.5H.75zM17.25 13.498a.75.75 0 010-1.5h6a.75.75 0 010 1.5h-6zM.75 17.998a.75.75 0 01-.75-.75v-1.5a.75.75 0 011.5 0v1.5a.75.75 0 01-.75.75zM23.25 17.998a.75.75 0 01-.75-.75v-1.5a.75.75 0 011.5 0v1.5a.75.75 0 01-.75.75zM5.25 23.998a.75.75 0 010-1.5h1.5a.75.75 0 010 1.5h-1.5zM11.25 23.998a.75.75 0 010-1.5h1.5a.75.75 0 010 1.5h-1.5zM17.25 23.998a.75.75 0 010-1.5h1.5a.75.75 0 010 1.5h-1.5zM2.25 23.998A2.252 2.252 0 010 21.748a.75.75 0 011.5 0c0 .414.336.75.75.75a.75.75 0 010 1.5zM21.75 23.998a.75.75 0 010-1.5c.414 0 .75-.336.75-.75a.75.75 0 011.5 0 2.252 2.252 0 01-2.25 2.25z" />
      <path d="M13.547 18.527a.469.469 0 01-.062-.005l-.03-.003-4.401-.527a.75.75 0 11.181-1.489l2.186.261a8.027 8.027 0 01-1.672-4.754 7.52 7.52 0 015-7.085.752.752 0 01.927 1.03.744.744 0 01-.427.384 6.013 6.013 0 00-4 5.66 6.433 6.433 0 001.757 4.315l.463-3.098a.754.754 0 01.854-.63.749.749 0 01.629.852l-.665 4.447c-.002.012-.004.025-.008.038a.723.723 0 01-.075.208.738.738 0 01-.387.342l-.019.007a.705.705 0 01-.251.047z" />
    </svg>
  );
}

export default SvgFlipVerticalDown;
