import * as React from 'react';

function SvgFolderUpload(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M2.4 19.5h-.2C1 19.3 0 18.3 0 17.1V2.2C0 1 1 0 2.2 0h4.5C8 0 9 1 9 2.2V3h9.8C20 3 21 4 21 5.2v2.2h.8c.6 0 1.2.2 1.6.7.4.4.7 1 .7 1.6 0 .2 0 .4-.1.6-.1.3-.4.6-.7.6h-.2c-.2-.1-.4-.2-.5-.3s-.1-.4-.1-.6v-.2c0-.2-.1-.4-.2-.5-.2-.2-.3-.3-.5-.3H7.9c-.4 0-.7.2-.7.5l-2.4 8.2c0 .1-.1.2-.1.3h3.6c.4 0 .7.3.7.7s-.3.8-.8.8H2.4zm-.2-18c-.4 0-.8.3-.8.8V17c0 .5.4.9 1 .9.4 0 .8-.3.9-.7L5.7 9C6 8 6.9 7.4 7.9 7.4h11.6V5.2c0-.4-.3-.8-.8-.8H8.2c-.4 0-.8-.3-.8-.7V2.2c0-.4-.3-.8-.8-.8H2.2z" />
      <path d="M17.2 24c-3.7 0-6.8-3-6.8-6.8s3-6.7 6.8-6.7 6.8 3 6.8 6.7-3 6.8-6.8 6.8zm0-12c-2.9 0-5.2 2.4-5.2 5.2s2.4 5.2 5.2 5.2 5.2-2.4 5.2-5.2-2.3-5.2-5.2-5.2z" />
      <path d="M17.2 13.5h.3c.1 0 .2.1.3.2L20 16c.3.3.3.8 0 1.1-.1.1-.3.2-.5.2s-.4-.1-.5-.2l-1-1v4.2c0 .4-.3.8-.8.8s-.8-.3-.8-.8v-4.2l-1 1c-.1.1-.3.2-.5.2s-.4-.1-.5-.2c-.3-.3-.3-.8 0-1.1l2.2-2.2c.1-.1.2-.1.2-.2.3-.1.4-.1.4-.1z" />
    </svg>
  );
}

export default SvgFolderUpload;
