import * as React from 'react';

function SvgConstructionHouse(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M.751 8.995A.75.75 0 01.3 7.645L9.831.5c.38-.323.888-.508 1.414-.508.532 0 1.051.19 1.459.536L22.2 7.645a.75.75 0 01-.9 1.2L11.769 1.7a.802.802 0 00-.524-.204.73.73 0 00-.479.177L1.2 8.845a.742.742 0 01-.449.15z" />
      <path d="M5.25 20.85A2.253 2.253 0 013 18.6V9.745a.75.75 0 011.5 0V18.6c0 .414.336.75.75.75H7.5v-3.302a2.252 2.252 0 012.25-2.25h1.5a.75.75 0 010 1.5h-1.5a.75.75 0 00-.75.75v3.302h2.25a.75.75 0 010 1.5h-6zM11.25 10.495c-1.654 0-3-1.346-3-3s1.346-3 3-3 3 1.346 3 3-1.345 3-3 3zm0-4.5c-.827 0-1.5.673-1.5 1.5s.673 1.5 1.5 1.5 1.5-.673 1.5-1.5-.672-1.5-1.5-1.5zM18 23.995a.75.75 0 01-.75-.75v-6.75h-.68a1.49 1.49 0 00-.99.722 1.133 1.133 0 01-1.625.309 1.13 1.13 0 01-.455-.898v-1.633a3.754 3.754 0 013.75-3.75h2.359c.195-.444.64-.75 1.141-.75h2.5a.75.75 0 01.75.75v5.25a.75.75 0 01-.75.75h-2.5c-.501 0-.946-.306-1.141-.75h-.859v6.75a.75.75 0 01-.75.75zm4.5-8.25v-3.75H21v3.75h1.5zm-5.25-3a2.252 2.252 0 00-2.25 2.25v.629a2.994 2.994 0 011.374-.618.738.738 0 01.126-.011h3v-2.25h-2.25z" />
    </svg>
  );
}

export default SvgConstructionHouse;
