import styled from '@emotion/styled';
import { grayscale, transparentize } from 'polished';
/** @jsx jsx */
import { jsx, css, keyframes } from '@emotion/core';
import { getExtraProps, withTheme, unit } from '@embracesbs/helpers';
import { propTypes, defaultProps } from './CircularProgress.props';
import { webStyles } from './CircularProgress.styles';

const StyledCircularProgress = styled.div`
  ${({ size }) => webStyles.styledCircularProgress(unit, size)}
`;

const rotate = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

const cssRotation = css`
  animation: ${rotate} 1.2s ease-in-out infinite;
`;

/** Returns the CircularProgressStyles */
const getCircularProgressStyles = (theme, progressColor) => ({
  default: {
    progress: progressColor,
    background: transparentize(0.7, grayscale(progressColor)),
  },
  hasBgColor: {
    progress: theme.color.light,
    background: 'rgba(255, 255, 255, 0.4)',
  },
});

/**
 * Circular Progress web component
 * @param {array} extraProps - An array of strings which includes the extra prop keys
 * @param {boolean} hasBgColor - Whether the loader is shown on color background
 * @param {number} progress - The size of the progress fill, can be used to create a determinate progress indicator
 * @param {string} progressColor - The color of the progress fill
 * @param {number} size - Size of the progress indicator
 */
const CircularProgress = (props) => {
  const {
    theme,
    extraProps,
    hasBgColor,
    progress,
    progressColor,
    size,
  } = props;

  const radius = size / 2;
  const stroke = radius / 8;
  const normalizedRadius = radius - stroke * 2;
  const circumference = normalizedRadius * 2 * Math.PI;

  const strokeDashoffset = circumference - (progress / 100) * circumference;
  const CircularProgressStyles = getCircularProgressStyles(
    theme,
    progressColor,
  );

  return (
    <StyledCircularProgress
      {...getExtraProps(props, extraProps)}
      css={cssRotation}
      size={size}
    >
      <svg height={radius * 2} width={radius * 2}>
        <circle
          className="circle"
          stroke={
            hasBgColor
              ? CircularProgressStyles.hasBgColor.progress
              : CircularProgressStyles.default.progress
          }
          fill="transparent"
          strokeWidth={stroke}
          strokeDasharray={`${circumference} ${circumference}`}
          style={{ strokeDashoffset }}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
        />
        <circle
          stroke={
            hasBgColor
              ? CircularProgressStyles.hasBgColor.background
              : CircularProgressStyles.default.background
          }
          fill="transparent"
          strokeWidth={stroke}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
        />
      </svg>
    </StyledCircularProgress>
  );
};

CircularProgress.propTypes = propTypes;
CircularProgress.defaultProps = defaultProps;

export default withTheme(CircularProgress);
