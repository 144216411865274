import PropTypes from 'prop-types';
import { theme } from '@embracesbs/helpers';

export const propTypes = {
  /** This will set the background color */
  color: PropTypes.string,

  /** An array of strings which includes the extra prop keys */
  extraProps: PropTypes.array,

  /** Creates a filled badge */
  isFilled: PropTypes.bool,

  /** The variant of the badge */
  variant: PropTypes.oneOf(['counter', 'status', 'contextual', 'toggle']),

  /** The size of the badge */
  size: PropTypes.number,

  /** Status indicator */
  status: PropTypes.oneOf(['online', 'offline', 'away', 'busy']),

  /** Mention indicator */
  hasMention: PropTypes.bool,

  /** Content inside the badge */
  text: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /** Gives the badge a low emphasis variant */
  isLowEmphasis: PropTypes.bool,
};

export const defaultProps = {
  color: theme.color.danger,
  extraProps: [],
  variant: 'counter',
  isFilled: false,
  size: null,
  status: null,
  hasMention: false,
  text: null,
  isLowEmphasis: false,
};
