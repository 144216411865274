import * as React from 'react';

function SvgFolderEdit(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M2.448 19.5a.742.742 0 01-.23-.036A2.447 2.447 0 010 17.061V2.25A2.254 2.254 0 012.251 0h4.5a2.252 2.252 0 012.25 2.25V3h9.75a2.252 2.252 0 012.25 2.25V7.5h.75a.75.75 0 010 1.5H7.88a.753.753 0 00-.721.542l-2.351 8.154a2.5 2.5 0 01-.109.304h3.552a.75.75 0 010 1.5H2.448zm-.197-18a.75.75 0 00-.75.75v14.8c.003.245.106.48.287.657a.912.912 0 00.645.269h.026a.957.957 0 00.906-.692l2.352-8.158A2.262 2.262 0 017.879 7.5h11.622V5.25a.75.75 0 00-.75-.75h-10.5a.75.75 0 01-.75-.75v-1.5a.75.75 0 00-.75-.75h-4.5z" />
      <path d="M11.251 24a.743.743 0 01-.53-.22.745.745 0 01-.205-.677l.75-3.75a.748.748 0 01.205-.384l7.63-7.63a2.844 2.844 0 012.026-.839c.763 0 1.48.296 2.02.832 1.09 1.119 1.099 2.89.036 4.045a.447.447 0 01-.021.022l-7.63 7.63a.744.744 0 01-.384.205l-3.749.75a.684.684 0 01-.148.016zm.956-1.706l2.424-.485 7.463-7.463c.499-.548.504-1.426-.001-1.945l-.021-.022a1.34 1.34 0 00-.945-.379l-.001-.375V12c-.364 0-.707.142-.964.4l-7.47 7.47-.485 2.424z" />
    </svg>
  );
}

export default SvgFolderEdit;
