import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { getExtraProps, withTheme } from '@embracesbs/helpers';
import Icon from '@embracesbs/component-icon';
import Text from '@embracesbs/component-text';
import Divider from '@embracesbs/component-divider';
import ContextModal from '@embracesbs/component-contextmodal';
import List from '@embracesbs/component-list';
import ListItem from '@embracesbs/component-listitem';
import Group from '@embracesbs/component-group';
import { propTypes, defaultProps } from './Table.props';
import { webStyles } from './Table.styles';

export const TableContext = React.createContext({
  columnStyle: defaultProps.columnStyle,
  rowHeight: defaultProps.rowHeight,
  border: defaultProps.border,
});

const StyledTable = styled.table`
  ${({ isTableFixed }) => webStyles.styledTable(isTableFixed)}
`;

const TableRow = styled.tr`
  ${({ theme, isFullWidth, borderOffset, hasHover, onClick }) =>
    webStyles.tableRow(isFullWidth, borderOffset, theme, hasHover, onClick)}
`;

const HeaderContextualItem = styled.th`
  ${({ width = 64, minWidth, maxWidth }) =>
    webStyles.cell(width, minWidth, maxWidth)}
`;

const HeaderItem = styled.th`
  ${({ width, minWidth, maxWidth }) =>
    webStyles.cell(width, minWidth, maxWidth)}
  cursor: pointer;
`;

const BodyContextualItem = styled.td`
  ${({ width, minWidth, maxWidth }) =>
    webStyles.cell(width, minWidth, maxWidth)}
`;

const StyledDivider = styled(Divider)`
  ${({ theme }) => webStyles.styledDivider(theme)}
`;

const StyledTopDivider = styled(Divider)`
  ${({ borderOffset, isFullWidth }) =>
    webStyles.styledTopDivider(borderOffset, isFullWidth)}
`;

const BodyItem = styled.td`
  ${({ theme }) => webStyles.bodyItem(theme)}
  ${({ width, minWidth, maxWidth }) =>
    webStyles.cell(width, minWidth, maxWidth)}
`;

const StyledAlignWrapper = styled.div`
  ${({
    justify,
    leftSpacing,
    rowHeight,
    align,
    isSingleLine = true,
    isDropdown,
  }) =>
    webStyles.cellInner(
      justify,
      leftSpacing,
      rowHeight,
      align,
      isSingleLine,
      isDropdown,
    )}
`;

/**
 * Table component
 * @param {array} extraProps - An array of strings which includes the extra prop keys
 * @param {object} children - The children of the component
 * @param {array} headerData - The table headers if children is not passed
 * @param {array} bodyData - The table body if children is not passed
 * @param {object} border - Optional border
 * @param {boolean} isTableFixed - fixed table style
 * @param {number} rowHeight - height of the rows
 * @param {object} columnStyle - style of each column
 * @param {func} onRowClick - Row click callback that receives the object and its index
 */
const Table = (props) => {
  const {
    extraProps,
    children,
    headerData,
    bodyData,
    border,
    rowHeight,
    theme,
    isTableFixed,
    columnStyle,
    onRowClick,
  } = props;

  const currentBorder = { ...defaultProps.border, ...border };
  const {
    isFullWidth: isBorderFullWidth,
    hasTopBorder,
    hasBorder,
    offsetWidth: borderOffset,
  } = currentBorder;

  const topDivider = hasTopBorder && (
    <StyledTopDivider
      isFullWidth={isBorderFullWidth}
      borderOffset={borderOffset}
      spacing={
        isBorderFullWidth ? 0 : { left: borderOffset, right: borderOffset }
      }
      extraProps={['className']}
      className="tableTopDivider"
    />
  );
  const divider = hasBorder && (
    <StyledDivider extraProps={['className']} className="tableDivider" />
  );

  if (children) {
    return (
      <TableContext.Provider value={{ border, rowHeight, columnStyle }}>
        <div>
          {topDivider}
          <StyledTable
            {...getExtraProps(props, extraProps)}
            isTableFixed={isTableFixed}
          >
            <tbody>{children}</tbody>
          </StyledTable>
        </div>
      </TableContext.Provider>
    );
  }

  const [sortItem, setSortItem] = useState('');
  const [isReversed, setIsReversed] = useState(false);
  const [bodySortedData, setBodySortedData] = useState(bodyData);
  const [openDropdowns, setOpenDropdowns] = useState({});

  useEffect(() => {
    setSortItem('');
    setIsReversed(false);
    setBodySortedData(bodyData);
  }, [bodyData]);

  /** On Sort Item Click */
  const onSortItemClick = (item) => {
    if (item === sortItem) {
      setIsReversed(!isReversed);
      setBodySortedData(bodySortedData.reverse());
    } else {
      setIsReversed(false);
      setSortItem(item);

      setBodySortedData(
        bodySortedData.sort((first, second) => {
          const firstItem = first[item].selected || first[item];
          const secondItem = second[item].selected || second[item];

          if (firstItem < secondItem) return -1;
          if (firstItem > secondItem) return 1;
          return 0;
        }),
      );
    }
  };

  const hasRowHead = bodySortedData.every((item) => item.rowHead);
  const hasRowTail = bodySortedData.every((item) => item.rowTail);

  return (
    <div>
      {topDivider}
      <StyledTable
        {...getExtraProps(props, extraProps)}
        isTableFixed={isTableFixed}
      >
        <thead>
          <TableRow
            isFullWidth={isBorderFullWidth}
            borderOffset={borderOffset}
            hasHover
            ariaLabel="Table header"
          >
            {hasRowHead && (
              <HeaderContextualItem {...columnStyle[0]}>
                {divider}
              </HeaderContextualItem>
            )}
            {headerData.map((headerItemParam, i) => {
              const headerItem = headerItemParam.keyValue || headerItemParam;

              const currentIndex = hasRowHead ? i + 1 : i;
              const currentColumnStyle = {
                ...columnStyle[currentIndex],
                ...{ align: 'flex-end' },
              };

              return (
                <HeaderItem
                  key={headerItem}
                  onClick={() => onSortItemClick(headerItem)}
                  {...currentColumnStyle}
                >
                  <StyledAlignWrapper
                    {...currentColumnStyle}
                    rowHeight={rowHeight}
                  >
                    {headerItemParam.iconName ? (
                      <Icon
                        color={theme.color.dark$2}
                        size={14}
                        iconName={headerItemParam.iconName}
                        spacing={{ bottom: 6 }}
                      />
                    ) : (
                      <Text
                        content={headerItemParam.textContent || headerItem}
                        textStyle="description"
                        textColor={theme.color.dark$2}
                        extraProps={['style']}
                        spacing={{ bottom: 6 }}
                        style={{ textTransform: 'capitalize' }}
                      />
                    )}
                    {headerItem === sortItem && (
                      <Icon
                        color={theme.color.dark$2}
                        size={14}
                        iconName={`Arrow${isReversed ? 'Up' : 'Down'}`}
                        extraProps={['style']}
                        spacing={{ top: 2, left: 6, bottom: 6 }}
                        style={{ position: 'relative' }}
                      />
                    )}
                  </StyledAlignWrapper>
                  {divider}
                </HeaderItem>
              );
            })}
            {hasRowTail && (
              <HeaderContextualItem
                width={128}
                {...columnStyle[columnStyle.length - 1]}
              >
                {divider}
              </HeaderContextualItem>
            )}
          </TableRow>
        </thead>
        <tbody>
          {bodySortedData.map((bodyItem, index) => (
            <TableRow
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              isFullWidth={isBorderFullWidth}
              borderOffset={borderOffset}
              rowHeight={rowHeight}
              hasHover
              onClick={onRowClick ? () => onRowClick(bodyItem, index) : null}
              ariaLabel={`Table row ${index}`}
            >
              {hasRowHead && (
                <BodyContextualItem {...columnStyle[0]}>
                  <StyledAlignWrapper {...columnStyle[0]} rowHeight={rowHeight}>
                    {bodyItem.rowHead}
                  </StyledAlignWrapper>
                  {divider}
                </BodyContextualItem>
              )}
              {headerData.map((headerItemParam, i) => {
                const headerItem = headerItemParam.keyValue || headerItemParam;

                const currentIndex = hasRowHead ? i + 1 : i;

                if (bodyItem[headerItem] && bodyItem[headerItem].selected) {
                  const currentItem = bodyItem[headerItem];
                  const currentKey = `${index}-${i}`;
                  const isOpen = openDropdowns[currentKey] || false;

                  const itemTrigger = (
                    <Icon
                      iconName="ArrowDown1"
                      size={16}
                      extraProps={['style']}
                      style={{
                        transform: `${isOpen ? 'rotate(-180deg)' : ''}`,
                        transition: theme.transition.arrow,
                      }}
                    />
                  );

                  const itemOptions = currentItem.options && (
                    <List>
                      {currentItem.options.map((option) => (
                        <ListItem
                          id={option.text}
                          key={option.text}
                          title={option.text}
                          icon={{
                            iconName: option.icon,
                            size: 16,
                            spacing: { right: theme.spacing.$4Number },
                            color: theme.color.dark$3,
                          }}
                          onItemClick={() => {
                            if (currentItem.onSelect)
                              currentItem.onSelect(option.text);
                            setOpenDropdowns((prev) => ({
                              ...prev,
                              [currentKey]: false,
                            }));
                          }}
                        />
                      ))}
                    </List>
                  );

                  return (
                    <BodyItem key={headerItem} {...columnStyle[currentIndex]}>
                      <StyledAlignWrapper
                        {...columnStyle[currentIndex]}
                        rowHeight={rowHeight}
                        isDropdown
                      >
                        <Group>
                          <Text
                            content={currentItem.selected}
                            textColor={
                              itemOptions
                                ? theme.color.black
                                : theme.color.dark$4
                            }
                          />
                          {itemOptions && (
                            <ContextModal
                              trigger={itemTrigger}
                              isOpen={isOpen}
                              popupProps={{ hasArrow: false, justify: 'right' }}
                              toggleOpen={(nextState) => {
                                if (nextState !== openDropdowns[currentKey]) {
                                  setOpenDropdowns((prev) => ({
                                    ...prev,
                                    [currentKey]: nextState,
                                  }));
                                }
                              }}
                              extraProps={['onClick']}
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                            >
                              {itemOptions}
                            </ContextModal>
                          )}
                          {!itemOptions && (
                            <Icon
                              iconName="ArrowDown1"
                              size={16}
                              color={theme.color.dark$4}
                            />
                          )}
                        </Group>
                      </StyledAlignWrapper>
                      {divider}
                    </BodyItem>
                  );
                }

                return (
                  <BodyItem key={headerItem} {...columnStyle[currentIndex]}>
                    <StyledAlignWrapper
                      {...columnStyle[currentIndex]}
                      rowHeight={rowHeight}
                    >
                      <Text content={bodyItem[headerItem]} />
                    </StyledAlignWrapper>
                    {divider}
                  </BodyItem>
                );
              })}
              {hasRowTail && (
                <BodyContextualItem {...columnStyle[columnStyle.length - 1]}>
                  <StyledAlignWrapper
                    className="row-tail"
                    justify="flex-end"
                    rowHeight={rowHeight}
                    {...columnStyle[columnStyle.length - 1]}
                  >
                    {bodyItem.rowTail}
                  </StyledAlignWrapper>
                  {divider}
                </BodyContextualItem>
              )}
            </TableRow>
          ))}
        </tbody>
      </StyledTable>
    </div>
  );
};

Table.propTypes = propTypes;
Table.defaultProps = defaultProps;

export default withTheme(Table);
