import PropTypes from 'prop-types';

export const propTypes = {
  /** This can contain text or a number */
  children: PropTypes.node,

  /** An array of strings which includes the extra prop keys */
  extraProps: PropTypes.array,

  /** Position of the badge wrapper */
  position: PropTypes.object,

  /** The Badge node */
  badge: PropTypes.node,

  /** Position Bottom Left */
  bottomLeft: PropTypes.bool,

  /** Position Bottom Right */
  bottomRight: PropTypes.bool,

  /** Position Top Left */
  topLeft: PropTypes.bool,

  /** Position Top Right */
  topRight: PropTypes.bool,
};

export const defaultProps = {
  children: null,
  extraProps: [],
  bottomLeft: false,
  bottomRight: false,
  topLeft: false,
  topRight: false,
  badge: null,
};
