import * as React from 'react';

function SvgListNumbers(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M8.25 4.498a.75.75 0 010-1.5h15a.75.75 0 010 1.5h-15zM8.25 13.498a.75.75 0 010-1.5h15a.75.75 0 010 1.5h-15zM8.25 22.498a.75.75 0 010-1.5h15a.75.75 0 010 1.5h-15zM2.251 23.998c-1.008 0-1.9-.679-2.169-1.65a.751.751 0 01.723-.95c.336 0 .633.226.723.551.089.323.387.55.723.55.2 0 .388-.078.53-.22a.744.744 0 000-1.06.743.743 0 00-.53-.219.751.751 0 01-.002-1.5.75.75 0 10-.725-.945.75.75 0 01-1.448-.389 2.249 2.249 0 113.851 2.079c.371.413.574.941.575 1.501a2.256 2.256 0 01-2.251 2.252c.001 0 0 0 0 0zM.75 14.998a.746.746 0 01-.719-.535.756.756 0 01.307-.842l2.421-1.591c.156-.229.241-.505.241-.782a.75.75 0 00-1.5 0 .75.75 0 01-1.5 0c0-1.241 1.009-2.25 2.25-2.25s2.25 1.009 2.25 2.25c0 .653-.224 1.294-.632 1.804a.746.746 0 01-.174.158l-.438.288h.494c.414 0 .75.336.75.75s-3.75.75-3.75.75zM.75 7.498a.75.75 0 010-1.5h.75v-4.5H.75a.75.75 0 010-1.5h.75c.827 0 1.5.673 1.5 1.5v4.5h.75a.75.75 0 010 1.5h-3z" />
    </svg>
  );
}

export default SvgListNumbers;
