import * as React from 'react';

function SvgVideoFileAvi(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M2.25 24A2.252 2.252 0 010 21.75V2.25A2.252 2.252 0 012.25 0h10.629c.6 0 1.165.234 1.59.658l5.872 5.872c.425.426.659.991.659 1.591v4.629a.75.75 0 01-1.5 0V9h-5.25A2.252 2.252 0 0112 6.75V1.5H2.25a.75.75 0 00-.75.75v19.5c0 .414.336.75.75.75h6a.75.75 0 010 1.5h-6zM13.5 6.75c0 .414.336.75.75.75h4.939L13.5 1.811V6.75z" />
      <path d="M14.25 24a.75.75 0 01-.75-.75V21H12v2.25a.75.75 0 01-1.5 0v-6A2.252 2.252 0 0112.75 15 2.252 2.252 0 0115 17.25v6a.75.75 0 01-.75.75zm-.75-4.5v-2.25a.75.75 0 00-1.5 0v2.25h1.5zM18.75 24.004a.75.75 0 01-.624-.334A9.65 9.65 0 0116.5 18.3v-2.55a.75.75 0 011.5 0v2.55c0 1.184.258 2.354.75 3.423a8.197 8.197 0 00.75-3.423v-2.55a.75.75 0 011.5 0v2.55a9.65 9.65 0 01-1.626 5.37.75.75 0 01-.624.334zM23.25 24a.75.75 0 01-.75-.75v-7.5a.75.75 0 011.5 0v7.5a.75.75 0 01-.75.75z" />
    </svg>
  );
}

export default SvgVideoFileAvi;
