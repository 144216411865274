import * as React from 'react';

function SvgProfessionsManConstruction1(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M21.7 24a.75.75 0 01-.75-.75 9.023 9.023 0 00-2.251-5.944v5.944a.75.75 0 01-1.5 0v-7.306a9.123 9.123 0 00-.771-.497c-.225 2.272-2.147 4.053-4.478 4.053s-4.253-1.781-4.478-4.053c-.266.153-.525.32-.773.499v7.304a.75.75 0 01-1.5 0v-5.942A9.028 9.028 0 002.95 23.25a.75.75 0 01-1.5 0c0-3.207 1.443-6.197 3.961-8.206a.719.719 0 01.16-.125 10.485 10.485 0 012.385-1.377 10.372 10.372 0 013.995-.792 10.424 10.424 0 016.387 2.174c.052.032.101.07.144.113a10.45 10.45 0 013.969 8.213.752.752 0 01-.751.75zM8.95 15c0 1.654 1.346 3 3 3s3-1.346 3-3v-.233c-.966-.343-1.973-.517-3-.517s-2.035.174-3 .517V15zM11.949 12a6.015 6.015 0 01-5.952-5.25h-.798a.75.75 0 010-1.5h.798A6.015 6.015 0 019.425.558 5.945 5.945 0 0111.949 0a5.937 5.937 0 012.604.596 6.005 6.005 0 013.348 4.654h.798a.75.75 0 010 1.5h-.798A6.014 6.014 0 0111.949 12zM7.516 6.75c.361 2.133 2.234 3.75 4.433 3.75s4.072-1.617 4.433-3.75H7.516zm8.865-1.5a4.528 4.528 0 00-1.432-2.594V5.25h1.432zm-2.932 0V1.758c-.482-.171-.985-.258-1.5-.258s-1.018.087-1.5.258V5.25h3zm-4.5 0V2.656A4.528 4.528 0 007.517 5.25h1.432z" />
    </svg>
  );
}

export default SvgProfessionsManConstruction1;
