import React from 'react';
import styled from '@emotion/styled';
import {
  getExtraProps,
  withTheme,
  unit,
  getSpacingString,
} from '@embracesbs/helpers';
import Scrollbar from '@embracesbs/component-scrollbar';
import { propTypes, defaultProps } from './Layout.props';

const StyledLayout = styled.div`
  ${({ columns, rows, areasString }) => `
    display: ${columns ? 'inline-grid' : 'grid'};
    grid-template-rows: ${rows || null};
    grid-template-columns: ${columns || null};
    grid-template-areas: ${areasString || null};
    width: 100%;
    height: 100%;
  `}
`;

const StyledChild = styled.div`
  ${({ theme, padding, border, childArea, bgColor }) => `
    grid-area: ${childArea || 0};
    max-height: ${childArea ? '100%' : null};
    padding: ${padding};
    border-color: ${theme.layout.border};
    border-width: ${border};
    border-style: solid;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: ${bgColor || 0};
      z-index: -1;
    }

    .track-vertical {
      position: absolute;
      width: ${unit(6)};
      transition: opacity 200ms ease 0s;
      opacity: 0;
      right: ${unit(5)};
      bottom: ${unit(24)};
      top: ${unit(24)};
      border-radius: ${unit(3)};
      visibility: hidden;
    }
  `}
`;

/**
 * Layout component
 *
 * @param {node} children
 * @param {string} rows
 * @param {string} columns
 * @param {string} bgColor
 * @param {string} className
 * @param {array} spacing
 * @param {array} border
 * @param {bool} hasScrollbar
 * @param {bool} horizontalScroll
 */
const Layout = (props) => {
  const {
    children,
    rows,
    columns,
    bgColor,
    spacing,
    border,
    hasScrollbar,
    className,
    extraProps,
  } = props;
  let areasString = '';
  /**
   * Checking if the grid is vertical or horizontal
   */
  const componentCheck = () => {
    const nodes = React.Children.map(children, (child, i) => {
      const element = (
        <StyledChild
          bgColor={bgColor}
          padding={getSpacingString(spacing[i], defaultProps.spacing)}
          border={getSpacingString(border[i], defaultProps.border)}
          childArea={`col-${[i]}`}
        >
          {hasScrollbar && (
            <Scrollbar extraProps={['className']} className="scrollbar-tracks">
              {child}
            </Scrollbar>
          )}

          {!hasScrollbar && child}
        </StyledChild>
      );

      if (columns) {
        areasString = `${areasString} col-${[i]}`;
      } else {
        areasString = `${areasString}'col-${[i]}' `;
      }

      return element;
    });

    if (columns) {
      areasString = `'${areasString.trim()}'`;
    }

    return (
      <StyledLayout
        className={className}
        rows={rows}
        columns={columns}
        areasString={areasString}
        {...getExtraProps(props, extraProps)}
      >
        {nodes}
      </StyledLayout>
    );
  };

  /**
   * Returns a styled Layout component with child components
   */
  return componentCheck();
};

Layout.propTypes = propTypes;
Layout.defaultProps = defaultProps;

/** @component */
export default withTheme(Layout);
