import * as React from 'react';

function SvgLayers(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M12 23.997c-.365 0-.728-.088-1.052-.255L.739 18.46a1.367 1.367 0 010-2.426l2.729-1.41L.74 13.213a1.36 1.36 0 01-.585-.585 1.359 1.359 0 01-.089-1.042 1.36 1.36 0 01.674-.8l2.732-1.413L.739 7.96a1.362 1.362 0 01-.675-1.626 1.36 1.36 0 01.674-.8L10.947.256a2.3 2.3 0 012.105-.001l10.209 5.278a.732.732 0 01.109.068 1.368 1.368 0 01-.107 2.363l-2.728 1.41 2.728 1.411a1.365 1.365 0 01.001 2.426l-2.731 1.413 2.73 1.414a1.363 1.363 0 010 2.425l-10.209 5.276c-.326.17-.689.258-1.054.258zm-.363-1.588a.801.801 0 00.727 0l9.979-5.156L18.9 15.47l-5.847 3.024a2.302 2.302 0 01-2.105.001L5.1 15.469l-3.442 1.778 9.979 5.162zm0-5.247a.788.788 0 00.726 0L22.342 12 18.9 10.22l-5.848 3.022a2.307 2.307 0 01-2.104 0l-5.845-3.024L1.658 12l9.979 5.162zm0-5.253a.801.801 0 00.727 0l9.984-5.159-9.984-5.162a.807.807 0 00-.728 0L1.658 6.747l9.979 5.162z" />
    </svg>
  );
}

export default SvgLayers;
