import * as React from 'react';

function SvgMessagesBubbleSquareAdd(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M17.25 24c-3.722 0-6.75-3.028-6.75-6.75s3.028-6.75 6.75-6.75S24 13.528 24 17.25 20.972 24 17.25 24zm0-12C14.355 12 12 14.355 12 17.25s2.355 5.25 5.25 5.25 5.25-2.355 5.25-5.25S20.145 12 17.25 12z" />
      <path d="M17.25 21a.75.75 0 01-.75-.75V18h-2.25a.75.75 0 010-1.5h2.25v-2.25a.75.75 0 011.5 0v2.25h2.25a.75.75 0 010 1.5H18v2.25a.75.75 0 01-.75.75z" />
      <path d="M3.75 24a.753.753 0 01-.75-.75V19.5h-.75A2.252 2.252 0 010 17.25v-15A2.252 2.252 0 012.25 0h19.5A2.252 2.252 0 0124 2.25v7.5a.75.75 0 01-1.5 0v-7.5a.75.75 0 00-.75-.75H2.25a.75.75 0 00-.75.75v15c0 .414.336.75.75.75h1.5a.75.75 0 01.75.75v3.001L7.773 19.3a.747.747 0 011.05.15c.12.161.171.358.142.556a.74.74 0 01-.293.494L4.2 23.85a.753.753 0 01-.45.15z" />
    </svg>
  );
}

export default SvgMessagesBubbleSquareAdd;
