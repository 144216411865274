import * as React from 'react';

function SvgSmileySickContageous(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M5.732 9.518a.757.757 0 01-.714-.52.74.74 0 01.047-.572 4.484 4.484 0 013.958-2.415c.294 0 .589.03.877.088a.751.751 0 01-.149 1.485.75.75 0 01-.148-.015 2.942 2.942 0 00-.583-.059 2.968 2.968 0 00-2.62 1.6.747.747 0 01-.668.408zM18.268 9.518a.747.747 0 01-.668-.408 2.968 2.968 0 00-2.621-1.599c-.194 0-.389.02-.58.058a.753.753 0 01-.884-.586.748.748 0 01.586-.884c.288-.058.583-.088.877-.088 1.653 0 3.207.948 3.958 2.415a.746.746 0 01-.668 1.092z" />
      <path d="M12 24a11.995 11.995 0 01-10.573-6.332.717.717 0 01-.077-.148A12.006 12.006 0 010 12a12.412 12.412 0 01.033-.854C.476 4.894 5.729 0 12 0c6.271 0 11.523 4.894 11.966 11.145a.69.69 0 01.009.127c.017.275.025.507.025.728 0 1.915-.467 3.823-1.351 5.521a.698.698 0 01-.076.146A11.993 11.993 0 0112 24zm-8.613-6A10.424 10.424 0 0012 22.5c3.472 0 6.644-1.67 8.613-4.5H18a2.252 2.252 0 01-2.25 2.25h-7.5A2.252 2.252 0 016 18H3.387zm4.863-4.5a.75.75 0 00-.75.75V18c0 .414.336.75.75.75h7.5a.75.75 0 00.75-.75v-3.75a.75.75 0 00-.75-.75h-7.5zm13.233 3a10.347 10.347 0 001.012-4.166l-4.507 1.69c.008.076.012.152.012.226v2.25h3.483zM6 16.5v-2.25c0-.074.004-.15.012-.225l-4.507-1.69A10.346 10.346 0 002.517 16.5H6zm9.75-4.5c.605 0 1.172.239 1.593.665l5.082-1.906C21.805 5.544 17.281 1.5 12 1.5c-5.281 0-9.805 4.044-10.425 9.259l5.081 1.906A2.232 2.232 0 018.25 12h7.5z" />
    </svg>
  );
}

export default SvgSmileySickContageous;
