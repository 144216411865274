import * as React from 'react';

function SvgSingleNeutralActionsRemove(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M.75 18.001A.752.752 0 010 17.25c.003-4.134 3.369-7.498 7.503-7.498a7.484 7.484 0 012.941.6.745.745 0 01.402.41.748.748 0 01-.696 1.03c-.101 0-.2-.02-.294-.06a5.964 5.964 0 00-2.349-.48 5.967 5.967 0 00-4.246 1.755A5.96 5.96 0 001.5 17.25a.751.751 0 01-.75.751zM7.5 9.751c-2.688 0-4.875-2.187-4.875-4.875S4.812.001 7.5.001s4.875 2.187 4.875 4.875S10.188 9.751 7.5 9.751zm0-8.25c-1.861 0-3.375 1.514-3.375 3.375S5.639 8.251 7.5 8.251s3.375-1.514 3.375-3.375S9.361 1.501 7.5 1.501zM17.25 23.999c-3.722 0-6.75-3.028-6.75-6.75s3.028-6.75 6.75-6.75S24 13.527 24 17.249s-3.028 6.75-6.75 6.75zm0-12c-2.895 0-5.25 2.355-5.25 5.25s2.355 5.25 5.25 5.25 5.25-2.355 5.25-5.25-2.355-5.25-5.25-5.25z" />
      <path d="M19.5 20.248a.743.743 0 01-.53-.22l-1.72-1.72-1.72 1.72a.744.744 0 01-1.06 0 .752.752 0 010-1.061l1.72-1.72-1.72-1.72a.752.752 0 01.53-1.281c.2 0 .389.078.53.22l1.72 1.72 1.72-1.72a.744.744 0 011.06 0 .752.752 0 010 1.061l-1.72 1.72 1.72 1.72a.752.752 0 01-.53 1.281z" />
    </svg>
  );
}

export default SvgSingleNeutralActionsRemove;
