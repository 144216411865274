import PropTypes from 'prop-types';

export const propTypes = {
  /** The children the SidebarHeader can contain */
  children: PropTypes.node,
  /** An array of strings which includes the extra prop keys */
  extraProps: PropTypes.array,
};

export const defaultProps = {
  children: null,
  extraProps: [],
};
