import * as React from 'react';

function SvgDisabilityWalkingHelp(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M10.501 6.75c-1.861 0-3.375-1.514-3.375-3.375S8.64 0 10.501 0s3.375 1.514 3.375 3.375-1.514 3.375-3.375 3.375zm0-5.25c-1.034 0-1.875.841-1.875 1.875s.841 1.875 1.875 1.875 1.875-.841 1.875-1.875S11.535 1.5 10.501 1.5z" />
      <path d="M2.248 24a2.253 2.253 0 01-2.053-3.165l6-13.5A2.257 2.257 0 018.2 6a2.33 2.33 0 012.054 1.224C10.292 7.296 11.25 9 14.251 9c.96 0 1.808.615 2.121 1.5h.787a3.745 3.745 0 013.638 2.841l.747 2.987.007.032.807 3.225a2.238 2.238 0 011.643 2.165c0 1.241-1.009 2.25-2.25 2.25s-2.25-1.009-2.25-2.25c0-.9.535-1.699 1.338-2.055l-.611-2.445h-2.446a2.24 2.24 0 00-2.085 1.415l-.434 1.078a2.264 2.264 0 011.238 2.007c0 1.241-1.009 2.25-2.25 2.25s-2.25-1.009-2.25-2.25a2.26 2.26 0 011.717-2.184l.587-1.46a3.731 3.731 0 013.476-2.356h2.072l-.511-2.045A2.248 2.248 0 0017.159 12h-.787a2.26 2.26 0 01-2.121 1.5 9.566 9.566 0 01-5.246-1.412l-.716 1.61a6.645 6.645 0 012.961 5.586v2.466C11.25 22.991 10.241 24 9 24s-2.25-1.009-2.25-2.25v-2.473a2.217 2.217 0 00-.389-1.239l-2.054 4.626A2.254 2.254 0 012.248 24zm5.97-16.5a.754.754 0 00-.653.445l-6 13.501a.747.747 0 00-.014.574.743.743 0 00.698.48.752.752 0 00.687-.446l2.479-5.583a.75.75 0 011.082-.332 3.692 3.692 0 011.753 3.132v2.477a.75.75 0 001.5 0V19.28a5.196 5.196 0 00-2.768-4.619.753.753 0 01-.334-.968L8 10.656a.746.746 0 01.686-.445c.163 0 .325.055.455.154a8.056 8.056 0 005.088 1.636c.43 0 .772-.337.772-.75s-.336-.75-.75-.75c-3.903 0-5.263-2.463-5.319-2.568a.82.82 0 00-.714-.433zM21.751 21a.75.75 0 100 1.5.75.75 0 000-1.5zm-7.5 0a.75.75 0 100 1.5.75.75 0 000-1.5z" />
    </svg>
  );
}

export default SvgDisabilityWalkingHelp;
