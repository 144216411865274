import * as React from 'react';

function SvgTextItalic(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M.75 23.998a.75.75 0 010-1.5h4.8l11.2-21h-4a.75.75 0 010-1.5h10.5a.75.75 0 010 1.5h-4.8l-11.2 21h4a.75.75 0 010 1.5H.75z" />
    </svg>
  );
}

export default SvgTextItalic;
