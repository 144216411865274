import * as React from 'react';

function SvgBathroomShower(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M21.374 24a.75.75 0 01-.75-.75V6.75a5.256 5.256 0 00-5.25-5.25 5.258 5.258 0 00-5.204 4.565c2.527.388 4.454 2.595 4.454 5.185a.75.75 0 01-.75.75h-9a.75.75 0 01-.75-.75c0-2.622 1.968-4.845 4.536-5.197A6.752 6.752 0 0115.374 0a6.758 6.758 0 016.75 6.75v16.5a.75.75 0 01-.75.75zm-8.325-13.5c-.352-1.712-1.892-3-3.674-3s-3.322 1.288-3.674 3h7.348zM4.874 17.25a.747.747 0 01-.711-.987l.75-2.25a.749.749 0 011.383-.098c.09.179.104.383.041.573l-.75 2.25a.751.751 0 01-.713.512zM2.624 24a.747.747 0 01-.712-.988l.75-2.25a.75.75 0 111.423.475l-.75 2.25a.746.746 0 01-.711.513z" />
      <path d="M13.874 17.25a.748.748 0 01-.711-.513l-.75-2.25a.746.746 0 01.712-.988c.323 0 .609.206.711.513l.75 2.25a.746.746 0 01-.712.988zM16.124 24a.748.748 0 01-.711-.513l-.75-2.25a.746.746 0 01.712-.988c.323 0 .609.206.711.513l.75 2.25a.746.746 0 01-.712.988zM9.374 17.25a.75.75 0 01-.75-.75v-2.25a.75.75 0 011.5 0v2.25a.75.75 0 01-.75.75zM9.374 24a.75.75 0 01-.75-.75V21a.75.75 0 011.5 0v2.25a.75.75 0 01-.75.75z" />
    </svg>
  );
}

export default SvgBathroomShower;
