import * as React from 'react';

function SvgLayoutDashboard(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M1.725 14.256a1.719 1.719 0 01-1.222-.508A1.718 1.718 0 010 12.525V1.736C-.001 1.276.178.841.504.515A1.718 1.718 0 011.725.006l7.803.013A1.732 1.732 0 0111.25 1.75v10.775a1.73 1.73 0 01-1.723 1.731H1.725zm.001-12.75a.218.218 0 00-.159.067.224.224 0 00-.067.161v10.792a.227.227 0 00.226.23h7.799a.227.227 0 00.225-.229V1.749a.23.23 0 00-.227-.23l-7.797-.013zM14.471 8.256a1.715 1.715 0 01-1.725-1.705v-4.82A1.723 1.723 0 0114.463.019l7.804-.013c.95 0 1.721.765 1.729 1.706v4.832a1.725 1.725 0 01-1.719 1.712h-7.806zm-.005-6.737a.22.22 0 00-.22.216v4.809a.21.21 0 00.065.149.23.23 0 00.159.063h7.801a.221.221 0 00.225-.217V1.718a.22.22 0 00-.22-.212l-7.81.013zM1.722 23.993A1.723 1.723 0 010 22.283V17.47a1.724 1.724 0 011.719-1.714h7.806c.948 0 1.719.767 1.725 1.71v4.825a1.716 1.716 0 01-1.72 1.715l-7.808-.013zm-.016-6.737c-.111 0-.205.1-.206.218v4.805a.219.219 0 00.219.214l7.807.013a.193.193 0 00.158-.064.213.213 0 00.066-.155V17.47a.219.219 0 00-.22-.214H1.706zM14.471 24.006a1.72 1.72 0 01-1.222-.509 1.718 1.718 0 01-.503-1.223V11.487a1.716 1.716 0 01.503-1.221 1.714 1.714 0 011.22-.51h7.802c.463.001.897.182 1.223.51.326.327.504.762.502 1.224v10.773a1.732 1.732 0 01-1.72 1.731l-7.802.013-.003-.001zm.001-12.75a.228.228 0 00-.226.229v10.79a.229.229 0 00.227.231l7.797-.013a.23.23 0 00.226-.23V11.487a.229.229 0 00-.227-.231h-7.797z" />
    </svg>
  );
}

export default SvgLayoutDashboard;
