/**
 * Handle the counter variant
 * @param {string|number} txt
 * */
const handleCounter = (txt) => {
  if (Number(txt) && txt > 99) {
    return '99+';
  }
  return txt;
};

export default handleCounter;
