import * as React from 'react';

function SvgTablet(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M0 5.3C0 4 1 3 2.2 3h19.5C23 3 24 4 24 5.3v13.5c0 1.2-1 2.2-2.2 2.2H2.2C1 21 0 20 0 18.8V5.3zm2.2-.8c-.4 0-.8.3-.8.8v13.5c0 .4.3.8.8.8h2.2v-15c.1-.1-2.2-.1-2.2-.1zm3.8 15h15.8c.4 0 .8-.3.8-.8V5.3c0-.4-.3-.8-.8-.8H6v15z" />
    </svg>
  );
}

export default SvgTablet;
