import * as React from 'react';

function SvgHumanResourcesSearchMen(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M22.878 14.25a.743.743 0 01-.53-.22l-3.293-3.293A5.99 5.99 0 0115.378 12c-3.308 0-6-2.692-6-6s2.692-6 6-6 6 2.692 6 6a5.99 5.99 0 01-1.263 3.677l3.293 3.293a.752.752 0 010 1.061.746.746 0 01-.53.219zm-7.5-12.75c-2.481 0-4.5 2.019-4.5 4.5s2.019 4.5 4.5 4.5 4.5-2.019 4.5-4.5-2.019-4.5-4.5-4.5zM7.521 18a4.88 4.88 0 01-4.875-4.875A4.88 4.88 0 017.521 8.25a.75.75 0 010 1.5 3.379 3.379 0 00-3.375 3.375c0 1.861 1.514 3.375 3.375 3.375s3.375-1.514 3.375-3.375a.75.75 0 011.5 0A4.88 4.88 0 017.521 18zM16.325 18.951a4.202 4.202 0 01-2.966-1.216.752.752 0 01.526-1.285.75.75 0 01.527.216 2.707 2.707 0 001.913.784 2.728 2.728 0 002.724-2.727.75.75 0 111.5-.002 4.199 4.199 0 01-1.235 2.988 4.194 4.194 0 01-2.986 1.24h-.003z" />
      <path d="M1.156 24.001A.748.748 0 01.449 23a7.47 7.47 0 014.571-4.571 7.439 7.439 0 015.734.302c.66.315 1.265.72 1.803 1.207a6.42 6.42 0 013.775-1.232c.96 0 1.891.212 2.769.631a6.392 6.392 0 013.295 3.662.745.745 0 01-.03.573.744.744 0 01-.678.428.752.752 0 01-.707-.5 4.904 4.904 0 00-2.527-2.808 4.894 4.894 0 00-3.772-.199c-.39.138-.762.327-1.112.565.433.59.777 1.242 1.024 1.942a.745.745 0 01-.03.573.744.744 0 01-.677.427.752.752 0 01-.707-.5 5.946 5.946 0 00-1.203-2.02.411.411 0 01-.03-.028c-.01-.011-.021-.023-.03-.037A6.032 6.032 0 007.514 19.5c-.679 0-1.35.115-1.995.344a5.974 5.974 0 00-3.657 3.657.75.75 0 01-.706.5z" />
    </svg>
  );
}

export default SvgHumanResourcesSearchMen;
