import * as React from 'react';

function SvgPhoneActionsPause(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M14.894 24.02a5.255 5.255 0 01-2.827-.83l-.025-.015A41.14 41.14 0 01.929 12.064c-1.346-2.112-1.056-4.806.699-6.56l.623-.624c.431-.43.995-.664 1.596-.664.602 0 1.167.234 1.592.66l2.646 2.646c.431.431.665.996.664 1.597 0 .601-.234 1.166-.659 1.591a.75.75 0 000 1.06l4.243 4.242a.748.748 0 001.061 0c.425-.424.99-.658 1.592-.658s1.167.234 1.591.658l2.653 2.652c.424.425.658.99.658 1.591s-.234 1.167-.658 1.591l-.628.63a5.226 5.226 0 01-3.708 1.544zM2.688 6.564a3.727 3.727 0 00-.513 4.667A39.671 39.671 0 0012.87 21.924a3.774 3.774 0 002.026.594c.992 0 1.931-.392 2.644-1.104l.628-.63a.75.75 0 000-1.061l-2.653-2.652a.75.75 0 00-1.062-.001c-.425.424-.99.658-1.592.658a2.234 2.234 0 01-1.59-.658l-4.243-4.242a2.253 2.253 0 010-3.182.745.745 0 00.22-.531.746.746 0 00-.216-.527L4.378 5.935a.745.745 0 00-.531-.22V5.34v.375a.746.746 0 00-.525.214l-.634.635zM15.652 9.762a2.252 2.252 0 01-2.25-2.25v-5.25c0-1.241 1.009-2.25 2.25-2.25s2.25 1.009 2.25 2.25v5.25c0 1.24-1.01 2.25-2.25 2.25zm0-8.25a.75.75 0 00-.75.75v5.25a.75.75 0 001.5 0v-5.25a.75.75 0 00-.75-.75zM21.652 9.762a2.252 2.252 0 01-2.25-2.25v-5.25c0-1.241 1.009-2.25 2.25-2.25s2.25 1.009 2.25 2.25v5.25c0 1.24-1.01 2.25-2.25 2.25zm0-8.25a.75.75 0 00-.75.75v5.25a.75.75 0 001.5 0v-5.25a.75.75 0 00-.75-.75z" />
    </svg>
  );
}

export default SvgPhoneActionsPause;
