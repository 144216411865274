import { unit } from '@embracesbs/helpers';

const baseStyles = {};

/** function for child direction */
const getDirection = (isVertical, isReversed) => {
  if (isVertical && isReversed) return 'column-reverse';
  if (isVertical && !isReversed) return 'column';
  if (!isVertical && isReversed) return ' row-reverse';
  return 'unset';
};

export const webStyles = {
  ...baseStyles,
  styledWrapper: (isVertical, isReversed, isWrapping) => `
    display: flex;
    flex-wrap: ${isVertical || isWrapping ? 'wrap' : 'nowrap'};
    flex-direction: ${getDirection(isVertical, isReversed)};
    box-sizing: border-box;
    align-items: center;
    button {
      vertical-align: middle;
    }
  `,
  styledGrid: ({
    theme,
    justify,
    align,
    isVertical,
    isFullHeight,
    isFullWidth,
    hasDivider,
    innerSpacing,
    padding,
    gridTemplate,
  }) => `
    display: grid;
    box-sizing: border-box;
    ${justify ? `justify-content: ${justify};` : ''}
    align-items: ${align};
    ${isFullHeight ? `height: ${theme.percentage.full};` : ''}
    ${isFullWidth ? `width: ${theme.percentage.full};` : ''}
    ${
      hasDivider
        ? `
          > div {
            padding: ${
              isVertical
                ? `${unit(innerSpacing / 2)} 0`
                : `0 ${unit(innerSpacing / 2)}`
            };
            border-${isVertical ? 'bottom' : 'right'}: 
              ${unit(1)} solid ${theme.color.dark$7};
            &:first-of-type { padding-${isVertical ? 'top' : 'left'}: 0; }
            &:last-of-type {
              padding-${isVertical ? 'bottom' : 'right'}: 0;
              border: none;
            }
          }
        `
        : `grid-gap: ${unit(innerSpacing)};`
    }
    padding: ${padding};
    ${
      gridTemplate
        ? `
          > * { min-height: 0; min-width: 0; }
          grid-auto-flow: ${isVertical ? 'column' : 'row'};
          grid-template-${isVertical ? 'rows' : 'columns'}: ${gridTemplate};
        `
        : `grid-auto-flow: ${isVertical ? 'row' : 'column'};`
    }
  `,
};

export const nativeStyles = {
  ...baseStyles,
  styledWrapper: () => `
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  `,
};
