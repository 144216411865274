import { keyframes, css } from '@emotion/core';
import { unit } from '@embracesbs/helpers';

const baseStyles = {};

/**
 * Styles for the tabs
 */
const tabListStyles = (theme) => css`
  li {
    padding: ${theme.spacing.$3};
    &:before {
      margin-left: -${theme.spacing.$3};
      width: calc(100% + (${theme.spacing.$3}*2));
      box-sizing: border-box;
    }
    &:hover {
      &:before {
        background: ${theme.color.light$8};
        width: calc(100% + (${theme.spacing.$3}*2));
      }
    }
    > div {
      padding: 0;
    }
    .infoCardContent div {
      padding-left: ${theme.spacing.$2};
      color: ${theme.color.light$4};
      white-space: nowrap;
    }
    i {
      min-width: ${theme.lineHeight.$9};
      min-height: ${theme.lineHeight.$9};
      padding: 0;
      color: ${theme.color.light$4};
      height: ${theme.spacing.$5};
      width: ${theme.spacing.$5};
      span {
        font-size: ${theme.spacing.$5};
        height: ${theme.spacing.$5};
        width: ${theme.spacing.$5};
      }
    }
    &.activeItem,
    &:hover {
      .infoCardContent div,
      i {
        color: ${theme.color.light};
      }
    }
  }
`;

export const webStyles = {
  ...tabListStyles,
  ...baseStyles,
  sidebar: (theme) => css`
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    position: absolute;
    left: 0;
    top: 0;
    max-height: 100vh;
    color: ${theme.color.light$4};
    height: ${theme.percentage.full};
    padding: calc(${theme.spacing.$5}) ${theme.spacing.$3};
    z-index: 1;

    /* Add closed and open mode animation based on that if the Sidebar is expanded or not */
    transition: ${theme.transition.basicWidth};
    /* Adding transition animation for every text content in the sidebar */
    .infoCardContent,
    .sidebar-app-context,
    p {
      ${theme.transition.forwards(keyframes`${theme.keyFrames.horizontal}`)}
    }

    .outerContainer {
      margin-left: 0;
    }

    &.mainSidebar {
      &.activeSearch {
        width: ${unit(395)};
      }
      &.activeSearchResults {
        min-width: ${unit(395)};
        width: ${unit(1080)};
      }
      &.showAppSwitcher {
        p,
        .infoCardContent {
          opacity: 1;
          visibility: visible;
        }
        > main {
          > div {
            opacity: 0;
          }
        }
      }
    }
    ${tabListStyles(theme)}
  `,
};

export const nativeStyles = {
  ...baseStyles,
};
