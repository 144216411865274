import PropTypes from 'prop-types';

export const propTypes = {
  /** An array of strings which includs the extra prop keys */
  extraProps: PropTypes.array,
  /** The child elements to be shown in the card content */
  children: PropTypes.node,
  /** Whether the scroll bar should be present */
  hasScrollbar: PropTypes.bool,
  /** The padding around the card content */
  spacing: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.shape({
      bottom: PropTypes.number,
      left: PropTypes.number,
      right: PropTypes.number,
      top: PropTypes.number,
    }),
  ]),
};

export const defaultProps = {
  extraProps: [],
  children: null,
  hasScrollbar: true,
  spacing: 24,
};
