import * as React from 'react';

function SvgFileJs(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M2.25 24.003A2.252 2.252 0 010 21.753v-19.5A2.252 2.252 0 012.25.003h10.629c.601 0 1.167.234 1.592.66l5.871 5.871c.424.425.658.99.658 1.591v4.627a.75.75 0 01-1.5 0v-3.75h-5.25A2.252 2.252 0 0112 6.752v-5.25H2.25a.75.75 0 00-.75.75v19.5c0 .414.336.75.75.75h8.25a.75.75 0 010 1.5H2.25zM13.5 6.753c0 .414.336.75.75.75h4.939L13.5 1.814v4.939z" />
      <path d="M14.25 24.003a.75.75 0 010-1.5h1.5a.75.75 0 00.75-.75v-6a.75.75 0 011.5 0v6a2.252 2.252 0 01-2.25 2.25h-1.5zM20.25 24.003a.75.75 0 010-1.5h1.5a.75.75 0 00.75-.75c0-.715-.434-1.075-1.2-1.65-.802-.602-1.8-1.35-1.8-2.85a2.252 2.252 0 012.25-2.25h1.5a.75.75 0 010 1.5h-1.5a.75.75 0 00-.75.75c0 .715.434 1.075 1.2 1.65.802.602 1.8 1.35 1.8 2.85a2.252 2.252 0 01-2.25 2.25h-1.5z" />
    </svg>
  );
}

export default SvgFileJs;
