import * as React from 'react';

function SvgTableDeleteColumn(props) {
  return (
    <svg data-name="Layer 1" viewBox="0 0 22 22" {...props}>
      <path d="M18.65 1.25H3.26a1.991 1.991 0 00-2.01 2.016v15.549a1.969 1.969 0 00.644 1.485 1.8 1.8 0 001.167.456 1.457 1.457 0 00.2-.014h6.191a.41.41 0 00.048.01.426.426 0 00.049-.01h2.9a.41.41 0 00.048.01.426.426 0 00.049-.01h6.194a1.991 1.991 0 002.01-2.015V3.266a2.014 2.014 0 00-2.1-2.016zm.6 17.475a.485.485 0 01-.51.515h-5.49V16a.75.75 0 00-1.5 0v3.243h-1.5V16a.75.75 0 00-1.5 0v3.244H3.167a.327.327 0 01-.28-.073.471.471 0 01-.137-.358V3.266a.486.486 0 01.51-.516h5.49V6a.75.75 0 001.5 0V2.75h1.5V6a.75.75 0 001.5 0V2.75h5.4c.376 0 .6.193.6.516z" />
      <path d="M13.53 8.621a.749.749 0 00-1.06 0L11 10.091l-1.47-1.47a.75.75 0 10-1.06 1.061l1.47 1.47-1.47 1.469a.75.75 0 101.06 1.061l1.47-1.47 1.47 1.47a.75.75 0 001.06-1.061l-1.469-1.469 1.469-1.47a.75.75 0 000-1.061z" />
    </svg>
  );
}

export default SvgTableDeleteColumn;
