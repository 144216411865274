export const baseStyles = {
  textFieldInput: (theme) => `
    border-radius: ${theme.borderRadius.$1};
    padding: ${theme.spacing.$3};
    text-align: ${theme.textAlign.left};
    width: ${theme.percentage.full};
  `,
  textFieldContainer: (theme) => `
    border-radius: ${theme.borderRadius.$1};
  `,
};

export const nativeStyles = {
  ...baseStyles,
  textFieldInput: (theme) => `
    ${baseStyles.textFieldInput(theme)}
    min-width: ${theme.lineHeight.$9};
  `,
  errorText: (theme) => `
    padding-left: ${theme.spacing.$4};
  `,
  borderContainer: (theme) => `
    border-radius: ${theme.borderRadius.$1};
  `,
  container: (theme) => `
    width: ${theme.percentage.full};
  `,
};

export const webStyles = {
  ...baseStyles,
  textFieldInput: (theme) => `
    ${theme.textStyle.input};
    ${baseStyles.textFieldInput(theme)}
    border: none;
    box-sizing: border-box;
    background-color: inherit;
    resize: vertical;
      &::placeholder {
        color: ${theme.color.dark$4};
      }
      &:disabled {
        &::placeholder {
          color: ${theme.color.dark$5};
        }
        border-color: ${theme.color.dark$6};
        color: ${theme.color.dark$5};
      }
  `,
  textFieldContainer: (theme) => `
    ${baseStyles.textFieldContainer(theme)}
    display: flex;
    flex-direction: row;
    vertical-align: middle;
  `,
  autocompleteStyles: (theme) => `
    ${theme.textStyle.input};
    ${baseStyles.textFieldInput(theme)}
    display: flex;
    padding: 0;
  `,
};
