import * as React from 'react';

function SvgPowerpoint(props) {
  return (
    <svg viewBox="0 0 32 32" {...props}>
      <g data-name="STYLE = COLOR">
        <path d="M18 2A14.041 14.041 0 004 16l17.737 3.737z" fill="#ed6c47" />
        <path
          d="M18 2a14.041 14.041 0 0114 14l-7 4.758L18 16z"
          fill="#ff8f6b"
        />
        <path
          d="M18 30a14.041 14.041 0 0014-14H4a14.041 14.041 0 0014 14z"
          fill="#d35230"
        />
        <path
          d="M16.666 7h-9.36a13.914 13.914 0 00.93 19h8.43A1.337 1.337 0 0018 24.667V8.333A1.337 1.337 0 0016.666 7z"
          opacity={0.1}
        />
        <path
          d="M15.666 8H6.54a13.906 13.906 0 002.845 19h6.282A1.337 1.337 0 0017 25.667V9.333A1.337 1.337 0 0015.666 8z"
          opacity={0.2}
        />
        <path
          d="M15.666 8H6.54a13.89 13.89 0 00.766 17h8.361A1.337 1.337 0 0017 23.667V9.333A1.337 1.337 0 0015.666 8z"
          opacity={0.2}
        />
        <path
          d="M14.666 8H6.54a13.89 13.89 0 00.766 17h7.361A1.337 1.337 0 0016 23.667V9.333A1.337 1.337 0 0014.666 8z"
          opacity={0.2}
        />
        <path
          data-name="Back Plate"
          d="M1.333 8h13.334A1.333 1.333 0 0116 9.333v13.334A1.333 1.333 0 0114.667 24H1.333A1.333 1.333 0 010 22.667V9.333A1.333 1.333 0 011.333 8z"
          fill="#c43e1c"
        />
        <path
          d="M7.997 11a4.168 4.168 0 012.755.805 2.878 2.878 0 01.956 2.331 2.726 2.726 0 01-.473 1.588 3.164 3.164 0 01-1.344 1.186 4.57 4.57 0 01-2.02.424h-1.91V21H4V11zM5.96 15.683h1.687a2.194 2.194 0 001.492-.444 1.107 1.107 0 00.504-1.026q0-1.659-1.933-1.659H5.96z"
          fill="#f9f7f7"
        />
        <path fill="none" d="M0 0h32v32H0z" />
      </g>
    </svg>
  );
}

export default SvgPowerpoint;
