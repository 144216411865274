import * as React from 'react';

function SvgComplex(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <g
        transform="translate(-1)"
        stroke="#000"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <rect x={1.5} y={0.5} width={21} height={19} rx={1} />
        <path d="M6.5 23.5v-4h-3v4M20.5 23.5v-4h-3v4M7.5 8.308V15a.5.5 0 00.5.5h8a.5.5 0 00.5-.5V8.308" />
        <path d="M5.5 10l5.854-4.953a1 1 0 011.292 0L18.5 10M13.5 15.5h-3v-3a1.5 1.5 0 013 0v3z" />
      </g>
    </svg>
  );
}

export default SvgComplex;
