import * as React from 'react';

function SvgPhoneActionsAdd(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M14.8 24c-1 0-2-.3-2.8-.8-4.4-3-8.1-6.7-11.1-11.1-1.3-2.1-1-4.8.7-6.5l.6-.6c.4-.4 1-.7 1.6-.7.6 0 1.2.2 1.6.7L8 7.5c.4.4.7 1 .7 1.6 0 .6-.2 1.2-.7 1.6-.3.3-.3.8 0 1.1l4.2 4.2c.1.1.3.2.5.2s.4-.1.5-.2c.4-.4 1-.7 1.6-.7s1.2.2 1.6.7l2.6 2.6c.4.4.7 1 .7 1.6s-.2 1.2-.7 1.6l-.6.6c-.9 1.1-2.2 1.6-3.6 1.6zM3.7 5.7c-.2 0-.4.1-.5.2l-.6.7c-1.3 1.3-1.5 3.2-.5 4.7C5 15.5 8.6 19.1 12.8 22c.6.4 1.3.6 2 .6 1 0 1.9-.4 2.7-1.1l.6-.6c.1-.1.2-.3.2-.5s-.1-.4-.2-.5l-2.7-2.7c-.1-.1-.3-.2-.5-.2s-.4.1-.5.2c-.4.4-1 .7-1.6.7-.6 0-1.2-.2-1.6-.7L7 13c-1-1-1-2.5-.1-3.4.1-.1.2-.3.2-.5s0-.4-.2-.5L4.3 5.9c-.2-.1-.4-.2-.6-.2zM18 11c-.4 0-.8-.3-.8-.8V6.8h-3.5c-.4 0-.7-.4-.7-.8s.3-.8.8-.8h3.5V1.8c-.1-.5.3-.8.7-.8s.8.3.8.8v3.5h3.5c.3 0 .7.3.7.7s-.3.8-.8.8h-3.5v3.5c0 .4-.3.7-.7.7z" />
    </svg>
  );
}

export default SvgPhoneActionsAdd;
