import * as React from 'react';

function SvgPencil1(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M.741 24.003a.755.755 0 01-.531-.22.754.754 0 01-.196-.716l1.771-6.905a.56.56 0 01.03-.081.756.756 0 01.158-.253c.002-.004 14.533-14.535 14.533-14.535A4.358 4.358 0 0119.614.004c1.17 0 2.27.453 3.096 1.277l.017.017c1.699 1.738 1.673 4.528-.057 6.219L8.177 22.01a.762.762 0 01-.231.155l-.025.01a.438.438 0 01-.089.032L.927 23.978a.695.695 0 01-.186.025zm1.041-1.792l4.41-1.131-3.28-3.273-1.13 4.404zm5.868-1.794L18.577 9.491l-4.073-4.073-10.93 10.93 4.076 4.069zM19.637 8.43l1.033-1.033-4.073-4.073-1.033 1.033 4.073 4.073zm2.092-2.096a2.895 2.895 0 00-.061-3.973l-.017-.017a2.868 2.868 0 00-2.038-.84 2.87 2.87 0 00-1.955.76l4.071 4.07z" />
    </svg>
  );
}

export default SvgPencil1;
