import * as React from 'react';

function SvgOfficeFileTextGraph(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M2.248 23.998a2.252 2.252 0 01-2.25-2.25v-19.5a2.252 2.252 0 012.25-2.25h15c.59 0 1.148.227 1.571.639l2.996 2.879c.435.424.684 1.012.684 1.615v16.617a2.252 2.252 0 01-2.25 2.25H2.248zm0-22.5a.75.75 0 00-.75.75v19.5c0 .414.336.75.75.75h18a.75.75 0 00.75-.75V5.131a.754.754 0 00-.227-.537l-2.996-2.879a.75.75 0 00-.527-.217h-15z" />
      <path d="M16.498 10.498a.75.75 0 010-1.5h.75a.75.75 0 010 1.5h-.75zM14.998 5.998a.75.75 0 010-1.5h2.25a.75.75 0 010 1.5h-2.25zM13.498 14.998a.75.75 0 010-1.5h3.75a.75.75 0 010 1.5h-3.75zM5.248 19.498a.75.75 0 010-1.5h12a.75.75 0 010 1.5h-12zM8.248 14.248c-2.895 0-5.25-2.355-5.25-5.25s2.355-5.25 5.25-5.25 5.25 2.355 5.25 5.25-2.355 5.25-5.25 5.25zm-2.638-2.59a3.73 3.73 0 002.638 1.09 3.754 3.754 0 003.75-3.75c0-1.781-1.288-3.32-3-3.672v3.672a.749.749 0 01-.334.624L5.61 11.658zm1.888-6.332c-1.712.352-3 1.892-3 3.672 0 .485.095.967.277 1.414l2.723-1.815V5.326z" />
    </svg>
  );
}

export default SvgOfficeFileTextGraph;
