import * as React from 'react';

function SvgCommonFileTextCash(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M17.25 21.75a.75.75 0 01-.75-.75h-.75a.75.75 0 010-1.5h2.031a.589.589 0 00.543-.824.584.584 0 00-.323-.317l-2.063-.826a2.083 2.083 0 01-1.312-1.94c0-.559.217-1.084.612-1.48a2.079 2.079 0 011.262-.603v-.01a.75.75 0 011.5 0h.75a.75.75 0 010 1.5h-2.033a.593.593 0 00-.22 1.142l2.063.826a2.094 2.094 0 011.161 2.722A2.08 2.08 0 0118 20.99V21a.75.75 0 01-.75.75z" />
      <path d="M17.25 24c-3.722 0-6.75-3.028-6.75-6.75s3.028-6.75 6.75-6.75S24 13.528 24 17.25 20.972 24 17.25 24zm0-12C14.355 12 12 14.355 12 17.25s2.355 5.25 5.25 5.25 5.25-2.355 5.25-5.25S20.145 12 17.25 12zM3.75 7.5a.75.75 0 010-1.5h10.5a.75.75 0 010 1.5H3.75zM3.75 12a.75.75 0 010-1.5h6a.75.75 0 010 1.5h-6zM3.75 16.5a.75.75 0 010-1.5H7.5a.75.75 0 010 1.5H3.75z" />
      <path d="M2.25 21A2.252 2.252 0 010 18.75V2.25A2.252 2.252 0 012.25 0h10.629c.601 0 1.165.234 1.59.658l2.872 2.872c.425.425.659.99.659 1.591V8.25a.75.75 0 01-1.5 0V5.121c0-.197-.08-.39-.219-.53l-2.872-2.872a.748.748 0 00-.53-.219H2.25a.75.75 0 00-.75.75v16.5c0 .414.336.75.75.75H7.5a.75.75 0 010 1.5H2.25z" />
    </svg>
  );
}

export default SvgCommonFileTextCash;
