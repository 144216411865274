import * as React from 'react';

function SvgLadder(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path
        d="M.75 23.25L10.5.75m3 22.5L23.25.75m-14.7 4.5H21.3M6.6 9.75h12.75m-14.7 4.5H17.4m-14.7 4.5h12.75"
        stroke="#262338"
        strokeWidth={1.5}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgLadder;
