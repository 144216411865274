import * as React from 'react';

function SvgLeaf(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <g
        stroke="#262338"
        strokeWidth={1.5}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M10.554 19.1c-3.878 0-7.021-3.373-7.021-7.532C3.533.645 15.787 6.252 21.686 1a.41.41 0 01.446-.048.495.495 0 01.28.378C24.213 12.643 16.45 19.1 10.554 19.1z" />
        <path d="M13.363 9.554a22.713 22.713 0 00-8.026 4.286l-3.638 3.33" />
      </g>
    </svg>
  );
}

export default SvgLeaf;
