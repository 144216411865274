import * as React from 'react';

function SvgStorage(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <g
        stroke="#000"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M2 6.225v13M22 6.225v13M5.5 16.225H12v7H5.5a.5.5 0 01-.5-.5v-6a.5.5 0 01.5-.5zM12 16.225h6.5a.5.5 0 01.5.5v6a.5.5 0 01-.5.5H12v-7zM8.5 9.225h6a.5.5 0 01.5.5v6.5H8v-6.5a.5.5 0 01.5-.5h0z" />
        <path d="M10 9.225h3v2.5a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-2.5zM7 16.225h3v2.5a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-2.5zM14 16.225h3v2.5a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-2.5zM22.5 6.225a.5.5 0 00.5-.5V4.586a.5.5 0 00-.342-.475L12.3.822a1 1 0 00-.606 0L1.354 4.117A.5.5 0 001 4.6v1.125a.5.5 0 00.5.5h21z" />
      </g>
    </svg>
  );
}

export default SvgStorage;
