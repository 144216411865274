import * as React from 'react';

function SvgRatingStarRemove(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M1.5 23.2c-.2 0-.4-.1-.5-.2-.1-.1-.2-.3-.2-.5s0-.4.2-.5L22 1c.1-.1.3-.2.5-.2s.4 0 .5.2c.1.1.2.3.2.5s0 .4-.2.5L2 23c-.1.2-.3.2-.5.2zM5.6 15.1c-.2 0-.4-.1-.5-.2L.5 10.3c-.3-.2-.5-.6-.5-1s.2-.8.5-1.1c.3-.3.6-.4 1-.5l6-.6L10.7.8c.2-.4.5-.7.9-.8.4-.1.8-.1 1.2.1.3.2.5.4.7.7l1.8 3.5c.2.4 0 .8-.3 1-.4.2-.8 0-1-.3l-1.8-3.5-.8-.3.7.3-3.5 6.7c-.1.2-.3.3-.6.4l-6.4.6-.6.6.5-.5 4.6 4.6c.3.3.3.8 0 1.1-.1.1-.3.1-.5.1zM18.9 24c-.2 0-.5-.1-.7-.2l-6.2-3-6.2 3.1c-.4.1-.8.1-1.2 0-.4-.1-.7-.4-.9-.8-.2-.4 0-.8.3-1 .4-.2.8 0 1 .3l6.6-3.2c.2-.1.5-.1.7 0l6.5 3.2.8-.3-.6.3-2-7.1c-.1-.3 0-.5.2-.7l5.3-5.3v-.1l-3.2-.3c-.4 0-.7-.4-.7-.8s.4-.7.8-.7l3.2.3c.8.1 1.5.8 1.4 1.7 0 .4-.2.7-.5 1l-5 4.9 1.8 6.7c.1.4.1.8-.2 1.2-.2.4-.5.6-1 .7.1.1-.1.1-.2.1z" />
    </svg>
  );
}

export default SvgRatingStarRemove;
