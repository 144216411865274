import * as React from 'react';

function SvgSynchronizeArrows1(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M3.749 15.249a.75.75 0 01-.56-.253l-2.971-2.97c-.142-.141-.22-.33-.22-.53s.078-.389.22-.53c.141-.142.33-.22.53-.22s.389.078.53.22l1.72 1.72v-.439a8.974 8.974 0 013.27-6.935 8.88 8.88 0 015.716-2.06c.288 0 .581.014.87.041a8.942 8.942 0 016.085 3.229.747.747 0 01-.101 1.056.749.749 0 01-1.056-.101 7.451 7.451 0 00-5.07-2.691 7.75 7.75 0 00-.729-.035 7.396 7.396 0 00-4.759 1.717 7.474 7.474 0 00-2.725 5.779v.439l1.72-1.72a.744.744 0 011.06 0c.142.141.22.33.22.53s-.078.389-.22.53l-2.986 2.986a.733.733 0 01-.544.237zM12.006 21.245a8.944 8.944 0 01-6.552-2.823A.747.747 0 016 17.158c.206 0 .405.086.546.235a7.45 7.45 0 005.451 2.352 7.477 7.477 0 005.15-2.045 7.533 7.533 0 002.345-5.136l-1.713 1.713a.744.744 0 01-1.06 0 .752.752 0 010-1.061l3-3a.75.75 0 01.245-.163l.024-.009a.737.737 0 01.554.012.75.75 0 01.238.16l3 3a.752.752 0 01-.53 1.281.743.743 0 01-.53-.22l-1.725-1.725a9.04 9.04 0 01-2.818 6.239 8.963 8.963 0 01-6.171 2.454z" />
    </svg>
  );
}

export default SvgSynchronizeArrows1;
