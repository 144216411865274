import * as React from 'react';

function SvgDistrict(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M7.553 23.999c-.315 0-.621-.065-.91-.193L1.348 21.56A2.26 2.26 0 010 19.499V2.249c0-.242.039-.481.115-.711C.305.968.705.506 1.243.237A2.222 2.222 0 013.05.15l4.292 2.071a.709.709 0 00.396.002L15.132.141a2.269 2.269 0 011.243.013l6.021 1.806A2.235 2.235 0 0124 4.115V20.87c0 .287-.054.568-.161.835a2.242 2.242 0 01-2.091 1.415c-.286 0-.566-.054-.833-.161l-4.907-1.928a.76.76 0 00-.54-.011l-7.122 2.836a2.308 2.308 0 01-.793.143zM2.249 1.498a.746.746 0 00-.749.751v17.25a.75.75 0 00.445.685l5.294 2.245a.75.75 0 00.574.025l7.121-2.835a2.3 2.3 0 01.793-.143c.286 0 .567.054.833.161l4.907 1.928a.748.748 0 001.032-.694V4.115a.746.746 0 00-.534-.719l-6.02-1.806a.745.745 0 00-.412-.005L8.139 3.668a2.269 2.269 0 01-1.309-.034.637.637 0 01-.089-.036L2.449 1.526a.76.76 0 00-.2-.028z" />
      <path d="M7.5 20.999a.755.755 0 01-.278-.053l-3.75-1.5A.747.747 0 013 18.749V4.499a.75.75 0 011.029-.696l3.516 1.406 8.008-2.184a.772.772 0 01.434.012l4.5 1.5a.75.75 0 01.513.712v13.5a.749.749 0 01-.75.75.742.742 0 01-.238-.039l-4.251-1.417-8.004 2.911a.76.76 0 01-.257.045zm.013-1.553L9 18.905v-2.792l-1.3.359a.748.748 0 01-.458-.019L4.5 15.451v2.791l3.013 1.204zm2.237-5.068a.75.75 0 01.75.75v3.232l3-1.091v-3.02c0-.286.159-.543.415-.671l1.5-.75a.754.754 0 01.574-.04l3.512 1.171v-8.17l-3.772-1.257-.729.199v3.331a.75.75 0 01-1.5 0V5.14L7.697 6.722a.755.755 0 01-.475-.026L4.5 5.607V9.37l3 1.2 3.471-1.388a.747.747 0 01.975.417.75.75 0 01-.418.975l-3.75 1.5a.732.732 0 01-.557 0L4.5 10.986v2.868l3.032 1.108 2.019-.557a.746.746 0 01.199-.027zm6 2.121c.081 0 .161.013.237.039l3.512 1.171V15.54l-3.692-1.231-.807.403v2.011l.494-.179a.755.755 0 01.256-.045z" />
    </svg>
  );
}

export default SvgDistrict;
