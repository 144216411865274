import * as React from 'react';

function SvgELearningMonitor(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M6 24.001a.75.75 0 010-1.5h1.615l.5-3H2.25A2.252 2.252 0 010 17.251v-15A2.252 2.252 0 012.25.001h19.5A2.252 2.252 0 0124 2.251v15a2.252 2.252 0 01-2.25 2.25h-5.865l.5 3H18a.75.75 0 010 1.5H6zm8.865-1.5l-.5-3h-4.73l-.5 3h5.73zm-12.615-21a.75.75 0 00-.75.75v15c0 .414.336.75.75.75h19.5a.75.75 0 00.75-.75v-15a.75.75 0 00-.75-.75H2.25z" />
      <path d="M12.006 15.001a6.512 6.512 0 01-4.262-1.696.753.753 0 01-.244-.554V9.322L4.945 8.186a.75.75 0 010-1.37l6.75-3a.746.746 0 01.61 0l6.743 2.997a.783.783 0 01.17.104l.034.028a.77.77 0 01.124.142l.018.031c.026.043.047.09.065.143l.016.053a.739.739 0 01.025.187v2.25a.75.75 0 01-1.5 0V8.655l-1.5.667v3.429a.75.75 0 01-.242.552 6.425 6.425 0 01-4.237 1.698h-.015zM9 12.406a5.007 5.007 0 003.022 1.095A4.91 4.91 0 0015 12.408v-2.42l-2.695 1.198a.743.743 0 01-.61 0L9 9.988v2.418zm3-2.726l4.903-2.179L12 5.322 7.097 7.501 12 9.68z" />
    </svg>
  );
}

export default SvgELearningMonitor;
