const baseStyles = {
  textFieldWrapperContainer: () => `
    display: flex; 
  `,
};

export const nativeStyles = {
  ...baseStyles,
  textFieldWrapperContainer: (theme) => `
    ${baseStyles.textFieldWrapperContainer(theme)}
    width: ${theme.percentage.full};
  `,
};

export const webStyles = {
  ...baseStyles,
  textFieldWrapperContainer: (theme, isFullHeight) => `
    ${baseStyles.textFieldWrapperContainer(theme)}
    width: 100%;
    ${isFullHeight ? `height: 100%` : ``};
    box-sizing: border-box;
  `,
  styledText: () => `
    display: flex;
    align-items: center;
  `,
};
