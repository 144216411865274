import * as React from 'react';

function SvgBoiler(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <g
        stroke="#000"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <rect x={0.5} y={8} width={23} height={14} rx={1} />
        <path d="M19.5 23.5V22M4.5 23.5v-1.498M7.5 11v8M11.5 11v8M15.5 11v8M19.5 11v8M3.25 11.5a.25.25 0 11-.5 0 .25.25 0 01.5 0M3.25 14.5a.25.25 0 11-.5 0 .25.25 0 01.5 0M13.264.5c-.937 2.327 1 3 0 5.484M17.264.5c-.937 2.327 1 3 0 5.484M9.264.5c-.937 2.327 1 3 0 5.484" />
      </g>
    </svg>
  );
}

export default SvgBoiler;
