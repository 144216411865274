import * as React from 'react';

function SvgCellBorderFrame(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M3 23.2c-1.2 0-2.2-1-2.2-2.2V3C.8 1.8 1.8.8 3 .8h18c1.2 0 2.2 1 2.2 2.2v18c0 1.2-1 2.2-2.2 2.2H3zm18-1.4c.4 0 .8-.3.8-.8v-8.2H21c-.4 0-.8-.3-.8-.8s.3-.8.8-.8h.8V3c0-.4-.3-.8-.8-.8h-8.2V3c0 .4-.3.8-.8.8s-.8-.4-.8-.8v-.8H3c-.4 0-.8.4-.8.8v8.2H3c.4 0 .8.3.8.8s-.4.8-.8.8h-.8V21c0 .4.3.8.8.8h8.2V21c0-.4.3-.8.8-.8s.8.3.8.8v.8H21z" />
      <path d="M6 12.8c-.4 0-.8-.3-.8-.8s.3-.8.8-.8h1.5c.4 0 .8.3.8.8s-.3.8-.8.8H6zM12 14.2c-.4 0-.8-.3-.8-.8v-.8h-.8c-.4 0-.8-.3-.8-.8s.3-.8.8-.8h.8v-.8c0-.4.3-.8.8-.8s.8.3.8.8v.8h.8c.4 0 .8.3.8.8s-.3.8-.8.8h-.8v.8c0 .5-.4.8-.8.8zM16.5 12.8c-.4 0-.8-.3-.8-.8s.3-.8.8-.8H18c.4 0 .8.3.8.8s-.3.8-.8.8h-1.5zM12 8.2c-.4 0-.8-.3-.8-.7V6c0-.4.3-.8.8-.8s.8.3.8.8v1.5c0 .4-.4.7-.8.7zM12 18.8c-.4 0-.8-.3-.8-.8v-1.5c0-.4.3-.8.8-.8s.8.3.8.8V18c0 .4-.4.8-.8.8z" />
    </svg>
  );
}

export default SvgCellBorderFrame;
