import * as React from 'react';

function SvgHome(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <g
        stroke="#262338"
        strokeWidth={1.5}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M3.098 8.4v12.35h4.195v-6c0-.828.626-1.5 1.398-1.5h1.399c.772 0 1.398.672 1.398 1.5v6h9.789V8.4" />
        <path d="M22.675 9.5l-9.661-7.6a1.323 1.323 0 00-1.654 0L1.7 9.5" />
        <path d="M15.683 4V1.25h4.195V7.3m-5.593 5.95h4.195v4.5h-4.195zM1.7 20.75h20.975" />
      </g>
    </svg>
  );
}

export default SvgHome;
