import * as React from 'react';

function SvgELearningExchange(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M12 16.486a.738.738 0 01-.238-.039l-2.811-.938a.748.748 0 01-.513-.711v-3.209l-1.313-.438v2.135a.75.75 0 01-1.5 0v-3.175a.72.72 0 01.032-.21l.013-.042a.754.754 0 01.122-.217.675.675 0 01.148-.14.733.733 0 01.198-.102l5.625-1.875a.736.736 0 01.474-.001L17.863 9.4a.748.748 0 010 1.422l-2.299.766v3.209a.748.748 0 01-.513.711l-2.813.938a.717.717 0 01-.238.04zm0-1.541l2.063-.688v-2.169l-1.826.609a.76.76 0 01-.475 0l-1.824-.608v2.168l2.062.688zm0-3.75l3.253-1.084L12 9.027l-3.253 1.084L12 11.195z" />
      <path d="M20.953 13.234a.752.752 0 01-.715-.791C20.49 7.901 17 4 12.458 3.748a8.208 8.208 0 00-5.426 1.654h1.256a.75.75 0 010 1.5H5.106a.731.731 0 01-.261-.048c-.014-.005-.028-.01-.04-.016a.738.738 0 01-.392-.401c-.003-.007-.007-.016-.009-.025a.734.734 0 01-.048-.26V2.97a.75.75 0 011.5 0v1.448a9.69 9.69 0 016.685-2.167c5.368.298 9.492 4.908 9.195 10.275a.75.75 0 01-.783.708zM18.894 21.751a.75.75 0 01-.75-.75v-1.447a9.69 9.69 0 01-6.684 2.167 9.69 9.69 0 01-6.726-3.234 9.683 9.683 0 01-2.469-7.042.75.75 0 01.749-.708l.042.001a.744.744 0 01.517.249.744.744 0 01.19.542 8.2 8.2 0 002.089 5.959 8.194 8.194 0 006.154 2.749 8.188 8.188 0 004.964-1.667h-1.258a.75.75 0 010-1.5h3.181a.75.75 0 01.531.218.732.732 0 01.176.28.71.71 0 01.045.249v3.185a.752.752 0 01-.751.749z" />
    </svg>
  );
}

export default SvgELearningExchange;
