import * as React from 'react';

function SvgLayoutColumn(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M4.7 22.75H3.3c-1.111 0-2.05-.939-2.05-2.05V3.3c0-1.111.938-2.05 2.05-2.05h1.5c1.039 0 1.95.958 1.95 2.05v17.3c0 1.185-.92 2.15-2.05 2.15zm-1.4-20a.572.572 0 00-.55.55v17.4c0 .288.262.55.55.55h1.4c.265 0 .55-.249.55-.65V3.3c0-.298-.276-.55-.45-.55H3.3zM12.7 22.65h-1.5c-1.111 0-2.05-.94-2.05-2.05V3.3c0-.036.002-.071.007-.107.156-1.09 1.097-1.943 2.143-1.943h1.5c1.039 0 1.95.958 1.95 2.05v17.3c0 1.11-.938 2.05-2.05 2.05zM10.65 3.363V20.6c0 .288.262.55.55.55h1.5c.288 0 .55-.262.55-.55V3.3c0-.298-.276-.55-.45-.55h-1.5c-.288 0-.582.282-.65.613zM20.7 22.65h-1.5c-1.111 0-2.05-.94-2.05-2.05V3.3c0-.035.003-.071.008-.106.155-1.09 1.096-1.944 2.142-1.944h1.5c1.039 0 1.95.958 1.95 2.05v17.3c0 1.11-.939 2.05-2.05 2.05zM18.65 3.362V20.6c0 .288.262.55.55.55h1.5c.288 0 .55-.262.55-.55V3.3c0-.298-.276-.55-.45-.55h-1.5c-.288 0-.582.281-.65.612z" />
    </svg>
  );
}

export default SvgLayoutColumn;
