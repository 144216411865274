import * as React from 'react';

function SvgTaskListCheck(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M10.142 17.842a1.56 1.56 0 01-1.108-.458l-1.877-1.878c-.142-.142-.219-.33-.219-.531s.078-.389.22-.53a.746.746 0 011.06.001l1.872 1.873c.028.024.043.024.052.024a.066.066 0 00.048-.02l5.593-5.593a.744.744 0 011.06 0c.142.141.22.33.22.53s-.078.389-.22.53l-5.592 5.592c-.295.296-.689.46-1.108.46h-.001z" />
      <path d="M4.5 24.01a2.252 2.252 0 01-2.25-2.25V6.01A2.252 2.252 0 014.5 3.76h3.063C7.924 1.631 9.81.01 12 .01c2.19 0 4.076 1.621 4.437 3.75H19.5a2.252 2.252 0 012.25 2.25v15.75a2.252 2.252 0 01-2.25 2.25h-15zm0-18.75a.75.75 0 00-.75.75v15.75c0 .414.336.75.75.75h15a.75.75 0 00.75-.75V6.01a.75.75 0 00-.75-.75h-3.75a.75.75 0 01-.75-.75c0-1.654-1.346-3-3-3s-3 1.346-3 3a.75.75 0 01-.75.75H4.5z" />
      <path d="M12 5.26a1.116 1.116 0 01-.908-.46 1.121 1.121 0 01-.204-.837 1.133 1.133 0 011.284-.939c.297.046.558.205.735.447.178.243.25.54.204.837A1.132 1.132 0 0112 5.26z" />
    </svg>
  );
}

export default SvgTaskListCheck;
