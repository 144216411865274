import * as React from 'react';

function SvgDesignFileIndd1(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M5.247 24a.75.75 0 01-.75-.75v-7.5a.75.75 0 011.5 0v7.5a.75.75 0 01-.75.75zM11.247 24a.745.745 0 01-.696-.472l-1.554-3.884v3.606a.75.75 0 01-1.5 0v-7.5a.75.75 0 011.446-.279l1.554 3.884V15.75a.75.75 0 011.5 0v7.5a.75.75 0 01-.75.75zM20.247 24a.75.75 0 01-.75-.75v-7.5a.75.75 0 01.75-.75 3.754 3.754 0 013.75 3.75v1.5a3.754 3.754 0 01-3.75 3.75zm.75-1.629a2.264 2.264 0 001.5-2.121v-1.5c0-.962-.621-1.808-1.5-2.121v5.742zM14.247 24a.75.75 0 01-.75-.75v-7.5a.75.75 0 01.75-.75 3.754 3.754 0 013.75 3.75v1.5a3.754 3.754 0 01-3.75 3.75zm.75-1.629a2.264 2.264 0 001.5-2.121v-1.5c0-.962-.621-1.808-1.5-2.121v5.742z" />
      <path d="M2.247 24a2.252 2.252 0 01-2.25-2.25V2.25A2.252 2.252 0 012.247 0h10.629c.601 0 1.166.234 1.591.658l5.871 5.872c.425.425.659.99.659 1.59v4.629a.75.75 0 01-1.5 0V9h-5.25a2.252 2.252 0 01-2.25-2.25V1.5h-9.75a.75.75 0 00-.75.75v19.5c0 .414.336.75.75.75a.75.75 0 010 1.5zm11.25-17.25c0 .414.336.75.75.75h4.939l-5.689-5.69v4.94z" />
    </svg>
  );
}

export default SvgDesignFileIndd1;
