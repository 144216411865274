import * as React from 'react';

function SvgBusinessContractHandshakeSign(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M2.25 24A2.252 2.252 0 010 21.75v-18A3.754 3.754 0 013.75 0h13.511a3.015 3.015 0 012.989 2.998V4.5a.75.75 0 01-.75.75h-3.75v4.5a.75.75 0 01-1.5 0V3a2.978 2.978 0 01.407-1.5H3.75A2.252 2.252 0 001.5 3.75v18c0 .414.336.75.75.75h1.435a.75.75 0 010 1.5H2.25zm16.5-20.25V3a1.51 1.51 0 00-1.501-1.5 1.51 1.51 0 00-1.499 1.502v.748h3z" />
      <path d="M3.75 5.25a.75.75 0 010-1.5h6.75a.75.75 0 010 1.5H3.75zM3.75 8.25a.75.75 0 010-1.5h6.75a.75.75 0 010 1.5H3.75zM3.75 11.25a.75.75 0 010-1.5h3.359a.75.75 0 010 1.5H3.75zM13.47 23.964c-.421 0-.829-.172-1.125-.473l-2.798-2.135h-.933a1.896 1.896 0 01-1.605.749l-.259.001a.75.75 0 010-1.5h.3l.044.376v-.375a.396.396 0 00.407-.367L7.5 14.927c-.011-.164-.19-.323-.391-.323l-.038.001h-.32a.749.749 0 11-.001-1.499h.3l.08-.002c.5 0 .979.208 1.33.569l3.011-1.277a2.57 2.57 0 01.962-.188c.483 0 .949.136 1.356.396l2.115-1.056a2.717 2.717 0 012.192-.126l4.387 1.729a1.823 1.823 0 01.506-.043l.259-.001a.751.751 0 01.001 1.5h-.3l-.037-.001a.436.436 0 00-.093.011.721.721 0 01-.135.055.378.378 0 00-.186.301l.001 5.313c.011.167.186.323.389.323l.041-.001h.32a.75.75 0 010 1.5h-.3a1.88 1.88 0 01-1.927-1.586l-3.718.744-2.827 2.334a1.584 1.584 0 01-1.007.364zM9.8 19.856c.163 0 .325.055.455.154l3.049 2.326a.748.748 0 01.105.098.082.082 0 00.061.029.075.075 0 00.051-.019l2.91-2.403a.74.74 0 01.114-.094l1.237-1.021a.09.09 0 00.021-.115l-1.964-2.184-.369.156a2.165 2.165 0 01-1.719-.021 2.25 2.25 0 01-1.178-1.252 2.248 2.248 0 01.063-1.782 1.038 1.038 0 00-.594.054l-3.044 1.291L9 19.856h.8zm9.146-2.017a1.582 1.582 0 01.293 1.509L21 18.995v-4.068c.014-.245.069-.475.161-.686l-3.602-1.419a1.233 1.233 0 00-.976.063l-2.268 1.132a.768.768 0 00-.342.957.744.744 0 00.389.418.68.68 0 00.528.006l2.255-.952a.75.75 0 01.584 1.382l-.429.181 1.646 1.83z" />
    </svg>
  );
}

export default SvgBusinessContractHandshakeSign;
