import * as React from 'react';

function SvgPhoneHangup(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M1.6 8.6c.7-.7 1.6-1.2 2.5-1.4 5.2-1 10.5-1 15.7 0 2.4.5 4 2.6 4 5v.9c0 .6-.2 1.1-.6 1.5s-.9.6-1.5.6h-3.8c-1.2 0-2.1-.9-2.1-2.1 0-.5-.4-.9-.9-.9h-6c-.2 0-.4.1-.6.3-.2.2-.3.4-.3.6 0 .6-.2 1.1-.6 1.5s-.9.6-1.4.6H2.2C1 15.2.1 14.3.1 13.1v-.9c0-1.4.5-2.6 1.5-3.6zm2.8-.2c-.8.2-1.4.5-1.9 1.1-.7.7-1.1 1.7-1.1 2.8v.9c0 .5.4.9.9.9L6 14c.2 0 .5-.1.6-.3.2-.2.3-.4.3-.6 0-.6.2-1.1.6-1.5.4-.4.9-.6 1.5-.6h6c1.2 0 2.1.9 2.1 2.1 0 .5.4.9.9.9h3.7c.2 0 .5-.1.6-.3.2-.2.3-.4.3-.6v-.9c0-1.8-1.3-3.4-3.1-3.8-4.9-1-10.1-1-15.1 0z" />
    </svg>
  );
}

export default SvgPhoneHangup;
