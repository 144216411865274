import * as React from 'react';

function SvgAlarmBellSleep(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M20.25 5.251a.754.754 0 01-.676-.425.752.752 0 01.091-.793l2.025-2.531h-1.44a.75.75 0 010-1.5h3c.287 0 .552.167.676.425a.752.752 0 01-.091.793L21.81 3.751h1.439a.75.75 0 010 1.5H20.25zM14.25 12.001a.751.751 0 01-.576-1.231l2.725-3.27H14.25a.75.75 0 010-1.5H18a.751.751 0 01.576 1.231l-2.725 3.27H18a.75.75 0 010 1.5h-3.75zM9.747 24.001a2.835 2.835 0 01-2.718-2.039.751.751 0 01.72-.961c.33 0 .626.221.719.538a1.34 1.34 0 001.66.906c.438-.129.777-.468.906-.906a.755.755 0 01.93-.509.751.751 0 01.51.931 2.826 2.826 0 01-1.923 1.923 2.834 2.834 0 01-.804.117z" />
      <path d="M.75 19.501a.748.748 0 01-.633-1.152c.011-.018 1.383-2.346 1.383-7.848A8.237 8.237 0 019 2.285V.751a.75.75 0 011.5 0v1.54a8.259 8.259 0 012.794.786.753.753 0 01.346 1.003.746.746 0 01-1.003.346 6.706 6.706 0 00-2.89-.674C6.027 3.751 3 6.779 3 10.501c0 3.812-.609 6.207-1.087 7.5h15.623a10.735 10.735 0 01-.779-2.812.746.746 0 01.642-.844.751.751 0 01.844.643c.162 1.2.552 2.34 1.156 3.388a.75.75 0 01-.649 1.125h-18z" />
    </svg>
  );
}

export default SvgAlarmBellSleep;
