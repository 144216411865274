import * as React from 'react';

function SvgCompass1(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M7.882 17.617a1.5 1.5 0 01-1.423-1.975l2.177-6.531a.75.75 0 01.475-.474l6.531-2.177a1.492 1.492 0 011.536.363c.405.404.543.993.362 1.536l-2.177 6.532a.75.75 0 01-.474.474l-6.533 2.177c-.152.05-.311.075-.474.075zm0-1.5l6.177-2.058 2.059-6.177-6.177 2.059-2.059 6.176z" />
      <path d="M12 24a11.922 11.922 0 01-8.43-3.468.343.343 0 01-.099-.099A11.924 11.924 0 010 12a11.92 11.92 0 013.469-8.43.418.418 0 01.098-.098A11.916 11.916 0 0112 0a11.92 11.92 0 018.43 3.468.397.397 0 01.099.099A11.92 11.92 0 0124 12a11.92 11.92 0 01-3.468 8.43.364.364 0 01-.099.098A11.916 11.916 0 0112 24zm0-3.75a.75.75 0 01.75.75v1.47a10.476 10.476 0 006.12-2.54l-1.036-1.036a.744.744 0 010-1.06.749.749 0 011.06 0l1.036 1.036a10.485 10.485 0 002.54-6.12H21a.75.75 0 010-1.5h1.47a10.476 10.476 0 00-2.54-6.12l-1.036 1.036a.744.744 0 01-1.06 0 .744.744 0 010-1.06L18.87 4.07a10.49 10.49 0 00-6.12-2.54V3a.75.75 0 01-1.5 0V1.53a10.476 10.476 0 00-6.12 2.54l1.036 1.036c.142.141.22.33.22.53s-.078.389-.22.53c-.141.142-.33.22-.53.22s-.389-.078-.53-.22L4.069 5.13a10.49 10.49 0 00-2.54 6.12H3a.75.75 0 010 1.5H1.53a10.476 10.476 0 002.54 6.12l1.036-1.036a.744.744 0 011.06 0 .744.744 0 010 1.06L5.13 19.931a10.483 10.483 0 006.12 2.54V21a.75.75 0 01.75-.75z" />
      <path d="M12 13.125a.796.796 0 01-.13-.011 1.127 1.127 0 01-.983-.982.717.717 0 010-.262c.06-.513.47-.922.982-.982a.717.717 0 01.262 0c.513.06.922.47.983.983a.796.796 0 010 .26c-.061.513-.47.923-.983.983a.817.817 0 01-.131.011z" />
    </svg>
  );
}

export default SvgCompass1;
