import * as React from 'react';

function SvgUploadButton(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M12 1c.2 0 .4.1.5.2L19.3 8c.2.2.3.5.2.8-.1.3-.4.5-.7.5h-3v6c0 .8-.7 1.5-1.5 1.5H9.8c-.8 0-1.5-.7-1.5-1.5v-6h-3c-.3 0-.6-.2-.7-.5s-.1-.6.1-.8l6.8-6.8c.1-.1.3-.2.5-.2zm0 1.8l-4.9 5H9c.4 0 .8.3.8.8v6.8h4.5V8.5c0-.4.3-.8.8-.8H17l-5-4.9zM2.3 22.8c-1.2 0-2.2-1-2.2-2.2v-3c0-.4.3-.8.8-.8s.8.3.8.8v3c0 .4.3.8.8.8H22c.4 0 .8-.3.8-.8v-3c0-.4.3-.8.8-.8s.8.3.8.8v3c0 1.2-1 2.2-2.2 2.2H2.3z" />
    </svg>
  );
}

export default SvgUploadButton;
