import * as React from 'react';

function SvgFileCsv(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M2.251 24.004a2.252 2.252 0 01-2.25-2.25v-19.5a2.252 2.252 0 012.25-2.25H12.88c.601 0 1.165.234 1.59.658l5.872 5.871c.425.425.659.99.659 1.592v4.629a.75.75 0 01-1.5 0v-3.75h-5.25a2.252 2.252 0 01-2.25-2.25v-5.25h-9.75a.75.75 0 00-.75.75v19.5c0 .414.336.75.75.75h3a.75.75 0 010 1.5h-3zm11.25-17.25c0 .414.336.75.75.75h4.94l-5.69-5.689v4.939z" />
      <path d="M11.251 24.004a3.754 3.754 0 01-3.75-3.75v-1.5a3.754 3.754 0 013.75-3.75.75.75 0 010 1.5 2.252 2.252 0 00-2.25 2.25v1.5a2.252 2.252 0 002.25 2.25.75.75 0 010 1.5zM14.251 24.004a.75.75 0 010-1.5h1.5a.75.75 0 00.75-.75c0-.715-.434-1.075-1.2-1.65-.802-.602-1.8-1.35-1.8-2.85a2.252 2.252 0 012.25-2.25h1.5a.75.75 0 010 1.5h-1.5a.75.75 0 00-.75.75c0 .715.434 1.075 1.2 1.65.802.602 1.8 1.35 1.8 2.85a2.252 2.252 0 01-2.25 2.25h-1.5zM21.751 24.005a.75.75 0 01-.624-.334 9.654 9.654 0 01-1.626-5.371v-2.546a.75.75 0 011.5 0V18.3c0 1.184.258 2.354.75 3.424.492-1.069.75-2.24.75-3.424v-2.546a.75.75 0 011.5 0V18.3a9.651 9.651 0 01-1.626 5.371.75.75 0 01-.624.334z" />
    </svg>
  );
}

export default SvgFileCsv;
