import * as React from 'react';

function SvgModule(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M12.002 24.001a3.71 3.71 0 01-1.355-.257l-9.1-3.569A2.515 2.515 0 010 17.797V6.186a2.464 2.464 0 01.463-1.492.763.763 0 01.099-.131c.259-.318.59-.57.959-.729L10.643.257a3.663 3.663 0 012.712.001l9.098 3.567c.385.164.709.407.972.725a.77.77 0 01.122.16c.309.443.465.958.453 1.493v11.611a2.493 2.493 0 01-1.521 2.351l-9.122 3.578c-.434.171-.89.258-1.355.258zm.748-1.632a1.41 1.41 0 00.059-.022l9.101-3.569a.996.996 0 00.591-.946V6.186a1.109 1.109 0 00-.006-.142l-9.744 3.82v12.505zM1.505 6.044a.852.852 0 00-.005.124v11.646c-.01.43.232.809.615.972l9.135 3.582V9.864l-9.745-3.82zM12 8.547l9.194-3.605-8.388-3.289a2.18 2.18 0 00-1.612-.001l-8.389 3.29L12 8.547z" />
    </svg>
  );
}

export default SvgModule;
