import * as React from 'react';

function SvgMapsPin1(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M8.25 20.768c-.287 0-.568-.054-.835-.161l-6-2.4A2.24 2.24 0 010 16.117V1.744A1.491 1.491 0 011.5.24c.191 0 .379.036.559.109l5.913 2.366a.747.747 0 00.267.053h.022a.732.732 0 00.268-.054L14.914.159a2.245 2.245 0 011.671 0l6 2.4A2.237 2.237 0 0124 4.648v4.35a.75.75 0 01-1.5 0v-4.35a.747.747 0 00-.471-.697L16.5 1.74v6.508a.75.75 0 01-1.5 0V1.74L9.086 4.106 9 4.139v14.887l2.409-.964a.746.746 0 01.975.417.746.746 0 01-.418.975l-2.881 1.153a2.243 2.243 0 01-.835.161zM1.5 16.117c0 .309.185.582.472.697L7.5 19.025V4.139l-.086-.032-5.913-2.366L1.5 16.117zM18.75 16.948c-.62 0-1.125-.505-1.125-1.125 0-.573.427-1.048.995-1.115a.796.796 0 01.261 0c.513.06.923.47.983.983a.808.808 0 010 .262c-.06.509-.466.918-.974.982a.745.745 0 01-.14.013z" />
      <path d="M18.749 23.998a1.489 1.489 0 01-1.182-.577c-1.855-2.372-4.067-5.616-4.067-7.598 0-2.895 2.355-5.25 5.25-5.25S24 12.927 24 15.822c0 1.979-2.212 5.225-4.068 7.6a1.491 1.491 0 01-1.183.576zm.001-11.926a3.754 3.754 0 00-3.75 3.75c0 1.161 1.437 3.719 3.75 6.676 2.313-2.959 3.75-5.517 3.75-6.676a3.754 3.754 0 00-3.75-3.75z" />
    </svg>
  );
}

export default SvgMapsPin1;
