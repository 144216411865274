import React, { useContext } from 'react';
import styled from '@emotion/styled';
import { SidebarContext } from '@embracesbs/component-sidebar';
import { getExtraProps, withTheme } from '@embracesbs/helpers';

import { propTypes, defaultProps } from './SidebarContent.props';
import { webStyles } from './SidebarContent.styles';

const StyledContent = styled.section`
  ${({ theme, isExpanded }) => webStyles.content(theme, isExpanded)}
`;

/**
 *
 * @param {*} props
 */
const SidebarContent = (props) => {
  const { isCollapsed: isPanelCollapsed, isExpanded } = useContext(
    SidebarContext,
  );
  const { extraProps, content, collapsedContent } = props;

  return (
    <StyledContent
      {...getExtraProps(props, extraProps)}
      isExpanded={isExpanded}
      className={isPanelCollapsed ? 'collapsed-panel' : ''}
    >
      {!isPanelCollapsed && content}
      {isPanelCollapsed && (
        <div role="menubar" aria-label="Applications">
          {collapsedContent}
        </div>
      )}
    </StyledContent>
  );
};

SidebarContent.propTypes = propTypes;
SidebarContent.defaultProps = defaultProps;

/** @component */
export default withTheme(SidebarContent);
