import * as React from 'react';

function SvgFileApp(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M2.241 24.004a2.252 2.252 0 01-2.25-2.25v-19.5a2.252 2.252 0 012.25-2.25H12.87c.601 0 1.166.234 1.591.658l5.869 5.871c.425.425.659.99.659 1.591v.091a.54.54 0 010 .081v4.457a.75.75 0 01-1.5 0v-3.75h-5.248a2.252 2.252 0 01-2.25-2.25v-5.25h-9.75a.75.75 0 00-.75.75v19.5c0 .414.336.75.75.75h3a.75.75 0 010 1.5h-3zm11.25-17.25c0 .414.336.75.75.75h4.938l-5.688-5.69v4.94z" />
      <path d="M11.241 24.004a.75.75 0 01-.75-.75v-2.25h-1.5v2.25a.75.75 0 01-1.5 0v-6c0-1.241 1.009-2.25 2.25-2.25s2.25 1.009 2.25 2.25v6a.75.75 0 01-.75.75zm-.75-4.5v-2.25a.75.75 0 00-1.5 0v2.25h1.5zM20.241 24.004a.75.75 0 01-.75-.75v-7.5a.75.75 0 01.75-.75h.75c1.654 0 3 1.346 3 3s-1.346 3-3 3v2.25a.75.75 0 01-.75.75zm.75-4.5c.827 0 1.5-.673 1.5-1.5s-.673-1.5-1.5-1.5v3zM14.241 24.004a.75.75 0 01-.75-.75v-7.5a.75.75 0 01.75-.75h.75c1.654 0 3 1.346 3 3s-1.346 3-3 3v2.25a.75.75 0 01-.75.75zm.75-4.5c.827 0 1.5-.673 1.5-1.5s-.673-1.5-1.5-1.5v3z" />
    </svg>
  );
}

export default SvgFileApp;
