const baseStyles = {};

export const webStyles = {
  ...baseStyles,
  styledCardContent: (theme, unit) => `
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
    box-sizing: border-box;
    ${theme.textStyle.body};

    .track-vertical {
      position: absolute;
      width: ${unit(6)};
      transition: opacity 200ms ease 0s;
      opacity: 0;
      right: 0;
      bottom: 0;
      top: 0;
      border-radius: ${unit(3)};
      visibility: hidden;
    }
  `,
};

export const nativeStyles = {
  ...baseStyles,
};
