import * as React from 'react';

function SvgSpiderHang(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M9.157 14.35a.749.749 0 01-.616-.322 5.874 5.874 0 01-1.042-3.52 5.895 5.895 0 013.75-5.793V.75a.75.75 0 011.5 0v3.965a5.896 5.896 0 013.751 5.793 5.872 5.872 0 01-1.042 3.52.751.751 0 01-1.044.187.751.751 0 01-.187-1.044A4.36 4.36 0 0015 10.528c-.001-.025 0-.049.001-.074.117-1.965-1.14-3.806-3.001-4.422-1.862.616-3.119 2.457-3.001 4.422a.963.963 0 010 .076 4.357 4.357 0 00.773 2.642.752.752 0 01-.615 1.178z" />
      <path d="M18.752 24.001a.746.746 0 01-.671-.415l-.883-1.766a2.25 2.25 0 00-1.467-1.177l-1.476-.369c-.511.646-1.269.973-2.257.973-.987 0-1.744-.327-2.255-.974l-1.477.369a2.245 2.245 0 00-1.467 1.177l-.88 1.764a.74.74 0 01-.67.417.75.75 0 01-.671-1.084l.88-1.765a3.747 3.747 0 012.445-1.962l1.225-.306a5.743 5.743 0 01-.046-.235l-2.634-.438a2.265 2.265 0 00-1.776.463l-2.704 2.163a.753.753 0 01-1.054-.118.747.747 0 01.117-1.054l2.704-2.163a3.762 3.762 0 012.959-.771l2.316.385.011-.112-3.209-1.375a3.757 3.757 0 01-2.25-3.032l-.558-5.013a.747.747 0 01.746-.833.75.75 0 01.745.667l.557 5.013a2.255 2.255 0 001.35 1.82l3.068 1.314c.233-.431.545-.818.917-1.135a.751.751 0 011.057.085.752.752 0 01-.085 1.058 2.45 2.45 0 00-.737 1.088.732.732 0 01-.051.189 2.462 2.462 0 00-.073.676c0 2.224.976 2.224 1.5 2.224s1.5 0 1.5-2.197a2.525 2.525 0 00-.076-.709l-.001-.003a.657.657 0 01-.046-.174 2.441 2.441 0 00-.737-1.094.752.752 0 01.487-1.321c.178 0 .35.063.486.178.372.317.684.705.917 1.135l3.069-1.314a2.255 2.255 0 001.35-1.82l.557-5.013a.747.747 0 01.83-.662.751.751 0 01.661.828l-.557 5.014a3.757 3.757 0 01-2.25 3.032l-3.21 1.375c.004.037.008.073.01.11l2.317-.386a3.768 3.768 0 012.96.771l2.7 2.163a.751.751 0 01-.469 1.335.755.755 0 01-.469-.165l-2.7-2.163a2.26 2.26 0 00-1.776-.463l-2.634.439c-.013.08-.029.158-.045.236l1.224.306a3.75 3.75 0 012.445 1.961l.883 1.766a.75.75 0 01-.672 1.087z" />
      <path d="M11.999 12.749a2.235 2.235 0 01-1.591-.658 2.254 2.254 0 010-3.181.748.748 0 011.061-.001.751.751 0 01.001 1.061.752.752 0 000 1.061c.141.141.33.219.531.219a.744.744 0 00.53-.219.746.746 0 00.001-1.06.744.744 0 010-1.06c.142-.142.33-.219.53-.219s.389.078.53.22c.425.425.659.99.659 1.591a2.24 2.24 0 01-2.252 2.246z" />
    </svg>
  );
}

export default SvgSpiderHang;
