import * as React from 'react';

function SvgFileCss(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M2.25 24.003A2.252 2.252 0 010 21.753v-19.5A2.252 2.252 0 012.25.003h10.629a2.239 2.239 0 011.591.66l5.872 5.871c.424.425.658.99.658 1.591v4.627a.75.75 0 01-1.5 0v-3.75h-5.25A2.252 2.252 0 0112 6.752v-5.25H2.25a.75.75 0 00-.75.75v19.5c0 .414.336.75.75.75h3a.75.75 0 010 1.5h-3zM13.5 6.753c0 .414.336.75.75.75h4.939L13.5 1.815v4.938z" />
      <path d="M11.25 24.003a3.754 3.754 0 01-3.75-3.75v-1.5a3.754 3.754 0 013.75-3.75.75.75 0 010 1.5A2.252 2.252 0 009 18.753v1.5a2.252 2.252 0 002.25 2.25.75.75 0 010 1.5zM14.25 24.003a.75.75 0 010-1.5h1.5a.75.75 0 00.75-.75c0-.715-.434-1.075-1.2-1.65-.802-.602-1.8-1.35-1.8-2.85a2.252 2.252 0 012.25-2.25h1.5a.75.75 0 010 1.5h-1.5a.75.75 0 00-.75.75c0 .715.434 1.075 1.2 1.65.802.602 1.8 1.35 1.8 2.85a2.252 2.252 0 01-2.25 2.25h-1.5zM20.25 24.003a.75.75 0 010-1.5h1.5a.75.75 0 00.75-.75c0-.715-.434-1.075-1.2-1.65-.802-.602-1.8-1.35-1.8-2.85a2.252 2.252 0 012.25-2.25h1.5a.75.75 0 010 1.5h-1.5a.75.75 0 00-.75.75c0 .715.434 1.075 1.2 1.65.802.602 1.8 1.35 1.8 2.85a2.252 2.252 0 01-2.25 2.25h-1.5z" />
    </svg>
  );
}

export default SvgFileCss;
